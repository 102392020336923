import React from "react";
import { useSelector } from "react-redux";
import { mergeStyles, useTheme, DefaultButton, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const SessionTimeout = () => {
  const visible = useSelector((state) => (state as any).portal.sessionTimeout);
  const [t] = useTranslation();
  const { semanticColors, effects } = useTheme();

  const sessionTimeoutClass = mergeStyles({
    top: "50%",
    left: "50%",
    height: "auto",
    width: "33%",
    backgroundColor: semanticColors.bodyBackground,
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: 10,
    borderRadius: effects.roundedCorner2,
  });

  if (!visible) return null;

  const layers = document.body.querySelectorAll<HTMLElement>(".ms-Layer");

  layers.forEach((e) => {
    e.style.filter = "blur(4px)";
  });

  return (
    <div className={rootClass}>
      <div className={sessionTimeoutClass}>
        <Text variant="medium" block>
          {t("messages.status.yourSessionTimedOutPleaseReload")}
        </Text>
        <DefaultButton onClick={handleRefresh} text={t("buttons.reload")} className={buttonClass} />
      </div>
    </div>
  );
};

export default SessionTimeout;

const handleRefresh = () => {
  window.location.reload();
};

const rootClass = mergeStyles({
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 2000000,
  position: "absolute",
  backgroundColor: "rgba(100,100,100,0.5);",
});

const buttonClass = mergeStyles({
  marginTop: 20,
  float: "right",
});
