export const helpers = {
    validateServer: validateServer,
    getDatabases: getDatabases,
    validateDatabase: validateDatabase,
    validateLogin: validateLogin,
    initPortal: initPortal,
    restartPortal: restartPortal,
    checkDatabase: checkDatabase
}

const headers = {
    'Content-Type': 'application/json',
    'Accept-Type': 'application/json'
};

function validateServer(sql: any) {
    const errors = [];
    if (!sql.server) {
        errors.push("Bitte geben Sie den Namen des SQL-Servers an.")
    }
    // TODO Handle Azure SQL DB properly (either; adjust Caption for 'integratedSecurity' (dynamically?) or ?)
    if (!sql.integratedSecurity && sql.server.toLowerCase().indexOf("database.windows.net") < 0) {
        if (!sql.username || !sql.password) {
            errors.push('Bitte geben Sie einen Benutzernamen und ein Passwort ein. Alternativ verwenden Sie "Integrated Security".')
        }
        if (sql.password !== sql.passwordConfirm) {
            errors.push("Das angegebene Passwort und die Bestätigung stimmen nicht überein. Bitte prüfen Sie Ihre Eingabe.");
        }
    }
    return errors;
};

function restartPortal() {
    return fetch("initportal/restart", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({})
    }).then(handleResponse);
}

function checkDatabase() {
    return fetch("initportal/checkdatabaseinitialized", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({})
    }).then(handleResponse);
};


function getDatabases(sql: any) {
    return fetch("initportal/getsqldatabases", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(sql)
    }).then(handleResponse);
};

function validateDatabase(sql: any) {
    const errors = [];
    if (sql.useExistingDB) {
        if (!sql.database) {
            errors.push("Bitte wählen Sie eine Datenbank aus der Liste der verfügbaren Datenbanken aus.");
        }
    } else {
        if (!sql.dataFilePath) {
            errors.push("Bitte geben Sie ein Datenbank-Verzeichnis für die Portal Datenbank an.");
        }
        if (!sql.logFilePath) {
            errors.push("Bitte geben Sie ein Log-Verzeichnis für die Portal Datenbank an.");
        }
    }
    return errors;
};


function validateLogin(login: any){
    const errors = [];

    if (!login.loginType) {
        errors.push("Bitte geben Sie eine Anmeldeart an.");
    }

    if (login.loginType === "usernamePassword") {
        if (!login.username || !login.password) {
            errors.push("Bitte geben Sie einen Benutzernamen und ein Passwort ein.")
        }
        if (login.password !== login.passwordConfirm) {
            errors.push("Das angegebene Passwort und die Bestätigung stimmen nicht überein. Bitte prüfen Sie Ihre Eingabe.");
        }
    }
    return errors;
};

function initPortal(sqlData: any, loginData: any){
    return fetch("initportal/init", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ sql: sqlData, login: loginData })
    }).then(handleResponse);
};

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        let data = text && JSON.parse(text);

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}