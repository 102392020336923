import * as React from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  TextField,
  Stack,
  ChoiceGroup,
  Label,
} from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSqlField,
  setErrors,
  setLoading,
  setAvailableDatabases,
  setLoginField,
} from "../../store/slices/wizardSlice";
import { helpers } from "../../initializationHelper";
const stackTokens = { childrenGap: 15 };
const listStyle = {
  root: {
    height: "100%",
    overflow: "auto",
  },
};
const overflowHidden = {
  overflow: "hidden",
};
const wrapperDivStyle = {
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};
const columns = [
  {
    key: "name",
    name: "Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "collation",
    name: "Sortierung",
    fieldName: "collation",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "recoveryModel",
    name: "Wiederherstellungsmodell",
    fieldName: "recoveryModel",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
];
const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
  getPageStyle: (page: any) => {
    page.style.paddingTop = 0;
    return page.style;
  },
};
const choiceGroupOptions = [
  { key: "newDB", text: "Neu", iconProps: { iconName: "Add" } },
  { key: "existingDB", text: "Bestehend", iconProps: { iconName: "Database" } },
];
export default () => {
  const sql = useSelector((state) => (state as any).wizard.sql);
  const availableDatabases = useSelector((state) => (state as any).wizard.availableDatabases);
  const [items, setItems] = React.useState(availableDatabases);
  const [selection, setSelection] = React.useState(new Selection());
  selection.setItems(availableDatabases);
  const dispatch = useDispatch();
  const setFilter = (event: any, filterValue: any) => {
    let filteredItems = [...availableDatabases];
    filteredItems = filteredItems.filter((i) => i.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
    setItems(filteredItems);
  };
  const toggleDatabase = async (event: any, option: any) => {
    const checked = option.key === "existingDB";
    dispatch(setSqlField({ name: "useExistingDB", value: checked }));
    dispatch(setErrors([]));
    if (checked) {
      dispatch(setLoading(true));
      helpers.getDatabases(sql).then(
        (response) => {
          if (response.error.length > 0) {
            const e = [];
            e.push(response.error);
            dispatch(setErrors(e));
          } else {
            dispatch(setErrors([]));
          }
          dispatch(setLoading(false));
          selection.setItems(response.databases);
          setItems(response.databases);
          dispatch(setAvailableDatabases(response.databases));
        },
        (error) => {
          dispatch(setLoading(false));
        }
      );
    } else {
      dispatch(setSqlField({ name: "database", value: "" }));
      dispatch(setLoginField({ name: "needsLogin", value: true }));
      selection.setItems([]);
      setItems([]);
      dispatch(setAvailableDatabases([]));
    }
  };
  if (sql.useExistingDB) {
    var index = availableDatabases.findIndex((p: any) => p.name == sql.database);
    if (index >= 0) {
      selection.setIndexSelected(index, true, false);
    }
  }
  const onActiveItemChanged = (item: any) => {
    dispatch(setSqlField({ name: "database", value: item.name }));
    dispatch(setLoginField({ name: "needsLogin", value: item.empty === "1" }));
  };
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ height: string; overflow: string; display:... Remove this comment to see the full error message
    <div style={wrapperDivStyle}>
      <ChoiceGroup
        label="Neue oder bestehende Datenbank verwenden"
        options={choiceGroupOptions}
        onChange={toggleDatabase}
        defaultSelectedKey={sql.useExistingDB ? "existingDB" : "newDB"}
      />
      {!sql.useExistingDB && (
        <Stack horizontal={false} tokens={stackTokens}>
          <Label>
            Bitte geben Sie nachfolgende Verzeichnisse für den Server <b>{sql.server}</b> an, sodass eine neue
            Portal-Datenbank angelegt werden kann. Stellen Sie sicher, dass das Benutzerkonto des SQL-Servers{" "}
            {sql.server} über Schreibrechte auf die angegebenen Verzeichnisse verfügt.
          </Label>
          <TextField
            label="Datenbankname"
            type="text"
            value={sql.newDatabaseName}
            onChange={(e, v) => dispatch(setSqlField({ name: "newDatabaseName", value: v }))}
          />
          <TextField
            label="Datenbank-Verzeichnis"
            type="text"
            value={sql.dataFilePath}
            onChange={(e, v) => dispatch(setSqlField({ name: "dataFilePath", value: v }))}
          />

          <TextField
            label="Log-Verzeichnis"
            type="text"
            value={sql.logFilePath}
            onChange={(e, v) => dispatch(setSqlField({ name: "logFilePath", value: v }))}
          />
        </Stack>
      )}
      {sql.useExistingDB && (
        <Stack horizontal={false} tokens={stackTokens} style={overflowHidden}>
          <Label>
            Auf dem von Ihnen angegebenem Server <b>{sql.server}</b> konnten folgende Datenbanken gefunden werden. Bitte
            wählen Sie eine bestehende Portaldatenbank aus oder erstellen Sie hier eine.
          </Label>
          <TextField label="Suchen:" onChange={setFilter} styles={{ root: { maxWidth: "300px" } }} />
          <DetailsList
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ root: { height: string; overflow: string; ... Remove this comment to see the full error message
            styles={listStyle}
            setKey="items"
            items={items}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selection={selection}
            selectionMode={SelectionMode.single}
            onActiveItemChanged={onActiveItemChanged}
            selectionPreservedOnEmptyClick={true}
            listProps={shimmeredDetailsListProps}
            ariaLabelForShimmer="Content is being fetched"
            ariaLabelForGrid="Item details"
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
          />
        </Stack>
      )}
    </div>
  );
};
