import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { mergeStyles, useTheme } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeDialog } from "../../store/slices/portalSlice";
import Modal from "../helpers/Modal";

const OpenFileDialog = () => {
  const dialog = useSelector((state) => (state as any).portal.fileDialog);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0)
        alert(
          `${t("dialogs.openFile.fileNotAccepted")} ${
            dialog?.filter ? `${t("singleWords.expected")} (${dialog.filter})` : ""
          }`
        );
      if (dialog && acceptedFiles.length > 0) {
        getBase64(acceptedFiles[0]).then(
          (result) => {
            (window as any).portalInterface
              .onFileUpload(dialog.id, acceptedFiles[0].name, result, acceptedFiles[0].type)
              .then((result: any) => {
                dispatch(closeDialog({ type: "openfile", id: dialog.id }));
              });
          },
          (error) => alert(error)
        );
      }
    },
    [dialog]
  );
  const onFileDialogCancel = () => {
    dispatch(closeDialog({ type: "openfile", id: dialog.id }));
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noDrag: false,
    maxFiles: 1,
    multiple: false,
    onFileDialogCancel: onFileDialogCancel,
    accept: dialog ? dialog.filter : "",
  });
  const { semanticColors, palette } = useTheme();
  const modalStyle = {
    width: 300,
    height: 200,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: semanticColors.bodyBackground,
  };
  const dropZoneStyle = mergeStyles({
    flexGrow: 1,
    margin: 10,
    border: `1px dotted ${palette.themePrimary}`,
    cursor: "pointer",
    position: "relative",
  });
  if (!dialog) {
    return null;
  }
  return (
    <Modal
      title={dialog.title}
      style={modalStyle}
      onClose={() => dispatch(closeDialog({ type: "openfile", id: dialog.id }))}
    >
      <div {...getRootProps()} className={dropZoneStyle}>
        <input {...getInputProps()} />
        <p className={textStyle}>
          {!isDragActive && t("dialogs.openFile.clickHereOrDropFile")}
          {isDragActive && t("dialogs.openFile.dropFileToUpload")}
        </p>
      </div>
    </Modal>
  );
};
export default OpenFileDialog;
const textStyle = mergeStyles({
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});
const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
