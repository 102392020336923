import React from "react";
import { mergeStyles, IconButton, Label, getColorFromString, ILabelStyles } from "@fluentui/react";
import { setPlugin, setPluginMenu } from "../../../../store/slices/portalSlice";
import { useDispatch, useSelector } from "react-redux";
import PluginIcon from "../../../navigation/PluginIcon";
import { useId } from "@fluentui/react-hooks";
import { RootState } from "../../../../store/store";
import { themeMapping, themes } from "../../../../utils/themes";

export const PluginTile = (props: any) => {
  const payload = props.json;
  const dispatch = useDispatch();
  const tileId = useId("pluginTile");

  const themeStyle = useSelector((state: RootState) => state.portal.theme.style);
  const appTheme = useSelector(
    (state: RootState) => themeMapping[state.portal.pluginIcons[payload.pluginToken]?.appTheme ?? 0] ?? themeMapping[0]
  );

  const primaryColor =
    themes[themeStyle][appTheme]?.palette?.themePrimary ?? themes.custom.default.palette.themePrimary;
  const color = getColorFromString(primaryColor);

  const buttonStyles = {
    root: {
      width: 100,
      height: 100,

      boxSizing: "border-box",
      boxShadow: color ? `rgb(${color.r} ${color.g} ${color.b} / 0.3) -1px 4px 13px 0px` : undefined,
      borderRadius: 24,
    },
    rootHovered: { backgroundColor: color ? `rgb(${color.r} ${color.g} ${color.b} / 0.05)` : undefined },
    rootPressed: { backgroundColor: color ? `rgb(${color.r} ${color.g} ${color.b} / 0.05)` : undefined },
    rootFocused: {
      backgroundColor: color ? `rgb(${color.r} ${color.g} ${color.b} / 0.05)` : undefined,
      boxShadow: `${primaryColor} 0px 0px 1px 1px`,
      "::after": {
        outline: "none!important",
      },
    },
    flexContainer: {
      flexDirection: "column",
    },
  };

  const changePlugin = () => {
    dispatch(setPlugin({ pluginId: payload.pluginToken }));
    dispatch(setPluginMenu(payload.pluginToken));
  };

  return (
    <div className={itemWrapperClass}>
      <IconButton
        id={tileId}
        onRenderIcon={() => <PluginIcon pluginToken={payload.pluginToken} size={90} />}
        data-debugid={payload.caption}
        styles={buttonStyles}
        onClick={changePlugin}
        title={payload.caption}
      />
      <Label title={payload.caption} htmlFor={tileId} styles={labelStyles}>
        {payload.caption}
      </Label>
    </div>
  );
};

const itemWrapperClass = mergeStyles({
  width: 160,
  height: 160,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const labelStyles: ILabelStyles = {
  root: {
    width: "100%",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    padding: "10px 20px",
  },
};
