import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { setLanguage } from "../../../store/slices/portalSlice";
import { Dropdown } from "@fluentui/react";
import { tenantService } from "../../authorization/tenantService";
import { useTranslation } from "react-i18next";

export default function TenantSettingsGroup(props: any) {
  const [languages, setLanguages] = useState([]);
  const language = useSelector((state: RootState) => state.portal.language);

  const [t, i18n] = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    tenantService.getLanguages().then((languages) => {
      setLanguages(languages);
    });
  }, []);

  const onLanguageChange = (e: any, o: any) => {
    i18n.changeLanguage(o.key);
    document.documentElement.lang = o.key;
    dispatch(setLanguage(o.key));
  };

  return (
    <div style={{ marginBottom: 8 }}>
      <Dropdown
        data-debugid="selectLanguage"
        placeholder={t("dialogs.tenantsettingscard.language.placeholder")}
        label={t("dialogs.tenantsettingscard.language.label")}
        options={languages}
        selectedKey={language}
        onChange={onLanguageChange}
      />
    </div>
  );
}
