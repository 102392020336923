import { pluginService } from "../components/authorization/pluginService";
import store from "../store/store";
import { changePortalSiteTitle } from "../store/slices/portalSlice";
export const portalInterface = {
  init: () => {
    window.portalInterface = {
      openPlugin: pluginService.openPlugin,
      getPageMetadata: pluginService.getPageMetadata,
      pageActionClicked: pluginService.pageActionClicked,
      getPageData: pluginService.getPageData,
      closePage: pluginService.pageClosed,
      pageValueChanged: pluginService.pageValueChanged,
      confirm: pluginService.confirm,
      loadCustomComponent: pluginService.loadCustomComponent,
      customRequest: pluginService.customRequest,
      refreshPageData: pluginService.refreshPageData,
      onFileUpload: pluginService.onFileUpload,
      filterPage: pluginService.filterPage,
      sortPage: pluginService.sortPage,
      pageLookup: pluginService.pageLookup,
      pageAssistEdit: pluginService.pageAssistEdit,
      getTenantSettingValue: pluginService.getTenantSettingValue,
      pageDrilldown: pluginService.pageDrilldown,
      pageCloseAction: pluginService.pageCloseAction,
      changeActiveRecord: pluginService.changeActiveRecord,
      getPageCustomization: pluginService.getPageCustomization,
      loadMoreRecords: pluginService.lazyLoad,
      saveColumnWidths: pluginService.saveColumnWidths,
      setPageStateCustomization: pluginService.setPageStateCustomization,
      setControlStateCustomization: pluginService.setControlStateCustomization,
      reloadPageMetaData: pluginService.reloadPageMetaData,
      savePageCustomization: pluginService.savePageCustomization,
      downloadDebugInfo: pluginService.downloadDebugInfo,
      selectAll: pluginService.selectAll,
      changePortalSiteTitle: (portalSiteTitleObject: any) =>
        store.dispatch(changePortalSiteTitle({ portalSiteTitleObject })),
    };
  },
};
