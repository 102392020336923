import React, { useContext } from "react";
import {
  View,
  StyleSheet,
  // @ts-expect-error ts-migrate(2305) FIXME: Module '"../../reactnative"' has no exported membe... Remove this comment to see the full error message
  Dimensions,
} from "../../reactnative";
import { InputContext } from "../../utils/context";
import { Registry } from "../registration/registry";
import * as Utils from "../../utils/util";
import * as Constants from "../../utils/constants";
import * as Enums from "../../utils/enums";
import { SelectAction } from "../actions";
import { HostConfigManager } from "../../utils/host-config";
import { StyleManager } from "../../styles/style-config";
import { ContainerWrapper } from "./";

//const deviceWidth = Dimensions.get('window').width;

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
const styleConfig = StyleManager.getManager().styles;
const hostConfig = HostConfigManager.getHostConfig();

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const Column = (props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onParseError' does not exist on type 'un... Remove this comment to see the full error message
  const { onParseError } = useContext(InputContext);
  let spacing = 0;

  const column = props.json;

  const parsePayload = () => {
    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'children' implicitly has type 'any[]' in... Remove this comment to see the full error message
    let children = [];
    if (!column)
      // @ts-expect-error ts-migrate(7005) FIXME: Variable 'children' implicitly has an 'any[]' type... Remove this comment to see the full error message
      return children;

    if (column.isFallbackActivated) {
      if (column.fallbackType == "drop") {
        return null;
      } else if (!Utils.isNullOrEmpty(column.fallback)) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        return Registry.getManager().parseComponent(column.fallback, onParseError);
      }
    }
    // parse elements
    if (!Utils.isNullOrEmpty(column.items) && column.isVisible !== false) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      children = Registry.getManager().parseRegistryComponents(column.items, onParseError);
    }

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'ChildElement' implicitly has an 'any' t... Remove this comment to see the full error message
    return children.map((ChildElement, index) =>
      React.cloneElement(ChildElement, {
        containerStyle: column.style,
        isFirst: index === 0,
        columnWidth: column.width,
      })
    );
  };

  const isForemostElement = () => {
    return props.columns[0] === column;
  };

  const renderSeparator = () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const { lineColor, lineThickness } = hostConfig.separator;
    const margin = (spacing - lineThickness) / 2;
    if (!isForemostElement()) {
      return (
        <View
          style={{
            borderLeftWidth: lineThickness,
            borderLeftColor: lineColor,
            marginLeft: margin,
            marginRight: margin,
          }}
        />
      );
    } else {
      return null;
    }
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'containerViewStyle' implicitly has an '... Remove this comment to see the full error message
  const flex = (containerViewStyle) => {
    var flex = 0;
    var columns = props.columns;

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'column' implicitly has an 'any' type.
    const widthArray = columns.map((column) => column.width);

    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'sizeValues' implicitly has type 'any[]' ... Remove this comment to see the full error message
    var sizeValues = [];

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
    widthArray.map((value) => {
      if (Utils.isaNumber(value)) {
        sizeValues.push(value);
      }
    });

    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'sizeValues' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    var minValue = Math.min.apply(null, sizeValues);

    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'sizeValues' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    var maxValue = Math.max.apply(null, sizeValues);
    if (Utils.isaNumber(column.width)) {
      flex = column.width / maxValue;
    } else if (!column || column.width === "auto") {
      if (sizeValues.length == 0) {
        containerViewStyle.push({ flexWrap: "wrap" });
      } else {
        flex = 0; // minValue / maxValue
      }
    } else if (column.width === undefined || column.width === "stretch") {
      flex = 1;
    }
    return flex;
  };

  const { isFirst, isLast } = props;
  const separator = column.separator || false;
  let containerViewStyle = [
    {
      flexDirection: separator ? Constants.FlexRow : Constants.FlexColumn,
    },
  ];

  const spacingEnumValue = Utils.parseHostConfigEnum(Enums.Spacing, column.spacing, Enums.Spacing.Default);

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  spacing = hostConfig.getEffectiveSpacing(spacingEnumValue);

  let spacingStyle = [];
  if (!isForemostElement() && column.spacing) {
    spacingStyle.push({ marginLeft: spacing });
  }
  spacingStyle.push({ flexGrow: 1 });

  if (Utils.isPixelValue(column.width) && Utils.isaNumber(column.width)) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ width: number; }' is not assig... Remove this comment to see the full error message
    containerViewStyle.push({ width: parseInt(column.width) });
  } else {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ flex: number; }' is not assign... Remove this comment to see the full error message
    containerViewStyle.push({ flex: flex(containerViewStyle) });
  }

  let ActionComponent = React.Fragment;
  let actionComponentProps = {};

  // select action
  if (!Utils.isNullOrEmpty(column.selectAction) && HostConfigManager.supportsInteractivity()) {
    // @ts-expect-error ts-migrate(2741) FIXME: Property '$$typeof' is missing in type '(props: an... Remove this comment to see the full error message
    ActionComponent = SelectAction;
    actionComponentProps = { selectActionData: column.selectAction };
  }

  let separatorStyles = [spacingStyle];

  if (separator) {
    separatorStyles = [containerViewStyle, styles.separatorStyle];
  }

  return (
    <ContainerWrapper
      json={column}
      hasBackgroundImage={props.hasBackgroundImage}
      isFirst={isFirst}
      isLast={isLast}
      style={[containerViewStyle]}
      containerStyle={props.containerStyle}
    >
      <ActionComponent {...actionComponentProps}>
        {separator && renderSeparator()}
        <View style={separatorStyles}>{parsePayload()}</View>
      </ActionComponent>
    </ContainerWrapper>
  );
};

const styles = StyleSheet.create({
  separatorStyle: {
    flexDirection: Constants.FlexColumn,
    flexGrow: 1,
  },
  defaultBGStyle: {
    backgroundColor: Constants.TransparentString,
  },
});
