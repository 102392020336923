import React from "react";
import { useSelector } from "react-redux";
import { css, Icon, Label, mergeStyles, useTheme } from "@fluentui/react";
import { themes } from "../../../utils/themes";
import { RootState, useAppDispatch } from "../../../store/store";
import { setThemeColor } from "../../../store/slices/portalSlice";

const ThemeColorPicker = () => {
  const themeStyle = useSelector((state: RootState) => state.portal.theme.style);
  const themeColor = useSelector((state: RootState) => state.portal.theme.color);
  const dispatch = useAppDispatch();

  const { palette, effects } = useTheme();

  const colorStyle = mergeStyles({
    boxSizing: "border-box",
    width: 42,
    height: 42,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: effects.roundedCorner2,
    margin: 2,
    cursor: "pointer",
    ":hover": { filter: "brightness(0.8)" },
  });

  const appThemeStyle = mergeStyles({ border: `${themeColor === "appTheme" ? 3 : 2}px solid ${palette.themePrimary}` });

  return (
    <div>
      <Label>Design</Label>
      <div className={colorContainerStyle}>
        <div className={css(colorStyle, appThemeStyle)} onClick={() => dispatch(setThemeColor("appTheme"))}>
          <Icon styles={{ root: { color: palette.themePrimary, fontSize: 22 } }} iconName="Cancel" />
        </div>
        {Object.keys(themes[themeStyle]).map((color) => (
          <div
            key={color}
            className={colorStyle}
            style={{ backgroundColor: themes[themeStyle][color].palette.themePrimary }}
            onClick={() => dispatch(setThemeColor(color))}
          >
            {themeColor === color && <Icon styles={{ root: { color: "white", fontSize: 22 } }} iconName="SkypeCheck" />}
          </div>
        ))}
      </div>
    </div>
  );
};

const colorContainerStyle = mergeStyles({ display: "flex", flexWrap: "wrap", margin: "0 8px 8px" });

export default ThemeColorPicker;
