import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  ContextualMenu,
  DirectionalHint,
  ICalloutProps,
  Icon,
  IContextualMenuItem,
  IContextualMenuListProps,
  IFontStyles,
  IRenderFunction,
  ITextStyles,
  mergeStyles,
  mergeStyleSets,
  SearchBox,
  Text,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

interface FilterableDropdownProperties {
  buttonText: string;
  dropdownTitle?: string | ReactNode;
  items: IContextualMenuItem[];
  searchBoxPlaceholder?: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
  allowDropdown?: boolean;
  buttonTextVariant?: keyof IFontStyles;
  debugId?: string;
}

const FilterableDropdown = (props: FilterableDropdownProperties) => {
  const {
    buttonText,
    dropdownTitle,
    items,
    searchBoxPlaceholder = "Suchen",
    visible,
    setVisible,
    allowDropdown = true,
    buttonTextVariant = "large",
    debugId,
  } = props;

  const [t] = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string | undefined>("");

  useEffect(() => {
    if (visible) setSearchTerm("");
  }, [visible]);

  const filteredItems = searchTerm
    ? items.filter((item) => item.text?.toLowerCase().includes(searchTerm.toLowerCase()))
    : items;

  if (filteredItems.length === 0) filteredItems.push({ key: "empty" });
  filteredItems.sort((item) => (item.checked ? -1 : 1));

  const renderMenuList: IRenderFunction<IContextualMenuListProps> = (props, defaultRender) => {
    return (
      <div className={classNames.menuListWrapper} data-debugid={`${debugId ?? "Dropdown"}Menu`}>
        <div className={classNames.spacer} />
        <div>{dropdownTitle}</div>
        <div style={{ paddingBlock: "0.5em 1em" }}>
          <SearchBox
            data-debugid={`search${debugId ?? "Dropdown"}`}
            value={searchTerm}
            onChange={(_e, newValue) => setSearchTerm(newValue ?? "")}
            onClear={() => setSearchTerm("")}
            placeholder={searchBoxPlaceholder}
            showIcon={true}
            clearButtonProps={{ styles: { root: { display: "none" } } }}
          />
        </div>
        {defaultRender && defaultRender(props)}
        <div className={classNames.spacer} />
      </div>
    );
  };

  const renderContextualMenuItem: IRenderFunction<IContextualMenuItem> = (props, defaultRender) => {
    if (props?.key === "empty" || !defaultRender) return <div>{t("messages.status.noResultFound")}</div>;

    return defaultRender();
  };

  const textWrapperClass = mergeStyles({
    cursor: allowDropdown ? "pointer" : "default",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
  });

  return (
    <div className={classNames.dropdownWrapper} ref={wrapperRef}>
      <div
        onClick={() => allowDropdown && setVisible(!visible)}
        className={textWrapperClass}
        data-debugid={`open${debugId ?? "Dropdown"}`}
      >
        <Text block styles={buttonTextStyles} variant={buttonTextVariant}>
          {buttonText}
        </Text>
        {allowDropdown && <Icon iconName={visible ? "CaretSolidDown" : "CaretSolidDown"} styles={dropdownIconStyles} />}
      </div>
      <ContextualMenu
        items={filteredItems}
        hidden={!visible || !allowDropdown}
        onDismiss={() => setVisible(false)}
        directionalHint={DirectionalHint.bottomLeftEdge}
        directionalHintFixed
        target={wrapperRef.current}
        useTargetAsMinWidth
        onRenderMenuList={renderMenuList}
        onRenderContextualMenuItem={renderContextualMenuItem}
        calloutProps={calloutProps}
        styles={contextualMenuStyles}
      />
    </div>
  );
};

export default FilterableDropdown;

const classNames = mergeStyleSets({
  dropdownWrapper: {
    color: "white",
    marginLeft: 20,
    marginRight: 20,
    overflow: "hidden",
  },
  menuListWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0em 2em",
    boxSizing: "border-box",
    height: "100%",
  },
  spacer: {
    flex: "0 0 1em",
  },
});

const calloutProps: ICalloutProps = {
  preventDismissOnEvent: (ev) => ev.type === "resize",
  styles: { root: { maxHeight: "38vh!important" }, calloutMain: { overflow: "hidden" } },
};
const contextualMenuStyles: any = {
  container: { height: "100%" },
  root: { height: "100%" },
  list: { overflow: "auto" },
};

const buttonTextStyles: ITextStyles = {
  root: { color: "white", whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" },
};
const dropdownIconStyles = { root: { margin: "0.25em 0.5em", fontSize: 12 } };
