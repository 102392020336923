import {BaseModel} from './base-model'
import { ElementType } from '../utils/enums'


export class TextBlockModel extends BaseModel {
    color: any;
    fontStyle: any;
    horizontalAlignment: any;
    isSubtle: any;
    maxLines: any;
    size: any;
    text: any;
    weight: any;
    wrap: any;
    type = ElementType.TextBlock;

    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.text = payload.text;
        this.color = payload.color;
        this.horizontalAlignment = payload.horizontalAlignment;
        this.isSubtle = payload.isSubtle || false;
        this.maxLines = payload.maxLines;
        this.size = payload.size;
        this.weight = payload.weight;
        this.wrap = payload.wrap || false;
        this.fontStyle = payload.fontStyle;
    }
}

export class ImageModel extends BaseModel {
    altText: any;
    backgroundColor: any;
    height: any;
    horizontalAlignment: any;
    size: any;
    style: any;
    url: any;
    width: any;
    type = ElementType.Image;

    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.url = payload.url;
        this.altText = payload.altText;
        this.horizontalAlignment = payload.horizontalAlignment;
        this.size = payload.size;
        this.style = payload.style;
        this.backgroundColor = payload.backgroundColor;
        this.size = payload.size;
        this.width = payload.width;
        this.height = payload.height;
    }
}

export class MediaModel extends BaseModel {
    altText: any;
    poster: any;
    type = ElementType.Media;
    sources = [];

    constructor(payload: any, parent: any) {
        super(payload, parent);

        if (payload.sources) {
            payload.sources.forEach((item: any) => {
                if (item) {

                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                    this.sources.push(item);
                }
            });
        }
        this.poster = payload.poster;
        this.altText = payload.altText;
    }
}

export class RichTextBlockModel extends BaseModel {
    color: any;
    fontStyle: any;
    horizontalAlignment: any;
    isSubtle: any;
    maxLines: any;
    paragraphs: any;
    size: any;
    text: any;
    weight: any;
    wrap: any;
    type = ElementType.RichTextBlock;

    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.text = payload.text;
        this.color = payload.color;
        this.horizontalAlignment = payload.horizontalAlignment;
        this.isSubtle = payload.isSubtle || false;
        this.maxLines = payload.maxLines;
        this.size = payload.size;
        this.weight = payload.weight;
        this.wrap = payload.wrap || false;
        this.paragraphs = payload.paragraphs;
        this.fontStyle = payload.fontStyle;
    }
}

