import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slices/portalSlice";
import PortalDialog from "./PortalDialog";
const MessageDialog = () => {
  const messages = useSelector((state) => (state as any).portal.messageDialogs);
  const dispatch = useDispatch();
  if (messages.length === 0) {
    return null;
  }
  const renderMessages = () => {
    return messages.map((message: any, index: any) => {
      return (
        <PortalDialog
          key={`MESSAGEDIALOG_${index}`}
          buttonId="messageOk"
          dialogId={`message-${index + 1}`}
          title={message.title}
          icon="error"
          msg={message.msg}
          onClose={() => dispatch(closeDialog({ type: "msg", id: message.id }))}
        />
      );
    });
  };
  return <React.Fragment>{renderMessages()}</React.Fragment>;
};
export default MessageDialog;
