import React, { useContext, useState, useEffect } from "react";
import {

  View,
  StyleSheet,

  Image,
} from "../../reactnative";

import * as Constants from "../../utils/constants";
import * as Enums from "../../utils/enums";
import * as Utils from "../../utils/util";
import { InputContext } from "../../utils/context";

type Props = {
  backgroundImage: any;
};

export const BackgroundImage = (props: Props) => {
  const [backgroundImageHeight, setBackgroundImageHeight] = useState(0);
  const [backgroundImageWidth, setBackgroundImageWidth] = useState(0);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onParseError' does not exist on type 'un... Remove this comment to see the full error message
  const { onParseError } = useContext(InputContext);
  const backgroundImage = props.backgroundImage;
  let backgroundImageModeEnumValue: any = undefined;
  let verticalAlignmentEnumValue: any = undefined;
  let horizontalAlignmentEnumValue: any = undefined;

  useEffect(() => {
    getSize(backgroundImage.url, (backgroundImageWidth: any, backgroundImageHeight: any) => {
      setBackgroundImageHeight(backgroundImageHeight);
      setBackgroundImageWidth(backgroundImageWidth);
    });
  }, []);

  const getSize = (url: any, callback: any) => {
    var img = document.createElement("img");
    img.addEventListener("load", function () {

      // @ts-expect-error ts-migrate(2551) FIXME: Property 'Width' does not exist on type 'HTMLImage... Remove this comment to see the full error message
      callback(this.Width, this.Height);
    });
    img.src = url;
  };

  const parseHostConfig = () => {
    backgroundImageModeEnumValue = Utils.parseHostConfigEnum(
      Enums.BackgroundImageMode,
      backgroundImage.fillMode || backgroundImage.mode,
      Constants.AlignStretch
    );
    verticalAlignmentEnumValue = Utils.parseHostConfigEnum(
      Enums.VerticalAlignment,
      backgroundImage.verticalAlignment,
      Constants.TopString
    );
    horizontalAlignmentEnumValue = Utils.parseHostConfigEnum(
      Enums.HorizontalAlignment,
      backgroundImage.horizontalAlignment,
      Constants.LeftAlign
    );
  };

  const onError = (onParseError: any) => {
    let error = { error: Enums.ValidationError.InvalidPropertyValue, message: `Not able to load the image` };
    onParseError(error);
  };

  const getImageVerticalAlignment = () => {
    switch (verticalAlignmentEnumValue) {
      case Enums.VerticalAlignment.Bottom:
        return { justifyContent: Constants.FlexEnd };
      case Enums.VerticalAlignment.Center:
        return { justifyContent: Constants.CenterString };
      case Enums.VerticalAlignment.Top:
      default:
        return { justifyContent: Constants.FlexStart };
    }
  };

  const getImageHorizontalAlignment = () => {
    switch (horizontalAlignmentEnumValue) {
      case Enums.HorizontalAlignment.Right:
        return { alignItems: Constants.FlexEnd };
      case Enums.HorizontalAlignment.Center:
        return { alignItems: Constants.CenterString };
      case Enums.HorizontalAlignment.Left:
      default:
        return { alignItems: Constants.FlexStart };
    }
  };

  const getBackgroundImage = (onParseError: any) => {
    let imageContainerStyle = [];
    let bgImage = [];
    imageContainerStyle.push(styles.imageContainer);
    switch (backgroundImageModeEnumValue) {
      case Enums.BackgroundImageMode.Repeat:
        imageContainerStyle.push({ alignItems: Constants.FlexStart, justifyContent: Constants.FlexStart });
        bgImage.push(
          <Image
            key="image-repeat"
            resizeMethod={Constants.Resize}
            source={{ uri: backgroundImage.url }}
            onError={() => {
              onError(onParseError);
            }}
            isBackgroundImage={true}
            style={{ width: Constants.FullWidth, height: Constants.FullWidth, resizeMode: Constants.Repeat }}
          />
        );
        break;
      case Enums.BackgroundImageMode.RepeatHorizontally:
        imageContainerStyle.push(getImageVerticalAlignment());
        bgImage.push(
          <Image
            key="image-repeat-horizontal"
            resizeMethod={Constants.Resize}
            source={{ uri: backgroundImage.url }}
            isBackgroundImage={true}
            onError={() => {
              onError(onParseError);
            }}
            /**
             * when the resize mode for the image is "repeat". it's always taking the row count as 3. So, we have divided the image height by 3.
             * Refer https://github.com/facebook/react-native/issues/17684
             */
            style={{
              width: Constants.FullWidth,
              height: backgroundImageHeight / 3,
              resizeMode: Constants.Repeat,
              zIndex: 0,
            }}
          />
        );
        break;
      case Enums.BackgroundImageMode.RepeatVertically:
        imageContainerStyle.push(getImageHorizontalAlignment());
        bgImage.push(
          <Image
            key="image-repeat-vertical"
            resizeMethod={Constants.Resize}
            isBackgroundImage={true}
            source={{ uri: backgroundImage.url }}
            onError={() => {
              onError(onParseError);
            }}
            style={{
              width: backgroundImageWidth / 3,
              height: Constants.FullWidth,
              resizeMode: Constants.Repeat,
              zIndex: 0,
            }}
          />
        );
        break;
      case Enums.BackgroundImageMode.Stretch:
      case Enums.BackgroundImageMode.Cover:
      default:
        bgImage.push(
          <Image
            key="image-stretch"
            source={{ uri: backgroundImage.url }}
            onError={() => {
              onError(onParseError);
            }}
            isBackgroundImage={true}
            style={{
              width: Constants.FullWidth,
              height: Constants.FullWidth,
              resizeMode: Constants.AlignStretch,
              zIndex: 0,
            }}
          />
        );
        break;
    }
    return <View style={imageContainerStyle}>{bgImage}</View>;
  };

  return getBackgroundImage(onParseError);
};

const styles = StyleSheet.create({
  imageContainer: {
    position: Constants.Absolute,
    width: Constants.FullWidth,
    height: Constants.FullWidth,
  },
});
