import React from "react";
import { Stack, List, Label, PrimaryButton, Dialog, DialogType, DialogFooter } from "@fluentui/react";
import { useSelector } from "react-redux";
import { helpers } from "../../initializationHelper";
import { useNavigate } from "react-router-dom";

const stackTokens = {
  childrenGap: 10,
};
const onRenderCell = (item: any) => {
  return (
    <Label>
      <b>{item.text}</b>
      {item.value}
    </Label>
  );
};
const dialogContentProps = {
  type: DialogType.normal,
  title: "Portal bereit",
  closeButtonAriaLabel: "Close",
  subText: "Das Portal wurde erfolgreich initialisiert. Das Portal muss neu gestartet werden.",
};
const dialogStyles = { main: { maxWidth: 450 } };
const modalProps = {
  isBlocking: true,
  styles: dialogStyles,
};

export default ({ initialized }: any) => {
  const sql = useSelector((state) => (state as any).wizard.sql);
  const login = useSelector((state) => (state as any).wizard.login);
  const navigate = useNavigate();
  const items = [
    { text: "SQL Servername: ", value: sql.server },
    { text: "Datenbankname: ", value: sql.useExistingDB ? sql.database : sql.newDatabaseName },
    { text: "Integrated Security verwenden: ", value: sql.integratedSecurity ? "Ja" : "Nein" },
  ];
  if (!sql.integratedSecurity) {
    items.push({ text: "SQL Server Benutzername: ", value: sql.username });
  }
  // items.push({
  //   text: "Art des Logins: ",
  //   value: login.loginType === "usernamePassword" ? "Benutzername/Passwort" : "",
  // });
  if (login.needsLogin && login.loginType === "usernamePassword") {
    items.push({ text: "Admin Benutzername: ", value: login.username });
  }
  const restartAfterTimeout = () => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };
  const restart = () => {
    helpers.restartPortal().then(
      () => {
        restartAfterTimeout();
      },
      () => {
        restartAfterTimeout();
      }
    );
  };
  return (
    <Stack horizontal={false} tokens={stackTokens}>
      <Label>Folgende Einstellungen werden übernommen:</Label>
      <List items={items} onRenderCell={onRenderCell} />
      {initialized && (
        <Dialog hidden={false} onDismiss={restart} dialogContentProps={dialogContentProps} modalProps={modalProps}>
          <DialogFooter>
            <PrimaryButton onClick={restart} text="Neu starten" />
          </DialogFooter>
        </Dialog>
      )}
    </Stack>
  );
};
