import React, { useEffect, useState, useRef } from "react";
import Dashboard from "./Dashboard";
import { authenticationService } from "./authorization/authenticationService";
import RequireAuthRoute from "./authorization/RequireAuthRoute";
import PageNotFound from "./authorization/PageNotFound";
import { useNavigate } from "react-router-dom";

const ExternalPlugin = (props: any) => {
  const [loaded, setLoaded] = useState(false);
  const [plugin, setPlugin] = useState(undefined);
  const [pageLink, setPageLink] = useState(undefined);
  const [tenantId, setTenantId] = useState(undefined);
  const [foundExternal, setFoundExternal] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const location = { pathName: window.location.href };

    const path = window.location.pathname;
    if (
      path.indexOf("/workflow-definitions") === 0 ||
      path.indexOf("/workflow-instances") === 0 ||
      path.indexOf("/workflow-registry") === 0
    ) {
      navigate("/");
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Type": "application/json",
        TabId: window.sessionStorage.getItem("tabid") ?? "",
      },
      body: JSON.stringify(location),
    };

    fetch("ext/ui/route", requestOptions).then(
      (response) => {
        if (response.ok) {
          response.json().then(
            (data) => {
              if (!data.routeFound) {
                setLoaded(true);
              } else {
                if (data.user) {
                  authenticationService.externallogin(data.user);
                }
                setPlugin(data.pluginToken);
                setPageLink(data.pageId);
                setTenantId(data.tenantId);
                setLoaded(true);
                setFoundExternal(true);
              }
            },
            () => setLoaded(true)
          );
        } else {
          setLoaded(true);
        }
      },
      () => setLoaded(true)
    );
  }, []);

  if (!loaded) {
    return null;
  }

  if (!foundExternal) {
    return <PageNotFound />;
  }

  return (
    <RequireAuthRoute>
      <Dashboard external={true} externalPlugin={plugin} externalPageLink={pageLink} externalTenantId={tenantId} />
    </RequireAuthRoute>
  );
};

export default ExternalPlugin;
