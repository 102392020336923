import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeDialog } from "../../store/slices/portalSlice";
import PortalDialog from "./PortalDialog";
import { CommandBarButton, Text, mergeStyleSets, useTheme } from "@fluentui/react";
import { useTranslation } from "react-i18next";

const ErrorDialog = () => {
  const errors = useSelector((state) => (state as any).portal.errorDialogs);
  const [supportOpen, setSupportOpen] = useState<boolean>(false);

  const { palette } = useTheme();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const downloadDebugInfo = async () => {
    try {
      await window.portalInterface.downloadDebugInfo();
    } catch (e: any) {
      console.log(e);
    }
  };

  if (errors.length === 0) return null;

  const renderErrors = () => {
    return errors.map((error: any, index: any) => {
      return (
        <PortalDialog
          key={`ERRORDIALOG_${index}`}
          title={error.title}
          buttonId="errorOk"
          dialogId={`error-${index + 1}`}
          msg={error.msg}
          type={error.type}
          icon="emojidisappointed"
          onClose={() => dispatch(closeDialog({ type: "error", id: error.id }))}
        >
          {error.details && (
            <div>
              <Text style={{ fontWeight: 700 }} variant="small">
                {t("singleWords.details")}:
              </Text>
              <br />
              <Text variant="small">{error.details}</Text>
              <br />
              <br />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1em" }}>
            <CommandBarButton
              text={`${t("dialogs.technicalDetails.technicalDetails_message")}`}
              iconProps={{ iconName: supportOpen ? "ChevronUp" : "ChevronDown" }}
              styles={{
                rootHovered: {
                  backgroundColor: "transparent",
                },
                rootPressed: { backgroundColor: "transparent" },
                flexContainer: {
                  flexDirection: "row-reverse",
                },
                root: {
                  display: "flex",
                  padding: "0px",
                },
                label: {
                  color: palette.themePrimary,
                  textAlign: "left",
                },
              }}
              onClick={() => setSupportOpen(!supportOpen)}
            />
          </div>
          {supportOpen && (
            <div
              style={{
                maxWidth: "450px",
                marginTop: "2em",
                transition: "1s",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{ color: palette.neutralSecondary }}>
                {t("dialogs.technicalDetails.technicalDetails_description")}
              </Text>
              <CommandBarButton
                text={t("dialogs.technicalDetails.technicalDetails_download")}
                color={palette.themePrimary}
                iconProps={{ iconName: "Download" }}
                className={classNames.commandBarButton}
                onClick={() => downloadDebugInfo()}
                styles={{
                  root: {
                    display: "inline-flex",
                    width: "max-content",
                  },
                  label: {
                    color: palette.themePrimary,
                    textDecoration: "underline",
                  },
                }}
              />
              <Text style={{ color: palette.neutralSecondary }}>
                {t("dialogs.technicalDetails.technicalDetails_list_info_part_1")}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {t("dialogs.technicalDetails.technicalDetails_list_info_part_2")}
                </span>{" "}
                {t("dialogs.technicalDetails.technicalDetails_list_info_part_3")}
              </Text>
              <ul style={{ color: palette.neutralSecondary }}>
                <li>{t("dialogs.technicalDetails.technicalDetails_list_item_one")}</li>
                <li>{t("dialogs.technicalDetails.technicalDetails_list_item_two")}</li>
                <li>{t("dialogs.technicalDetails.technicalDetails_list_item_three")}</li>
                <li>{t("dialogs.technicalDetails.technicalDetails_list_item_four")}</li>
                <li>{t("dialogs.technicalDetails.technicalDetails_list_item_five")}</li>
              </ul>
            </div>
          )}
        </PortalDialog>
      );
    });
  };

  return <React.Fragment>{renderErrors()}</React.Fragment>;
};
export default ErrorDialog;

const classNames = mergeStyleSets({
  commandBarButton: {
    marginTop: "1em",
    marginBottom: "1em",
    padding: "5px 1px 5px 1px",
    "::hover": {
      background: "transparent",
    },
  },
});
