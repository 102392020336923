import { HostConfigManager } from '../utils/host-config';
import { ThemeConfigManager } from '../utils/theme-config';
import { StyleSheet, Platform } from '../reactnative';
import * as Constants from '../utils/constants';

export class StyleManager {
	static styleManagerInstance = null;
	hostConfig = HostConfigManager.getHostConfig();
	themeConfig = ThemeConfigManager.getThemeConfig();

	getFontType = () => {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
		return this.hostConfig.fontFamily;
	}

	static getManager() {
		if (!StyleManager.styleManagerInstance) {

// @ts-expect-error ts-migrate(2322) FIXME: Type 'StyleManager' is not assignable to type 'nul... Remove this comment to see the full error message
			StyleManager.styleManagerInstance = new StyleManager();
		}
		return StyleManager.styleManagerInstance;
	}

	styles = StyleSheet.create({
		defaultFontFamilyName: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			fontFamily: this.hostConfig.fontStyles.default.fontFamily
		},
		defaultFontConfig: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			fontSize: this.hostConfig.fontStyles.default.fontSizes.default,

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			fontWeight: this.hostConfig.fontStyles.default.fontWeights.default.toString(),

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			fontFamily: this.hostConfig.fontStyles.default.fontFamily
		},
		actionIconFlex: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			flexDirection: (this.hostConfig.actions.iconPlacement === 0) ?
				Constants.FlexRow : Constants.FlexColumn
		},
		actionIcon: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			width: this.hostConfig.actions.iconSize,

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			height: this.hostConfig.actions.iconSize,
		},
		separatorStyle: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			borderColor: this.hostConfig.separator.lineColor,

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			borderTopWidth: this.hostConfig.separator.lineThickness
		},
		borderAttention: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			borderColor: this.hostConfig.containerStyles.default.foregroundColors.attention.default,
		},
		button: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			...this.themeConfig.button[Platform.OS]
		},
		buttonTitle: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			color: this.themeConfig.button[Platform.OS].color,

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			textTransform: this.themeConfig.button[Platform.OS].textTransform,
		},
		input: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			...this.themeConfig.input[Platform.OS]
		},
		defaultPositiveButtonBackgroundColor: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			backgroundColor: this.hostConfig.containerStyles.default.foregroundColors.accent.default,
		},
		defaultDestructiveButtonForegroundColor: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			color: this.hostConfig.containerStyles.default.foregroundColors.attention.default,
		},
		inputDate: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			...this.themeConfig.inputDate[Platform.OS]
		},
		inputTime: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			...this.themeConfig.inputTime[Platform.OS]
		},
		radioButton: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			...this.themeConfig.radioButton[Platform.OS]
		},
		checkBox: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			...this.themeConfig.checkBox[Platform.OS]
		},
		choiceSetTitle: {

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			...this.themeConfig.choiceSetTitle[Platform.OS]
		},
	});
}