import React from "react";
export const Image = (props: any) => {
  let style = {};
  const createStyle = (el: any) => {
    if (!el) return;
    if (Array.isArray(el)) {
      for (var i = 0; i < el.length; i++) {
        createStyle(el[i]);
      }
    } else {
      Object.keys(el).forEach((key) => {

        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        style[key] = el[key];
      });
    }
  };
  createStyle(props.style);
  (style as any).position = "relative";
  return <img id="image" src={props.source.uri} style={style} />;
};
