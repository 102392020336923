import React, { useState, useContext } from "react";
import { StyleSheet, View } from "../../reactnative";
import { Registry } from "../registration/registry";
import * as Constants from "../../utils/constants";
import { InputContext } from "../../utils/context";
import AdaptiveCard from "../../AdaptiveCard";
import * as Utils from "../../utils/util";
import * as Enums from "../../utils/enums";
import { HostConfigManager } from "../../utils/host-config";

const padding = 10;

const hostConfig = HostConfigManager.getHostConfig();

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const ActionWrapper = (props) => {
  const [isShowCard, setIsShowCard] = useState(false);
  const [cardJson, setCardJson] = useState(null);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExecuteAction' does not exist on type ... Remove this comment to see the full error message
  const { onExecuteAction, onParseError } = useContext(InputContext);

  let hasShowCard = false;

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'adaptiveCard' implicitly has an 'any' t... Remove this comment to see the full error message
  const onShowAdaptiveCard = (adaptiveCard) => {
    let isDifferentcard = Utils.isNullOrEmpty(cardJson) ? false : adaptiveCard === cardJson ? false : true;
    setIsShowCard(!isShowCard);
    setCardJson(adaptiveCard);
    if (isDifferentcard) {
      setTimeout(() => {
        setIsShowCard(true);
      }, 50);
    }
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'onParseError' implicitly has an 'any' t... Remove this comment to see the full error message
  const parseActionsArray = (onParseError) => {
    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'renderedElement' implicitly has type 'an... Remove this comment to see the full error message
    const renderedElement = [];
    const { actions } = props;

    if (!actions)
      // @ts-expect-error ts-migrate(7005) FIXME: Variable 'renderedElement' implicitly has an 'any[... Remove this comment to see the full error message
      return renderedElement;

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'element' implicitly has an 'any' type.
    actions.map((element, index) => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const Element = Registry.getManager().getComponentOfType(element.type);
      if (Element) {
        let isValid = true;
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        for (var key in Registry.getManager().validateSchemaForType(element.type)) {
          if (!element.hasOwnProperty(key)) {
            let error = {
              error: Enums.ValidationError.PropertyCantBeNull,
              message: `Required property ${key} for ${element.type} is missing`,
            };
            onParseError(error);
            isValid = false;
          }
        }
        if (isValid) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          const isFirstElement = index == 0 || hostConfig.actions.actionsOrientation === Enums.Orientation.Vertical;
          if (element.type === "Action.ShowCard") {
            hasShowCard = true;
            renderedElement.push(
              <Element
                json={element}
                isFirst={isFirstElement}
                onShowCardTapped={onShowAdaptiveCard}
                key={`${element.type}-${index}`}
              />
            );
          } else {
            renderedElement.push(<Element json={element} isFirst={isFirstElement} key={`${element.type}-${index}`} />);
          }
        }
      } else {
        let error = {
          error: Enums.ValidationError.UnknownActionType,
          message: `Unknown Type ${element.type} encountered`,
        };
        onParseError(error);
        return null;
      }
    });

    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'renderedElement' implicitly has an 'any[... Remove this comment to see the full error message
    return renderedElement;
  };

  const getActionOrientation = () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (hostConfig.actions.actionsOrientation === Enums.Orientation.Horizontal) return styles.actionAlignmentHorizontal;
    else return styles.actionAlignmentVertical;
  };

  return (
    <View id="actionwrapper">
      <View style={[styles.actionButtonContainer, getActionOrientation()]}>{parseActionsArray(onParseError)}</View>
      {hasShowCard ? (
        isShowCard ? (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ payload: null; onExecuteAction: any; isAct... Remove this comment to see the full error message
          <AdaptiveCard payload={cardJson} onExecuteAction={onExecuteAction} isActionShowCard={true} />
        ) : null
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  actionButtonContainer: {
    paddingTop: padding,
    flexWrap: Constants.FlexWrap,
    justifyContent: Constants.CenterString,
  },
  actionAlignmentHorizontal: {
    flexDirection: Constants.FlexRow,
  },
  actionAlignmentVertical: {
    flexDirection: Constants.FlexColumn,
  },
});
