import React from "react";
import { concatStyleSets, Icon, mergeStyleSets, Text, useTheme } from "@fluentui/react";

interface ChangePasswordCriterionProperties {
  text: string;
  met: boolean;
  style?: React.CSSProperties;
}

const ChangePasswordCriterion = (props: ChangePasswordCriterionProperties) => {
  const { text, met } = props;
  const { semanticColors } = useTheme();

  const metStyle = {
    root: {
      color: semanticColors.successIcon,
    },
  };
  const notMetStyle = {
    root: {
      color: semanticColors.errorIcon,
    },
  };

  return (
    <div className={classNames.passwordCriteria}>
      {met ? (
        <Icon iconName={"Completed"} styles={concatStyleSets(iconStyles, metStyle)} />
      ) : (
        <Icon iconName={"CircleRing"} styles={concatStyleSets(iconStyles, notMetStyle)} />
      )}
      <Text style={props.style}>{text}</Text>
    </div>
  );
};

export default ChangePasswordCriterion;

const classNames = mergeStyleSets({
  criteriaWrapper: {
    marginBlock: "1em",
  },
  passwordCriteria: {
    display: "flex",
    alignItems: "center",
  },
});

const iconStyles = {
  root: {
    marginInline: "0.5em",
  },
};
