import React from "react";
import { Fabric, Stack, TextField, ComboBox, SelectableOptionMenuItemType } from "@fluentui/react";
import { setLoginField } from "../../store/slices/wizardSlice";
import { useSelector, useDispatch } from "react-redux";
const comboBoxBasicOptions = [
  {
    key: "internal",
    text: "Interne Anmeldeprovider",
    itemType: SelectableOptionMenuItemType.Header,
  },
  { key: "usernamePassword", text: "Benutzername und Passwort" },
  { key: "divider", text: "-", itemType: SelectableOptionMenuItemType.Divider },
  {
    key: "external",
    text: "Externe Anmeldeprovider",
    itemType: SelectableOptionMenuItemType.Header,
  },
  { key: "SAML", text: "SAML", disabled: true },
  { key: "OPENID", text: "OpenID", disabled: true },
];
// export const ComboBoxErrorHandlingExample: React.FC = () => {
//   const [selectedKey, setSelectedKey] =
//     (React.useState < string) | (undefined > "");
//   const onChange = (event, option) => setSelectedKey(option.key);
// };
const columnProps = {
  tokens: {
    childrenGap: 15,
  },
};
const revealPassword = true;
const stackTokens = {
  childrenGap: 50,
};
export default () => {
  const { loginType, username, password, passwordConfirm, needsLogin } = useSelector(
    (state) => (state as any).wizard.login
  );
  const dispatch = useDispatch();
  return (
    <div>
      <Fabric>
        <Stack horizontal={false} tokens={stackTokens}>
          <Stack {...columnProps}>
            {/* <ComboBox
    label="Art des Logins (Provider)"
    defaultSelectedKey={loginType}
    // onChange={onChangeLoginType}
    options={comboBoxBasicOptions}
    onChange={(e, option) => dispatch(setLoginField({ name: "loginType", value: option.key }))}
/> */}
            <div hidden={!(loginType === "usernamePassword")}>
              <TextField
                label="Admin Benutzername"
                type="text"
                disabled={!needsLogin}
                value={username}
                onChange={(e, v) => dispatch(setLoginField({ name: "username", value: v }))}
              />
              <Stack horizontal tokens={stackTokens} horizontalAlign="space-between">
                <TextField
                  label="Passwort"
                  type="password"
                  disabled={!needsLogin}
                  canRevealPassword={revealPassword}
                  value={password}
                  onChange={(e, v) => dispatch(setLoginField({ name: "password", value: v }))}
                />
                <TextField
                  label="Passwort wiederholen"
                  type="password"
                  disabled={!needsLogin}
                  canRevealPassword={revealPassword}
                  value={passwordConfirm}
                  onChange={(e, v) => dispatch(setLoginField({ name: "passwordConfirm", value: v }))}
                />
              </Stack>
            </div>
          </Stack>
        </Stack>
      </Fabric>
    </div>
  );
};
