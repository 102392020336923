import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ChangePortalSiteTitlePayload, PortalState } from "../../types/portalReducerTypes";
import { authenticationService } from "../../components/authorization/authenticationService";
import { userService } from "../../components/authorization/userService";
import { pluginService } from "../../components/authorization/pluginService";
import { tenantService } from "../../components/authorization/tenantService";
import { AppThunk } from "../store";
import { ChangeProfileResultDto } from "../../types/commonTypes";

export const setPluginMenu = createAsyncThunk("nav/setPortalMenu", async (pluginId: string, thunkApi) => {
  const state = thunkApi.getState();

  if ((state as any).portal.pluginNavigation[pluginId]) {
    return (state as any).portal.pluginNavigation;
  }
  thunkApi.dispatch(setMenuLoaded(false));
  const nav = await pluginService.loadNavigation(pluginId);
  thunkApi.dispatch(setMenuLoaded(true));
  thunkApi.dispatch(setNavigation(nav));
  return nav;
});

function saveTenantSettings(state: PortalState) {
  tenantService.updateTenantSettings({ theme: state.theme, language: state.language, timezone: state.timezone });
  tenantService.saveGlobalSettings({
    theme: JSON.stringify(state.theme),
    language: state.language,
    timezone: state.timezone,
  });
}

export const unloadTenantScriptBundles = (): AppThunk => (dispatch, getState) => {
  const bundles = document.querySelectorAll("script[tenant]");
  bundles.forEach((script) => {
    delete window[script.id.indexOf("Bundle") >= 0 ? script.id.substring(0, script.id.indexOf("Bundle")) : script.id];
    script.remove();
  });
};

export const changeUserProfile =
  (profileId: number): AppThunk =>
  async (dispatch, getState) => {
    const response = await userService.changeUserProfile(profileId);
    authenticationService.changeProfile(profileId);
    dispatch(setChangedUserProfile(response.changeProfileResult));

    const widgetResponse = await tenantService.getWidgets();
    dispatch(setWidgets(widgetResponse));

    return dispatch(setPlugin(undefined));
  };

const getInitialState: () => PortalState = () => ({
  plugins: {},
  pluginNavigation: {},
  plugin: undefined,
  pluginIcons: {},
  pageLink: undefined,
  pluginMenu: undefined,
  menuLoaded: false,
  sessionTimeout: false,
  changePassword: false,
  enableTwoFactor: false,
  changePasswordPanelOpen: false,
  loaded: false,
  language: tenantService.getTenantSettings().language,
  timezone: "",
  messageDialogs: [],
  queuedLink: undefined,
  confirmDialogs: [],
  errorDialogs: [],
  progressBars: [],
  fileDialog: undefined,
  tfaWizardOpen: false,
  tfaWizardLoginId: null,
  reportViewer: undefined,
  pageZoom: undefined,
  widgets: [],
  pluginNavigationOpen: userService.getUserSettings().pluginbaropen,
  theme: tenantService.getTenantSettings().theme,
  customTheme: {},
  portalSiteTitleObject: undefined,
  showPasswordOption: true,
  showTfaOption: false,
  showTenantDropdown: false,
  showProfileDropdown: false,
  unreadNotifications: 0,
  portalSettingsPaneOpen: false,
  portalNotificationPaneOpen: false,
});

const portalSlice = createSlice({
  name: "portalshell",
  initialState: getInitialState(),
  reducers: {
    companyOpened(state, action) {
      state.loaded = true;
      state.theme = tenantService.defaultSettings.theme;

      if (!action.payload) {
        return;
      }
      state.changePassword = action.payload.changePassword;
      if (state.changePassword) {
        state.changePasswordPanelOpen = true;
      }
      state.showPasswordOption = action.payload.showPasswordOption;
      state.enableTwoFactor = action.payload.enableTfa;
      if (state.enableTwoFactor && !state.changePassword) state.tfaWizardOpen = true;

      state.showTfaOption = action.payload.showTfa;
      state.plugins = { ...action.payload.plugins };
      state.unreadNotifications = action.payload.unreadNotifications;
      // state.pluginIcons = { ...action.payload.pluginIcons };
      state.language = action.payload.language;
      if (action.payload.language) document.documentElement.lang = action.payload.language;
      state.timezone = action.payload.timeZone;
      if (action.payload.profileId !== undefined) {
        state.profile = { profileId: action.payload.profileId, profileName: action.payload.profileName };
        authenticationService.changeProfile(action.payload.profileId);
      }
      if (action.payload.theming) {
        state.theme = JSON.parse(action.payload.theming);
      }
      tenantService.updateTenantSettings({ language: state.language, theme: state.theme, timezone: state.timezone });
    },
    setChangedUserProfile(state, action: PayloadAction<ChangeProfileResultDto>) {
      state.plugins = { ...action.payload.plugins };
      state.profile = { profileId: action.payload.profileId, profileName: action.payload.profileName };
    },
    setPasswordChanged(state, action) {
      state.changePassword = false;
    },
    setChangePasswordPanelOpen(state, action: PayloadAction<boolean>) {
      state.changePasswordPanelOpen = action.payload;
    },
    setTfaWizardOpen(state, action: PayloadAction<boolean>) {
      state.tfaWizardOpen = action.payload;
      if (!action.payload) {
        state.tfaWizardLoginId = null;
      }
    },
    setTfaLoginId(state, action: PayloadAction<number | null>) {
      state.tfaWizardLoginId = action.payload;
    },
    setPlugins(state, action) {
      state.plugins = { ...action.payload };
    },
    setPluginIcons(state, action) {
      state.pluginIcons = { ...action.payload };
    },
    setNavigation(state, action) {
      state.pluginNavigation = { ...action.payload };
      //merge(state.pluginNavigation, action.payload);
    },
    setPageLink(state, action) {
      state.pageLink = action.payload;
    },
    setPlugin(state, action) {
      if (action.payload) {
        state.plugin = action.payload.pluginId;
      } else {
        state.plugin = undefined;
      }
    },
    setWidgets(state, action) {
      state.widgets = [...action.payload];
    },
    setMenuLoaded(state, action) {
      state.menuLoaded = action.payload;
    },
    setLoadedPlugin(state, action) {
      (state as any).loadedPlugin = action.payload;
    },
    setSessionTimeout(state, action) {
      state.sessionTimeout = action.payload;
    },
    setQueuedLink(state, action) {
      if (action.payload) {
        state.queuedLink = action.payload;
      } else {
        state.queuedLink = undefined;
      }
    },
    setLoaded(state, action) {
      state.loaded = action.payload;
    },
    setPageZoom(state, action) {
      if (action.payload) {
        state.pageZoom = JSON.parse(action.payload);
      } else {
        state.pageZoom = undefined;
      }
    },
    togglePluginNavigation(state) {
      if (state.pluginNavigationOpen) {
        state.pluginNavigationOpen = false;
        const s = userService.getUserSettings();
        s.pluginbaropen = false;
        userService.updateUserSettings(s);
      } else {
        state.pluginNavigationOpen = true;
        const s = userService.getUserSettings();
        s.pluginbaropen = true;
        userService.updateUserSettings(s);
      }
    },
    setLanguage(state, action) {
      state.language = action.payload;
      saveTenantSettings(state);
    },
    showDialog(state, action) {
      switch (action.payload.type) {
        case "msg": {
          const messages = [...state.messageDialogs];
          messages.push(action.payload);
          state.messageDialogs = messages;
          break;
        }
        case "error": {
          const messages = [...state.errorDialogs];
          messages.push(action.payload);
          state.errorDialogs = messages;
          break;
        }
        case "confirm": {
          const messages = [...state.confirmDialogs];
          messages.push(action.payload);
          state.confirmDialogs = messages;
          break;
        }
        case "openfile": {
          state.fileDialog = { ...action.payload };
          break;
        }
        case "reportviewer": {
          state.reportViewer = { ...action.payload };
          break;
        }
        case "progressbar": {
          const progressBars = [...state.progressBars];
          progressBars.push(action.payload.bar);
          state.progressBars = progressBars;
          break;
        }
        case "updatedprogressbar": {
          const { bar } = action.payload;
          if (bar) {
            const progressBars = [...state.progressBars];
            state.progressBars = progressBars.map((t) => (t.id === bar.id ? bar : t));
          }
          break;
        }
      }
    },
    resetState() {
      return getInitialState();
    },
    closeDialog(state, action) {
      switch (action.payload.type) {
        case "msg": {
          state.messageDialogs = state.messageDialogs.filter(function (message) {
            return message.id !== action.payload.id;
          });
          break;
        }
        case "error": {
          state.errorDialogs = state.errorDialogs.filter(function (message) {
            return message.id !== action.payload.id;
          });
          break;
        }
        case "confirm": {
          state.confirmDialogs = state.confirmDialogs.filter(function (message) {
            return message.id !== action.payload.id;
          });
          break;
        }
        case "openfile": {
          state.fileDialog = undefined;
          break;
        }
        case "reportviewer": {
          state.reportViewer = undefined;
          break;
        }
        case "progressbar": {
          state.progressBars = state.progressBars.filter(function (message) {
            return (message as any).id !== action.payload.id;
          });
          break;
        }
      }
    },
    setTfaEnabled(state, action) {
      state.enableTwoFactor = false;
    },
    setThemeStyle(state, action) {
      state.theme.style = action.payload;
      saveTenantSettings(state);
    },
    setThemeColor(state, action) {
      state.theme.color = action.payload;
      saveTenantSettings(state);
    },
    setCustomTheme(state, action) {
      const newCustomTheme = action.payload ? JSON.parse(action.payload) : { palette: {} };
      if (!newCustomTheme?.palette) state.customTheme = { palette: newCustomTheme };
      else state.customTheme = newCustomTheme;

      state.theme.style = "custom";
    },
    changePortalSiteTitle(state, action: PayloadAction<ChangePortalSiteTitlePayload>) {
      const { portalSiteTitleObject: newPortalSiteTitleObject } = action.payload;
      state.portalSiteTitleObject = { ...state.portalSiteTitleObject, ...newPortalSiteTitleObject };

      const { plugin, page, dataId, status } = state.portalSiteTitleObject;

      const titleParts = [];

      if (plugin) titleParts.unshift(plugin);
      else if (state.plugin && state.plugins[state.plugin]?.caption)
        titleParts.unshift(state.plugins[state.plugin].caption);
      if (page) titleParts.unshift(page);
      if (dataId) titleParts.unshift(dataId);
      if (status) titleParts.unshift(status);

      document.title = titleParts.length > 0 ? titleParts.join(" ∙ ") : "AppSpace";
    },
    setShowTenantDropdown(state, action: PayloadAction<boolean>) {
      state.showTenantDropdown = action.payload;
    },
    setShowProfileDropdown(state, action: PayloadAction<boolean>) {
      state.showProfileDropdown = action.payload;
    },
    setUnReadNotifications(state, action) {
      state.unreadNotifications += action.payload;
    },
    setPortalSettingsPaneOpen(state, action: PayloadAction<boolean>) {
      state.portalSettingsPaneOpen = action.payload;
    },
    setPortalNotificationPaneOpen(state, action: PayloadAction<boolean>) {
      state.portalNotificationPaneOpen = action.payload;
    },
  },
  extraReducers:
    // Add reducers for additional action types here, and handle loading state as needed
    (builder) => {
      builder.addCase(setPluginMenu.fulfilled, (state, action) => {
        state.pluginMenu = { ...action.payload[state.plugin] };
      });
    },
});

const { setChangedUserProfile } = portalSlice.actions;

export const {
  setPlugins,
  setTfaEnabled,
  setWidgets,
  setLoadedPlugin,
  setPlugin,
  setPluginIcons,
  setSessionTimeout,
  setLoaded,
  companyOpened,
  setNavigation,
  togglePluginNavigation,
  showDialog,
  closeDialog,
  setPageLink,
  setMenuLoaded,
  setPageZoom,
  setQueuedLink,
  resetState,
  setPasswordChanged,
  setChangePasswordPanelOpen,
  setThemeStyle,
  setThemeColor,
  setLanguage,
  setCustomTheme,
  changePortalSiteTitle,
  setTfaWizardOpen,
  setTfaLoginId,
  setShowTenantDropdown,
  setShowProfileDropdown,
  setUnReadNotifications,
  setPortalSettingsPaneOpen,
  setPortalNotificationPaneOpen,
} = portalSlice.actions;

export default portalSlice.reducer;
