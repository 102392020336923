import { concatStyleSets, Icon, ITextStyles, mergeStyleSets, Text, Theme, useTheme } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SystemIndicatorSettings, SystemIndicatorStyle, TenantType } from "../../types/commonTypes";
import { tenantService } from "../authorization/tenantService";
import { userService } from "../authorization/userService";

const SystemIndicator = () => {
  const [pinned, setPinned] = useState(true);
  const [settings, setSettings] = useState<SystemIndicatorSettings | null>(null);
  const showChangePassword = useSelector((state) => (state as any).portal.changePassword);
  const { semanticColors, palette, effects }: Theme & AppSpaceTheme = useTheme();
  const [t] = useTranslation();

  const getSettings = async () => {
    try {
      const response = await tenantService.getSystemIndicatorSettings();
      setSettings(response);
    } catch {}
  };

  useEffect(() => {
    getSettings();

    const savedSettings = userService.getUserSettings();
    if (savedSettings && savedSettings.hasOwnProperty("systemIndicatorPinned"))
      setPinned(savedSettings.systemIndicatorPinned);
  }, []);

  if (showChangePassword || !settings || settings.style === SystemIndicatorStyle.None) return null;

  const tenantTypeIcon = (() => {
    switch (settings.tenantType) {
      case TenantType.Production:
        return "Running";
      case TenantType.Dev:
        return "DeveloperTools";
      case TenantType.Demo:
        return "GiftboxOpen";
      case TenantType.Test:
      default:
        return "TestBeaker";
    }
  })();

  const tenantTypeDescription = (() => {
    switch (settings.tenantType) {
      case TenantType.Production:
        return t("menu.productionEnvironment");
      case TenantType.Dev:
        return t("menu.developmentEnvironment");
      case TenantType.Demo:
        return t("menu.demoEnvironment");
      case TenantType.Test:
      default:
        return t("menu.testEnvironment");
    }
  })();

  let backgroundBorder = null;
  let backgroundBadge = null;
  switch (settings.style) {
    case SystemIndicatorStyle.Attention:
      backgroundBorder = semanticColors.systemIndicatorBackgroundAttention;
      backgroundBadge = semanticColors.systemIndicatorBackgroundAttention;
      break;
    case SystemIndicatorStyle.Info:
      backgroundBorder = semanticColors.systemIndicatorBackgroundInfo;
      backgroundBadge = semanticColors.systemIndicatorBackgroundInfo;
      break;
    case SystemIndicatorStyle.InfoGradient:
      backgroundBorder = semanticColors.systemIndicatorBackgroundInfo;
      backgroundBadge = effects.systemIndicatorBackgroundInfoGradient;
      break;
  }

  const commonStyles = {
    zIndex: 9000000,
    position: "fixed",
    inset: 0,
    background: backgroundBorder,
  };

  const classNames = mergeStyleSets({
    top: {
      ...commonStyles,
      bottom: "auto",
      height: settings.showBorder ? 5 : 0,
    },
    badge: {
      ...commonStyles,
      background: backgroundBadge,
      position: "absolute",
      width: "30vw",
      height: 30,
      left: "50%",
      transform: pinned ? "translate(-50%, 0px)" : "translate(-50%, -20px)",
      borderRadius: "0 0 10px 10px",
      transition: "transform 200ms",
      ":hover": {
        transform: "translate(-50%, 0px)",
      },
    },
    content: {
      display: "flex",
      height: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      opacity: pinned ? 1 : 0,
      ":hover": {
        opacity: 1,
      },
    },
    bottom: {
      ...commonStyles,
      top: "auto",
      height: 5,
      "::before": {
        content: "''",
        ...commonStyles,
        right: "auto",
        width: 5,
      },
      "::after": {
        content: "''",
        ...commonStyles,
        left: "auto",
        width: 5,
      },
    },
  });

  const textStyles: ITextStyles = {
    root: { color: palette.white, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "pre" },
  };
  const iconStyles = { root: { userSelect: "none", margin: "0.5em", color: palette.white, fontSize: 16 } };

  return (
    <>
      <div className={classNames.top}>
        <div className={classNames.badge}>
          <div className={classNames.content}>
            <Icon iconName={tenantTypeIcon} styles={iconStyles} title={tenantTypeDescription} />
            <Text block styles={textStyles}>
              {settings.text}
            </Text>
            <Icon
              iconName={pinned ? "Pinned" : "Unpin"}
              onClick={() => {
                const savedSettings = userService.getUserSettings();
                savedSettings.systemIndicatorPinned = !pinned;
                userService.updateUserSettings(savedSettings);

                setPinned(!pinned);
              }}
              styles={concatStyleSets(iconStyles, pinStyles)}
            />
          </div>
        </div>
      </div>
      {settings.showBorder && <div className={classNames.bottom} />}
    </>
  );
};

export default SystemIndicator;

const pinStyles = { root: { cursor: "pointer" } };
