import React, { useEffect } from "react";

export const View = (props: any) => {
  let style = {
    display: "flex",
    flexDirection: "column",
  };

  useEffect(() => {
    if (props.onLayout) {
      props.onLayout({ nativeEvent: { layout: { width: 100, layoutWidth: 100 } } });
    }
  }, []);

  const createStyle = (el: any) => {
    if (!el) return;

    if (Array.isArray(el)) {
      for (var i = 0; i < el.length; i++) {
        createStyle(el[i]);
      }
    } else {
      Object.keys(el).forEach((key) => {

        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        style[key] = el[key];
      });
    }
  };

  createStyle(props.style);

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ display: string; flexDirection: string; }'... Remove this comment to see the full error message
    <div id={props.source} style={style} className="view">
      {props.children}
    </div>
  );
};
