import React from "react";
import { mergeStyles, useTheme, Text, ProgressIndicator, Icon } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";

const ProcessingTimeout = () => {
  const [t] = useTranslation();
  const { semanticColors, effects } = useTheme();

  const sessionTimeoutClass = mergeStyles({
    top: "50%",
    left: "50%",
    height: 110,
    width: 240,
    backgroundColor: semanticColors.bodyBackground,
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: 20,
    borderRadius: effects.roundedCorner2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "left",
    border: "1px solid black",
  });

  const modal = (
    <div id="processingTimeout" className={rootClass}>
      <div className={sessionTimeoutClass}>
        <Text variant="large" block style={{ width: "100%" }}>
          <Icon iconName="clock" style={{ paddingRight: 10 }} /> {t("messages.status.pleaseWait")}
        </Text>
        <ProgressIndicator barHeight={5} styles={progressStyle} />
        <Text variant="medium" block style={{ width: "100%", textAlign: "center" }}>
          {t("messages.status.processingTimeout")}
        </Text>
      </div>
    </div>
  );

  const layerHostId = "fluent-default-layer-host";
  let layerHost = document.getElementById(layerHostId);
  if (!layerHost) {
    const host = document.createElement("div");
    host.setAttribute("id", layerHostId);
    host.style.cssText = "z-index:1000000";
    layerHost = document.body.appendChild(host);
  }

  return ReactDOM.createPortal(modal, layerHost);
};

export default ProcessingTimeout;

const progressStyle = {
  root: {
    width: "100%",
  },
};

const rootClass = mergeStyles({
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 1000001,
  position: "absolute",
  display: "none",
  backgroundColor: "rgba(100,100,100,0.25);",
});
