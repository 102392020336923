import { authenticationService } from "./authenticationService";
import { setSessionTimeout, showDialog } from "../../store/slices/portalSlice";
import store from "../../store/store";

export const download = (url: any) => {
  const requestOptions: RequestInit = { method: "GET", headers: authHeader() };
  //@ts-ignore
  requestOptions.headers["responseType"] = "blob";

  let filename = "download";

  return fetch(url, requestOptions)
    .then(function (response) {
      var disposition = response.headers.get("Content-Disposition");
      if (disposition) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      return response.blob();
    })
    .then(function (myBlob) {
      return new Promise(function (resolve) {
        (myBlob as any).filename = filename;
        resolve(myBlob);
      });
    });
};

export const postRequest = (url: string, request: any) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(request),
  };

  let timer = setInterval(onTimeOut, 3000);
  return fetch(url, requestOptions).then((response) => {
    clearTimeout(timer);
    return handleResponse(response, url, request);
  });
};

export const getRequest = (url: string) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(url, requestOptions).then((response) => {
    return handleResponse(response, url, {});
  });
};

export const handleResponse = (response: any, url: any, request: any) => {
  return response.text().then((text: any) => {
    let contentType = response.headers.get("content-type");
    let data = {};
    if (contentType && contentType.indexOf("application/json") > -1) {
      data = text && JSON.parse(text);
    } else {
      data = text;
    }
    if (!response.ok) {
      if (response.status === 408 && response.headers.has("usersessiontimeout")) {
        store.dispatch(setSessionTimeout(true));
      } else {
        if (response.status === 401 && response.headers.has("Token-Expired")) {
          const user = authenticationService.currentUserValue;
          if (user && user.refreshToken) {
            return getRefreshToken(user).then((result) => {
              if (result != "") {
                authenticationService.refreshUserToken((result as any).accessToken, (result as any).refreshToken);
              }
              return postRequest(url, request);
            });
          }
        }
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          //authenticationService.logout();
          //window.location.reload(true);
          var baseUrl = window.location.protocol + "//" + window.location.host + "/login";
          window.location.assign(baseUrl);
        }
        if (response.status === 555) {
          handleError(data);
          return Promise.reject({});
        }
        if (response.status === 556) {
          return Promise.reject({});
        }
        const error = (data && (data as any).message) || data || response.statusText;
        return Promise.reject(error);
      }
    }
    return data;
  });
};

const handleError = (error: any) => {
  //const e = JSON.parse(error);
  store.dispatch(
    showDialog({
      type: "error",
      id: error.traceId,
      msg: error.detail,
      title: error.title,
      parentPageId: "",
      details: error.instance,
      //additionalInformation: error.type,
    })
  );
};
const getRefreshToken = (user: any) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ accessToken: user.token, refreshToken: user.refreshToken }),
  };

  return fetch("login/refreshtoken", requestOptions).then(
    (response) => {
      return response.text().then((text) => {
        let contentType = response.headers.get("content-type");
        let data = {};
        if (contentType !== "application/javascript") {
          data = text && JSON.parse(text);
        } else {
          data = text;
        }
        if (response.ok) {
          return Promise.resolve(data);
        }
        authenticationService.logout();
        window.location.reload();
      });
    },
    (error) => {
      debugger;
    }
  );
};

const authHeader = (): HeadersInit => {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (currentUser) {
    if (currentUser.windows) {
      return { mode: "cors", credentials: "include" };
    } else if (currentUser.token) {
      return {
        Authorization: `Bearer ${currentUser.token}`,
        "Content-Type": "application/json",
        "Accept-Type": "application/json",
        TabId: window.sessionStorage.getItem("tabid") ?? "",
      };
    } else {
      return {};
    }
  } else {
    return {
      "Content-Type": "application/json",
      "Accept-Type": "application/json",
      TabId: window.sessionStorage.getItem("tabid") ?? "",
    };
  }
};

const onTimeOut = () => {
  const e = document.getElementById("processingTimeout");
  if (e) e.style.display = "block";
};

const clearTimeout = (timer: NodeJS.Timer) => {
  clearInterval(timer);
  const e = document.getElementById("processingTimeout");
  if (e) e.style.display = "none";
};
