import { useEffect, useState } from "react";
import { VersionDto } from "../types/commonTypes";

const useAppSpaceVersion = () => {
  const [version, setVersion] = useState<VersionDto | null>(null);
  useEffect(() => {
    fetch("api/version", requestOptions)
      .then((response) => response.json())
      .then((data) => setVersion(data))
      .catch(console.error);
  }, []);

  return version;
};

export default useAppSpaceVersion;

const requestOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json", "Accept-Type": "application/json" },
};
