import React from "react";
export const Text = (props: any) => {
  let style = {};
  const createStyle = (el: any) => {
    if (!el) return;
    if (Array.isArray(el)) {
      for (var i = 0; i < el.length; i++) {
        createStyle(el[i]);
      }
    } else {
      if (typeof el === "object") {
        Object.keys(el).forEach((key) => {
          if (el[key]) {

            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            style[key] = el[key];
          }
        });
      }
    }
  };
  createStyle(props.style);
  (style as any).zIndex = 1;
  return (
    <div id="text" style={style}>
      {props.children}
    </div>
  );
};
