import React from "react";
import { cssColor, PrimaryButton } from "@fluentui/react";

interface LoginButtonProperties {
  provider: LoginProvider;
  externalProvider?: boolean;
  onClick: Function;
}

function LoginButton(props: LoginButtonProperties) {
  const { provider, onClick, externalProvider } = props;
  const { id, caption, color } = provider;

  const baseColor = cssColor(color) ?? { r: 162, g: 0, b: 103 };
  const buttonColor = `rgb(${baseColor.r},${baseColor.g},${baseColor.b})`;
  const buttonColorDarker = `rgba(${baseColor.r},${baseColor.g},${baseColor.b},0.5)`;
  const buttonColorDark = `rgba(${baseColor.r},${baseColor.g},${baseColor.b},0.2)`;

  const buttonStyles = {
    root: {
      width: "100%",
      backgroundColor: buttonColorDark,
      borderColor: buttonColor,
      marginBottom: 15,
      color: "white",
    },
    rootHovered: {
      backgroundColor: buttonColor,
      borderColor: buttonColor,
    },
    rootPressed: {
      backgroundColor: buttonColorDarker,
      borderColor: buttonColor,
    },
    flexContainer: {
      flexFlow: "row-reverse",
    },
    textContainer: {
      flexGrow: 0,
    },
  };

  return (
    <PrimaryButton
      styles={buttonStyles}
      data-debugid={`loginButton-${id}`}
      text={`Mit ${caption} anmelden`}
      onClick={() => onClick()}
      iconProps={externalProvider ? { iconName: "OpenInNewTab" } : undefined}
      allowDisabledFocus
    />
  );
}

export default LoginButton;
