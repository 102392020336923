import { createSlice } from "@reduxjs/toolkit";

const wizardSlice = createSlice({
    name: "wizard",
    initialState: {
        sql: {
            server: "",
            useExistingDB: false,
            database: "",
            newDatabaseName: "Portal",
            integratedSecurity: true,
            username: "",
            password: "",
            passwordConfirm: "",
            dataFilePath: "",
            logFilePath: "",
        },
        login: {
            loginType: "usernamePassword",
            username: "",
            password: "",
            passwordConfirm: "",
            needsLogin: true
        },
        errors: [],
        loading: false,
        availableDatabases: [],
    },
    reducers: {
        setSqlField(state, action) {

            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            state.sql[action.payload.name] = action.payload.value;
        },
        setLoginField(state, action) {

            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            state.login[action.payload.name] = action.payload.value;
        },
        setErrors(state, action) {

            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
            state.errors = [...action.payload];
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setAvailableDatabases(state, action) {

            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
            state.availableDatabases = [...action.payload];
        },
    },
});

export const { setSqlField, setLoginField, setErrors, setLoading, setAvailableDatabases } = wizardSlice.actions;

export default wizardSlice.reducer;
