import { PluginDto } from "./commonTypes";

export interface PortalState {
  [property: string]: any;
  plugins: { [pluginId: string]: PluginDto };
  messageDialogs: Dialog[];
  confirmDialogs: Dialog[];
  errorDialogs: Dialog[];
  progressBars: ProgressBar[];
  portalSiteTitleObject?: PortalSiteTitleObject;
  pluginIcons: PluginIcons;
  theme: TenantTheme;
  language: string;
  timezone: string;
  profile?: UserProfile;
  changePasswordPanelOpen: boolean;
  portalSettingsPaneOpen: boolean;
  portalNotificationPaneOpen: boolean;
  tfaWizardOpen: boolean;
  tfaWizardLoginId: number | null;
  showTfaOption: boolean;
  showPasswordOption: boolean;
  showTenantDropdown: boolean;
  showProfileDropdown: boolean;
  unreadNotifications: number;
}

export interface Dialog {
  type: "msg" | "error" | "confirm";
  id: string;
  msg: string;
  title: string;
}

export interface PortalSiteTitleObject {
  plugin?: string;
  page?: string;
  dataId?: string;
  status?: string;
}

export interface ChangePortalSiteTitlePayload {
  portalSiteTitleObject: PortalSiteTitleObject;
}

export interface TenantTheme {
  style: "dark" | "light" | "custom";
  color: "blue" | "magenta" | "green" | "orange" | "violet" | "appTheme";
}

export interface TenantSettings {
  theme: TenantTheme;
  language: string;
  timezone: string;
}

export interface ProgressBar {
  id: string;
  title: string;
  description: string;
  progress: number;
  indeterminate: boolean;
  bulletPoints?: ProgressBarBulletPoint[];
}

export interface ProgressBarBulletPoint {
  text: string;
  state: ProgressBarBulletStatus;
}

export enum ProgressBarBulletStatus {
  None,
  Waiting,
  Processing,
  Processed,
  Failed,
}

export interface UserProfile {
  profileId: number;
  profileName: string;
}

export interface PluginIcons {
  [pluginToken: string]: {
    icon?: string;
    appTheme?: number;
  };
}
