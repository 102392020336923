import React, { useEffect, useState } from "react";

import AdaptiveCard from "./adaptivecards/AdaptiveCard";
import { tenantService } from "../authorization/tenantService";

const Widget = (props: any) => {
  const [payload, setPayload] = useState(undefined);

  useEffect(() => {
    tenantService
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      .loadWidgetPayload({ pluginToken: props.payload.pluginToken, instanceId: props.payload.instanceId })
      .then((p) => {
        setPayload(p);
      });
  }, [props.payload]);

  if (!payload) {
    return null;
  }

  return (
    <div style={{ width: "100%", maxWidth: 900 }}>
      <AdaptiveCard payload={payload} />
    </div>
  );
};

export default Widget;
