import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
  DefaultButton,
  PrimaryButton,
  FontWeights,
  Text,
  Icon,
  ContextualMenu,
  mergeStyleSets,
  Callout,
  Dropdown,
  Checkbox,
} from "@fluentui/react";
import { setTfaEnabled, setTfaWizardOpen } from "../../store/slices/portalSlice";
import { RootState, useAppDispatch } from "../../store/store";
import { useTranslation } from "react-i18next";
import { userService } from "../authorization/userService";
import { useSelector } from "react-redux";
import MicrosoftAuthenticatorIcon from "../../images/MicrosoftAuthenticatorIcon.png";
import GoogleAuthenticatorIcon from "../../images/GoogleAuthenticatorLogo.svg";
import GoogePlay from "../../images/google-play-de-de.svg";
import AppStore from "../../images/app-store.svg";
import { Shimmer, ShimmerElementType } from "@fluentui/react";
import AuthCode from "./AuthCode";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { mergeStyles } from "@fluentui/react/lib/Styling";

const TwoFactorSetup = (props: any) => {
  const enableTwoFactor = useSelector((state: RootState) => state.portal.enableTwoFactor);
  const tfaWizardOpen = useSelector((state: RootState) => state.portal.tfaWizardOpen);
  const tfaWizardLoginId = useSelector((state: RootState) => state.portal.tfaWizardLoginId);
  const [t] = useTranslation();
  const [manualKeyOpen, { toggle: toggleManualKeyOpen }] = useBoolean(false);
  const [providers, setProviders] = useState<any>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [selectedProvider, setSelectedProvider] = useState<any>(null);
  const [providerOptions, setProviderOptions] = useState<any>([]);
  const [enable, setEnable] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [providerMetadata, setProviderMetadata] = useState<any>(undefined);
  const [code, setCode] = useState<string>("");
  const buttonId = useId("callout-manualkey");
  const [providerSelectionOpen, setProviderSelectionOpen] = useState<boolean>(false);
  const [deactivateChecked, setDeactivateChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!tfaWizardOpen) {
      return;
    }
    setProviderMetadata(undefined);
    userService.getTfaProviders(tfaWizardLoginId).then(
      (result) => {
        setProviders(result.providers);
        const enabled = result.selectedprovider.enabled && result.selectedprovider.id > 0;
        if (enabled) {
          setEnable(true);
        } else {
          setEnable(false);
        }
        setSelectedProvider(null);
        const options: any = [];
        if (result.providers) {
          result.providers.map((p: { id: number; name: string }) => {
            options.push({ key: p.id, text: p.name });
          });

          if (enabled) {
            const current = result.providers.find((p: { id: number }) => p.id == result.selectedprovider.id);
            if (current) {
              setSelectedProvider(current);
            }
          } else {
            if (result.providers.length > 0) {
              setSelectedProvider(result.providers[0]);
            }
          }
        }
        setProviderOptions(options);
      },
      (error) => {}
    );
  }, [tfaWizardOpen, tfaWizardLoginId]);

  useEffect(() => {
    if (providerMetadata || !selectedProvider || !tfaWizardOpen) {
      return;
    }
    userService.getTfaProviderMetadata(selectedProvider.id, tfaWizardLoginId).then(
      (result) => {
        setProviderMetadata(result.provider);
      },
      (error) => {
        setProviderMetadata(undefined);
      }
    );
  }, [currentStep]);

  if (!tfaWizardOpen) {
    return null;
  }

  const dialogContentProps = {
    type: DialogType.normal,
    title: (
      <span>
        <div style={{ width: 40, height: 40, position: "absolute" }}>
          <CircularProgressbar value={(currentStep + 1) * 20} />
        </div>
        <Icon style={{ position: "absolute", left: 34, top: 26 }} iconName="AuthenticatorApp" />
        <span style={{ position: "absolute", left: 80, top: 20 }}>
          Zwei-Faktor-Authentifizierung {enable ? "löschen" : "einrichten"}
        </span>
      </span>
    ),
    showCloseButton: enableTwoFactor != true,
  };

  const renderQrCode = () => {
    if (!selectedProvider || !providerMetadata) {
      return <Shimmer shimmerElements={[{ type: ShimmerElementType.line, width: 150, height: 150 }]} />;
    }
    const image = atob(providerMetadata.qrCode);
    return <img style={{ width: 150, margin: "auto", paddingLeft: 25 }} src={image} />;
  };

  const deactivate = () => {
    userService.deactivateTfa().then(
      () => {
        if (!enableTwoFactor) {
          onDismiss();
        }
      },
      (error) => setError(error)
    );
  };

  const onChangeProvider = (event: any, item: any): void => {
    const provider = providers.find((p: any) => p.id === item.key);
    if (provider) {
      setSelectedProvider(provider);
    }
  };

  const openUrl = (url: string) => {
    window.open(url);
  };

  const renderProvider = (name: string, logo: any) => {
    const renderImages = selectedProvider.appleStoreLink?.length > 0 || selectedProvider.playstoreLink?.length > 0;
    if (!renderImages) {
      return null;
    }
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 0, margin: "auto" }}>
            <img style={{ width: 100 }} src={logo} alt="Authenticator" />
          </div>
          <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 20 }}>
            <div style={{ height: 10 }}></div>
            <div style={{ marginBottom: 20 }}>
              <b>{name}</b> in einem separaten Tab öffnen.
            </div>
            <div style={{ display: "flex" }}>
              {selectedProvider.playstoreLink?.length > 0 && (
                <div>
                  <img
                    style={{ width: 120, cursor: "pointer" }}
                    src={GoogePlay}
                    alt="Google Play"
                    onClick={() => openUrl(selectedProvider.playstoreLink)}
                  />
                </div>
              )}
              {selectedProvider.appleStoreLink?.length > 0 && (
                <div style={{ marginLeft: 50 }}>
                  <img
                    style={{ width: 120, cursor: "pointer" }}
                    src={AppStore}
                    alt="Apple Store"
                    onClick={() => openUrl(selectedProvider.appleStoreLink)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderFirstStep = () => {
    let name = selectedProvider.name;
    let logo: any = undefined;
    switch (selectedProvider.type) {
      case "MircrosoftAuthenticator":
        logo = MicrosoftAuthenticatorIcon;
        break;
      case "GoogleAuthenticator":
        logo = GoogleAuthenticatorIcon;
        break;
      case "Other":
        logo = undefined;
        break;
    }
    const selectedKey = selectedProvider?.id ?? 0;

    return (
      <WizardBody title="Was brauche ich dafür?">
        <React.Fragment>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">
              Um mit der Einrichtung loslegen zu können, benötigen Sie die "{name}" App auf Ihrem mobilen Endgerät:
            </Text>
          </div>
          <div>
            <div>{renderProvider(name, logo)}</div>
          </div>
          {providers && providers.length > 1 && (
            <div>
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                <a
                  style={{ textDecoration: "unset", cursor: "pointer" }}
                  href="#"
                  onClick={() => setProviderSelectionOpen(true)}
                >
                  Ich möchte lieber eine andere App verwenden.
                </a>
              </div>
              <div style={{ width: 250 }}>
                {providerSelectionOpen && (
                  <Dropdown
                    style={{ marginBottom: 10 }}
                    options={providerOptions}
                    selectedKey={selectedKey}
                    onChange={onChangeProvider}
                  />
                )}
              </div>
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            Wenn Sie mit der Installation der App fertig sind, klicken Sie bitte auf "Weiter".
          </div>
        </React.Fragment>
      </WizardBody>
    );
  };

  const renderThirdStep = () => {
    return (
      <WizardBody title="Jetzt habe ich ein Konto... und nun?">
        <React.Fragment>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">
              Scannen Sie nun mit Hilfe der Authenticator App den abgebildeten QR Code. Dieser Schritt verknüpft Ihr
              erstelltes Konto mit dem AppSpace.
            </Text>
          </div>
          <div>
            <Text variant="medium">Klicken Sie auf "Weiter" wenn Sie den QR Code gescannt haben.</Text>
          </div>
          <div>{renderQrCode()}</div>
          <DefaultButton id={buttonId} text="Scannen nicht möglich?" onClick={toggleManualKeyOpen} />
          {manualKeyOpen && (
            <Callout
              className={styles.callout}
              gapSpace={0}
              target={`#${buttonId}`}
              onDismiss={toggleManualKeyOpen}
              setInitialFocus
            >
              <Text block variant="xLarge" className={styles.title}>
                Einrichtungs mittels manuellem Schlüssel
              </Text>
              <Text block variant="small">
                Fügen Sie ein neues Konto in der Authenticator App hinzu und wählen Sie "Code manuell eingeben" Geben
                Sie anschließend folgenden Code ein:
              </Text>
              <Text block variant="medium" style={{ marginTop: 10 }}>
                {providerMetadata.manualSetupKey}
              </Text>
            </Callout>
          )}
        </React.Fragment>
      </WizardBody>
    );
  };

  const renderSecondStep = () => {
    return (
      <WizardBody title="Wie richte ich die App ein?">
        <React.Fragment>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">
              Bitte erteilen Sie der App die Erlaubnis Benachrichtigungen anzuzeigen, um zukünftige Berechtigungen
              schneller zu ermöglichen.
            </Text>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">Legen Sie ein neues Konto mit dem Typ "Anderes Konto" an.</Text>
          </div>
          <div>
            <Text variant="medium">Nachdem Sie das Konto angelegt haben, klicken Sie auf "Weiter".</Text>
          </div>
        </React.Fragment>
      </WizardBody>
    );
  };

  const renderFourthStep = () => {
    return (
      <WizardBody title="Wie geht es weiter?">
        <React.Fragment>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">
              Sie erhalten nun per Authenticator App einen Sicherheitscode. Bitte geben Sie den Code ein
            </Text>
          </div>
          <div>
            <AuthCode
              length={providerMetadata.codeLength}
              onChange={(s) => setCode(s)}
              onEnter={(s) => {}}
              containerClassName={contentStyles.authContainer}
              inputClassName={mergeStyles(contentStyles.input, error ? contentStyles.errorInput : undefined)}
            />
            {error && (
              <div className={contentStyles.errorWrapper}>
                <Text style={{ color: "red" }} variant="medium">
                  {error}
                </Text>
              </div>
            )}
          </div>
          <div style={{ marginTop: 20 }}>
            <Text variant="medium">Klicken Sie auf "Weiter" wenn Sie den Code eingegeben haben.</Text>
          </div>
        </React.Fragment>
      </WizardBody>
    );
  };

  const renderFifthStep = () => {
    return (
      <WizardBody title="Wie geht es weiter?">
        <React.Fragment>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">Vielen Dank! Die Einrichtung ist abgeschlossen.</Text>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">Sie müssen nichts Weiteres mehr erledigen.</Text>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">
              Ab sofort erhalten Sie nach dem Login per Authenticator App einen Sicherheitscode als Benachrichtigung.
              Diesen Code geben Sie bitte ein, um Zugang in den AppSpace zu erhalten.
            </Text>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">Ihr Account ist nun geschützt.</Text>
          </div>
        </React.Fragment>
      </WizardBody>
    );
  };

  const deactivateStep = () => {
    return (
      <WizardBody title="Wie mach ich das?">
        <React.Fragment>
          <div style={{ marginBottom: 20 }}>
            <Text variant="medium">
              Bitte bestätigen Sie. dass Sie Ihre mobiles Endgerät nicht mehr für Ihre Zwei-Faktor-Authentifizierung
              verwenden möchten.
            </Text>
            <br />
            <Text variant="medium">
              Das mobile Endgerät wird im Anschluss aus Ihren Kontoeinstellungen entfernt und wir bitten Sie bei Bedarf
              erneut ein mobiles Endgerät einzurichten, um Ihr Konto zusätzlich durch Zwei-Faktor-Authentifizierung zu
              schützen.
            </Text>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Checkbox
              label="Ich möchte mein mobiles Endgerät nicht mehr für meine Zwei-Faktor-Authentifizierung verwenden. Das Gerät kann unwiderruflich gelöscht werden."
              checked={deactivateChecked}
              onChange={(e, checked) => setDeactivateChecked(checked ?? false)}
            />
          </div>
        </React.Fragment>
      </WizardBody>
    );
  };

  const renderStep = () => {
    if (!selectedProvider) {
      return null;
    }

    if (enable) {
      return deactivateStep();
    }

    switch (currentStep) {
      case 0:
        return renderFirstStep();
      case 1:
        return renderSecondStep();
      case 2:
        return renderThirdStep();
      case 3:
        return renderFourthStep();
      case 4:
        return renderFifthStep();
    }
  };

  const setNextStep = () => {
    switch (currentStep) {
      case 0:
        if (selectedProvider.type === "Other") {
          setCurrentStep(2);
        } else {
          setCurrentStep(1);
        }
        break;
      case 1:
        setCurrentStep(2);
        break;
      case 2:
        setCurrentStep(3);
        break;
      case 3: {
        setError(undefined);
        if (!code || code.length !== providerMetadata.codeLength) {
          setError("Bitte geben Sie einen Code ein");
          return;
        }

        userService.activateTfa(selectedProvider.id, code, tfaWizardLoginId).then(
          (result) => {
            if (result.success === true) {
              dispatch(setTfaEnabled({}));
              setCurrentStep(4);
              setError(undefined);
            } else {
              setError("Der eingebene Code ist ungültig.");
            }
          },
          (error) => setError(error)
        );
        break;
      }
    }
  };

  const setPreviousStep = () => {
    if (currentStep === 2) {
      if (selectedProvider.type === "Other") {
        setCurrentStep(0);
      } else {
        setCurrentStep(currentStep - 1);
      }
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onDismiss = () => {
    setCurrentStep(0);
    setCode("");
    setSelectedProvider(providers[0]);
    setProviderSelectionOpen(false);
    setError(undefined);
    dispatch(setTfaWizardOpen(false));
  };

  const renderFooter = () => {
    return (
      <DialogFooter>
        {currentStep === 0 && !enableTwoFactor && (
          <DefaultButton data-debugid="cancelButton" onClick={onDismiss} text={t("buttons.cancel")} />
        )}
        {currentStep > 0 && currentStep < 4 && (
          <DefaultButton data-debugid="cancelButton" onClick={setPreviousStep} text={t("buttons.back")} />
        )}
        {currentStep === 4 && (
          <DefaultButton data-debugid="cancelButton" onClick={onDismiss} text={t("buttons.finish")} />
        )}

        {enable && (
          <PrimaryButton
            disabled={!deactivateChecked}
            data-debugid="activateTfa"
            onClick={() => deactivate()}
            text="Löschen"
          />
        )}
        {enable === false && currentStep < 4 && (
          <PrimaryButton data-debugid="activateTfa" onClick={() => setNextStep()} text={t("buttons.next")} />
        )}
      </DialogFooter>
    );
  };

  return (
    <React.Fragment>
      <Dialog hidden={false} onDismiss={onDismiss} dialogContentProps={dialogContentProps} modalProps={modalProps}>
        <div style={{ height: 340, paddingTop: 10 }}>{renderStep()}</div>
        {renderFooter()}
      </Dialog>
    </React.Fragment>
  );
};

export default TwoFactorSetup;

const contentStyles = mergeStyleSets({
  errorWrapper: {
    alignItems: "center",
    color: "red",
    marginTop: 20,
    textAlign: "center",
    display: "block",
  },
  errorInput: {
    borderColor: "red",
  },
  authContainer: {
    textAlign: "center",
  },
  input: {
    width: 30,
    margin: 5,
    height: 40,
    fontSize: "20pt",
    textAlign: "center",
  },
});

const modalPropsStyles = {
  main: { height: "480px !important", width: "600px !important", maxWidth: "100vh !important" },
};

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};

const modalProps = {
  isBlocking: true,
  styles: modalPropsStyles,
  dragOptions: dragOptions,
};

const WizardBody = (props: { title: string; children?: JSX.Element }) => {
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Text variant="large">{props.title}</Text>
      </div>
      {props.children}
    </div>
  );
};

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 540,
    maxWidth: "90%",
    padding: "20px 24px",
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: "block",
    marginTop: 20,
  },
});
