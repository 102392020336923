import React, { useEffect, useState } from "react";
import { mergeStyleSets, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { userService } from "../../authorization/userService";
import ChangePasswordCriterion from "./ChangePasswordCriterion";

interface ChangePasswordCriteriaProperties {
  password: string;
  style?: React.CSSProperties;
}

type PasswordCriteria = {
  min_password_length: number | null;
  uppercase_char_required: boolean | null;
  lowercase_char_required: boolean | null;
  numeric_char_required: boolean | null;
  special_char_required: boolean | null;
};

const ChangePasswordCriteria = (props: ChangePasswordCriteriaProperties) => {
  const { password } = props;
  const [passwordCriteria, setPasswordCriteria] = useState<PasswordCriteria | undefined>(undefined);
  const [t] = useTranslation();

  const getCriteria = async () => {
    try {
      const response = await userService.getPasswordCriteria();
      if (response?.success) {
        setPasswordCriteria(JSON.parse(response.criteria));
      }
    } catch (e) {
      console.log(t("dialogs.changePasswordPanel.errorGettingPasswordCriteria"));
    }
  };

  useEffect(() => {
    getCriteria();
  }, []);

  if (
    !passwordCriteria ||
    (!passwordCriteria.min_password_length &&
      !passwordCriteria.uppercase_char_required &&
      !passwordCriteria.lowercase_char_required &&
      !passwordCriteria.numeric_char_required &&
      !passwordCriteria.special_char_required)
  )
    return null;

  return (
    <div className={classNames.criteriaWrapper}>
      <Text block styles={headerTextStyles} style={props.style}>
        {t("dialogs.changePasswordPanel.newPasswordMustContain")}
      </Text>

      {passwordCriteria.min_password_length && (
        <ChangePasswordCriterion
          style={props.style}
          text={t("dialogs.changePasswordPanel.minLength", { length: passwordCriteria.min_password_length })}
          met={password.length >= passwordCriteria.min_password_length}
        />
      )}

      {passwordCriteria.uppercase_char_required && (
        <ChangePasswordCriterion
          style={props.style}
          text={t("dialogs.changePasswordPanel.oneUppercaseLetter")}
          met={/[A-Z]/.test(password)}
        />
      )}

      {passwordCriteria.lowercase_char_required && (
        <ChangePasswordCriterion
          style={props.style}
          text={t("dialogs.changePasswordPanel.oneLowercaseLetter")}
          met={/[a-z]/.test(password)}
        />
      )}

      {passwordCriteria.numeric_char_required && (
        <ChangePasswordCriterion
          style={props.style}
          text={t("dialogs.changePasswordPanel.oneNumber")}
          met={/[0-9]/.test(password)}
        />
      )}

      {passwordCriteria.special_char_required && (
        <ChangePasswordCriterion
          style={props.style}
          text={t("dialogs.changePasswordPanel.oneSpecialCharacter")}
          met={/[!"#$%&'()*+,-./<=>?@]/.test(password)}
        />
      )}
    </div>
  );
};

export default ChangePasswordCriteria;

const classNames = mergeStyleSets({
  criteriaWrapper: {
    marginBlock: "1em",
  },
});

const headerTextStyles = {
  root: {
    marginBottom: "0.5em",
  },
};
