import React, { useRef } from "react";
import { useSelector } from "react-redux";
import {
  Nav,
  Shimmer,
  ActionButton,
  Text,
  useTheme,
  mergeStyleSets,
  css,
  INavButtonProps,
  concatStyleSets,
  CommandBarButton,
  INav,
} from "@fluentui/react";
import {
  togglePluginNavigation,
  setPlugin,
  setQueuedLink,
  setPluginMenu,
  setPageLink,
} from "../../store/slices/portalSlice";
import { pluginService } from "../authorization/pluginService";
import { RootState, useAppDispatch } from "../../store/store";
import { cloneDeep } from "lodash";
import PluginIcon from "./PluginIcon";

const drawerWidth = 75;

type PluginBarProperties = {
  mobile?: boolean;
  parentDismiss?: () => void;
};

const PluginBar = (props: PluginBarProperties) => {
  const { mobile = false, parentDismiss } = props;
  const open = useSelector((state: RootState) => state.portal.pluginNavigationOpen);
  const plugins = useSelector((state: RootState) => state.portal.plugins);
  const selectedPlugin = useSelector((state: RootState) => state.portal.plugin);
  const selectedPage = useSelector((state: RootState) => state.portal.pageLink);
  const menuLoaded = useSelector((state: RootState) => state.portal.menuLoaded);
  const pluginMenu = useSelector((state: RootState) => state.portal.pluginMenu);
  const dispatch = useAppDispatch();
  const rootNavBar = useRef<INav>(null);
  const pluginNavBar = useRef<INav>(null);
  const systemNavBar = useRef<INav>(null);

  const currentPlugin = plugins[selectedPlugin];
  const pluginTitle = currentPlugin?.caption ?? "";

  const pluginGroup = React.useMemo(() => {
    if (!pluginMenu?.navigation) return [];

    const addDebugIds = (listOfLinks: any) => {
      return listOfLinks.map((link: any) => ({ ...link, ["data-debugid"]: link.name, links: addDebugIds(link.links) }));
    };

    const newLinks = addDebugIds(cloneDeep(pluginMenu.navigation));

    return [{ links: newLinks }];
  }, [pluginMenu]);

  const rootLinks = React.useMemo(() => {
    const pluginLinks: Array<any> = [];
    const systemLinks: Array<any> = [];
    Object.keys(plugins).forEach((pid) => {
      const p = plugins[pid];
      const item = {
        iconOnly: true,
        name: p.caption,
        key: p.id,
        iconProps: { iconName: p.icon },
        isPlugin: true,
        area: p.area,
        id: p.id,
        ["data-debugid"]: p.caption,
      };
      switch (p.area) {
        case "PLUGINS": {
          pluginLinks.push(item);
          break;
        }
        case "SYSTEM": {
          systemLinks.push(item);
          break;
        }
      }
    });

    if (mobile)
      pluginLinks.unshift({ key: "home", iconOnly: true, iconProps: { iconName: "WaffleOffice365" }, isPlugin: true });

    return { pluginLinks, systemLinks };
  }, [plugins]);

  const linkGroups = React.useMemo(
    () => ({
      pluginLinkGroups: [{ links: rootLinks.pluginLinks }],
      systemLinkGroups: [{ links: rootLinks.systemLinks }],
    }),
    [rootLinks]
  );

  const onClick = (e: any, i: any) => {
    if (i.isPlugin) {
      dispatch(setQueuedLink(null));
      if (i.key === "home") {
        dispatch(setPlugin(undefined));
        parentDismiss?.();
      } else {
        dispatch(setPlugin({ pluginId: i.key }));
        dispatch(setPluginMenu(i.key));
      }
    } else {
      dispatch(setQueuedLink(null));
      switch (i.linkType) {
        case 2:
          return;
        case 0: {
          dispatch(setPageLink(i.key));
          parentDismiss?.();
          break;
        }
        case 3: {
          i.isExpanded = false;
          dispatch(setPageLink(i.key));
          parentDismiss?.();
          break;
        }
        default: {
          pluginService.onLinkClicked(i.key);
          parentDismiss?.();
          return;
        }
      }
    }
  };

  const { semanticColors } = useTheme();

  const dynamicClassNames = mergeStyleSets({
    pluginBarWrapper: {
      display: "flex",
      flexGrow: 1,
      overflow: "hidden",
      backgroundColor: semanticColors.bodyBackground,
      borderColor: semanticColors.disabledSubtext,
      borderStyle: mobile ? "none" : "solid",
      borderWidth: "0 1px 1px 1px",
    },
    pluginBar: {
      width: drawerWidth,
      minWidth: drawerWidth,
      maxWidth: drawerWidth,
      display: "flex",
      flexDirection: "column",
    },
    pluginNavigationBar: {
      display: selectedPlugin && (open || mobile) && currentPlugin ? "flex" : "none",
      flexGrow: 1,
      flexDirection: "column",
      borderLeft: `1px solid ${semanticColors.disabledSubtext}`,
      overflow: "hidden",
    },
  });

  const chevronButtonStyles = {
    root: { minHeight: 32, borderBottom: `1px solid ${semanticColors.disabledSubtext}` },
    flexContainer: { justifyContent: "center" },
    icon: { fontSize: 18 },
  };

  return (
    <div className={dynamicClassNames.pluginBarWrapper}>
      <div className={css(fixedClassNames.navWrapper, fixedClassNames.scrollbars, dynamicClassNames.pluginBar)}>
        <CommandBarButton
          onClick={() => (mobile && parentDismiss ? parentDismiss() : dispatch(togglePluginNavigation()))}
          iconProps={
            selectedPlugin && currentPlugin
              ? { iconName: open || mobile ? "ChevronLeft" : "ChevronRight" }
              : mobile && parentDismiss
              ? { iconName: "ChevronLeft" }
              : undefined
          }
          data-debugid="togglePluginMenuButton"
          allowDisabledFocus
          styles={chevronButtonStyles}
          disabled={!(selectedPlugin && currentPlugin) && !(mobile && parentDismiss)}
        />
        <div className={css(fixedClassNames.navWrapper, fixedClassNames.scrollbars)} data-debugid="pluginBar">
          <Nav
            onLinkClick={onClick}
            ariaLabel="Portal Navigation"
            groups={linkGroups.pluginLinkGroups}
            componentRef={rootNavBar}
            linkAs={PluginBarIconButton}
            styles={navStyles}
            selectedKey={
              !rootLinks.pluginLinks.some((i) => i.key === selectedPlugin) || selectedPlugin === undefined
                ? null
                : selectedPlugin
            }
          />
        </div>
        <div data-debugid="systemPluginBar">
          <Nav
            onLinkClick={onClick}
            ariaLabel="System Navigation"
            groups={linkGroups.systemLinkGroups}
            styles={concatStyleSets(systemBarStyles, navStyles)}
            componentRef={systemNavBar}
            linkAs={PluginBarIconButton}
            selectedKey={
              !rootLinks.systemLinks.some((i) => i.key === selectedPlugin) || selectedPlugin === undefined
                ? null
                : selectedPlugin
            }
          />
        </div>
      </div>

      <div className={dynamicClassNames.pluginNavigationBar}>
        <Text className={fixedClassNames.title} variant="mediumPlus">
          {pluginTitle}
        </Text>
        {!menuLoaded && (open || mobile) && getShimmer()}
        <div className={css(fixedClassNames.navWrapper, fixedClassNames.scrollbars)} data-debugid="pluginNavigationBar">
          <Nav
            componentRef={pluginNavBar}
            onLinkClick={onClick}
            ariaLabel="App Navigation"
            groups={menuLoaded ? pluginGroup : null}
            selectedKey={selectedPage === undefined ? null : selectedPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PluginBar;

const getShimmer = () => {
  return (
    <>
      <Shimmer width="75%" styles={shimmerStyles} />
      <Shimmer width="65%" styles={shimmerStyles} />
      <Shimmer width="70%" styles={shimmerStyles} />
      <Shimmer width="80%" styles={shimmerStyles} />
      <Shimmer width="40%" styles={shimmerStyles} />
    </>
  );
};

const navStyles = {
  link: {
    height: 56,
    padding: "0 4px",
    fontSize: 10,
    lineHeight: 12,
  },
  linkText: {
    margin: 0,
    width: "100%",
    textAlign: "center",
  },
};

const systemBarStyles = {
  groupContent: {
    marginBottom: 0,
  },
};

const fixedClassNames = mergeStyleSets({
  navWrapper: {
    flexGrow: 1,
    overflow: "hidden auto",
  },
  title: { padding: 5, fontWeight: 600 },
  scrollbars: {
    "::-webkit-scrollbar-corner": {
      background: "0 0",
    },
    "::-webkit-scrollbar": {
      width: 5,
      height: 5,
      backgroundColor: "#faf9f8",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#aaa",
    },
  },
});

const shimmerStyles = {
  shimmerWrapper: {
    marginLeft: 15,
    marginBottom: 10,
  },
  shimmerGradient: {
    backgroundImage: "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #c7e0f4 50%, rgba(255, 255, 255, 0) 100%)",
  },
};

const PluginBarIconButton = (props: INavButtonProps) => {
  const buttonStyles = {
    root: { padding: "0 4px" },
    flexContainer: { flexDirection: "column", justifyContent: "center" },
  };

  if (props.link?.key === "home") return <ActionButton {...props} styles={buttonStyles} />;

  return (
    <ActionButton
      {...props}
      styles={buttonStyles}
      onRenderIcon={() => <PluginIcon pluginToken={props.link?.key} size={32} />}
    />
  );
};
