import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { pluginService } from "../components/authorization/pluginService";
import { showDialog } from "../store/slices/portalSlice";
import { useAppDispatch } from "../store/store";

const useMicroFrontend = (id: string, pluginId: string) => {
  const scriptId = `${id}Bundle`;
  const [isLoaded, setLoaded] = useState<boolean>((window as any)[id]);
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const handleLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.addEventListener("load", handleLoad);

      return () => existingScript.removeEventListener("load", handleLoad);
    }

    pluginService.loadCustomComponent(id, pluginId).then(
      (result) => {
        const script = document.createElement("script");
        script.id = scriptId;
        script.text = result;
        script.setAttribute("tenant", "");
        document.body.appendChild(script);
        setLoaded(true);
      },
      (error) => {
        setLoaded(true);
        dispatch(
          showDialog({
            type: "error",
            id: "loadBaseUIError",
            title: t("messages.status.baseUINotFound"),
            msg: t("messages.status.baseUINotFoundIsBaseUIAppInstalled"),
          })
        );
      }
    );
  }, []);
  return { isLoaded, [id]: (window as any)[id] };
};

export default useMicroFrontend;
