import React from "react";
import { ITextFieldStyles, TextField } from "@fluentui/react";

interface ChangePasswordFieldProperties {
  type: "Old" | "New" | "Repeat";
  label: string;
  password: { value: string; error: string };
  dispatch: Function;
  style?: ITextFieldStyles;
}

const ChangePasswordField = (props: ChangePasswordFieldProperties) => {
  const { type, label, password, dispatch } = props;

  return (
    <TextField
      data-debugid={`${type}Password-input`}
      label={label}
      type="password"
      canRevealPassword
      required
      styles={props.style}
      autoComplete="off"
      value={password.value}
      errorMessage={password.error}
      onChange={(e, newValue) => dispatch({ type: `set${type}Password`, value: newValue ?? "" })}
    />
  );
};

export default ChangePasswordField;
