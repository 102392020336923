import React from "react";
import { StyleSheet } from "../../reactnative";
import { SelectAction } from "../actions";
import { ElementWrapper } from "../elements/ElementWrapper";
import { Column } from "./Column";
import * as Constants from "../../utils/constants";
import { HostConfigManager } from "../../utils/host-config";
import { ContainerWrapper } from "./";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const ColumnSet = (props) => {
  const payload = props.json;

  const parsePayload = () => {
    const p = payload;

    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'children' implicitly has type 'any[]' in... Remove this comment to see the full error message
    const children = [];
    if (!payload)
      // @ts-expect-error ts-migrate(7005) FIXME: Variable 'children' implicitly has an 'any[]' type... Remove this comment to see the full error message
      return children;

    // parse elements

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'element' implicitly has an 'any' type.
    p.columns.map((element, index) => {
      children.push(
        <Column
          json={element}
          columns={p.columns}
          hasBackgroundImage={p.parent.backgroundImage}
          key={`ELEMENT-${index}`}
          isFirst={index === 0}
          isLast={index === p.columns.length - 1}
        />
      );
    });

    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'children' implicitly has an 'any[]' type... Remove this comment to see the full error message
    return children.map((ChildElement) => React.cloneElement(ChildElement, { containerStyle: payload.style }));
  };

  const internalRenderer = () => {
    const p = payload;

    var columnSetContent = (
      <ContainerWrapper style={{ flex: payload.columns.length }} json={p} containerStyle={props.containerStyle}>
        <ElementWrapper json={p} style={styles.defaultBGStyle} isFirst={props.isFirst}>
          {parsePayload()}
        </ElementWrapper>
      </ContainerWrapper>
    );

    if (payload.selectAction === undefined || HostConfigManager.supportsInteractivity() === false) {
      return columnSetContent;
    } else {
      return <SelectAction selectActionData={payload.selectAction}>{columnSetContent}</SelectAction>;
    }
  };

  let containerRender = internalRenderer();
  return containerRender;
};

const styles = StyleSheet.create({
  defaultBGStyle: {
    backgroundColor: Constants.TransparentString,
    flexDirection: Constants.FlexRow,
  },
});
