import { BehaviorSubject } from "rxjs";

import { handleResponse } from "./request";

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("currentUser")));

export const authenticationService = {
  login,
  loginTfa,
  resetPassword,
  setNewPassword,
  finishAuthentication,
  logout,
  changeTenant,
  changeProfile,
  externallogin,
  currentUser: currentUserSubject.asObservable(),
  refreshUserToken: refreshUserToken,
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function refreshUserToken(token: any, refreshToken: any) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  let user = JSON.parse(localStorage.getItem("currentUser"));
  user.token = token;
  user.refreshToken = refreshToken;
  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
}

function finishAuthentication(id: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Accept-Type": "application/json" },
  };
  return (
    fetch("/login/completeLogin?id=" + id, requestOptions)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(response: any, url: any, reques... Remove this comment to see the full error message
      .then(handleResponse)
      .then((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
      })
  );
}

function login(username: string, password: string, id: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      TabId: window.sessionStorage.getItem("tabid") ?? "",
    },
    body: JSON.stringify({ username, password }),
  };

  return (
    fetch("login/authenticate?providerId=" + id, requestOptions)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(response: any, url: any, reques... Remove this comment to see the full error message
      .then(handleResponse)
      .then((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
      })
  );
}

function loginTfa(code: string, token: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      TabId: window.sessionStorage.getItem("tabid") ?? "",
    },
  };

  return (
    fetch("login/authenticatetfa?code=" + code + "&token=" + token, requestOptions)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(response: any, url: any, reques... Remove this comment to see the full error message
      .then(handleResponse)
      .then((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
      })
  );
}

function resetPassword(mail: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      TabId: window.sessionStorage.getItem("tabid") ?? "",
    },
    body: JSON.stringify({ mail }),
  };

  return (
    fetch("login/resetpassword", requestOptions)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(response: any, url: any, reques... Remove this comment to see the full error message
      .then(handleResponse)
  );
}

function setNewPassword(newPassword: string, token: string) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      TabId: window.sessionStorage.getItem("tabid") ?? "",
    },
    body: JSON.stringify({ newPassword, token }),
  };

  return (
    fetch("login/setnewpassword", requestOptions)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(response: any, url: any, reques... Remove this comment to see the full error message
      .then(handleResponse)
  );
}

function externallogin(user: any) {
  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
}

function changeTenant(id: any, caption: any) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  let user = JSON.parse(localStorage.getItem("currentUser"));
  user.tenantId = id;
  user.tenantName = caption;
  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
}

function changeProfile(id: any) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  let user = JSON.parse(localStorage.getItem("currentUser"));
  user.profileId = id;
  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
}

function logout() {
  // remove user from local storage to log user out
  const user = currentUserSubject.value;
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
  if (user && user.providerType === 3 && user.logoutUrl) {
    var baseUrl = window.location.protocol + "//" + window.location.host + "/login";
    window.location.assign(user.logoutUrl + "?post_logout_redirect_uri=" + encodeURI(baseUrl));
  }
}
