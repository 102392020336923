import "./globals";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import { v4 as uuid } from "uuid";

//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

window.sessionStorage.setItem("tabid", uuid());

(window as any).plugins = {};

ReactDOM.render(
        <BrowserRouter basename={baseUrl ?? undefined}>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>,
  rootElement
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
