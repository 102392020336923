import React, { useCallback, useEffect, useRef, Ref } from "react";
import { useSelector } from "react-redux";
import { Spinner, SpinnerSize, useTheme, mergeStyles, css } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import useMicroFrontend from "../hooks/useMicroFrontend";
import { RootState } from "../store/store";

export interface BasePluginRef {
  unMount: () => void;
  reload: (id: string, linkId: string) => void;
  getPluginId: () => string;
}

interface BasePluginProperties {
  id: string;
  linkId: string;
  onPluginLoaded: Function;
}

const BasePlugin = (props: BasePluginProperties, ref: Ref<BasePluginRef>) => {
  const { id, linkId, onPluginLoaded } = props;
  const { isLoaded, baseplugin } = useMicroFrontend("baseplugin", "e0850ff2d5b1befc");
  const [t] = useTranslation();

  const saveContainerRef = useRef(null);
  const setSaveContainerRef = useCallback((element) => {
    if (element) saveContainerRef.current = element;
  }, []);

  const theme = useTheme();
  const language = useSelector((state: RootState) => state.portal.language);
  const timezone = useSelector((state: RootState) => state.portal.timezone);

  React.useImperativeHandle(ref, () => ({
    unMount() {
      if (baseplugin && saveContainerRef.current) {
        baseplugin.unMount(saveContainerRef.current);
      }
    },
    reload(id: string, linkId: string) {
      if (baseplugin && saveContainerRef.current) {
        baseplugin.render(saveContainerRef.current, {
          pluginId: id,
          linkId: linkId,
          onPluginLoaded: onPluginLoaded,
          theme,
          language,
          timezone,
        });
      }
    },
    getPluginId() {
      return id;
    },
  }));

  useEffect(() => {
    if (!baseplugin) return;

    const { render, unMount } = baseplugin;
    saveContainerRef.current &&
      render(saveContainerRef.current, {
        pluginId: id,
        linkId: linkId,
        onPluginLoaded: onPluginLoaded,
        theme,
        language,
      });

    return () => {
      saveContainerRef.current && unMount(saveContainerRef.current);
    };
  }, [isLoaded]);

  if (!id) return null;

  if (!isLoaded) {
    return (
      <div className={rootClass}>
        <Spinner className={rootClass} size={SpinnerSize.large} label={t("messages.status.loadingApp")} />
      </div>
    );
  }

  if (!baseplugin)
    return <div className={css(rootClass, centerClass)}>{t("messages.status.baseClientAppNotAvailable")}</div>;

  return <div id="baseplugin" className={rootClass} ref={setSaveContainerRef} />;
};

export default React.forwardRef<BasePluginRef, BasePluginProperties>(BasePlugin);

const rootClass = mergeStyles({
  height: "100%",
  width: "100%",
});

const centerClass = mergeStyles({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
