import React, { ReactNode } from "react";
import { IconButton, IIconProps, mergeStyleSets, useTheme } from "@fluentui/react";

interface FilterableDropdownItemProperties {
  mainInfoComponent: string | ReactNode;
  iconComponent?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  secondaryButtonIconProps?: IIconProps;
  secondaryButtonOnClick?: React.MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement
  >;
  debugId?: string;
}

const FilterableDropdownItem = (props: FilterableDropdownItemProperties) => {
  const { mainInfoComponent, iconComponent, onClick, secondaryButtonIconProps, secondaryButtonOnClick } = props;

  const { semanticColors, effects } = useTheme();

  const classNames = mergeStyleSets({
    dropdownItemWrapper: {
      display: "flex",
      alignItems: "center",
      margin: 1,
      marginBottom: "0.5em",
      cursor: "pointer",
      padding: "1px 2px",
      borderRadius: effects.roundedCorner2,
      ":hover, :focus, :focus-visible": {
        background: semanticColors.buttonBackgroundHovered,
      },
      ":focus-visible": {
        outline: `${semanticColors.focusBorder} solid 1px`,
      },
    },
    dropdownInfoWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      padding: "0.25em",
      margin: "0.25em",
    },
    dropdownLogoWrapper: {
      width: 48,
      height: 48,
      maxWidth: 48,
      maxHeight: 48,
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  const secondaryButtonStyles = {
    root: { marginRight: 8 },
    rootHovered: { backgroundColor: semanticColors.buttonBackgroundPressed },
  };

  return (
    <div className={classNames.dropdownItemWrapper} onClick={onClick} data-is-focusable data-debugid={props.debugId}>
      {iconComponent && <div className={classNames.dropdownLogoWrapper}>{iconComponent}</div>}

      <div className={classNames.dropdownInfoWrapper}>{mainInfoComponent}</div>

      {secondaryButtonIconProps && (
        <IconButton
          iconProps={secondaryButtonIconProps}
          styles={secondaryButtonStyles}
          onClick={secondaryButtonOnClick}
          data-is-focusable={false}
        />
      )}
    </div>
  );
};

export default FilterableDropdownItem;
