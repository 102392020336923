import React, { useContext } from "react";
import { StyleSheet, View, Text } from "../../reactnative";
import { Registry } from "../registration/registry";
import { SelectAction } from "../actions";
import * as Constants from "../../utils/constants";
import * as Utils from "../../utils/util";
import * as Enums from "../../utils/enums";
import { HostConfigManager } from "../../utils/host-config";
import { StyleManager } from "../../styles/style-config";
import { InputContext } from "../../utils/context";
import { ContainerWrapper } from "./";

const hostConfig = HostConfigManager.getHostConfig();

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
const styleConfig = StyleManager.getManager().styles;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const Container = (props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onParseError' does not exist on type 'un... Remove this comment to see the full error message
  const { onParseError, showErrors } = useContext(InputContext);
  const payload = props.json;
  const selectionActionData = props.json.selectAction;

  const parsePayload = () => {
    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'children' implicitly has type 'any[]' in... Remove this comment to see the full error message
    let children = [];
    if (!payload) {
      // @ts-expect-error ts-migrate(7005) FIXME: Variable 'children' implicitly has an 'any[]' type... Remove this comment to see the full error message
      return children;
    }

    if (payload.isFallbackActivated) {
      if (payload.fallbackType == "drop") {
        return null;
      } else if (!Utils.isNullOrEmpty(payload.fallback)) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        return Registry.getManager().parseComponent(payload.fallback, onParseError);
      }
    }

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    children = Registry.getManager().parseRegistryComponents(payload.items, onParseError);

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'ChildElement' implicitly has an 'any' t... Remove this comment to see the full error message
    return children.map((ChildElement, index) =>
      React.cloneElement(ChildElement, { containerStyle: payload.style, isFirst: index === 0 })
    );
  };

  const internalRenderer = () => {
    const p = payload;
    const minHeight = Utils.convertStringToNumber(p.minHeight);
    //We will pass the style as array, since it can be updated in the container wrapper if required.
    const containerStyle = typeof minHeight === "number" ? [{ minHeight }] : [];

    const showValidationText = props.isError && showErrors;

    var containerContent = (
      <ContainerWrapper json={payload} style={containerStyle} containerStyle={props.containerStyle}>
        {payload.spacing && payload.separator && getSpacingElement()}
        {parsePayload()}
        {showValidationText && getValidationText()}
      </ContainerWrapper>
    );
    if (p.selectAction === undefined || HostConfigManager.supportsInteractivity() === false) {
      return containerContent;
    } else {
      return <SelectAction selectActionData={p.selectAction}>{containerContent}</SelectAction>;
    }
  };

  const getSpacingElement = () => {
    const spacingEnumValue = Utils.parseHostConfigEnum(Enums.Spacing, payload.spacing, Enums.Spacing.Default);

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const spacing = hostConfig.getEffectiveSpacing(spacingEnumValue);

    // spacing styles
    const separatorStyles = [{ height: spacing }];

    // separator styles
    separatorStyles.push(styleConfig.separatorStyle);

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ paddingTop: number; marginTop:... Remove this comment to see the full error message
    separatorStyles.push({ paddingTop: spacing / 2, marginTop: spacing / 2, height: 0 });
    return <View style={separatorStyles}></View>;
  };

  const getValidationText = () => {
    const p = payload;
    const validationTextStyles = [styleConfig.fontFamilyName, styleConfig.defaultDestructiveButtonForegroundColor];
    const errorMessage = p.validation && p.validation.errorMessage ? p.validation.errorMessage : Constants.ErrorMessage;

    return <Text style={validationTextStyles}>{errorMessage}</Text>;
  };

  let containerRender = internalRenderer();
  return containerRender;
};

const styles = StyleSheet.create({
  defaultBGStyle: {
    backgroundColor: Constants.TransparentString,
  },
});
