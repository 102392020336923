import { configureStore } from "@reduxjs/toolkit";
import wizardReducer from "./slices/wizardSlice";

const store = configureStore({
  reducer: {
    wizard: wizardReducer,
  },
  devTools: { name: "AppSpace SetupWizard" },
});

export default store;
