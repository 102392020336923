import React, { useContext } from "react";
import { TouchableOpacity } from "../../reactnative";
import { InputContext } from "../../utils/context";
import * as Constants from "../../utils/constants";
import * as Utils from "../../utils/util";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const SelectAction = (props) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExecuteAction' does not exist on type ... Remove this comment to see the full error message
  const { onExecuteAction, toggleVisibilityForElementWithID } = useContext(InputContext);

  const onClickHandle = () => {
    switch (props.selectActionData.type) {
      case Constants.ActionSubmit:
        let actionObject = { type: Constants.ActionSubmit, data: props.selectActionData.data };
        onExecuteAction(actionObject);
        break;
      case Constants.ActionOpenUrl:
        if (!Utils.isNullOrEmpty(props.selectActionData.url)) {
          let actionObject = { type: Constants.ActionOpenUrl, url: props.selectActionData.url };
          onExecuteAction(actionObject);
        }
        break;
      case Constants.ActionToggleVisibility:
        toggleVisibilityForElementWithID(props.selectActionData.targetElements);
        break;
      default:
        break;
    }
  };

  const ButtonComponent = TouchableOpacity;
  return (
    <ButtonComponent
      onPress={() => {
        onClickHandle();
      }}
      style={props.style}
    >
      <React.Fragment>{props.children}</React.Fragment>
    </ButtonComponent>
  );
};
