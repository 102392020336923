import { BaseModel } from './base-model';
import { ElementType } from '../utils/enums';
export class BaseInputModel extends BaseModel {
    inlineAction: any;
    placeholder: any;
    validation: any;
    value: any;
    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.placeholder = payload.placeholder;
        this.value = payload.value;
        this.inlineAction = payload.inlineAction;
        this.validation = payload.validation;
    }
}
export class TextInputModel extends BaseInputModel {
    isMultiline: any;
    maxLength: any;
    style: any;
    type = ElementType.TextInput;
    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.isMultiline = payload.isMultiline || false;
        this.maxLength = payload.maxLength;
        this.style = payload.style;
    }
}
export class NumberInputModel extends BaseInputModel {
    max: any;
    min: any;
    type = ElementType.NumberInput;
    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.max = payload.max;
        this.min = payload.min;
    }
}
export class DateInputModel extends BaseInputModel {
    max: any;
    min: any;
    type = ElementType.DateInput;
    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.max = payload.max;
        this.min = payload.min;
    }
}
export class TimeInputModel extends BaseInputModel {
    max: any;
    min: any;
    type = ElementType.TimeInput;
    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.max = payload.max;
        this.min = payload.min;
    }
}
export class ToggleInputModel extends BaseInputModel {
    title: any;
    value: any;
    valueOff: any;
    valueOn: any;
    wrap: any;
    type = ElementType.ToggleInput;
    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.title = payload.title;
        this.valueOff = payload.valueOff;
        this.valueOn = payload.valueOn;
        this.value = payload.value === payload.valueOn;
        this.wrap = payload.wrap;
    }
}
export class ChoiceSetModel extends BaseInputModel {
    isMultiSelect: any;
    style: any;
    wrap: any;
    type = ElementType.ChoiceSetInput;
    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.isMultiSelect = payload.isMultiSelect;
        this.style = payload.style;
        this.wrap = payload.wrap;
        if (payload.choices) {
            payload.choices.forEach((item: any, index: any) => {
                let choice = new ChoiceModel(item, this);
                if (choice) {

                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ChoiceModel' is not assignable t... Remove this comment to see the full error message
                    this.children.push(choice);
                }
            });
        }
        if (payload.value) {
            let selected = (payload.value).split(',');
            if (selected) {
                selected.forEach((current: any) => {
                    let choice = this.choices.find(c => (c as any).value === current);
                    if (choice) {
                        (choice as any).selected = true;
                    }
                });
            }
        }
    }
    get choices() {
        return this.children;
    }
}
export class ChoiceModel extends BaseInputModel {
    parent: any;
    type = 'Input.Choice';
    title;
    value;
    selected;
    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.title = payload.title;
        this.value = payload.value;
        this.selected = false;
    }
}
