import React, { CSSProperties, useEffect } from "react";
import { Label } from "@fluentui/react";
import Wizard from "./wizard/Wizard";
import silhouette from "../../images/silhouette.png";
import { Provider } from "react-redux";
import store from "./store/store";
import { helpers } from "./initializationHelper";
import { useNavigate } from "react-router-dom";

const footerStyle: CSSProperties = {
  backgroundColor: "#fff",
  opacity: 0.5,
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
};

const divStyle: CSSProperties = {
  width: 480,
  height: "100vh",
  margin: "auto",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  padding: 5,
  boxSizing: "border-box",
};

const labelStyle = {
  root: {
    fontSize: 22,
  },
};

export default function InitDatabaseWizard() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    helpers.checkDatabase().then(
      () => {},
      () => {
        navigate("/");
      }
    );
  }, []);

  return (
    <>
      <div style={divStyle}>
        <Label styles={labelStyle}>Willkommen zum Infoma AppSpace Setup</Label>
        <Provider store={store}>
          <Wizard />
        </Provider>
      </div>
      <div style={footerStyle}>
        <img src={silhouette} alt="silhouette" />
      </div>
    </>
  );
}
