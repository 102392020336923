//@ts-nocheck Because of custom AppSpace Theme Slots
import { createTheme, Theme } from "@fluentui/react";

const commonSettings: Theme = {
  effects: {
    roundedCorner2: "6px",
    roundedCorner4: "8px",
    roundedCorner6: "10px",
  },
};

const blueDarkTheme = createTheme({
  isInverted: true,
  ...commonSettings,
  palette: {
    themePrimary: "#0078d4",
    themeLighterAlt: "#000508",
    themeLighter: "#001322",
    themeLight: "#00243f",
    themeTertiary: "#00487f",
    themeSecondary: "#006aba",
    themeDarkAlt: "#1684d8",
    themeDark: "#3595de",
    themeDarker: "#66afe7",
    neutralLighterAlt: "#021423",
    neutralLighter: "#04192c",
    neutralLight: "#09233b",
    neutralQuaternaryAlt: "#0c2a44",
    neutralQuaternary: "#10304b",
    neutralTertiaryAlt: "#254a6a",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#000e1a",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#f4bba5",
    systemIndicatorBackgroundInfo: "#8989ba",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #8989ba 0%, #bac8e0 50.00%, #8989ba 99%)",
  },
});

const blueLightTheme = createTheme({
  ...commonSettings,
  palette: {
    themePrimary: "#0078d4",
    themeLighterAlt: "#f3f9fd",
    themeLighter: "#d0e7f8",
    themeLight: "#a9d3f2",
    themeTertiary: "#5ca9e5",
    themeSecondary: "#1a86d9",
    themeDarkAlt: "#006cbe",
    themeDark: "#005ba1",
    themeDarker: "#004377",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#e24b0f",
    systemIndicatorBackgroundInfo: "#96d3f1",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #96d3f1 0%, #1096d7 50.00%, #96d3f1 99%)",
  },
});

const magentaDarkTheme = createTheme({
  isInverted: true,
  ...commonSettings,
  palette: {
    themePrimary: "#e30090",
    themeLighterAlt: "#090006",
    themeLighter: "#240017",
    themeLight: "#44002b",
    themeTertiary: "#880056",
    themeSecondary: "#c8007e",
    themeDarkAlt: "#e6179a",
    themeDark: "#ea38a9",
    themeDarker: "#ef69be",
    neutralLighterAlt: "#17010e",
    neutralLighter: "#210314",
    neutralLight: "#30071f",
    neutralQuaternaryAlt: "#390b26",
    neutralQuaternary: "#410e2c",
    neutralTertiaryAlt: "#612247",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#0c0007",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#f7cb9c",
    systemIndicatorBackgroundInfo: "#d299c2",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #d299c2 0%, #fef9d7 50.00%, #d299c2 99%)",
  },
});

const magentaLightTheme = createTheme({
  ...commonSettings,
  palette: {
    themePrimary: "#a20067",
    themeLighterAlt: "#fbf1f8",
    themeLighter: "#f0cae2",
    themeLight: "#e39fca",
    themeTertiary: "#c8509c",
    themeSecondary: "#ae1576",
    themeDarkAlt: "#93005d",
    themeDark: "#7c004f",
    themeDarker: "#5b003a",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#c62f2f",
    systemIndicatorBackgroundInfo: "#c71d6f",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #c71d6f 0%, #e55779 50.00%, #c71d6f 99%)",
  },
});

const greenDarkTheme = createTheme({
  isInverted: true,
  ...commonSettings,
  palette: {
    themePrimary: "#b0e324",
    themeLighterAlt: "#070901",
    themeLighter: "#1c2406",
    themeLight: "#35440b",
    themeTertiary: "#6a8816",
    themeSecondary: "#9bc820",
    themeDarkAlt: "#b7e638",
    themeDark: "#c2ea54",
    themeDarker: "#d1ef7f",
    neutralLighterAlt: "#121704",
    neutralLighter: "#1a2107",
    neutralLight: "#27300d",
    neutralQuaternaryAlt: "#2f3911",
    neutralQuaternary: "#364115",
    neutralTertiaryAlt: "#54612b",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#0b0e02",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#f8bcab",
    systemIndicatorBackgroundInfo: "#c1dfc4",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #c1dfc4 0%, #deecdd 50.00%, #c1dfc4 99%)",
  },
});

const greenLightTheme = createTheme({
  ...commonSettings,
  palette: {
    themePrimary: "#5c7811",
    themeLighterAlt: "#f7faf1",
    themeLighter: "#e1e9c9",
    themeLight: "#c8d69f",
    themeTertiary: "#96ae54",
    themeSecondary: "#6d8821",
    themeDarkAlt: "#536c0f",
    themeDark: "#465b0d",
    themeDarker: "#344309",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#d83d1e",
    systemIndicatorBackgroundInfo: "#8fa84b",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #8fa84b 0%, #2b6317 50.00%, #8fa84b 99%)",
  },
});

const orangeDarkTheme = createTheme({
  isInverted: true,
  ...commonSettings,
  palette: {
    themePrimary: "#eb5c15",
    themeLighterAlt: "#090401",
    themeLighter: "#260f03",
    themeLight: "#461c06",
    themeTertiary: "#8d370d",
    themeSecondary: "#ce5113",
    themeDarkAlt: "#ed6b2b",
    themeDark: "#ef814a",
    themeDarker: "#f4a177",
    neutralLighterAlt: "#1c0c04",
    neutralLighter: "#251007",
    neutralLight: "#34190c",
    neutralQuaternaryAlt: "#3d1f11",
    neutralQuaternary: "#452415",
    neutralTertiaryAlt: "#653d2a",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#120702",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#ffbb55",
    systemIndicatorBackgroundInfo: "#fbc0a3",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #fbc0a3 0%, #fcdfc9 50.00%, #fbc0a3 99%)",
  },
});

const orangeLightTheme = createTheme({
  ...commonSettings,
  palette: {
    themePrimary: "#c74e11",
    themeLighterAlt: "#fdf7f4",
    themeLighter: "#f6ded2",
    themeLight: "#eec3ad",
    themeTertiary: "#dd8d64",
    themeSecondary: "#ce6029",
    themeDarkAlt: "#b34610",
    themeDark: "#973b0e",
    themeDarker: "#6f2c0a",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#d32815",
    systemIndicatorBackgroundInfo: "#c79081",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #c79081 0%, #b44d00 50.00%, #c79081 99%)",
  },
});

const violetDarkTheme = createTheme({
  isInverted: true,
  ...commonSettings,
  palette: {
    themePrimary: "#8c97bc",
    themeLighterAlt: "#060608",
    themeLighter: "#16181e",
    themeLight: "#2a2d39",
    themeTertiary: "#545b71",
    themeSecondary: "#7b85a6",
    themeDarkAlt: "#96a0c3",
    themeDark: "#a4aecd",
    themeDarker: "#bac2da",
    neutralLighterAlt: "#2f313e",
    neutralLighter: "#353846",
    neutralLight: "#404353",
    neutralQuaternaryAlt: "#474a5b",
    neutralQuaternary: "#4d5061",
    neutralTertiaryAlt: "#676a7c",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#282a36",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#eaa263",
    systemIndicatorBackgroundInfo: "#8989ba",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #8989ba 0%, #a7a6cb 50.00%, #8989ba 99%)",
  },
});

const violetLightTheme = createTheme({
  ...commonSettings,
  palette: {
    themePrimary: "#852faa",
    themeLighterAlt: "#f9f4fc",
    themeLighter: "#e9d6f2",
    themeLight: "#d7b4e6",
    themeTertiary: "#b274cd",
    themeSecondary: "#9342b5",
    themeDarkAlt: "#792b9a",
    themeDark: "#662482",
    themeDarker: "#4b1b60",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
  semanticColors: {
    systemIndicatorBackgroundAttention: "#d82b39",
    systemIndicatorBackgroundInfo: "#7873f5",
  },
  effects: {
    ...commonSettings.effects,
    systemIndicatorBackgroundInfoGradient: "linear-gradient(90deg, #7873f5 0%, #935cad 50.00%, #7873f5 99%)",
  },
});

export const themes: ThemeCollection = {
  dark: {
    blue: blueDarkTheme,
    violet: violetDarkTheme,
    magenta: magentaDarkTheme,
    orange: orangeDarkTheme,
    green: greenDarkTheme,
  },
  light: {
    blue: blueLightTheme,
    violet: violetLightTheme,
    magenta: magentaLightTheme,
    orange: orangeLightTheme,
    green: greenLightTheme,
  },
  custom: {
    default: blueLightTheme,
  },
};

export const themeMapping = ["blue", "violet", "magenta", "orange", "green"];

export interface ThemeCollection {
  [themeStyle: string]: {
    [themeColor: string]: Theme;
  };
}
