import React from "react";

import { PluginTile } from "./PluginTile";
import { Stack } from "@fluentui/react";

export const PluginStarter = (props: any) => {
  const payload = props.json;

  return (
    <Stack wrap horizontal tokens={numericalSpacingStackTokens}>
      {payload.children &&
        payload.children.map((child: any, index: any) => {
          return (
            <PluginTile key={"widgetspan_" + index} json={child} id={`plugintile_${index}`} isFirst={index === 0} />
          );
        })}
    </Stack>
  );
};

const numericalSpacingStackTokens = {
  childrenGap: 5,
};
