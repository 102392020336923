import React, { useEffect, useState } from "react";
import { CommandButton, mergeStyleSets, Panel, PanelType, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";

interface AboutUsContent {
  title: string;
  content: string;
  image: string;
}

const AboutUsPanel = () => {
  const [content, setContent] = useState<AboutUsContent | false>(false);
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    fetch("api/aboutus", requestOptions)
      .then((response) => response.json())
      .then((data) => setContent(data))
      .catch(console.error);
  }, []);

  if (!content) return null;

  return (
    <>
      <Text block styles={textStyles} onClick={() => setOpen(true)}>
        {content.title}
      </Text>
      <Panel
        isOpen={open}
        isLightDismiss={true}
        onDismiss={() => setOpen(false)}
        type={PanelType.custom}
        customWidth="70vw"
        styles={panelStyles}
        overlayProps={overlayProps}
        onRenderNavigation={() => null}
      >
        <CommandButton
          text={t("dialogs.aboutUsPanel.backToLogin")}
          iconProps={{ iconName: "ChromeBack" }}
          onClick={() => setOpen(false)}
          styles={buttonStyles}
        />
        {content.image && (
          <div className={classNames.imageWrapper}>
            <img src={content.image} className={classNames.image} />
          </div>
        )}
        <div className={classNames.contentWrapper}>
          {content.content && <div dangerouslySetInnerHTML={{ __html: content.content }} />}
        </div>
      </Panel>
    </>
  );
};

export default AboutUsPanel;

const textStyles = { root: { color: "white", cursor: "pointer", ":hover": { textDecoration: "underline" } } };
const buttonStyles = {
  root: { display: "block", marginBottom: "2em", width: "fit-content" },
  label: { color: "white" },
  icon: { color: "white" },
  iconHovered: { color: "#a20067" },
  iconPressed: { color: "#a20067b3" },
};
const panelStyles = {
  main: {
    minWidth: 800,
    boxShadow: "none",
    backgroundColor: "transparent",

    "@media (max-width: 850px)": { minWidth: "100%" },
    "@media (min-width: 1500px)": {
      backgroundImage: "url('images/FancyDesignOutside.svg')",
      backgroundPosition: "right top",
      backgroundRepeat: "no-repeat",
      "-webkit-mask": "url('images/FancyDesignInsideNegative.svg') no-repeat, url('images/Square.svg')",
      "-webkit-mask-position": "calc(100% - 30px) 30px",
      "-webkit-mask-composite": "xor",
      mask: "url('images/FancyDesignInsideNegative.svg') no-repeat, url('images/Square.svg')",
      maskPosition: "calc(100% - 30px) 30px",
      maskComposite: "exclude",
    },
  },
  contentInner: {
    margin: "30px 30px 30px 0",
    padding: 30,
    borderRadius: 38,
    background: "#3b4059f2",
    "@media (max-width: 850px)": { margin: 30 },
  },
  scrollableContent: { overflow: "hidden" as "hidden" },
  content: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    height: "100%",
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      color: "#bdc7fd",
    },
    "& div": {
      color: "white",
    },
  },
  navigation: {},
};
const overlayProps = { isDarkThemed: true };

const classNames = mergeStyleSets({
  imageWrapper: { height: 200, "@media (min-width: 1500px)": { minHeight: 200 } },
  image: { minHeight: 150, maxHeight: 200 },
  contentWrapper: {
    paddingRight: "1em",
    overflow: "hidden auto",
    "::-webkit-scrollbar": {
      width: 8,
      height: 8,
    },
    "::-webkit-scrollbar-thumb": {
      background: "#bdc7fd",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#858ebf",
    },
    "::-webkit-scrollbar-thumb:active": {
      background: "#5b638b",
    },
    "::-webkit-scrollbar-track": {
      background: "#252940",
    },
    "@media (min-width: 1500px)": { marginRight: 250 },
  },
});

const requestOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json", "Accept-Type": "application/json" },
};
