// Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license

// tslint:disable:max-line-length

import { IIconOptions, IIconSubset, registerIcons } from "@fluentui/react";

export function initializeIcons(baseUrl: string = "", options?: IIconOptions): void {
  const subset: IIconSubset = {
    style: {
      MozOsxFontSmoothing: "grayscale",
      WebkitFontSmoothing: "antialiased",
      fontStyle: "normal",
      fontWeight: "normal",
      speak: "none",
    },
    fontFace: {
      fontFamily: `"FabricMDL2Icons"`,
      src: `url('${baseUrl}additional-fabric-icons-04edc7eb.woff') format('woff')`,
    },
    icons: {
      Reset: "\uE423",
    },
  };

  registerIcons(subset, options);
}
