export const eventsToDispatch = {
    PLUGIN_OPEN_PAGE: "PLUGIN_OPEN_PAGE",
    PLUGIN_MESSAGE: "PLUGIN_MESSAGE",
    PLUGIN_CONFIRM: "PLUGIN_CONFIRM",
    PLUGIN_OPEN_REPORT: "PLUGIN_OPEN_REPORT",
    PLUGIN_CLOSE_PAGE: "PLUGIN_CLOSE_PAGE",
    PLUGIN_REFRESH_PAGE: "PLUGIN_REFRESH_PAGE",
    PLUGIN_REFRESH_PAGEDATA: "PLUGIN_REFRESH_PAGEDATA",
    PLUGIN_SHOW_NOTIFICATION: "PLUGIN_SHOW_NOTIFICATION",
    PLUGIN_HIDE_NOTIFICATION: "PLUGIN_HIDE_NOTIFICATION",
    PLUGIN_CLOSE_PAGE_NOTIFICATIONS: "PLUGIN_CLOSE_PAGE_NOTIFICATIONS",
    PLUGIN_CUSTOMPAGE_NOTIFICATION: "PLUGIN_CUSTOMPAGE_NOTIFICATION"
};

const dispatchEvent = (event: any, data: any) => window.dispatchEvent(new CustomEvent(event, { detail: data }));

export default dispatchEvent;
