const EYEABLE_CONTAINERID = "eyeAble_container_ID";
const EYEABLE_BUTTONID = "eyeAble_columID";
const EYEABLE_HIDEFORMEID = "hideForMeID";

const EYEABLE_FORCESHOW_CLASSNAME = "forceShow";
const EYEABLE_FORCEHIDE_CLASSNAME = "forceHide";
const EYEABLE_HIDEICON_STORAGEKEY = "eyeAbleHideIcon";

export function insertEyeAbleForcedStyles() {
  const eyeAbleRoot = document.getElementById(EYEABLE_CONTAINERID)?.shadowRoot;
  const hideForMeButton = eyeAbleRoot?.getElementById(EYEABLE_HIDEFORMEID);
  hideForMeButton?.remove();

  if (eyeAbleRoot) {
    const styleSheet = new CSSStyleSheet();
    styleSheet.insertRule(`.${EYEABLE_FORCESHOW_CLASSNAME} { display: block!important }`);
    styleSheet.insertRule(`.${EYEABLE_FORCEHIDE_CLASSNAME} { display: none!important }`);
    (eyeAbleRoot as any).adoptedStyleSheets.push(styleSheet);
  }
}

export function setEyeAbleVisible(newVisible: boolean) {
  const eyeAbleRoot = document.getElementById(EYEABLE_CONTAINERID)?.shadowRoot;
  const eyeAbleButton = eyeAbleRoot?.getElementById(EYEABLE_BUTTONID);

  if (newVisible) {
    localStorage.removeItem(EYEABLE_HIDEICON_STORAGEKEY);
    eyeAbleButton && eyeAbleButton.classList.add(EYEABLE_FORCESHOW_CLASSNAME);
    eyeAbleButton && eyeAbleButton.classList.remove(EYEABLE_FORCEHIDE_CLASSNAME);
  } else {
    localStorage.setItem(EYEABLE_HIDEICON_STORAGEKEY, "true");
    eyeAbleButton && eyeAbleButton.classList.add(EYEABLE_FORCEHIDE_CLASSNAME);
    eyeAbleButton && eyeAbleButton.classList.remove(EYEABLE_FORCESHOW_CLASSNAME);
  }
}
