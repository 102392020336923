import React from "react";
import Mousetrap from "mousetrap";
import { useAppDispatch } from "../../store/store";
import { setShowProfileDropdown, setShowTenantDropdown } from "../../store/slices/portalSlice";
import { setEyeAbleVisible } from "../../utils/eyeAbleFunctions";

const GlobalShortcuts = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const mousetrap = new Mousetrap();
    mousetrap.stopCallback = stopCallback;
    mousetrap.bind("alt+q", (e) => {
      e.preventDefault();
      document.getElementById("globalSearch")?.focus();
    });
    mousetrap.bind("alt+m", (e) => {
      dispatch(setShowTenantDropdown(true));
    });
    mousetrap.bind("alt+p", (e) => {
      dispatch(setShowProfileDropdown(true));
    });
    mousetrap.bind("alt+1", (e) => {
      const showIcon = !JSON.parse(`${localStorage.getItem("eyeAbleHideIcon")}`);
      if (!showIcon) setEyeAbleVisible(!showIcon);
    });
    mousetrap.bind("f5", (e) => {
      e.preventDefault();
    });

    return () => {
      mousetrap.unbind("alt+q");
      mousetrap.unbind("alt+m");
      mousetrap.unbind("alt+p");
      mousetrap.unbind("alt+1");
      mousetrap.unbind("f5");
      mousetrap.reset();
    };
  }, []);

  return null;
};

export default React.memo(GlobalShortcuts);

const stopCallback = (e: Mousetrap.ExtendedKeyboardEvent, element: Element, combo: string) => {
  return false;
};
