import React, { useContext } from "react";

import {

  View,

  Text,
  StyleSheet,
} from "../../reactnative";
import { HostConfigManager } from "../../utils/host-config";
import { StyleManager } from "../../styles/style-config";
import { InputContext } from "../../utils/context";
import * as Constants from "../../utils/constants";
import * as Utils from "../../utils/util";
import * as Enums from "../../utils/enums";

const hostConfig = HostConfigManager.getHostConfig();

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
const styleConfig = StyleManager.getManager().styles;


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const ElementWrapper = (props) => {

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'showErrors' does not exist on type 'unkn... Remove this comment to see the full error message
  const { showErrors } = useContext(InputContext);

  const getComputedStyles = () => {
    const payload = props.json;
    const receivedStyles = props.style;

    let computedStyles = [styles.inputContainer, receivedStyles];

    // height
    const height = payload.height || false;
    if (height) {
      const heightEnumValue = Utils.parseHostConfigEnum(Enums.Height, payload.height, Enums.Height.Auto);

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const height = hostConfig.getEffectiveHeight(heightEnumValue);
      computedStyles.push({ flex: height });
    }

    return computedStyles;
  };

  const getValidationText = () => {
    const payload = props.json;
    const validationTextStyles = [styleConfig.fontFamilyName, styleConfig.defaultDestructiveButtonForegroundColor];
    const errorMessage =
      payload.validation && payload.validation.errorMessage ? payload.validation.errorMessage : Constants.ErrorMessage;

    return <Text style={validationTextStyles}>{errorMessage}</Text>;
  };

  const getSpacingElement = () => {
    const payload = props.json;
    const spacingEnumValue = Utils.parseHostConfigEnum(Enums.Spacing, payload.spacing, Enums.Spacing.Default);

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const spacing = hostConfig.getEffectiveSpacing(spacingEnumValue);
    const separator = payload.separator || false;

    // spacing styles
    const separatorStyles = [{ height: spacing }];

    // separator styles
    if (separator) {
      separatorStyles.push(styleConfig.separatorStyle);

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ paddingTop: number; marginTop:... Remove this comment to see the full error message
      separatorStyles.push({ paddingTop: spacing / 2, marginTop: spacing / 2, height: 0 });
    }

    return <View style={separatorStyles}></View>;
  };

  const getSeparatorElement = () => {
    return <View style={[styleConfig.separatorStyle, { height: 3 }]}></View>;
  };

  const computedStyles = getComputedStyles();
  const showValidationText = props.isError && showErrors;
  const { isFirst } = props; //isFirst represent, it is first element
  const isColumnSet = props.json.type === Constants.TypeColumnSet;
  return (
    <React.Fragment>
      {!isColumnSet ? !isFirst && getSpacingElement() : props.json.separator && !isFirst && getSeparatorElement()}
      <View style={computedStyles} onLayout={props.onPageLayout}>
        {props.children}
        {showValidationText && getValidationText()}
      </View>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: Constants.TransparentString,
  },
});
