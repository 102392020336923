import React from "react";
import { View } from "../../reactnative";
import * as Utils from "../../utils/util";
import * as Enums from "../../utils/enums";
import * as Constants from "../../utils/constants";
import { BackgroundImage } from "../elements";
import { HostConfigManager } from "../../utils/host-config";

export const ContainerWrapper = (props: any) => {
  const payload = props.json;

  const getBackgroundImageContainer = () => {
    if (Utils.isString(payload.backgroundImage)) {
      payload.backgroundImage = {
        url: payload.backgroundImage,
      };
    }
    return (
      <React.Fragment>
        <BackgroundImage backgroundImage={payload.backgroundImage} />
        {props.children}
      </React.Fragment>
    );
  };

  const getComputedStyles = () => {
    let computedStyles = [];
    let hostConfig = HostConfigManager.getHostConfig();

    // vertical content alignment
    let verticalContentAlignment = Utils.parseHostConfigEnum(
      Enums.VerticalAlignment,
      payload["verticalContentAlignment"],
      Enums.VerticalAlignment.Top
    );
    switch (verticalContentAlignment) {
      case Enums.VerticalAlignment.Center:
        computedStyles.push({ justifyContent: Constants.CenterString });
        break;
      case Enums.VerticalAlignment.Bottom:
        computedStyles.push({ justifyContent: Constants.FlexEnd });
        break;
      default:
        computedStyles.push({ justifyContent: Constants.FlexStart });
        break;
    }
    computedStyles.push({ backgroundColor: Constants.TransparentString });

    // container BG style
    let backgroundStyle;

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const styleDefinition = hostConfig.containerStyles.getStyleByName(
      payload["style"],
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      hostConfig.containerStyles.getStyleByName("default")
    );
    //we won't apply the background color for the container which has backgroundImage. Even we won't apply color if its parent container having backgroundImage
    const hasBackgroundImage =
      !Utils.isNullOrEmpty(payload.backgroundImage) ||
      (payload.parent && !Utils.isNullOrEmpty(payload.parent.backgroundImage)) ||
      !Utils.isNullOrEmpty(props.hasBackgroundImage);
    if (!hasBackgroundImage && !Utils.isNullOrEmpty(styleDefinition.backgroundColor)) {
      backgroundStyle = { backgroundColor: Utils.hexToRGB(styleDefinition.backgroundColor) };
    }
    computedStyles.push(backgroundStyle);

    // border
    const borderThickness = styleDefinition.borderThickness || 0;
    const borderColor = styleDefinition.borderColor;
    computedStyles.push({ borderWidth: borderThickness, borderColor: Utils.hexToRGB(borderColor) });

    // padding

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const padding = hostConfig.getEffectiveSpacing(Enums.Spacing.Padding);
    computedStyles.push({ padding: padding });

    // bleed
    if (payload.bleed && props.containerStyle) {
      const { isFirst, isLast } = props;
      const marginLeft = isFirst ? -padding : 0;
      const marginRight = isLast ? -padding : 0;

      computedStyles.push({ marginVertical: -padding, marginLeft, marginRight });
    }

    // height
    const payloadHeight = payload.height || false;
    if (payloadHeight) {
      const heightEnumValue = Utils.parseHostConfigEnum(Enums.Height, payload.height, Enums.Height.Auto);

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const height = hostConfig.getEffectiveHeight(heightEnumValue);
      computedStyles.push({ flex: height });
      !payload["verticalContentAlignment"] && height && computedStyles.push({ justifyContent: Constants.SpaceBetween });
    }

    return computedStyles;
  };

  const receivedStyles = props.style;
  const computedStyles = getComputedStyles();
  return (
    <View source="containerWrapper" style={[computedStyles, receivedStyles]}>
      {!Utils.isNullOrEmpty(payload.backgroundImage) ? getBackgroundImageContainer() : props.children}
    </View>
  );
};
