import React, { useContext } from "react";
import { Text, View, StyleSheet, Image, Platform, TouchableOpacity, TouchableNativeFeedback } from "../../reactnative";

import { StyleManager } from "../../styles/style-config";
import * as Utils from "../../utils/util";
import { InputContext } from "../../utils/context";
import * as Constants from "../../utils/constants";
import { HostConfigManager } from "../../utils/host-config";
import * as Enums from "../../utils/enums";

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
const styleConfig = StyleManager.getManager().styles;

export const ActionButton = (props: any) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onExecuteAction' does not exist on type ... Remove this comment to see the full error message
  const { onExecuteAction, inputArray, addResourceInformation, toggleVisibilityForElementWithID } =
    useContext(InputContext);
  const payload = props.json;
  let title = Constants.EmptyString;
  let type = Constants.EmptyString;
  let iconUrl = Constants.EmptyString;
  let showCardHandler: any = undefined;
  let sentiment: any = undefined;
  let data = {};
  if (props.json.type === "Action.ShowCard") {
    showCardHandler = props.onShowCardTapped;
  }

  if (!Utils.isNullOrEmpty(payload.iconUrl)) {
    addResourceInformation(payload.iconUrl, "");
  }

  const onActionButtonTapped = () => {
    switch (payload.type) {
      case Constants.ActionSubmit:
        onSubmitActionCalled();
        break;
      case Constants.ActionOpenUrl:
        onOpenURLCalled();
        break;
      case Constants.ActionShowCard:
        changeShowCardState();
        break;
      case Constants.ActionToggleVisibility:
        onToggleActionCalled();
        break;
      default:
        break;
    }
  };

  const onSubmitActionCalled = () => {
    let mergedObject = {};
    for (const key in inputArray) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      mergedObject[key] = inputArray[key].value;
    }
    if (data !== null) {
      if (data instanceof Object) mergedObject = { ...mergedObject, ...data };
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      else mergedObject["actionData"] = data;
    }
    let actionObject = { type: payload.type, data: mergedObject };
    onExecuteAction(actionObject, payload.ignoreInputValidation === true);
  };

  const onToggleActionCalled = () => {
    toggleVisibilityForElementWithID(payload.targetElements);
  };

  const onOpenURLCalled = () => {
    let actionObject = { type: payload.type, url: payload.url };
    onExecuteAction(actionObject);
  };

  const changeShowCardState = () => {
    showCardHandler(payload.children[0]);
  };

  const parseHostConfig = () => {
    title = payload.title;
    type = payload.type;
    let imageUrl = payload.iconUrl;
    iconUrl = Utils.getImageUrl(imageUrl);
    data = payload.data;
    sentiment = payload.sentiment;
    sentiment = Utils.parseHostConfigEnum(Enums.Sentiment, payload.sentiment, Enums.Sentiment.Default);
  };

  const getButtonStyles = () => {
    let computedStyles = [styleConfig.button, styleConfig.actionIconFlex, styles.button];

    if (sentiment == Enums.Sentiment.Positive) {
      computedStyles.push(styleConfig.defaultPositiveButtonBackgroundColor);
    }

    return computedStyles;
  };

  const getButtonTitleStyles = () => {
    var computedStyles = [styleConfig.defaultFontConfig, styleConfig.buttonTitle];
    if (sentiment == Enums.Sentiment.Destructive) {
      computedStyles.push(styleConfig.defaultDestructiveButtonForegroundColor);
    }
    return computedStyles;
  };

  const buttonContent = () => {
    return (
      <View style={getButtonStyles()}>
        {!Utils.isNullOrEmpty(iconUrl) ? (
          <Image resizeMode="center" source={{ uri: iconUrl }} style={[styles.buttonIcon, styleConfig.actionIcon]} />
        ) : null}
        <Text style={getButtonTitleStyles()}>{title}</Text>
      </View>
    );
  };

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  if (HostConfigManager.getHostConfig().supportsInteractivity === false) {
    return null;
  }

  parseHostConfig();

  const ButtonComponent = Platform.OS === Constants.PlatformAndroid ? TouchableNativeFeedback : TouchableOpacity;

  return (
    <ButtonComponent style={{ flexGrow: 1 }} onPress={onActionButtonTapped}>
      {buttonContent()}
    </ButtonComponent>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: Constants.CenterString,
    justifyContent: Constants.CenterString,
    padding: 10,
    marginBottom: 10,
    flexGrow: 1,
    marginHorizontal: 5,
  },
  buttonIcon: {
    marginLeft: 5,
    marginRight: 10,
  },
});
