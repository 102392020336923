/* eslint-disable */
import React, { useEffect, useState } from "react";
import { authenticationService } from "../authorization/authenticationService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { closeDialog } from "../../store/slices/portalSlice";
import Modal from "../helpers/Modal";
import { pluginService } from "../authorization/pluginService";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useTranslation } from "react-i18next";

const ReportViewer = () => {
  const report = useSelector((state) => (state as any).portal.reportViewer);
  const language = useSelector((state: RootState) => state.portal.language);
  const dispatch = useDispatch();
  const [printMode, setPrintMode] = useState(true);
  const [loading, setLoading] = useState(true);
  const [reportSetupLoaded, setReportSetupLoaded] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (!report) return;
    setLoading(true);

    pluginService
      .getReportSetup(report.id)
      .then((result) => {
        if (result) setPrintMode(result.printMode ?? true);
      })
      .finally(() => setReportSetupLoaded(true));

    return () => setReportSetupLoaded(false);
  }, [report]);

  if (!report) return null;

  const onAjaxSuccess = (args: any) => {
    if (args.inProgress === "completed") {
      setLoading(false);
    }
  };

  const onAjaxError = () => {
    setLoading(false);
  };

  const onAjaxRequest = (event: any) => {
    //Passing custom data to server
    const currentUser = authenticationService.currentUserValue;
    event.headers.push(
      {
        Key: "Authorization",
        Value: `Bearer ${currentUser.token}`,
      },
      {
        Key: "TabId",
        Value: window.sessionStorage.getItem("tabid"),
      }
    );
    event.data = {
      id: report.id,
      pluginId: report.pluginId,
      tenantId: report.tenantId,
    };
  };

  return (
    <Modal
      title={report.title}
      style={modalStyle}
      onClose={() => dispatch(closeDialog({ type: "reportviewer", id: report.id }))}
    >
      <div style={viewerStyle}>
        {reportSetupLoaded && (
          // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'BoldReportViewerComponent'.
          <BoldReportViewerComponent
            id="reportviewer-container"
            reportServiceUrl={"/reporting/ReportViewer"}
            ajaxBeforeLoad={onAjaxRequest}
            ajaxSuccess={onAjaxSuccess}
            ajaxError={onAjaxError}
            locale={language}
            isResponsive={true}
            printMode={printMode}
          />
        )}
        {loading && (
          <Spinner
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", margin: "auto" }}
            label={t("spinner.getdata")}
            size={SpinnerSize.large}
          />
        )}
      </div>
    </Modal>
  );
};

export default ReportViewer;

const modalStyle = {
  top: 10,
  left: 10,
  bottom: 10,
  right: 10,
};

const viewerStyle = { height: "100%", width: "100%", backgroundColor: "#dadada" };
