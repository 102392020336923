export function getValueOrDefault(obj: any, defaultValue: any) {
	return obj ? obj : defaultValue;
}



// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function isNullOrEmpty(value) {
	return value === undefined || value === null || value === "";
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function convertStringToNumber(value) {
	let intValue = parseInt(value);
	if (isNaN(intValue)) {
		return null;
	} else {
		return intValue;
	}
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function isPixelValue(value) {
	return isString(value) && value.indexOf('px') >= 0
}



// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
export function validateUrl(url) {
	let urlRegEx = /^(?:[a-z]+:)?\/\/[\w.]+/i;
	let dataregex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

	return urlRegEx.test(url) || dataregex.test(url);
}



// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function isString(value) {
	return typeof value === 'string' || value instanceof String;
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
export function getImageUrl(image) {
	if (isNullOrEmpty(image)) {
		return image
	}

// @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
	image = this.isString(image) ? image : image.url
	return validateUrl(image) ? image : image.split('/').pop().split('.')[0];
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export function isaNumber(value) {
	var parsedValue = parseInt(value, 10);
	if (!isNaN(parsedValue)) {
		return true;
	}
	return false;
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'sizeValue' implicitly has an 'any' type... Remove this comment to see the full error message
export function getSize(sizeValue) {
	return sizeValue ? parseInt(sizeValue, 10) : undefined;
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'input' implicitly has an 'any' type.
export function isValidImageURI(input) {
	const assetRegex = /^Assets\//;
	return validateUrl(input) || assetRegex.test(input);
}



// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'versionString' implicitly has an 'any' ... Remove this comment to see the full error message
export function parseVersion(versionString) {
	var result = {
		major: 0,
		minor: 0
	};

	if (!versionString)
		return result;

	var regEx = /([\d]+)(?:\.([\d]+))?/gi;
	var matches = regEx.exec(versionString);
	if (matches != null) {
		// major
		result.major = parseInt(matches[1]);

		// minor
		result.minor = parseInt(matches[2]) || result.minor;
	}
	return result;
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
export function hexToRGB(color) {
	var regEx = /#([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})?/gi;

	var matches = regEx.exec(color);

	if (matches && matches[4]) {
		var a = parseInt(matches[1], 16) / 255;
		var r = parseInt(matches[2], 16);
		var g = parseInt(matches[3], 16);
		var b = parseInt(matches[4], 16);

		return "rgba(" + r + "," + g + "," + b + "," + a + ")";
	}
	else {
		return color;
	} 
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'targetEnum' implicitly has an 'any' typ... Remove this comment to see the full error message
export function parseHostConfigEnum(targetEnum, value, defaultValue) {
	if (typeof value === "string") {
		return getEnumValueOrDefault(targetEnum, value, defaultValue);
	} else if (typeof value === "number") {
		return getValueOrDefault(value, defaultValue);
	} else {
		return defaultValue;
	}
}


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'targetEnum' implicitly has an 'any' typ... Remove this comment to see the full error message
export function getEnumValueOrDefault(targetEnum, keyName, defaultValue) {
	if (isNullOrEmpty(keyName)) {
		return defaultValue;
	}
	for (var key in targetEnum) {
		let value = targetEnum[key];
		if (key.toLowerCase() === keyName.toLowerCase()) {
			return value;
		}
	}
	return defaultValue;
}

export function generateID() {
	return Math.random().toString(36).substr(2, 9);
}