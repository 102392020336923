const useFluentDefaultLayerHost = () => {
  // Use FluentUI default layer host, create if it's not created yet
  const layerHostId = "fluent-default-layer-host";
  let layerHost = document.getElementById(layerHostId);
  if (!layerHost) {
    const host = document.createElement("div");
    host.setAttribute("id", layerHostId);
    host.style.cssText = "position:fixed;z-index:1000000";
    layerHost = document.body.appendChild(host);
  }

  return layerHost;
};

export default useFluentDefaultLayerHost;
