import React, { useEffect } from "react";

import Widget from "./Widget";
import { setWidgets } from "../../store/slices/portalSlice";
import { useSelector, useDispatch } from "react-redux";
import { tenantService } from "../authorization/tenantService";
import { useTheme, mergeStyles } from "@fluentui/react";
const WidgetContainer = (props: any) => {
  const widgets = useSelector((state) => (state as any).portal.widgets);
  const dispatch = useDispatch();
  useEffect(() => {
    tenantService.getWidgets().then((result) => {
      dispatch(setWidgets(result));
    });
  }, []);
  const { semanticColors } = useTheme();
  const widgetContainerStyleClass = mergeStyles({
    backgroundColor: semanticColors.bodyBackground,
  });
  if (!props.visible) {
    return null;
  }
  return (
    <div className={widgetContainerStyleClass}>
      {widgets &&
        widgets.map((widget: any, index: any) => {
          return <Widget key={"widget_" + index} payload={widget} />;
        })}
    </div>
  );
};
export default WidgetContainer;
