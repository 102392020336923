import React, { useContext } from "react";
import { StyleSheet } from "../../reactnative";
import { Registry } from "../registration/registry";
import { ElementWrapper } from "../elements/ElementWrapper";
import { InputContext } from "../../utils/context";
import * as Constants from "../../utils/constants";
import * as Enums from "../../utils/enums";

const FlexWrap = "wrap";
const SizeKey = "size";
const ImageSetKey = "fromImageSet";

export const ImageSet = (props: any) => {
  const payload = props.json;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onParseError' does not exist on type 'un... Remove this comment to see the full error message
  const { onParseError } = useContext(InputContext);

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'imageSetJson' implicitly has an 'any' t... Remove this comment to see the full error message
  const parsePayload = (imageSetJson, onParseError) => {
    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'renderedElement' implicitly has type 'an... Remove this comment to see the full error message
    const renderedElement = [];
    if (!payload)
      // @ts-expect-error ts-migrate(7005) FIXME: Variable 'renderedElement' implicitly has an 'any[... Remove this comment to see the full error message
      return renderedElement;

    const register = Registry.getManager();
    // parse elements
    // This function is repetitive across containers. Needs to be made Generic.

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'element' implicitly has an 'any' type.
    imageSetJson.images.map((element, index) => {
      element[SizeKey] = payload.imageSize;
      element[ImageSetKey] = true;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const Element = register.getComponentOfType(element.type);
      if (Element) {
        renderedElement.push(<Element json={element} key={`ELEMENT-${index}`} isFirst={index == 0} />);
      } else {
        let error = {
          error: Enums.ValidationError.UnknownElementType,
          message: `Unknown Type ${element.type} encountered`,
        };
        onParseError(error);
        return null;
      }
    });

    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'renderedElement' implicitly has an 'any[... Remove this comment to see the full error message
    return renderedElement;
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'imageSetJson' implicitly has an 'any' t... Remove this comment to see the full error message
  const internalRenderer = (imageSetJson) => {
    return (
      <ElementWrapper json={imageSetJson} style={[styles.container, styles.defaultBGStyle]} isFirst={props.isFirst}>
        {parsePayload(imageSetJson, onParseError)}
      </ElementWrapper>
    );
  };

  let containerRender = internalRenderer(props.json);
  return containerRender;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: Constants.FlexRow,
    flexWrap: FlexWrap,
  },
  defaultBGStyle: {
    backgroundColor: Constants.TransparentString,
  },
});
