export const getDarkerColorOfTwo = (colorOneHex: string, colorTwoHex: string): string => {
  const colorOne = hexToRgb(colorOneHex);
  const colorTwo = hexToRgb(colorTwoHex);

  const colorOneLuminance = colorOne && calcLuminance(colorOne);
  const colorTwoLuminance = colorTwo && calcLuminance(colorTwo);

  if (colorOneLuminance && colorTwoLuminance) return colorOneLuminance < colorTwoLuminance ? colorOneHex : colorTwoHex;

  if (colorOneLuminance) return colorOneHex;
  if (colorTwoLuminance) return colorTwoHex;

  return "";
};

export const hexToRgb = (colorHex: string): RGBColor | null => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const calcLuminance = (colorRgb: RGBColor) => {
  const { r, g, b } = colorRgb;
  return (r * 0.299 + g * 0.587 + b * 0.114) / 256;
};

export interface RGBColor {
  r: number;
  g: number;
  b: number;
}

export function checkIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function addMaximumScaleToMetaViewportForIOS() {
  if (!checkIOS()) return;

  const el = document.querySelector("meta[name=viewport]");

  if (el !== null) {
    let content = el.getAttribute("content") || "maximum-scale=1.0";
    let re = /maximum\-scale=[0-9\.]+/g;

    if (re.test(content)) {
      content = content.replace(re, "maximum-scale=1.0");
    } else {
      content = [content, "maximum-scale=1.0"].join(", ");
    }

    el.setAttribute("content", content);
  }
}
