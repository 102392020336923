import React from "react";
import {
  Icon,
  SearchBox,
  Callout,
  Spinner,
  mergeStyleSets,
  Text,
  useTheme,
  FocusZone,
  FocusZoneTabbableElements,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { tenantService } from "../authorization/tenantService";
import { PluginSearchResultDto, SearchResultDto } from "../../types/commonTypes";

const SearchBar = () => {
  const [callOutVisible, setCallOutVisible] = React.useState(false);
  const [resultLoaded, setResultLoaded] = React.useState(false);
  const [result, setResult] = React.useState<Array<PluginSearchResultDto> | null>(null);
  const [resultCount, setResultCount] = React.useState(0);

  const { palette } = useTheme();
  const [t] = useTranslation();

  const onSearch = (s: any) => {
    setCallOutVisible(true);
    setResultLoaded(false);

    tenantService.search(s).then((result: PluginSearchResultDto[]) => {
      setResultLoaded(true);
      setResultCount(result.reduce((prev, curr) => prev + curr.results.length, 0));
      setResult(result);
    });
  };

  const closeCallOut = () => {
    setCallOutVisible(false);
  };

  const searchItemClick = (id: string) => {
    tenantService.openSearchLink(id);
    closeCallOut();
  };

  const styles = mergeStyleSets({
    searchBoxContainer: {
      verticalAlign: "top",
      display: "flex",
      flexShrink: 1,
      margin: "0",
      minWidth: 200,
      height: 32,
    },
    callout: { maxWidth: 300, width: "100%", marginLeft: 0 },
    loading: { padding: 20 },
    contentWrapper: { padding: "0 10px" },
    searchResultsWrapper: { height: "100%", padding: "5px 0 0" },
  });

  const searchBoxStyles = {
    root: {
      width: 300,
      borderColor: palette.neutralTertiary,
      backgroundColor: palette.themeLight,
    },
  };

  return (
    <React.Fragment>
      <div className={styles.searchBoxContainer}>
        <SearchBox
          id="globalSearch"
          data-debugid="globalSearch"
          styles={searchBoxStyles}
          placeholder={t("menu.search")}
          onSearch={onSearch}
          onClear={closeCallOut}
        />
      </div>
      {callOutVisible && (
        <Callout
          className={styles.callout}
          role="alertdialog"
          gapSpace={0}
          beakWidth={0}
          target={`.${styles.searchBoxContainer}`}
          onDismiss={closeCallOut}
          setInitialFocus
        >
          <div className={styles.contentWrapper}>
            {resultLoaded && result && (
              <>
                {resultCount === 0 && (
                  <>
                    <Text variant="tiny">{t("messages.status.noSearchResultFound")}</Text>
                    <div className={styles.searchResultsWrapper} />
                  </>
                )}

                {resultCount > 0 && (
                  <FocusZone shouldFocusOnMount handleTabKey={FocusZoneTabbableElements.all}>
                    <Text variant="tiny">{t("messages.status.searchResultFound", { count: resultCount })}</Text>
                    <div className={styles.searchResultsWrapper}>
                      {result.map((group) => (
                        <PluginSearchResult plugin={group} searchItemClick={searchItemClick} key={group.pluginId} />
                      ))}
                    </div>
                  </FocusZone>
                )}
              </>
            )}

            {!resultLoaded && (
              <Spinner
                className={styles.loading}
                label={t("spinner.searching")}
                ariaLive="assertive"
                labelPosition="top"
              />
            )}
          </div>
        </Callout>
      )}
    </React.Fragment>
  );
};

export default SearchBar;

function PluginSearchResult(props: { plugin?: PluginSearchResultDto; searchItemClick: (id: string) => void }) {
  const { plugin, searchItemClick } = props;
  const { palette } = useTheme();

  const styles = mergeStyleSets({
    pluginHeader: { marginBottom: "0.5em", fontWeight: 600, color: palette.themePrimary },
    pluginWrapper: { marginBottom: "0.25em" },
    icon: { paddingRight: 10 },
  });

  if (!plugin) return null;

  return (
    <div className={styles.pluginWrapper}>
      <Text className={styles.pluginHeader} variant="mediumPlus">
        <Icon iconName={plugin.icon} className={styles.icon} />
        {plugin.pluginName}
      </Text>
      {plugin.results.map((item) => (
        <PageSearchResult page={item} searchItemClick={searchItemClick} key={item.linkId} />
      ))}
    </div>
  );
}

function PageSearchResult(props: { page?: SearchResultDto; searchItemClick: (id: string) => void }) {
  const { page, searchItemClick } = props;
  const { semanticColors } = useTheme();

  const styles = mergeStyleSets({
    itemWrapper: {
      paddingLeft: 12,
      paddingBlock: 6,
      cursor: "pointer",
      userSelect: "none",
      ":hover": {
        backgroundColor: semanticColors.bodyBackgroundHovered,
      },
      ":focus-within": {
        backgroundColor: semanticColors.bodyBackgroundHovered,
      },
      ":focus-visible": { outline: "none" },
    },
    icon: { paddingRight: 5 },
  });

  if (!page) return null;

  return (
    <div
      className={styles.itemWrapper}
      role="row"
      tabIndex={0}
      data-is-focusable={true}
      onClick={() => searchItemClick(page.linkId)}
    >
      <Text role="cell" variant="medium">
        <Icon iconName={page.icon} className={styles.icon} />
        {page.description}
      </Text>
    </div>
  );
}
