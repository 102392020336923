import React, { useState } from "react";
import { View, StyleSheet } from "../../reactnative";
import { ElementWrapper } from "../elements/ElementWrapper";
import * as Constants from "../../utils/constants";
import { StyleManager } from "../../styles/style-config";
import { HostConfigManager } from "../../utils/host-config";
import * as Utils from "../../utils/util";
import { Label } from "../elements";

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
const styleConfig = StyleManager.getManager().styles;
const hostConfig = HostConfigManager.getHostConfig();

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const FactSet = (props) => {
  const payload = props.json;
  // state
  const [isMaximumWidthValueFound, setIsMaximumWidthValueFound] = useState(false);
  const [keyWidth, setKeyWidth] = useState("50%");
  const [valueWidth, setValueWidth] = useState("50%");
  let viewSize = 0;
  let maxWidth = 0;
  let widthArray = [];
  let currentWidth = 0;

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const measureView = (event) => {
    if (currentWidth === 0 || currentWidth !== event.nativeEvent.layout.width) {
      currentWidth = event.nativeEvent.layout.width;
      viewSize = event.nativeEvent.layout.width;
      getFactSetWidthFromHostConfig();
    }
  };

  const getFactSetWidthFromHostConfig = () => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    let titleConfig = hostConfig.factSet.title;

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    let valueConfig = hostConfig.factSet.value;
    if (
      !Utils.isNullOrEmpty(titleConfig.maxWidth) &&
      titleConfig.maxWidth !== 0 &&
      Utils.isaNumber(titleConfig.maxWidth)
    ) {
      if (titleConfig.maxWidth < 0.8 * viewSize) {
        let currentValueWidth = viewSize - titleConfig.maxWidth;
        setFactSetWidthSize(titleConfig.maxWidth, currentValueWidth);
      } else {
        let currentTitleWidth = 0.8 * viewSize;
        let currentValueWidth = viewSize - currentTitleWidth;
        setFactSetWidthSize(currentTitleWidth, currentValueWidth);
      }
    } else if (
      !Utils.isNullOrEmpty(valueConfig.maxWidth) &&
      valueConfig.maxWidth !== 0 &&
      Utils.isaNumber(valueConfig.maxWidth)
    ) {
      if (valueConfig.maxWidth < 0.8 * viewSize) {
        let currentTitleWidth = viewSize - valueConfig.maxWidth;
        setFactSetWidthSize(currentTitleWidth, valueConfig.maxWidth);
      } else {
        let currentValueWidth = 0.8 * viewSize;

        // @ts-expect-error ts-migrate(7022) FIXME: 'currentTitleWidth' implicitly has type 'any' beca... Remove this comment to see the full error message
        let currentTitleWidth = viewSize - currentTitleWidth;
        setFactSetWidthSize(currentTitleWidth, currentValueWidth);
      }
    } else {
      setFactSetWidthSize("50%", "50%");
    }
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'titleWidth' implicitly has an 'any' typ... Remove this comment to see the full error message
  const setFactSetWidthSize = (titleWidth, valueWidth) => {
    setKeyWidth(titleWidth);
    setValueWidth(valueWidth);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'factSetJson' implicitly has an 'any' ty... Remove this comment to see the full error message
  const parsePayload = (factSetJson) => {
    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'renderedElement' implicitly has type 'an... Remove this comment to see the full error message
    const renderedElement = [];
    if (!payload)
      // @ts-expect-error ts-migrate(7005) FIXME: Variable 'renderedElement' implicitly has an 'any[... Remove this comment to see the full error message
      return renderedElement;

    // host config

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    let titleConfig = hostConfig.factSet.title;

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    let valueConfig = hostConfig.factSet.value;

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'element' implicitly has an 'any' type.
    factSetJson.facts.map((element, index) => {
      renderedElement.push(
        <View style={[styles.textContainer]} key={`FACT-${element.title}-${index}`}>
          <Label
            text={element.title}
            size={titleConfig.size}
            weight={titleConfig.weight}
            color={titleConfig.color}
            isSubtle={titleConfig.isSubtle}
            wrap={titleConfig.wrap}
            style={{ width: keyWidth }}
          />
          <Label
            text={element.value}
            size={valueConfig.size}
            weight={valueConfig.weight}
            color={valueConfig.color}
            isSubtle={valueConfig.isSubtle}
            wrap={valueConfig.wrap}
            style={[styles.valueTextStyle, { width: valueWidth }]}
          />
        </View>
      );
    });

    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'renderedElement' implicitly has an 'any[... Remove this comment to see the full error message
    return renderedElement;
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'containerJson' implicitly has an 'any' ... Remove this comment to see the full error message
  const internalRenderer = (containerJson) => {
    let factSetObject = null;
    factSetObject = parsePayload(containerJson);
    return (
      <ElementWrapper json={containerJson} isFirst={props.isFirst}>
        <View
          style={[styles.container]}
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
          onLayout={(event) => {
            measureView(event);
          }}
        >
          {factSetObject}
        </View>
      </ElementWrapper>
    );
  };

  let factSetContainer = internalRenderer(props.json);
  return factSetContainer;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: Constants.FlexColumn,
    backgroundColor: "transparent",
  },
  textContainer: {
    flexDirection: Constants.FlexRow,
    backgroundColor: "transparent",
  },
  valueTextStyle: {
    paddingLeft: 5,
  },
});
