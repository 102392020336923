import { BehaviorSubject } from "rxjs";
import { authenticationService } from "./authenticationService";
import { postRequest, getRequest } from "./request";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ChangeProfileResultDto } from "../../types/commonTypes";

dayjs.extend(utc);
dayjs.extend(timezone);

export const userService = {
  openCompany,
  reconnect,
  openExternalSession,
  closeCompany,
  changeCompany,
  setFavoriteTenant,
  getProfiles,
  changeUserProfile,
  setFavoriteProfile,
  updateUserSettings,
  removeUserSettings,
  getUserSettings,
  getPasswordCriteria,
  changePassword,
  saveWidgetSettings,
  getTfaProviders,
  getTfaProvider,
  getTfaProviderMetadata,
  checkTfaCode,
  deactivateTfa,
  activateTfa,
  getWidgetData,
  getNotifications,
  setNotificationRead,
  deleteNotification,
};

function closeCompany() {
  return postRequest("api/user/closecompany", {});
}

function reconnect(connectionId: string) {
  return getRequest(`api/user/reconnectsignalr?connectionid=${connectionId}`);
}

function openCompany(connectionId: any) {
  const user = authenticationService.currentUserValue;
  return postRequest("api/user/opencompany", {
    tenantId: user.tenantId,
    connectionId: connectionId,
    timezone: dayjs.tz.guess() ?? "",
    loginProviderId: user.providerType,
    profileId: user.profileId,
  });
}

function openExternalSession(connectionId: any, pluginToken: any, pageId: any, tenantId: any) {
  return postRequest("ext/ui/init", { connectionId, pluginToken, pageId, tenantId });
}

function changeCompany(tenantId: any, isDefault: any) {
  return postRequest("api/tenant/changecompany", { tenantId, isDefault, timezone: dayjs.tz.guess() });
}

function setFavoriteTenant(tenantId: number) {
  return postRequest("api/tenant/setfavoritetenant", { tenantId });
}

function getProfiles() {
  return postRequest("api/tenant/availableprofiles", {});
}

function changeUserProfile(profileId: number): { changeProfileResult: ChangeProfileResultDto } {
  return postRequest("api/tenant/changeprofile", { profileId }) as any;
}

function setFavoriteProfile(profileId: number) {
  return postRequest("api/tenant/setfavoriteprofile", { profileId });
}

function getPasswordCriteria() {
  return postRequest("api/user/getPasswordCriteria", {});
}

function changePassword(oldPwd: any, newPwd: any) {
  return postRequest("api/user/changePassword", { oldPwd, newPwd });
}

function getTfaProviders(loginId: number | null) {
  if (loginId) {
    return postRequest(`api/tenant/gettfaproviders?loginId=${loginId}`, {});
  } else {
    return postRequest("api/tenant/gettfaproviders", {});
  }
}

function getTfaProvider(id: number, loginId: number | null) {
  if (loginId) {
    return postRequest(`api/tenant/gettfaprovider?id=${id}&loginId=${loginId}`, {});
  } else {
    return postRequest(`api/tenant/gettfaprovider?id=${id}`, {});
  }
}

function getTfaProviderMetadata(id: number, loginId: number | null) {
  if (loginId) {
    return postRequest(`api/tenant/gettfaproviderdata?id=${id}&loginId=${loginId}`, {});
  } else {
    return postRequest(`api/tenant/gettfaproviderdata?id=${id}`, {});
  }
}

function deactivateTfa() {
  return postRequest("api/user/disbaletfa", {});
}

function activateTfa(id: number, code: string, loginId: number | null) {
  if (loginId) {
    return postRequest(`api/user/enabletfa?id=${id}&code=${code}&loginId=${loginId}`, {});
  } else {
    return postRequest(`api/user/enabletfa?id=${id}&code=${code}`, {});
  }
}

function getNotifications() {
  return postRequest("api/user/notifications", {});
}

function setNotificationRead(id: string, read: boolean) {
  return postRequest("api/user/setnotificationread", { id, read });
}

function deleteNotification(id: string) {
  return postRequest(`api/user/deletenotification?id=${id}`, {});
}

function checkTfaCode(providerid: number, code: string) {
  return postRequest(`api/user/checktfacode?id=${providerid}&code=${code}`, {});
}

function saveWidgetSettings(widgets: any) {
  return postRequest("api/tenant/savewidgetsettings", { widgets });
}

function getWidgetData(widgetId: any, pluginToken: any) {
  return postRequest("api/tenant/getWidgetData", { widgetId, pluginToken: pluginToken });
}

function getUserSettings() {
  let s = JSON.parse(`${localStorage.getItem("currentUserSettings")}`);
  if (s === null) {
    return { pluginbaropen: true };
  } else {
    return s;
  }
}

function updateUserSettings(settings: any) {
  localStorage.setItem("currentUserSettings", JSON.stringify(settings));
}

function removeUserSettings() {
  localStorage.removeItem("currentUserSettings");
}
