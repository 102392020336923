import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { authenticationService } from "./authenticationService";

const RequireAuthRoute = ({ children = null }: any) => {
  const currentUser = authenticationService.currentUserValue;
  let returnUrl = "";

  const location = useLocation();

  if (!currentUser) {
    // not logged in so redirect to login page with the return url
    const l = window.location;
    if (l.pathname.indexOf("/tenant/") === 0) {
      const parts = l.pathname.split("/");
      returnUrl = "/" + parts[2];
    }
  }

  return currentUser ? children : <Navigate to={"./login" + returnUrl} state={{ from: location }} />;
};

export default RequireAuthRoute;
