import React from "react";
import { Fabric, Stack, TextField, Checkbox } from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { setSqlField } from "../../store/slices/wizardSlice";
const stackTokens = {
  childrenGap: 10,
};
const revealPassword = true;
export default () => {
  const dispatch = useDispatch();
  const { server, integratedSecurity, username, password, passwordConfirm } = useSelector(
    (state) => (state as any).wizard.sql
  );
  return (
    <div>
      <Fabric>
        <Stack horizontal={false}>
          <Stack tokens={stackTokens}>
            <TextField
              label="SQL Servername"
              type="text"
              value={server}
              onChange={(e, v) => dispatch(setSqlField({ name: "server", value: v }))}
            />
            <Checkbox
              label="Integrated Security (Windows-Authentifizierung) verwenden"
              defaultChecked={integratedSecurity}
              onChange={(e, v) => dispatch(setSqlField({ name: "integratedSecurity", value: v }))}
            />
            <div hidden={integratedSecurity}>
              <TextField
                label="SQL Server Benutzername"
                type="text"
                value={username}
                onChange={(e, v) => dispatch(setSqlField({ name: "username", value: v }))}
              />
              <Stack horizontal tokens={stackTokens} horizontalAlign="space-between">
                <TextField
                  label="Passwort"
                  type="password"
                  canRevealPassword={revealPassword}
                  value={password}
                  onChange={(e, v) => dispatch(setSqlField({ name: "password", value: v }))}
                />
                <TextField
                  label="Passwort wiederholen"
                  type="password"
                  canRevealPassword={revealPassword}
                  value={passwordConfirm}
                  onChange={(e, v) => dispatch(setSqlField({ name: "passwordConfirm", value: v }))}
                />
              </Stack>
            </div>
          </Stack>
        </Stack>
      </Fabric>
    </div>
  );
};
