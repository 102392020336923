import React from "react";
import ErrorDialog from "./ErrorDialog";
import MessageDialog from "./MessageDialog";
import ConfirmDialog from "./ConfirmDialog";
import OpenFileDialog from "./OpenFileDialog";
import ProgressBar from "./ProgressBar";
import PageInfo from "./PageInfo";
import ReportViewer from "../reporting/ReportViewer";
import TwoFactorSetup from "../admin/TwoFactorSetup";

const Dialogs = () => {
  return (
    <React.Fragment>
      <ErrorDialog />
      <MessageDialog />
      <ConfirmDialog />
      <OpenFileDialog />
      <ReportViewer />
      <PageInfo />
      <ProgressBar />
      <TwoFactorSetup />
    </React.Fragment>
  );
};

export default Dialogs;
