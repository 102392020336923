import React from "react";
import { useSelector } from "react-redux";
import { ProgressIndicator, Icon, Text, mergeStyles, ITextStyles, useTheme, keyframes } from "@fluentui/react";
import { RootState } from "../../store/store";
import { ProgressBarBulletPoint, ProgressBarBulletStatus } from "../../types/portalReducerTypes";
import PortalDialog from "./PortalDialog";

const ProgressBar = () => {
  const progressBars = useSelector((state: RootState) => state.portal.progressBars);

  if (progressBars.length === 0) return null;

  const renderProgressBars = () => {
    return progressBars.map((bar, index) => {
      return (
        <PortalDialog key={`PROGRESSBAR_${index}`} dialogId={`progress-${index + 1}`} title={bar.title} icon="Snooze">
          <BarWrapper
            key={`BARWRAPPER_${index}`}
            indeterminate={bar.indeterminate}
            description={bar.description}
            progress={bar.progress}
            bulletPoints={bar.bulletPoints}
          />
        </PortalDialog>
      );
    });
  };

  return <React.Fragment>{renderProgressBars()}</React.Fragment>;
};

export default ProgressBar;

interface BarWrapperProperties {
  indeterminate: boolean;
  description: string;
  progress: number;
  bulletPoints?: ProgressBarBulletPoint[];
}

const BarWrapper = (props: BarWrapperProperties) => {
  const { semanticColors } = useTheme();

  const renderBulletPoints = () => {
    if (!props.bulletPoints) return null;

    return (
      <div className={bulletPointsContainerClass}>
        {props.bulletPoints.map((b, index) => {
          let iconName = undefined;
          let color = undefined;

          switch (b.state) {
            case 1: {
              iconName = "CircleRing";
              color = semanticColors.disabledText;
              break;
            }
            case 2: {
              iconName = "Refresh";
              color = semanticColors.bodyText;
              break;
            }
            case 3: {
              iconName = "Completed";
              color = semanticColors.successIcon;
              break;
            }
            case 4: {
              iconName = "ErrorBadge";
              color = semanticColors.errorIcon;
              break;
            }
          }

          const textStyles: ITextStyles = {
            root: {
              color,
              fontWeight: b.state === ProgressBarBulletStatus.Processing ? 500 : undefined,
            },
          };
          const iconStyles = {
            root: [
              {
                color,
                marginRight: 5,
                alignSelf: "flex-start",
                marginTop: 1,
              },
              b.state === ProgressBarBulletStatus.Processing && spinAnimation,
            ],
          };

          const icon =
            b.state === ProgressBarBulletStatus.None ? null : <Icon styles={iconStyles} iconName={iconName} />;

          return (
            <div key={`BULLETPOINTDIV_${index}`} className={bulletPointWrapperClass}>
              {icon}
              <Text styles={textStyles} variant="smallPlus" key={`BULLETPOINT_${index}`}>
                {b.text}
              </Text>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <ProgressIndicator
        barHeight={20}
        description={props.description}
        percentComplete={props.indeterminate ? undefined : props.progress / 100}
      />
      {renderBulletPoints()}
    </div>
  );
};

const bulletPointsContainerClass = mergeStyles({
  marginTop: 10,
  maxHeight: "50vh",
  overflow: "auto",
  padding: "0px 1px",
});
const bulletPointWrapperClass = mergeStyles({ display: "flex", alignItems: "center", marginTop: 2 });
const spinKeyframes = keyframes({
  from: {
    transform: "rotate(0deg)",
  },
  to: {
    transform: "rotate(360deg)",
  },
});
const spinAnimation = {
  animationName: spinKeyframes,
  animationDuration: "1500ms",
  animationIterationCount: "infinite",
  animationTimingFunction: "ease-in-out",
};
