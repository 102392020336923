import React, { useContext } from "react";
import { HostConfigManager } from "../../utils/host-config";

import MarkDownFormatter from "../../utils/markdown-formatter";
import { TextFormatter } from "../../utils/text-formatters";
import { InputContext } from "../../utils/context";
import * as Utils from "../../utils/util";
import * as Enums from "../../utils/enums";
const hostConfig = HostConfigManager.getHostConfig();
export const Label = (props: any) => {
  const context = useContext(InputContext);
  const getComputedStyle = () => {
    const { size, weight, color, isSubtle, fontStyle, align } = props;
    let { containerStyle } = props;
    // font-style

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const fontStyleValue = hostConfig.getTextFontStyle(
      Utils.parseHostConfigEnum(Enums.FontStyle, fontStyle, Enums.FontStyle.Default)
    );
    // font-size

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const fontSize = hostConfig.getTextFontSize(
      Utils.parseHostConfigEnum(Enums.TextSize, size, Enums.TextSize.Default),
      fontStyleValue
    );
    // font-weight

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const fontWeight = hostConfig.getTextFontWeight(
      Utils.parseHostConfigEnum(Enums.TextWeight, weight, Enums.TextWeight.Default),
      fontStyleValue
    );
    const fontFamilyValue = fontStyleValue.fontFamily;
    // text-color
    if (Utils.isNullOrEmpty(containerStyle)) {
      containerStyle = "default";
    }
    let colorEnum = Utils.parseHostConfigEnum(Enums.TextColor, color, Enums.TextColor.Default);

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    let colorDefinition = hostConfig.getTextColorForStyle(colorEnum, containerStyle);
    let colorValue = isSubtle ? colorDefinition.subtle : colorDefinition.default;
    // horizontal text alignment

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const textAlign = hostConfig.getTextAlignment(
      Utils.parseHostConfigEnum(Enums.HorizontalAlignment, align, Enums.HorizontalAlignment.Left)
    );
    return {
      fontSize,
      fontWeight: fontWeight.toString(),
      fontFamily: fontFamilyValue,
      color: colorValue,
      textAlign,
    };
  };
  let { text, wrap, maxLines, onDidLayout } = props;
  // parse & format DATE/TIME values
  let lang = (context as any).lang;
  let formattedText = TextFormatter(lang, text);
  // received style
  let receivedStyle = props.style;
  // compute style from props
  let computedStyle = getComputedStyle();
  // number of lines
  let numberOfLines = wrap ? (maxLines != undefined ? maxLines : 0) : 1;
  let clickProps;
  if (props.onClick) {
    clickProps = { onClick: props.onClick };
  }
  return (
    // @ts-expect-error ts-migrate(2786) FIXME: 'MarkDownFormatter' cannot be used as a JSX compon... Remove this comment to see the full error message
    <MarkDownFormatter
      defaultStyles={[receivedStyle, computedStyle]}
      numberOfLines={numberOfLines}
      text={formattedText}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClick?: any; defaultStyles: any[]; numbe... Remove this comment to see the full error message
      onDidLayout={onDidLayout}
      {...clickProps}
    />
  );
};
