import { getRequest, postRequest } from "./request";

let pluginId = "undefined";

export const pluginService = {
  openPlugin,
  getPageMetadata,
  pageActionClicked,
  confirm,
  getPageData,
  pageValueChanged,
  openLink,
  customRequest,
  lazyLoad,
  loadCustomComponent,
  pageClosed,
  reloadPageMetaData,
  pageCloseAction,
  refreshPageData,
  onFileUpload,
  filterPage,
  sortPage,
  pageLookup,
  pageAssistEdit,
  pageDrilldown,
  changeActiveRecord,
  loadNavigation,
  onLinkClicked,
  getPageCustomization,
  savePageCustomization,
  saveColumnWidths,
  setPageStateCustomization,
  setControlStateCustomization,
  getReportSetup,
  getTenantSettingValue,
  selectAll,
  downloadDebugInfo,
};

async function openPlugin(pId: any, linkId: any) {
  pluginId = pId;
  return postRequest("api/plugin/openplugin", buildRequest({ linkId }));
}

async function loadNavigation(pluginId: any) {
  return postRequest("api/plugin/getnavigation", { pluginId });
}

async function getReportSetup(reportId: string) {
  return postRequest("reporting/ReportViewer/GetReportSetup?reportId=" + reportId, buildRequest({}));
}

async function onLinkClicked(linkId: any) {
  return postRequest("api/plugin/linkclicked", buildRequest({ linkId }));
}

async function filterPage(pageId: any, filters: any) {
  return postRequest("api/plugin/filter", buildRequest({ pageId, filters }));
}

async function sortPage(pageId: any, keys: any) {
  return postRequest("api/plugin/sort", buildRequest({ pageId, keys }));
}

async function selectAll(pageId: any) {
  return postRequest("api/plugin/selectAll", buildRequest({ pageId }));
}

async function getPageMetadata(pageId: any) {
  return postRequest("api/plugin/getpagemetadata", buildRequest({ pageId }));
}

async function pageActionClicked(
  pageId: any,
  actionId: any,
  activeRecordId: any,
  selectedRecordIds: any,
  filters: any
) {
  return postRequest(
    "api/plugin/pageactionclicked",
    buildRequest({ pageId, actionId, activeRecordId, selectedRecordIds, filters })
  );
}

async function pageLookup(pageId: any, recordId: any, controlId: any) {
  return postRequest("api/plugin/fieldlookup", buildRequest({ pageId, recordId, controlId }));
}

async function pageAssistEdit(pageId: any, recordId: any, controlId: any) {
  return postRequest("api/plugin/fieldassistedit", buildRequest({ pageId, recordId, controlId }));
}

async function pageDrilldown(pageId: any, recordId: any, controlId: any) {
  return postRequest("api/plugin/fielddrilldown", buildRequest({ pageId, recordId, controlId }));
}

async function refreshPageData(pageId: any) {
  return postRequest("api/plugin/refreshpagedata", buildRequest({ pageId }));
}

async function lazyLoad(pageId: any, direction: any) {
  return postRequest("api/plugin/lazyload", buildRequest({ pageId, direction }));
}

async function confirm(id: any, result: any) {
  return postRequest("api/plugin/confirm", buildRequest({ id, result }));
}

async function getPageData(pageId: any, refresh: any) {
  return postRequest("api/plugin/getpagedata", buildRequest({ pageId, refresh }));
}

async function reloadPageMetaData(pageId: any) {
  return postRequest("api/plugin/reloadpagemetadata", buildRequest({ pageId }));
}

async function pageValueChanged(pageId: any, recordId: any, id: any, value: any, newUiKey: any) {
  return postRequest("api/plugin/pagevaluechanged", buildRequest({ pageId, controlId: id, recordId, value, newUiKey }));
}

async function openLink(pId: any, link: any) {
  pluginId = pId;
  return postRequest("api/plugin/openlink", buildRequest({ link }));
}

async function customRequest(pageId: any, action: any, payload: any) {
  return postRequest("api/plugin/custompagerequest", buildRequest({ pageId, action, payload }));
}

async function loadCustomComponent(id: any, pId: any) {
  pluginId = pId;
  return postRequest("api/plugin/loadcustomcomponent", buildRequest({ componentId: id }));
}

async function pageCloseAction(pageId: any, activeRecordId: any, selectedRecordIds: any, closeType: any) {
  return postRequest(
    "api/plugin/pagecloseaction",
    buildRequest({ pageId, activeRecordId, selectedRecordIds, closeType })
  );
}

async function pageClosed(pageId: any) {
  return postRequest("api/plugin/pageclosed", buildRequest({ pageId }));
}

async function onFileUpload(id: any, fileName: string, content: any, mimeType?: string) {
  return postRequest("api/plugin/fileupload", buildRequest({ id, fileName, content, mimeType }));
}

async function changeActiveRecord(pageId: any, recordId: any, pendingChanges: any) {
  return postRequest("api/plugin/rowselected", buildRequest({ pageId, recordId, pendingChanges }));
}

async function getPageCustomization(pageId: any, resetToDefault: any) {
  return postRequest("api/plugin/getpagecustomization", buildRequest({ pageId, resetToDefault }));
}

async function savePageCustomization(pageId: any, customization: any) {
  return postRequest("api/plugin/setpagecustomization", buildRequest({ pageId, customization }));
}

// TODO: Replace with setControlStateCustomization?
async function saveColumnWidths(pageId: any, customization: any) {
  return postRequest("api/plugin/setcolumnwidths", buildRequest({ pageId, customization }));
}

async function setPageStateCustomization(pageId: any, pageStateCustomization: any) {
  return postRequest("api/plugin/setpagestatecustomization", buildRequest({ pageId, pageStateCustomization }));
}

async function setControlStateCustomization(pageId: any, controlStateCustomization: any) {
  return postRequest("api/plugin/setcontrolstatecustomization", buildRequest({ pageId, controlStateCustomization }));
}

async function downloadDebugInfo() {
  return postRequest("api/plugin/triggerDownloadDebugInfo", buildRequest({}));
}

async function getTenantSettingValue(key: string) {
  return postRequest(`ext/ui/tenantSetting?key=${encodeURI(key)}`, buildRequest({}));
}

function buildRequest(request: any) {
  request.pluginId = pluginId;
  return request;
}
