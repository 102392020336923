import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { initializeIcons, ThemeProvider } from "@fluentui/react";
import Dashboard from "./components/Dashboard";
import LoginPage from "./components/authorization/login/LoginPage";
import InitDatabaseWizard from "./components/initialization/InitDatabaseWizard";
import ExternalPlugin from "./components/ExternalPlugin";
import RequireAuthRoute from "./components/authorization/RequireAuthRoute";
import { RootState } from "./store/store";
import { portalInterface } from "./utils/portalInterface";
import { themeMapping, themes } from "./utils/themes";
import { registerCustomIcons } from "./utils/customIcons";
import { addMaximumScaleToMetaViewportForIOS } from "./utils/helperFunctions";
import ResetPasswordPage from "./components/authorization/login/ResetPasswordPage";
import { insertEyeAbleForcedStyles } from "./utils/eyeAbleFunctions";
import "./globalization/globalization";
import "./custom.css";
import "./gridLayout/dashboard.css";
//Report Viewer source
import "@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min";
import "@boldreports/javascript-reporting-controls/Content/office-365/bold.reports.all.min.css";
//Data-Visualization
import "@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min";
import "@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min";
//Reports react base
import "@boldreports/react-reporting-components/Scripts/bold.reports.react.min";
import "@boldreports/global/l10n/ej.localetexts.de-DE.min.js";
import "@boldreports/global/i18n/ej.culture.de.min.js";

initializeIcons("./fonts/");
registerCustomIcons();
portalInterface.init();

//correct shitty reportviewer translations
if (window.Syncfusion && window.Syncfusion.ReportViewer && window.Syncfusion.ReportViewer.Locale["de-DE"]) {
  window.Syncfusion.ReportViewer.Locale["de-DE"].toolbar.exportformat.Excel = "Excel";
  window.Syncfusion.ReportViewer.Locale["de-DE"].toolbar.exportformat.contentText = "Zu exportierendes Format wählen";
  window.Syncfusion.ReportViewer.Locale["de-DE"].toolbar.pageIndex.contentText = "Aktuelle Seite";
  window.Syncfusion.ReportViewer.Locale["de-DE"].toolbar.print.contentText = "Bericht ausdrucken";
  window.Syncfusion.ReportViewer.Locale["de-DE"].toolbar.refresh.headerText = "Aktualisieren";
  window.Syncfusion.ReportViewer.Locale["de-DE"].toolbar.stop.headerText = "Anhalten";
  window.Syncfusion.ReportViewer.Locale["de-DE"].toolbar.zoomIn.headerText = "Vergrößern";
  window.Syncfusion.ReportViewer.Locale["de-DE"].toolbar.zoomOut.headerText = "Verkleinern";
  window.Syncfusion.ReportViewer.Locale["de-DE"].pagesetupDialog.bottom = "Unten";
  window.Syncfusion.ReportViewer.Locale["de-DE"].pagesetupDialog.margins = "Ränder";
  window.Syncfusion.ReportViewer.Locale["de-DE"].exportsetupDialog.excel = "Excel";
  window.Syncfusion.ReportViewer.Locale["de-DE"].exportsetupDialog.word = "Word";
  window.Syncfusion.ReportViewer.Locale["de-DE"].viewButton = "Bericht ansehen";
}

// Workaround for input auto zoom on iOS (#10670, https://stackoverflow.com/a/57527009)
addMaximumScaleToMetaViewportForIOS();

export default function App() {
  const themeSettings = useSelector(
    (state: RootState) => state.portal.theme,
    (left, right) => left.style === right.style && left.color === right.color
  );
  const customTheme = useSelector((state) => (state as any).portal.customTheme);
  const appTheme = useSelector((state: RootState) =>
    state.portal.plugin
      ? themeMapping[state.portal.pluginIcons[state.portal.plugin]?.appTheme ?? 0] ?? themeMapping[0]
      : themeMapping[0]
  );
  const appliedTheme =
    themeSettings.style === "custom"
      ? customTheme
      : themeSettings.color === "appTheme"
      ? themes[themeSettings.style][appTheme]
      : themes[themeSettings.style][themeSettings.color];

  const [loaded, setLoaded] = useState(false);
  const [exactRoutes, setExactRoutes] = useState<{ route: string; redirect: string }[]>([]);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Type": "application/json" },
    };
    fetch("ext/ui/routes", requestOptions).then(
      (response) => {
        if (response.ok) {
          response.json().then(
            (data) => {
              setExactRoutes(data);
              setLoaded(true);
            },
            () => setLoaded(true)
          );
        }
      },
      () => setLoaded(true)
    );
  }, []);

  useEffect(() => {
    document.addEventListener("eyeable:init_completed", insertEyeAbleForcedStyles);
    return () => {
      document.removeEventListener("eyeable:init_completed", insertEyeAbleForcedStyles);
    };
  }, []);

  const render = () => {
    let host = window.location.host;
    if (host.indexOf("www.") === 0) host = host.substring(4);
    for (var i = 0; i < exactRoutes.length; i++) {
      const route = exactRoutes[i];
      let rp = route.route;
      if (rp.indexOf("www.") === 0) {
        rp = rp.substring(4);
      }
      if (rp === host) {
        if (route.redirect && route.redirect.length !== 0) {
          return <Navigate to={route.redirect} />;
        } else {
          return <ExternalPlugin />;
        }
      }
    }

    return (
      <RequireAuthRoute>
        <Dashboard />
      </RequireAuthRoute>
    );
  };

  if (!loaded) {
    return null;
  }

  return (
    <ThemeProvider theme={appliedTheme} applyTo="body" style={{ height: "100%" }}>
      <Routes>
        <Route path="/" element={render()} />

        <Route
          path="/tenant/:name"
          element={
            <RequireAuthRoute>
              <Dashboard />
            </RequireAuthRoute>
          }
        />

        <Route path="/initportal" element={<InitDatabaseWizard />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />}></Route>
        <Route path="/login" element={<LoginPage />}>
          <Route path=":tenant" element={<LoginPage />} />
        </Route>
        <Route path="*" element={<ExternalPlugin />} />
      </Routes>
    </ThemeProvider>
  );
}
