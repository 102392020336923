import React from "react";
import { useId } from "@fluentui/react-hooks";
import {
  Icon,
  ContextualMenu,
  PrimaryButton,
  Dialog,
  DialogType,
  DialogFooter,
  Text,
  IconButton,
  IModalProps,
  IDialogContentProps,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setPageZoom } from "../../store/slices/portalSlice";
import { mergeStyles, mergeStyleSets, useTheme } from "@fluentui/react";

const PageInfo = () => {
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");
  const pageZoom = useSelector((state) => (state as any).portal.pageZoom);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const modalProps = React.useMemo<IModalProps>(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      // styles: dialogStyles,
      dragOptions: dragOptions,
      styles: { scrollableContent: { overflow: "hidden" } },
    }),
    [labelId, subTextId]
  );

  if (!pageZoom) return null;

  const toggleConfirmClick = () => {
    dispatch(setPageZoom(null));
  };

  const dialogContentProps: IDialogContentProps = {
    type: DialogType.normal,
    title: (
      <>
        <Icon iconName="DeveloperTools" style={{ paddingRight: 10 }} />
        {t("dialogs.pageInfo.title")}
      </>
    ),
    closeButtonAriaLabel: t("buttons.close"),
    styles: {
      content: { height: "100%", display: "flex", flexDirection: "column" },
      inner: { overflow: "hidden", display: "flex", flexDirection: "column" },
      innerContent: { overflow: "auto" },
    },
  };

  const renderPageInfo = () => {
    return (
      <PageInfoTab key="pageInfo" debugId="pageInfoTab" caption={t("dialogs.pageInfo.pageInformation")} opened={true}>
        <table className={tableClass}>
          <tbody>
            <tr>
              <td className={tdClass}>
                <Text variant={variant}>{t("dialogs.pageInfo.pageName")}:</Text>
              </td>
              <td>
                <Text variant={variant}>{pageZoom.PageName}</Text>
              </td>
            </tr>
            <tr>
              <td className={tdClass}>
                <Text variant={variant}>{t("dialogs.pageInfo.pageCaption")}:</Text>
              </td>
              <td>
                <Text variant={variant}>{pageZoom.PageCaption}</Text>
              </td>
            </tr>
            <tr>
              <td className={tdClass}>
                <Text variant={variant}>{t("dialogs.pageInfo.pageID")}:</Text>
              </td>
              <td>
                <Text variant={variant}>{pageZoom.PageId}</Text>
              </td>
            </tr>
            <tr>
              <td className={tdClass}>
                <Text variant={variant}>{t("dialogs.pageInfo.pageApp")}:</Text>
              </td>
              <td>
                <Text variant={variant}>{pageZoom.App}</Text>
              </td>
            </tr>
          </tbody>
        </table>
      </PageInfoTab>
    );
  };
  const renderTableInfo = () => {
    return (
      <PageInfoTab
        key="tableInfo"
        debugId="tableInfoTab"
        caption={t("dialogs.pageInfo.tableInformation")}
        opened={false}
      >
        <table className={tableClass}>
          <tbody>
            <tr>
              <td className={tdClass}>
                <Text variant={variant}>{t("dialogs.pageInfo.tableName")}:</Text>
              </td>
              <td>
                <Text variant={variant}>{pageZoom.TableZoom.TableName}</Text>
              </td>
            </tr>
            <tr>
              <td className={tdClass}>
                <Text variant={variant}>{t("dialogs.pageInfo.tableCaption")}:</Text>
              </td>
              <td>
                <Text variant={variant}>{pageZoom.TableZoom.TableCaption}</Text>
              </td>
            </tr>
            <tr>
              <td className={tdClass}>
                <Text variant={variant}>{t("dialogs.pageInfo.tableID")}:</Text>
              </td>
              <td>
                <Text variant={variant}>{pageZoom.TableZoom.TableId}</Text>
              </td>
            </tr>
            <tr>
              <td className={tdClass}>
                <Text variant={variant}>{t("dialogs.pageInfo.tableApp")}:</Text>
              </td>
              <td>
                <Text variant={variant}>{pageZoom.TableZoom.App}</Text>
              </td>
            </tr>
          </tbody>
        </table>
      </PageInfoTab>
    );
  };
  const renderTableValues = () => {
    const values = [...pageZoom.TableZoom.Values];
    return (
      <PageInfoTab
        key="tableValues"
        debugId="tableValuesTab"
        caption={t("dialogs.pageInfo.tableValues")}
        opened={false}
      >
        <table className={tableClass}>
          <tbody>
            {values &&
              values.map((value) => {
                return (
                  <tr key={value.FieldName}>
                    <td className={tdClass}>
                      <Text variant={variant}>{value.FieldName}</Text>
                    </td>
                    <td>
                      <Text variant={variant}>{value.Value}</Text>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </PageInfoTab>
    );
  };
  const renderFilters = () => {
    const filters = pageZoom.TableZoom.Filters;
    return (
      <PageInfoTab
        key="tableFilters"
        debugId="tableFiltersTab"
        caption={t("dialogs.pageInfo.tableFilters")}
        opened={false}
      >
        <table className={tableClass}>
          <tbody>
            {filters &&
              Object.keys(filters).map((filtergroup) => {
                const filterGroupFilters = filters[filtergroup];
                const f = filterGroupFilters.map((fi: any) => {
                  return (
                    <tr key={fi.FieldName}>
                      <td colSpan={2}>
                        <Text variant={variant}>{fi.FieldName}</Text>
                      </td>
                      <td>
                        <Text variant={variant}>{fi.Filter}</Text>
                      </td>
                    </tr>
                  );
                });
                return (
                  <>
                    <tr>
                      <td className={tdClass}>
                        <Text variant={variant}>
                          {t("dialogs.pageInfo.filterGroup")} {filtergroup}
                        </Text>
                      </td>
                      <td></td>
                    </tr>
                    {f}
                  </>
                );
              })}
          </tbody>
        </table>
      </PageInfoTab>
    );
  };
  const renderKeys = () => {
    const keys = [...pageZoom.TableZoom.Keys];
    return (
      <PageInfoTab key="tableKeys" debugId="tableKeysTab" caption={t("dialogs.pageInfo.primaryKeys")} opened={false}>
        <table className={tableClass}>
          <tbody>
            {keys &&
              keys.map((key) => {
                return (
                  <tr key={key.FieldName}>
                    <td className={tdClass}>
                      <Text variant={variant}>{key.FieldName}</Text>
                    </td>
                    <td>
                      <Text variant={variant}>
                        {key.Descending === true ? t("singleWords.descending") : t("singleWords.ascending")}
                      </Text>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </PageInfoTab>
    );
  };
  const renderSorting = () => {
    const keys = [...pageZoom.TableZoom.Sorting];
    return (
      <PageInfoTab key="tableSorting" debugId="tableSortingTab" caption={t("dialogs.pageInfo.sorting")} opened={false}>
        <table className={tableClass}>
          <tbody>
            {keys &&
              keys.map((key) => {
                return (
                  <tr key={key.FieldName}>
                    <td className={tdClass}>
                      <Text variant={variant}>{key.FieldName}</Text>
                    </td>
                    <td>
                      <Text variant={variant}>
                        {key.Descending === true ? t("singleWords.descending") : t("singleWords.ascending")}
                      </Text>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </PageInfoTab>
    );
  };
  return (
    <Dialog
      hidden={false}
      onDismiss={() => toggleConfirmClick()}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      maxWidth="30vw"
      minWidth={350}
    >
      {renderPageInfo()}
      {renderTableInfo()}
      {renderTableValues()}
      {renderFilters()}
      {renderKeys()}
      {renderSorting()}
      <DialogFooter>
        <PrimaryButton data-debugid="closePageInfo" onClick={() => toggleConfirmClick()} text={t("buttons.ok")} />
      </DialogFooter>
    </Dialog>
  );
};

export default PageInfo;

const PageInfoTab = (props: any) => {
  const { caption, children, opened, debugId } = props;
  const [open, setOpen] = React.useState(opened);
  const { semanticColors, effects } = useTheme();
  const tabStyles = mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      boxShadow: effects.elevation4,
      margin: "2px 2px 4px 2px",
      borderRadius: effects.roundedCorner2,
    },
    header: {
      displayName: "tab-header",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      height: 30,
      padding: "5px 5px 5px 10px",
      backgroundColor: semanticColors.bodyBackground,
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: open ? `${effects.roundedCorner2} ${effects.roundedCorner2} 0 0` : effects.roundedCorner2,
    },
    content: {
      displayName: "tab-content",
      boxSizing: "border-box",
      display: open ? "flex" : "none",
      flexDirection: "column",
      flex: "1 1 auto",
      padding: "10px",
      backgroundColor: semanticColors.bodyBackground,
      overflow: "auto",
      borderRadius: `0 0 ${effects.roundedCorner2} ${effects.roundedCorner2}`,
    },
    headerMidContent: {
      flex: "1 1 auto",
      height: "100%",
      borderLeft: `${semanticColors.disabledSubtext} solid 1px`,
      marginLeft: 10,
    },
  });
  const textStyle = {
    root: {
      color: semanticColors.bodyText,
      cursor: "pointer",
      fontWeight: "500",
      height: "100%",
    },
  };
  return (
    <div className={tabStyles.root}>
      <div className={tabStyles.header}>
        <Text styles={textStyle} variant={headerVariant} onClick={() => setOpen(!open)}>
          {caption}
        </Text>
        <IconButton
          data-debugid={`${debugId}-toggle`}
          onClick={() => setOpen(!open)}
          tabIndex={-1}
          iconProps={{ iconName: open ? "ChevronUp" : "ChevronDown" }}
          styles={{ root: { height: 24 } }}
        />
      </div>
      <div className={tabStyles.content}>{children}</div>
    </div>
  );
};
// const dialogStyles = { main: { maxWidth: 800, width: 600 } };
const variant = "smallPlus";
const headerVariant = "medium";
const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};
const tableClass = mergeStyles({
  width: "100%",
});
const tdClass = mergeStyles({
  width: "50%",
});
