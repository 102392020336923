import React from "react";
import { Label, Text, mergeStyleSets, Icon } from "@fluentui/react";

const PageNotFound = () => {
  return (
    <div className={classNames.fullSizeBackground}>
      <div className={classNames.loginPanel}>
        <div className={classNames.loginPanelContent}>
          <Label styles={{ root: { fontSize: 24, color: "white", whiteSpace: "nowrap", textAlign: "center" } }}>
            Die von Ihnen gesuchte Seite {decodeURI(window.location.href)} <br /> konnte nicht gefunden werden.
          </Label>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;

const classNames = mergeStyleSets({
  fullSizeBackground: {
    position: "absolute",
    inset: 0,
    backgroundColor: "#000912",
    backgroundImage: "url('images/LoginBackground.jpg')",
    background:
      "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 700px), url('images/LoginBackground.jpg'), #000912",
    backgroundSize: "cover",
  },
  loginPanel: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  loginPanelContent: {
    margin: "auto",
  },
});
