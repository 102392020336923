export enum IconNames {
  TwelvePointStar = "TwelvePointStar",
  SixPointStar = "SixPointStar",
  AADLogo = "AADLogo",
  Accept = "Accept",
  AcceptMedium = "AcceptMedium",
  AccessibiltyChecker = "AccessibiltyChecker",
  AccessLogo = "AccessLogo",
  AccountActivity = "AccountActivity",
  AccountBrowser = "AccountBrowser",
  AccountManagement = "AccountManagement",
  Accounts = "Accounts",
  ActionCenter = "ActionCenter",
  ActivateOrders = "ActivateOrders",
  ActivityFeed = "ActivityFeed",
  Add = "Add",
  AddBookmark = "AddBookmark",
  AddConnection = "AddConnection",
  AddEvent = "AddEvent",
  AddFavorite = "AddFavorite",
  AddFavoriteFill = "AddFavoriteFill",
  AddField = "AddField",
  AddFriend = "AddFriend",
  AddGroup = "AddGroup",
  AddHome = "AddHome",
  AddIn = "AddIn",
  AddLink = "AddLink",
  AddMedium = "AddMedium",
  AddMultiple = "AddMultiple",
  AddNotes = "AddNotes",
  AddOnlineMeeting = "AddOnlineMeeting",
  AddPhone = "AddPhone",
  AddReaction = "AddReaction",
  AddSpaceAfter = "AddSpaceAfter",
  AddSpaceBefore = "AddSpaceBefore",
  AddTable = "AddTable",
  AddTo = "AddTo",
  AddToShoppingList = "AddToShoppingList",
  AddWork = "AddWork",
  Admin = "Admin",
  AdminALogo32 = "AdminALogo32",
  AdminALogoFill32 = "AdminALogoFill32",
  AdminALogoInverse32 = "AdminALogoInverse32",
  AdminCLogoInverse32 = "AdminCLogoInverse32",
  AdminDLogoInverse32 = "AdminDLogoInverse32",
  AdminELogoInverse32 = "AdminELogoInverse32",
  AdminLLogoInverse32 = "AdminLLogoInverse32",
  AdminMLogoInverse32 = "AdminMLogoInverse32",
  AdminOLogoInverse32 = "AdminOLogoInverse32",
  AdminPLogoInverse32 = "AdminPLogoInverse32",
  AdminSLogoInverse32 = "AdminSLogoInverse32",
  AdminYLogoInverse32 = "AdminYLogoInverse32",
  Airplane = "Airplane",
  AirplaneSolid = "AirplaneSolid",
  AirTickets = "AirTickets",
  AlarmClock = "AlarmClock",
  Album = "Album",
  AlbumRemove = "AlbumRemove",
  AlertSettings = "AlertSettings",
  AlertSolid = "AlertSolid",
  AlignCenter = "AlignCenter",
  AlignHorizontalCenter = "AlignHorizontalCenter",
  AlignHorizontalLeft = "AlignHorizontalLeft",
  AlignHorizontalRight = "AlignHorizontalRight",
  AlignJustify = "AlignJustify",
  AlignLeft = "AlignLeft",
  AlignRight = "AlignRight",
  AlignVerticalBottom = "AlignVerticalBottom",
  AlignVerticalCenter = "AlignVerticalCenter",
  AlignVerticalTop = "AlignVerticalTop",
  AllApps = "AllApps",
  AllAppsMirrored = "AllAppsMirrored",
  AllCurrency = "AllCurrency",
  AltText = "AltText",
  AnalyticsLogo = "AnalyticsLogo",
  AnalyticsQuery = "AnalyticsQuery",
  AnalyticsReport = "AnalyticsReport",
  AnalyticsView = "AnalyticsView",
  AnchorLock = "AnchorLock",
  Annotation = "Annotation",
  AppIconDefault = "AppIconDefault",
  AppIconDefaultAdd = "AppIconDefaultAdd",
  AppIconDefaultEdit = "AppIconDefaultEdit",
  AppIconDefaultList = "AppIconDefaultList",
  AppIconSecure = "AppIconSecure",
  AppsContent = "AppsContent",
  Archive = "Archive",
  ArchiveUndo = "ArchiveUndo",
  AreaChart = "AreaChart",
  ArrangeBringForward = "ArrangeBringForward",
  ArrangeBringToFront = "ArrangeBringToFront",
  ArrangeByFrom = "ArrangeByFrom",
  ArrangeSendBackward = "ArrangeSendBackward",
  ArrangeSendToBack = "ArrangeSendToBack",
  Arrivals = "Arrivals",
  ArrowDownRight8 = "ArrowDownRight8",
  ArrowDownRightMirrored8 = "ArrowDownRightMirrored8",
  ArrowTallDownLeft = "ArrowTallDownLeft",
  ArrowTallDownRight = "ArrowTallDownRight",
  ArrowTallUpLeft = "ArrowTallUpLeft",
  ArrowTallUpRight = "ArrowTallUpRight",
  ArrowUpRight = "ArrowUpRight",
  ArrowUpRight8 = "ArrowUpRight8",
  ArrowUpRightMirrored8 = "ArrowUpRightMirrored8",
  Articles = "Articles",
  Ascending = "Ascending",
  AspectRatio = "AspectRatio",
  AssessmentGroup = "AssessmentGroup",
  AssessmentGroupTemplate = "AssessmentGroupTemplate",
  AssetLibrary = "AssetLibrary",
  Assign = "Assign",
  AssignPolicy = "AssignPolicy",
  Asterisk = "Asterisk",
  AsteriskSolid = "AsteriskSolid",
  ATPLogo = "ATPLogo",
  Attach = "Attach",
  AustralianRules = "AustralianRules",
  AuthenticatorApp = "AuthenticatorApp",
  AutoDeploySettings = "AutoDeploySettings",
  AutoEnhanceOff = "AutoEnhanceOff",
  AutoEnhanceOn = "AutoEnhanceOn",
  AutoFillTemplate = "AutoFillTemplate",
  AutoFitContents = "AutoFitContents",
  AutoFitWindow = "AutoFitWindow",
  AutoHeight = "AutoHeight",
  AutomateFlow = "AutomateFlow",
  AutoRacing = "AutoRacing",
  AwayStatus = "AwayStatus",
  AzureAPIManagement = "AzureAPIManagement",
  AzureDataExplorer = "AzureDataExplorer",
  AzureIcon = "AzureIcon",
  AzureKeyVault = "AzureKeyVault",
  AzureServiceEndpoint = "AzureServiceEndpoint",
  Back = "Back",
  BackgroundColor = "BackgroundColor",
  Backlog = "Backlog",
  BacklogBoard = "BacklogBoard",
  BacklogList = "BacklogList",
  BackToWindow = "BackToWindow",
  Badge = "Badge",
  Balloons = "Balloons",
  Bank = "Bank",
  BankSolid = "BankSolid",
  BarChart4 = "BarChart4",
  BarChartHorizontal = "BarChartHorizontal",
  BarChartVertical = "BarChartVertical",
  BarChartVerticalEdit = "BarChartVerticalEdit",
  BarChartVerticalFill = "BarChartVerticalFill",
  BarChartVerticalFilter = "BarChartVerticalFilter",
  BarChartVerticalFilterSolid = "BarChartVerticalFilterSolid",
  Baseball = "Baseball",
  BeerMug = "BeerMug",
  BIDashboard = "BIDashboard",
  BidiLtr = "BidiLtr",
  BidiRtl = "BidiRtl",
  Bill = "Bill",
  BingLogo = "BingLogo",
  BirthdayCake = "BirthdayCake",
  BlobStorage = "BlobStorage",
  BlockContact = "BlockContact",
  Blocked = "Blocked",
  Blocked12 = "Blocked12",
  Blocked2 = "Blocked2",
  Blocked2Solid = "Blocked2Solid",
  BlockedSite = "BlockedSite",
  BlockedSiteSolid12 = "BlockedSiteSolid12",
  BlockedSolid = "BlockedSolid",
  Blog = "Blog",
  BlowingSnow = "BlowingSnow",
  Blur = "Blur",
  Boards = "Boards",
  Bold = "Bold",
  BoldBulgarian = "BoldBulgarian",
  BoldF = "BoldF",
  BoldG = "BoldG",
  BoldK = "BoldK",
  BoldKazakh = "BoldKazakh",
  BoldKorean = "BoldKorean",
  BoldN = "BoldN",
  BoldP = "BoldP",
  BoldRussion = "BoldRussion",
  BoldSerbian = "BoldSerbian",
  BoldT = "BoldT",
  BookAnswers = "BookAnswers",
  BookingsLogo = "BookingsLogo",
  BookmarkReport = "BookmarkReport",
  Bookmarks = "Bookmarks",
  BookmarksMirrored = "BookmarksMirrored",
  BooleanData = "BooleanData",
  BorderAll = "BorderAll",
  BorderDash = "BorderDash",
  BorderDot = "BorderDot",
  BorderInside = "BorderInside",
  BorderInsideHorizontal = "BorderInsideHorizontal",
  BorderInsideVertical = "BorderInsideVertical",
  BorderNone = "BorderNone",
  BoxAdditionSolid = "BoxAdditionSolid",
  BoxCheckmarkSolid = "BoxCheckmarkSolid",
  BoxMultiplySolid = "BoxMultiplySolid",
  BoxPlaySolid = "BoxPlaySolid",
  BoxSubtractSolid = "BoxSubtractSolid",
  BranchCommit = "BranchCommit",
  BranchCompare = "BranchCompare",
  BranchFork = "BranchFork",
  BranchFork2 = "BranchFork2",
  BranchLocked = "BranchLocked",
  BranchMerge = "BranchMerge",
  BranchPullRequest = "BranchPullRequest",
  BranchSearch = "BranchSearch",
  BranchShelveset = "BranchShelveset",
  Breadcrumb = "Breadcrumb",
  Breakfast = "Breakfast",
  Brightness = "Brightness",
  Broom = "Broom",
  BrowserScreenShot = "BrowserScreenShot",
  BrowserTab = "BrowserTab",
  BrowserTabScreenshot = "BrowserTabScreenshot",
  Brunch = "Brunch",
  Brush = "Brush",
  BucketColor = "BucketColor",
  BucketColorFill = "BucketColorFill",
  BufferTimeAfter = "BufferTimeAfter",
  BufferTimeBefore = "BufferTimeBefore",
  BufferTimeBoth = "BufferTimeBoth",
  Bug = "Bug",
  BugAction = "BugAction",
  BugBlock = "BugBlock",
  BugSolid = "BugSolid",
  BugSync = "BugSync",
  BugWarning = "BugWarning",
  Build = "Build",
  BuildDefinition = "BuildDefinition",
  BuildIssue = "BuildIssue",
  BuildQueue = "BuildQueue",
  BuildQueueNew = "BuildQueueNew",
  BulkPageBlock = "BulkPageBlock",
  BulkUpload = "BulkUpload",
  BulletedList = "BulletedList",
  BulletedList2 = "BulletedList2",
  BulletedList2Mirrored = "BulletedList2Mirrored",
  BulletedListBullet = "BulletedListBullet",
  BulletedListBulletMirrored = "BulletedListBulletMirrored",
  BulletedListMirrored = "BulletedListMirrored",
  BulletedListText = "BulletedListText",
  BulletedListTextMirrored = "BulletedListTextMirrored",
  BulletedTreeList = "BulletedTreeList",
  Bullseye = "Bullseye",
  BullseyeTarget = "BullseyeTarget",
  BullseyeTargetAdd = "BullseyeTargetAdd",
  BullseyeTargetDelete = "BullseyeTargetDelete",
  BullseyeTargetEdit = "BullseyeTargetEdit",
  Bus = "Bus",
  BusinessCard = "BusinessCard",
  BusinessCenterLogo = "BusinessCenterLogo",
  BusinessHoursSign = "BusinessHoursSign",
  BusinessRule = "BusinessRule",
  BusSolid = "BusSolid",
  ButtonControl = "ButtonControl",
  Cafe = "Cafe",
  Cake = "Cake",
  CalculatedTable = "CalculatedTable",
  Calculator = "Calculator",
  CalculatorAddition = "CalculatorAddition",
  CalculatorDelta = "CalculatorDelta",
  CalculatorEqualTo = "CalculatorEqualTo",
  CalculatorGroup = "CalculatorGroup",
  CalculatorMultiply = "CalculatorMultiply",
  CalculatorNotEqualTo = "CalculatorNotEqualTo",
  CalculatorPercentage = "CalculatorPercentage",
  CalculatorSubtract = "CalculatorSubtract",
  Calendar = "Calendar",
  CalendarAgenda = "CalendarAgenda",
  CalendarDay = "CalendarDay",
  CalendarMirrored = "CalendarMirrored",
  CalendarReply = "CalendarReply",
  CalendarSettings = "CalendarSettings",
  CalendarSettingsMirrored = "CalendarSettingsMirrored",
  CalendarWeek = "CalendarWeek",
  CalendarWorkWeek = "CalendarWorkWeek",
  CalendarYear = "CalendarYear",
  Calories = "Calories",
  CaloriesAdd = "CaloriesAdd",
  Camera = "Camera",
  CampaignTemplate = "CampaignTemplate",
  Cancel = "Cancel",
  CannedChat = "CannedChat",
  CanvasAppTemplate32 = "CanvasAppTemplate32",
  Car = "Car",
  CareActivity = "CareActivity",
  CarePlan = "CarePlan",
  CarePlanTemplate = "CarePlanTemplate",
  CaretBottomLeftCenter8 = "CaretBottomLeftCenter8",
  CaretBottomLeftSolid8 = "CaretBottomLeftSolid8",
  CaretBottomRightCenter8 = "CaretBottomRightCenter8",
  CaretBottomRightSolid8 = "CaretBottomRightSolid8",
  CaretDown8 = "CaretDown8",
  CaretDownSolid8 = "CaretDownSolid8",
  CaretHollow = "CaretHollow",
  CaretHollowMirrored = "CaretHollowMirrored",
  CaretLeft8 = "CaretLeft8",
  CaretLeftSolid8 = "CaretLeftSolid8",
  CaretRight = "CaretRight",
  CaretRight8 = "CaretRight8",
  CaretRightSolid8 = "CaretRightSolid8",
  CaretSolid = "CaretSolid",
  CaretSolid16 = "CaretSolid16",
  CaretSolidAlt = "CaretSolidAlt",
  CaretSolidDown = "CaretSolidDown",
  CaretSolidLeft = "CaretSolidLeft",
  CaretSolidMirrored = "CaretSolidMirrored",
  CaretSolidRight = "CaretSolidRight",
  CaretSolidUp = "CaretSolidUp",
  CaretTopLeftCenter8 = "CaretTopLeftCenter8",
  CaretTopLeftSolid8 = "CaretTopLeftSolid8",
  CaretTopRightCenter8 = "CaretTopRightCenter8",
  CaretTopRightSolid8 = "CaretTopRightSolid8",
  CaretUp8 = "CaretUp8",
  CaretUpSolid8 = "CaretUpSolid8",
  Cat = "Cat",
  CategoryClassification = "CategoryClassification",
  CC = "CC",
  CCSolid = "CCSolid",
  CellPhone = "CellPhone",
  CellSplitVertical = "CellSplitVertical",
  Certificate = "Certificate",
  CertifiedDatabase = "CertifiedDatabase",
  ChangeEntitlements = "ChangeEntitlements",
  Chart = "Chart",
  CharticulatorArrangePolar = "CharticulatorArrangePolar",
  CharticulatorArrangePolarAngles = "CharticulatorArrangePolarAngles",
  CharticulatorBand = "CharticulatorBand",
  CharticulatorGuideCoordinator = "CharticulatorGuideCoordinator",
  CharticulatorGuideX = "CharticulatorGuideX",
  CharticulatorGuideY = "CharticulatorGuideY",
  CharticulatorLegend = "CharticulatorLegend",
  CharticulatorLine = "CharticulatorLine",
  CharticulatorLineStyleDashed = "CharticulatorLineStyleDashed",
  CharticulatorLineStyleDotted = "CharticulatorLineStyleDotted",
  CharticulatorLinkingData = "CharticulatorLinkingData",
  CharticulatorLinkingSequence = "CharticulatorLinkingSequence",
  CharticulatorOrderColumn = "CharticulatorOrderColumn",
  CharticulatorOrderRow = "CharticulatorOrderRow",
  CharticulatorPlotCartesian = "CharticulatorPlotCartesian",
  CharticulatorPlotCurve = "CharticulatorPlotCurve",
  CharticulatorPolarCoordinates = "CharticulatorPolarCoordinates",
  CharticulatorSpiral = "CharticulatorSpiral",
  CharticulatorStackRadial = "CharticulatorStackRadial",
  CharticulatorStackY = "CharticulatorStackY",
  ChartSeries = "ChartSeries",
  ChartTemplate = "ChartTemplate",
  ChartXAngle = "ChartXAngle",
  ChartYAngle = "ChartYAngle",
  Chat = "Chat",
  ChatBot = "ChatBot",
  ChatInviteFriend = "ChatInviteFriend",
  ChatSettings = "ChatSettings",
  ChatSolid = "ChatSolid",
  Checkbox = "Checkbox",
  CheckboxComposite = "CheckboxComposite",
  CheckboxCompositeReversed = "CheckboxCompositeReversed",
  CheckboxFill = "CheckboxFill",
  CheckboxIndeterminate = "CheckboxIndeterminate",
  CheckboxIndeterminateCombo = "CheckboxIndeterminateCombo",
  CheckedOutByOther12 = "CheckedOutByOther12",
  CheckedOutByYou12 = "CheckedOutByYou12",
  CheckList = "CheckList",
  CheckListCheck = "CheckListCheck",
  CheckListCheckMirrored = "CheckListCheckMirrored",
  CheckListText = "CheckListText",
  CheckListTextMirrored = "CheckListTextMirrored",
  CheckMark = "CheckMark",
  ChevronDown = "ChevronDown",
  ChevronDownEnd = "ChevronDownEnd",
  ChevronDownEnd6 = "ChevronDownEnd6",
  ChevronDownMed = "ChevronDownMed",
  ChevronDownSmall = "ChevronDownSmall",
  ChevronFold10 = "ChevronFold10",
  ChevronLeft = "ChevronLeft",
  ChevronLeftEnd6 = "ChevronLeftEnd6",
  ChevronLeftMed = "ChevronLeftMed",
  ChevronLeftSmall = "ChevronLeftSmall",
  ChevronRight = "ChevronRight",
  ChevronRightEnd6 = "ChevronRightEnd6",
  ChevronRightMed = "ChevronRightMed",
  ChevronRightSmall = "ChevronRightSmall",
  ChevronUnfold10 = "ChevronUnfold10",
  ChevronUp = "ChevronUp",
  ChevronUpEnd = "ChevronUpEnd",
  ChevronUpEnd6 = "ChevronUpEnd6",
  ChevronUpMed = "ChevronUpMed",
  ChevronUpSmall = "ChevronUpSmall",
  Childof = "Childof",
  ChoiceColumn = "ChoiceColumn",
  Chopsticks = "Chopsticks",
  ChromeBack = "ChromeBack",
  ChromeBackMirrored = "ChromeBackMirrored",
  ChromeClose = "ChromeClose",
  ChromeFullScreen = "ChromeFullScreen",
  ChromeMinimize = "ChromeMinimize",
  ChromeRestore = "ChromeRestore",
  CircleAddition = "CircleAddition",
  CircleAdditionSolid = "CircleAdditionSolid",
  CircleDollar = "CircleDollar",
  CircleFill = "CircleFill",
  CircleHalfFull = "CircleHalfFull",
  CirclePause = "CirclePause",
  CirclePauseSolid = "CirclePauseSolid",
  CirclePlus = "CirclePlus",
  CircleRing = "CircleRing",
  CircleShape = "CircleShape",
  CircleShapeSolid = "CircleShapeSolid",
  CircleStop = "CircleStop",
  CircleStopSolid = "CircleStopSolid",
  CityNext = "CityNext",
  CityNext2 = "CityNext2",
  ClassNotebookLogo16 = "ClassNotebookLogo16",
  ClassNotebookLogo32 = "ClassNotebookLogo32",
  ClassNotebookLogoFill16 = "ClassNotebookLogoFill16",
  ClassNotebookLogoFill32 = "ClassNotebookLogoFill32",
  ClassNotebookLogoInverse = "ClassNotebookLogoInverse",
  ClassNotebookLogoInverse16 = "ClassNotebookLogoInverse16",
  ClassNotebookLogoInverse32 = "ClassNotebookLogoInverse32",
  ClassroomLogo = "ClassroomLogo",
  Clear = "Clear",
  ClearFilter = "ClearFilter",
  ClearFormatting = "ClearFormatting",
  ClearFormattingA = "ClearFormattingA",
  ClearFormattingEraser = "ClearFormattingEraser",
  ClearNight = "ClearNight",
  ClearSelection = "ClearSelection",
  ClearSelectionMirrored = "ClearSelectionMirrored",
  Clicked = "Clicked",
  ClinicalImpression = "ClinicalImpression",
  ClipboardList = "ClipboardList",
  ClipboardListAdd = "ClipboardListAdd",
  ClipboardListMirrored = "ClipboardListMirrored",
  ClipboardListQuestion = "ClipboardListQuestion",
  ClipboardListReply = "ClipboardListReply",
  ClipboardSolid = "ClipboardSolid",
  Clock = "Clock",
  CloneToDesktop = "CloneToDesktop",
  ClosedCaption = "ClosedCaption",
  ClosePane = "ClosePane",
  ClosePaneMirrored = "ClosePaneMirrored",
  Cloud = "Cloud",
  CloudAdd = "CloudAdd",
  CloudDownload = "CloudDownload",
  CloudEdit = "CloudEdit",
  CloudFlow = "CloudFlow",
  CloudImportExport = "CloudImportExport",
  CloudLink = "CloudLink",
  CloudNotSynced = "CloudNotSynced",
  CloudPrinter = "CloudPrinter",
  CloudSearch = "CloudSearch",
  CloudSecure = "CloudSecure",
  CloudUpload = "CloudUpload",
  CloudWeather = "CloudWeather",
  Cloudy = "Cloudy",
  Cocktails = "Cocktails",
  Code = "Code",
  CodeEdit = "CodeEdit",
  Coffee = "Coffee",
  CoffeeScript = "CoffeeScript",
  CollapseAll = "CollapseAll",
  CollapseContent = "CollapseContent",
  CollapseContentSingle = "CollapseContentSingle",
  CollapseMenu = "CollapseMenu",
  CollegeFootball = "CollegeFootball",
  CollegeHoops = "CollegeHoops",
  Color = "Color",
  ColorSolid = "ColorSolid",
  Column = "Column",
  ColumnFunction = "ColumnFunction",
  ColumnLeftTwoThirds = "ColumnLeftTwoThirds",
  ColumnLeftTwoThirdsEdit = "ColumnLeftTwoThirdsEdit",
  ColumnOptions = "ColumnOptions",
  ColumnQuestion = "ColumnQuestion",
  ColumnQuestionMirrored = "ColumnQuestionMirrored",
  ColumnRightTwoThirds = "ColumnRightTwoThirds",
  ColumnRightTwoThirdsEdit = "ColumnRightTwoThirdsEdit",
  ColumnSigma = "ColumnSigma",
  ColumnVerticalSection = "ColumnVerticalSection",
  ColumnVerticalSectionEdit = "ColumnVerticalSectionEdit",
  Combine = "Combine",
  Combobox = "Combobox",
  CommandPrompt = "CommandPrompt",
  Comment = "Comment",
  CommentActive = "CommentActive",
  CommentAdd = "CommentAdd",
  CommentNext = "CommentNext",
  CommentPrevious = "CommentPrevious",
  CommentSolid = "CommentSolid",
  CommentUrgent = "CommentUrgent",
  Commitments = "Commitments",
  CommonDataServiceCDS = "CommonDataServiceCDS",
  CommunicationDetails = "CommunicationDetails",
  CommunicationDetailsMirrored = "CommunicationDetailsMirrored",
  Communications = "Communications",
  CompanyDirectory = "CompanyDirectory",
  CompanyDirectoryMirrored = "CompanyDirectoryMirrored",
  Compare = "Compare",
  CompareUneven = "CompareUneven",
  CompassNW = "CompassNW",
  Completed = "Completed",
  Completed12 = "Completed12",
  CompletedSolid = "CompletedSolid",
  ComplianceAudit = "ComplianceAudit",
  ConfigurationSolid = "ConfigurationSolid",
  ConfirmEvent = "ConfirmEvent",
  ConnectContacts = "ConnectContacts",
  ConnectVirtualMachine = "ConnectVirtualMachine",
  ConstructionCone = "ConstructionCone",
  ConstructionConeSolid = "ConstructionConeSolid",
  Contact = "Contact",
  ContactCard = "ContactCard",
  ContactCardSettings = "ContactCardSettings",
  ContactCardSettingsMirrored = "ContactCardSettingsMirrored",
  ContactHeart = "ContactHeart",
  ContactInfo = "ContactInfo",
  ContactInfoMirrored = "ContactInfoMirrored",
  ContactLink = "ContactLink",
  ContactList = "ContactList",
  ContactLock = "ContactLock",
  ContentFeed = "ContentFeed",
  ContentSettings = "ContentSettings",
  ContentUnderstandingApp = "ContentUnderstandingApp",
  ContextMenu = "ContextMenu",
  Contrast = "Contrast",
  Copy = "Copy",
  CopyEdit = "CopyEdit",
  CortanaLogoBeckonInner = "CortanaLogoBeckonInner",
  CortanaLogoBeckonOuter = "CortanaLogoBeckonOuter",
  CortanaLogoInner = "CortanaLogoInner",
  CortanaLogoOuter = "CortanaLogoOuter",
  CortanaLogoReadyInner = "CortanaLogoReadyInner",
  CortanaLogoReadyOuter = "CortanaLogoReadyOuter",
  CostContralLedgerAdmin = "CostContralLedgerAdmin",
  CostControl = "CostControl",
  Cotton = "Cotton",
  Count = "Count",
  Coupon = "Coupon",
  CPlusPlus = "CPlusPlus",
  CPlusPlusLanguage = "CPlusPlusLanguage",
  CreateMailRule = "CreateMailRule",
  CreditCardBill = "CreditCardBill",
  Cricket = "Cricket",
  CriticalErrorSolid = "CriticalErrorSolid",
  CRMLead = "CRMLead",
  CRMProcesses = "CRMProcesses",
  CRMReport = "CRMReport",
  CRMResourceOptimizationApp32 = "CRMResourceOptimizationApp32",
  CRMServices = "CRMServices",
  Crop = "Crop",
  Crown = "Crown",
  CrownSolid = "CrownSolid",
  CSharp = "CSharp",
  CSharpLanguage = "CSharpLanguage",
  CSS = "CSS",
  CtrlButton = "CtrlButton",
  CubeShape = "CubeShape",
  CubeShapeSolid = "CubeShapeSolid",
  Currency = "Currency",
  CustomActivity = "CustomActivity",
  CustomEntity = "CustomEntity",
  CustomerAssets = "CustomerAssets",
  CustomizeToolbar = "CustomizeToolbar",
  CustomList = "CustomList",
  CustomListMirrored = "CustomListMirrored",
  Cut = "Cut",
  Cycling = "Cycling",
  D365BusinessCentral = "D365BusinessCentral",
  D365CoreHR = "D365CoreHR",
  D365CustomerInsights = "D365CustomerInsights",
  D365CustomerVoiceApp = "D365CustomerVoiceApp",
  D365ProjectOperations = "D365ProjectOperations",
  D365TalentInsight = "D365TalentInsight",
  D365TalentLearn = "D365TalentLearn",
  DashboardAdd = "DashboardAdd",
  Database = "Database",
  DatabaseActivity = "DatabaseActivity",
  DatabaseBlock = "DatabaseBlock",
  DatabaseRefresh = "DatabaseRefresh",
  DatabaseSource = "DatabaseSource",
  DatabaseSwap = "DatabaseSwap",
  DatabaseSync = "DatabaseSync",
  DatabaseView = "DatabaseView",
  DataConnectionLibrary = "DataConnectionLibrary",
  DataEnrichment = "DataEnrichment",
  DataFlow = "DataFlow",
  Dataflows = "Dataflows",
  DataflowsLink = "DataflowsLink",
  DataManagementSettings = "DataManagementSettings",
  Dataverse = "Dataverse",
  DateTime = "DateTime",
  DateTime12 = "DateTime12",
  DateTime2 = "DateTime2",
  DateTimeMirrored = "DateTimeMirrored",
  DeactivateOrders = "DeactivateOrders",
  Decimals = "Decimals",
  DecisionSolid = "DecisionSolid",
  DeclineCall = "DeclineCall",
  DecreaseIndent = "DecreaseIndent",
  DecreaseIndentArrow = "DecreaseIndentArrow",
  DecreaseIndentArrowMirrored = "DecreaseIndentArrowMirrored",
  DecreaseIndentMirrored = "DecreaseIndentMirrored",
  DecreaseIndentText = "DecreaseIndentText",
  DecreaseIndentTextMirrored = "DecreaseIndentTextMirrored",
  DefaultRatio = "DefaultRatio",
  DefaultSettings = "DefaultSettings",
  DefectSolid = "DefectSolid",
  DefenderApp = "DefenderApp",
  DefenderBadge12 = "DefenderBadge12",
  DefenderTVM = "DefenderTVM",
  Delete = "Delete",
  DeleteColumns = "DeleteColumns",
  DeleteRows = "DeleteRows",
  DeleteRowsMirrored = "DeleteRowsMirrored",
  DeleteTable = "DeleteTable",
  DeliveryTruck = "DeliveryTruck",
  DelveAnalytics = "DelveAnalytics",
  DelveAnalyticsLogo = "DelveAnalyticsLogo",
  DelveLogo = "DelveLogo",
  DelveLogoFill = "DelveLogoFill",
  DelveLogoInverse = "DelveLogoInverse",
  DensityComfy = "DensityComfy",
  DensityDefault = "DensityDefault",
  DependencyAdd = "DependencyAdd",
  DependencyRemove = "DependencyRemove",
  Deploy = "Deploy",
  Descending = "Descending",
  Design = "Design",
  DesktopFlow = "DesktopFlow",
  DesktopScreenshot = "DesktopScreenshot",
  DeveloperTools = "DeveloperTools",
  DeviceBug = "DeviceBug",
  DeviceOff = "DeviceOff",
  DeviceRun = "DeviceRun",
  Devices2 = "Devices2",
  Devices3 = "Devices3",
  Devices4 = "Devices4",
  Diagnostic = "Diagnostic",
  DiagnosticDataBarTooltip = "DiagnosticDataBarTooltip",
  DiagnosticDataViewerApp = "DiagnosticDataViewerApp",
  Dialpad = "Dialpad",
  Diamond = "Diamond",
  DiamondSolid = "DiamondSolid",
  DiamondUser = "DiamondUser",
  Dictionary = "Dictionary",
  DictionaryRemove = "DictionaryRemove",
  DietPlanNotebook = "DietPlanNotebook",
  DiffInline = "DiffInline",
  DiffSideBySide = "DiffSideBySide",
  Diploma = "Diploma",
  DisableUpdates = "DisableUpdates",
  DisconnectVirtualMachine = "DisconnectVirtualMachine",
  Dislike = "Dislike",
  DislikeSolid = "DislikeSolid",
  DistributeDown = "DistributeDown",
  DockLeft = "DockLeft",
  DockLeftMirrored = "DockLeftMirrored",
  DockRight = "DockRight",
  DocLibrary = "DocLibrary",
  DocsLogoInverse = "DocsLogoInverse",
  Document = "Document",
  DocumentApproval = "DocumentApproval",
  Documentation = "Documentation",
  DocumentManagement = "DocumentManagement",
  DocumentReply = "DocumentReply",
  DocumentSearch = "DocumentSearch",
  DocumentSet = "DocumentSet",
  DOM = "DOM",
  DonutChart = "DonutChart",
  Door = "Door",
  DoubleBookmark = "DoubleBookmark",
  DoubleChevronDown = "DoubleChevronDown",
  DoubleChevronDown12 = "DoubleChevronDown12",
  DoubleChevronDown8 = "DoubleChevronDown8",
  DoubleChevronLeft = "DoubleChevronLeft",
  DoubleChevronLeft12 = "DoubleChevronLeft12",
  DoubleChevronLeft8 = "DoubleChevronLeft8",
  DoubleChevronLeftMed = "DoubleChevronLeftMed",
  DoubleChevronLeftMedMirrored = "DoubleChevronLeftMedMirrored",
  DoubleChevronRight = "DoubleChevronRight",
  DoubleChevronRight12 = "DoubleChevronRight12",
  DoubleChevronRight8 = "DoubleChevronRight8",
  DoubleChevronUp = "DoubleChevronUp",
  DoubleChevronUp12 = "DoubleChevronUp12",
  DoubleChevronUp8 = "DoubleChevronUp8",
  DoubleColumn = "DoubleColumn",
  DoubleColumnEdit = "DoubleColumnEdit",
  DoubleDownArrow = "DoubleDownArrow",
  Down = "Down",
  Download = "Download",
  DownloadDocument = "DownloadDocument",
  DragObject = "DragObject",
  DrillDown = "DrillDown",
  DrillDownSolid = "DrillDownSolid",
  DrillExpand = "DrillExpand",
  DrillShow = "DrillShow",
  DrillThrough = "DrillThrough",
  DriverOff = "DriverOff",
  DRM = "DRM",
  Drop = "Drop",
  Dropdown = "Dropdown",
  DropShape = "DropShape",
  DropShapeSolid = "DropShapeSolid",
  DuplicateRow = "DuplicateRow",
  Duststorm = "Duststorm",
  DynamicList = "DynamicList",
  Dynamics365Logo = "Dynamics365Logo",
  DynamicSMBLogo = "DynamicSMBLogo",
  EaseOfAccess = "EaseOfAccess",
  EatDrink = "EatDrink",
  EdgeLogo = "EdgeLogo",
  EdgeLogo16 = "EdgeLogo16",
  EdgeOldLogo = "EdgeOldLogo",
  eDiscovery = "eDiscovery",
  Edit = "Edit",
  EditContact = "EditContact",
  EditCreate = "EditCreate",
  EditEvent = "EditEvent",
  EditListPencil = "EditListPencil",
  EditMail = "EditMail",
  EditMirrored = "EditMirrored",
  EditNote = "EditNote",
  EditPhoto = "EditPhoto",
  EditSolid12 = "EditSolid12",
  EditSolidMirrored12 = "EditSolidMirrored12",
  EditStyle = "EditStyle",
  EditTable = "EditTable",
  Education = "Education",
  Ellipse = "Ellipse",
  Embed = "Embed",
  EMI = "EMI",
  Emoji = "Emoji",
  Emoji2 = "Emoji2",
  EmojiDisappointed = "EmojiDisappointed",
  EmojiNeutral = "EmojiNeutral",
  EmojiTabSymbols = "EmojiTabSymbols",
  EmployeeSelfService = "EmployeeSelfService",
  EmptyRecycleBin = "EmptyRecycleBin",
  Encounter = "Encounter",
  Encryption = "Encryption",
  EndPointSolid = "EndPointSolid",
  EngineeringGroup = "EngineeringGroup",
  EntitlementPolicy = "EntitlementPolicy",
  EntitlementRedemption = "EntitlementRedemption",
  EntityExtraction = "EntityExtraction",
  EntryDecline = "EntryDecline",
  EntryView = "EntryView",
  Equalizer = "Equalizer",
  EraseTool = "EraseTool",
  Error = "Error",
  ErrorBadge = "ErrorBadge",
  ErrorBadge12 = "ErrorBadge12",
  Event = "Event",
  Event12 = "Event12",
  EventAccepted = "EventAccepted",
  EventDate = "EventDate",
  EventDateMissed12 = "EventDateMissed12",
  EventDeclined = "EventDeclined",
  EventInfo = "EventInfo",
  EventTentative = "EventTentative",
  EventTentativeMirrored = "EventTentativeMirrored",
  EventToDoLogo = "EventToDoLogo",
  ExcelDocument = "ExcelDocument",
  ExcelLogo = "ExcelLogo",
  ExcelLogo16 = "ExcelLogo16",
  ExcelLogoInverse = "ExcelLogoInverse",
  ExcelLogoInverse16 = "ExcelLogoInverse16",
  ExchangeLogo = "ExchangeLogo",
  ExchangeLogoInverse = "ExchangeLogoInverse",
  ExerciseTracker = "ExerciseTracker",
  ExpandAll = "ExpandAll",
  ExpandMenu = "ExpandMenu",
  ExploreContent = "ExploreContent",
  ExploreContentSingle = "ExploreContentSingle",
  ExploreData = "ExploreData",
  Export = "Export",
  ExportMirrored = "ExportMirrored",
  ExpressRouteCircuits = "ExpressRouteCircuits",
  ExternalBuild = "ExternalBuild",
  ExternalTFVC = "ExternalTFVC",
  ExternalUser = "ExternalUser",
  ExternalXAML = "ExternalXAML",
  Eyedropper = "Eyedropper",
  EyeShadow = "EyeShadow",
  F12DevTools = "F12DevTools",
  FabricAssetLibrary = "FabricAssetLibrary",
  FabricChannelFolder = "FabricChannelFolder",
  FabricDataConnectionLibrary = "FabricDataConnectionLibrary",
  FabricDocLibrary = "FabricDocLibrary",
  FabricFolder = "FabricFolder",
  FabricFolderConfirm = "FabricFolderConfirm",
  FabricFolderFill = "FabricFolderFill",
  FabricFolderLink = "FabricFolderLink",
  FabricFolderSearch = "FabricFolderSearch",
  FabricFolderUpload = "FabricFolderUpload",
  FabricFormLibrary = "FabricFormLibrary",
  FabricFormLibraryMirrored = "FabricFormLibraryMirrored",
  FabricMovetoFolder = "FabricMovetoFolder",
  FabricNetworkFolder = "FabricNetworkFolder",
  FabricNewFolder = "FabricNewFolder",
  FabricOpenFolderHorizontal = "FabricOpenFolderHorizontal",
  FabricPictureLibrary = "FabricPictureLibrary",
  FabricPublicFolder = "FabricPublicFolder",
  FabricReportLibrary = "FabricReportLibrary",
  FabricReportLibraryMirrored = "FabricReportLibraryMirrored",
  FabricSyncFolder = "FabricSyncFolder",
  FabricTextHighlight = "FabricTextHighlight",
  FabricTextHighlightComposite = "FabricTextHighlightComposite",
  FabricUnsyncFolder = "FabricUnsyncFolder",
  FabricUserFolder = "FabricUserFolder",
  Factory = "Factory",
  Family = "Family",
  FangBody = "FangBody",
  FastForward = "FastForward",
  FastForwardEightX = "FastForwardEightX",
  FastForwardFourX = "FastForwardFourX",
  FastForwardOneFiveX = "FastForwardOneFiveX",
  FastForwardOneX = "FastForwardOneX",
  FastForwardPointFiveX = "FastForwardPointFiveX",
  FastForwardTwoX = "FastForwardTwoX",
  FastMode = "FastMode",
  Favicon = "Favicon",
  FavoriteBlock = "FavoriteBlock",
  FavoriteList = "FavoriteList",
  FavoriteStar = "FavoriteStar",
  FavoriteStarFill = "FavoriteStarFill",
  Fax = "Fax",
  Feedback = "Feedback",
  FeedbackRequestMirroredSolid = "FeedbackRequestMirroredSolid",
  FeedbackRequestSolid = "FeedbackRequestSolid",
  FeedbackResponseSolid = "FeedbackResponseSolid",
  Ferry = "Ferry",
  FerrySolid = "FerrySolid",
  FieldChanged = "FieldChanged",
  FieldEmpty = "FieldEmpty",
  FieldFilled = "FieldFilled",
  FieldNotChanged = "FieldNotChanged",
  FieldReadOnly = "FieldReadOnly",
  FieldRequired = "FieldRequired",
  FileASPX = "FileASPX",
  FileBug = "FileBug",
  FileCode = "FileCode",
  FileComment = "FileComment",
  FileCSS = "FileCSS",
  FileHTML = "FileHTML",
  FileImage = "FileImage",
  FileJAVA = "FileJAVA",
  FileLess = "FileLess",
  FileOff = "FileOff",
  FilePDB = "FilePDB",
  FileRequest = "FileRequest",
  FileSass = "FileSass",
  FileSQL = "FileSQL",
  FileSymlink = "FileSymlink",
  FileSystem = "FileSystem",
  FileTemplate = "FileTemplate",
  FileTypeSolution = "FileTypeSolution",
  FileYML = "FileYML",
  Filter = "Filter",
  FilterAscending = "FilterAscending",
  FilterDescending = "FilterDescending",
  Filters = "Filters",
  FilterSettings = "FilterSettings",
  FilterSolid = "FilterSolid",
  FiltersSolid = "FiltersSolid",
  Financial = "Financial",
  FinancialMirroredSolid = "FinancialMirroredSolid",
  FinancialSolid = "FinancialSolid",
  Fingerprint = "Fingerprint",
  FitPage = "FitPage",
  FitWidth = "FitWidth",
  FiveTileGrid = "FiveTileGrid",
  FixedAssetManagement = "FixedAssetManagement",
  FixedColumnWidth = "FixedColumnWidth",
  Flag = "Flag",
  FlameSolid = "FlameSolid",
  FlashAuto = "FlashAuto",
  Flashlight = "Flashlight",
  FlashOff = "FlashOff",
  FlickDown = "FlickDown",
  FlickLeft = "FlickLeft",
  FlickRight = "FlickRight",
  FlickUp = "FlickUp",
  Flow = "Flow",
  FlowChart = "FlowChart",
  Flower = "Flower",
  FlowTemplate = "FlowTemplate",
  FlowTrigger = "FlowTrigger",
  FluidLogo = "FluidLogo",
  FocalPoint = "FocalPoint",
  Focus = "Focus",
  FocusView = "FocusView",
  Fog = "Fog",
  Folder = "Folder",
  FolderFill = "FolderFill",
  FolderHorizontal = "FolderHorizontal",
  FolderList = "FolderList",
  FolderListMirrored = "FolderListMirrored",
  FolderOpen = "FolderOpen",
  FolderQuery = "FolderQuery",
  FolderSearch = "FolderSearch",
  FollowUser = "FollowUser",
  Font = "Font",
  FontColor = "FontColor",
  FontColorA = "FontColorA",
  FontColorKorean = "FontColorKorean",
  FontColorSwatch = "FontColorSwatch",
  FontDecrease = "FontDecrease",
  FontIncrease = "FontIncrease",
  FontSize = "FontSize",
  FontSize2 = "FontSize2",
  FontStyleKorean = "FontStyleKorean",
  Footer = "Footer",
  FormatPainter = "FormatPainter",
  FormLibrary = "FormLibrary",
  FormLibraryMirrored = "FormLibraryMirrored",
  FormProcessing = "FormProcessing",
  Forum = "Forum",
  Forward = "Forward",
  ForwardEvent = "ForwardEvent",
  Freezing = "Freezing",
  FreezingRain = "FreezingRain",
  Frigid = "Frigid",
  FrontCamera = "FrontCamera",
  FSharp = "FSharp",
  FSharpLanguage = "FSharpLanguage",
  FullCircleMask = "FullCircleMask",
  FullHistory = "FullHistory",
  FullScreen = "FullScreen",
  FullView = "FullView",
  FullWidth = "FullWidth",
  FullWidthEdit = "FullWidthEdit",
  FunctionalManagerDashboard = "FunctionalManagerDashboard",
  FunnelChart = "FunnelChart",
  GallatinLogo = "GallatinLogo",
  Game = "Game",
  Gather = "Gather",
  Generate = "Generate",
  GenericScan = "GenericScan",
  GenericScanFilled = "GenericScanFilled",
  GIF = "GIF",
  Giftbox = "Giftbox",
  GiftboxOpen = "GiftboxOpen",
  GiftBoxSolid = "GiftBoxSolid",
  GiftCard = "GiftCard",
  GitGraph = "GitGraph",
  Glasses = "Glasses",
  Glimmer = "Glimmer",
  GlobalNavButton = "GlobalNavButton",
  GlobalNavButtonActive = "GlobalNavButtonActive",
  Globe = "Globe",
  Globe2 = "Globe2",
  GlobeFavorite = "GlobeFavorite",
  Go = "Go",
  Golf = "Golf",
  GoMirrored = "GoMirrored",
  GoToDashboard = "GoToDashboard",
  GotoToday = "GotoToday",
  GraphSymbol = "GraphSymbol",
  GreetingCard = "GreetingCard",
  GridViewLarge = "GridViewLarge",
  GridViewMedium = "GridViewMedium",
  GridViewSmall = "GridViewSmall",
  GripperBarHorizontal = "GripperBarHorizontal",
  GripperBarVertical = "GripperBarVertical",
  GripperDotsVertical = "GripperDotsVertical",
  GripperTool = "GripperTool",
  Group = "Group",
  GroupedAscending = "GroupedAscending",
  GroupedDescending = "GroupedDescending",
  GroupedList = "GroupedList",
  GroupList = "GroupList",
  GroupObject = "GroupObject",
  GroupRemove = "GroupRemove",
  GUID = "GUID",
  Guitar = "Guitar",
  HailDay = "HailDay",
  HailNight = "HailNight",
  HalfAlpha = "HalfAlpha",
  HalfCircle = "HalfCircle",
  HandsFree = "HandsFree",
  Handwriting = "Handwriting",
  HardDrive = "HardDrive",
  HardDriveGroup = "HardDriveGroup",
  HardDriveLock = "HardDriveLock",
  HardDriveUnlock = "HardDriveUnlock",
  HazyDay = "HazyDay",
  HazyNight = "HazyNight",
  Header = "Header",
  Header1 = "Header1",
  Header2 = "Header2",
  Header3 = "Header3",
  Header4 = "Header4",
  Headset = "Headset",
  HeadsetSolid = "HeadsetSolid",
  Health = "Health",
  HealthRefresh = "HealthRefresh",
  HealthSolid = "HealthSolid",
  Heart = "Heart",
  HeartBroken = "HeartBroken",
  HeartFill = "HeartFill",
  Help = "Help",
  HelpMirrored = "HelpMirrored",
  HexaditeInvestigation = "HexaditeInvestigation",
  HexaditeInvestigationCancel = "HexaditeInvestigationCancel",
  HexaditeInvestigationSemiAuto = "HexaditeInvestigationSemiAuto",
  Hexagon = "Hexagon",
  Hide = "Hide",
  Hide2 = "Hide2",
  Hide3 = "Hide3",
  HideVisualFilter = "HideVisualFilter",
  Highlight = "Highlight",
  HighlightMappedShapes = "HighlightMappedShapes",
  HintText = "HintText",
  HistoricalWeather = "HistoricalWeather",
  History = "History",
  Home = "Home",
  HomeDropdown = "HomeDropdown",
  HomeGroup = "HomeGroup",
  HomeSolid = "HomeSolid",
  HomeVerify = "HomeVerify",
  HorizontalDistributeCenter = "HorizontalDistributeCenter",
  HorizontalTabKey = "HorizontalTabKey",
  Hospital = "Hospital",
  Hot = "Hot",
  Hotel = "Hotel",
  HourGlass = "HourGlass",
  Ice = "Ice",
  IconSetsFlag = "IconSetsFlag",
  IDBadge = "IDBadge",
  IgnoreConversation = "IgnoreConversation",
  ImageCrosshair = "ImageCrosshair",
  ImageDiff = "ImageDiff",
  ImageInAR = "ImageInAR",
  ImagePixel = "ImagePixel",
  ImageSearch = "ImageSearch",
  Import = "Import",
  ImportAllMirrored = "ImportAllMirrored",
  Important = "Important",
  ImportMirrored = "ImportMirrored",
  Inbox = "Inbox",
  InboxActive = "InboxActive",
  InboxCheck = "InboxCheck",
  IncidentTriangle = "IncidentTriangle",
  IncomingCall = "IncomingCall",
  IncreaseIndent = "IncreaseIndent",
  IncreaseIndentArrow = "IncreaseIndentArrow",
  IncreaseIndentArrowMirrored = "IncreaseIndentArrowMirrored",
  IncreaseIndentHanging = "IncreaseIndentHanging",
  IncreaseIndentHangingMirrored = "IncreaseIndentHangingMirrored",
  IncreaseIndentMirrored = "IncreaseIndentMirrored",
  IncreaseIndentText = "IncreaseIndentText",
  IncreaseIndentTextMirrored = "IncreaseIndentTextMirrored",
  IndentFirstLine = "IndentFirstLine",
  Info = "Info",
  Info12 = "Info12",
  Info2 = "Info2",
  InformationBarriers = "InformationBarriers",
  InfoSolid = "InfoSolid",
  InkingTool = "InkingTool",
  InputAddress = "InputAddress",
  Insert = "Insert",
  InsertColumnsLeft = "InsertColumnsLeft",
  InsertColumnsRight = "InsertColumnsRight",
  InsertRowsAbove = "InsertRowsAbove",
  InsertRowsBelow = "InsertRowsBelow",
  InsertSignatureLine = "InsertSignatureLine",
  InsertTextBox = "InsertTextBox",
  InsertTextBoxKorean = "InsertTextBoxKorean",
  Insights = "Insights",
  Installation = "Installation",
  InstallToDrive = "InstallToDrive",
  IntermittentCloudsDay = "IntermittentCloudsDay",
  IntermittentCloudsNight = "IntermittentCloudsNight",
  InternalInvestigation = "InternalInvestigation",
  InternetSharing = "InternetSharing",
  IntersectShape = "IntersectShape",
  Invoice = "Invoice",
  IOT = "IOT",
  IoTSecure = "IoTSecure",
  IRMForward = "IRMForward",
  IRMForwardMirrored = "IRMForwardMirrored",
  IRMReply = "IRMReply",
  IRMReplyMirrored = "IRMReplyMirrored",
  IssueSolid = "IssueSolid",
  IssueTracking = "IssueTracking",
  IssueTrackingMirrored = "IssueTrackingMirrored",
  Italic = "Italic",
  ItalicC = "ItalicC",
  ItalicD = "ItalicD",
  ItalicK = "ItalicK",
  ItalicKazakh = "ItalicKazakh",
  ItalicKorean = "ItalicKorean",
  ItalicL = "ItalicL",
  ItalicS = "ItalicS",
  ItalicT = "ItalicT",
  JavaScriptLanguage = "JavaScriptLanguage",
  JoinOnlineMeeting = "JoinOnlineMeeting",
  JS = "JS",
  KaizalaLogo = "KaizalaLogo",
  KeyboardClassic = "KeyboardClassic",
  KeyPhraseExtraction = "KeyPhraseExtraction",
  KnowledgeArticle = "KnowledgeArticle",
  KnowledgeManagementApp = "KnowledgeManagementApp",
  Label = "Label",
  LadybugSolid = "LadybugSolid",
  Lamp = "Lamp",
  LandscapeOrientation = "LandscapeOrientation",
  LaptopSecure = "LaptopSecure",
  LaptopSelected = "LaptopSelected",
  LargeGrid = "LargeGrid",
  LearningApp = "LearningApp",
  LearningTools = "LearningTools",
  Leave = "Leave",
  LeaveUser = "LeaveUser",
  Library = "Library",
  LibraryAddTo = "LibraryAddTo",
  Lifesaver = "Lifesaver",
  LifesaverLock = "LifesaverLock",
  Light = "Light",
  Lightbulb = "Lightbulb",
  LightbulbSolid = "LightbulbSolid",
  LightningBolt = "LightningBolt",
  LightningBoltSolid = "LightningBoltSolid",
  LightningSecure = "LightningSecure",
  LightSnow = "LightSnow",
  LightWeight = "LightWeight",
  Like = "Like",
  LikeSolid = "LikeSolid",
  Line = "Line",
  LineChart = "LineChart",
  LineSpacing = "LineSpacing",
  LineStyle = "LineStyle",
  LineThickness = "LineThickness",
  Link = "Link",
  Link12 = "Link12",
  LinkedDatabase = "LinkedDatabase",
  LinkedInLogo = "LinkedInLogo",
  List = "List",
  ListMirrored = "ListMirrored",
  LiveSite = "LiveSite",
  LocalAdmin = "LocalAdmin",
  LocaleLanguage = "LocaleLanguage",
  Location = "Location",
  LocationCircle = "LocationCircle",
  LocationDot = "LocationDot",
  LocationFill = "LocationFill",
  LocationOutline = "LocationOutline",
  Lock = "Lock",
  Lock12 = "Lock12",
  LockShare = "LockShare",
  LockSolid = "LockSolid",
  LogRemove = "LogRemove",
  LookupEntities = "LookupEntities",
  LowerBrightness = "LowerBrightness",
  LowerCase = "LowerCase",
  LyncLogo = "LyncLogo",
  M365InvoicingLogo = "M365InvoicingLogo",
  MachineLearning = "MachineLearning",
  Mail = "Mail",
  MailAlert = "MailAlert",
  MailAttached = "MailAttached",
  MailCheck = "MailCheck",
  MailFill = "MailFill",
  MailForward = "MailForward",
  MailForwardMirrored = "MailForwardMirrored",
  MailLink = "MailLink",
  MailLowImportance = "MailLowImportance",
  MailOptions = "MailOptions",
  MailPause = "MailPause",
  MailReminder = "MailReminder",
  MailRepeat = "MailRepeat",
  MailReply = "MailReply",
  MailReplyAll = "MailReplyAll",
  MailReplyAllMirrored = "MailReplyAllMirrored",
  MailReplyMirrored = "MailReplyMirrored",
  MailSchedule = "MailSchedule",
  MailSecure = "MailSecure",
  MailSolid = "MailSolid",
  MailTentative = "MailTentative",
  MailTentativeMirrored = "MailTentativeMirrored",
  MailUndelivered = "MailUndelivered",
  ManagerSelfService = "ManagerSelfService",
  Manufacturing = "Manufacturing",
  MapDirections = "MapDirections",
  MapLayers = "MapLayers",
  MapPin = "MapPin",
  MapPin12 = "MapPin12",
  MapPinSolid = "MapPinSolid",
  MarkAsProtected = "MarkAsProtected",
  MarkDownLanguage = "MarkDownLanguage",
  Market = "Market",
  MarketDown = "MarketDown",
  MasterDatabase = "MasterDatabase",
  MaximumValue = "MaximumValue",
  Medal = "Medal",
  MedalSolid = "MedalSolid",
  Media = "Media",
  MediaAdd = "MediaAdd",
  Medical = "Medical",
  MedicalCare = "MedicalCare",
  MedicationAdmin = "MedicationAdmin",
  MedicationRequest = "MedicationRequest",
  Megaphone = "Megaphone",
  MegaphoneSolid = "MegaphoneSolid",
  Memo = "Memo",
  Merge = "Merge",
  MergeCase = "MergeCase",
  MergeDuplicate = "MergeDuplicate",
  Message = "Message",
  MessageFill = "MessageFill",
  MessageFriendRequest = "MessageFriendRequest",
  MetricsFailure = "MetricsFailure",
  MetricsInstall = "MetricsInstall",
  MetricsUsage = "MetricsUsage",
  MicOff = "MicOff",
  MicOff2 = "MicOff2",
  Microphone = "Microphone",
  MicrosoftStaffhubLogo = "MicrosoftStaffhubLogo",
  MicrosoftTranslatorLogo = "MicrosoftTranslatorLogo",
  MicrosoftTranslatorLogoBlue = "MicrosoftTranslatorLogoBlue",
  MicrosoftTranslatorLogoGreen = "MicrosoftTranslatorLogoGreen",
  MiniContract = "MiniContract",
  MiniContractMirrored = "MiniContractMirrored",
  MiniExpand = "MiniExpand",
  MiniExpandMirrored = "MiniExpandMirrored",
  MiniLink = "MiniLink",
  MinimumValue = "MinimumValue",
  MobileAngled = "MobileAngled",
  MobileReport = "MobileReport",
  MobileSelected = "MobileSelected",
  ModelAppTemplate32 = "ModelAppTemplate32",
  ModelingView = "ModelingView",
  Money = "Money",
  More = "More",
  MoreSports = "MoreSports",
  MoreVertical = "MoreVertical",
  MostyClearNight = "MostyClearNight",
  MostyCloudyFlurriesDay = "MostyCloudyFlurriesDay",
  MostyCloudyFlurriesNight = "MostyCloudyFlurriesNight",
  MostyCloudyShowersDay = "MostyCloudyShowersDay",
  MostyCloudyTStormsDay = "MostyCloudyTStormsDay",
  MostyCloudyTStormsNight = "MostyCloudyTStormsNight",
  MostySunnyDay = "MostySunnyDay",
  MountainClimbing = "MountainClimbing",
  Move = "Move",
  Movers = "Movers",
  MoveToFolder = "MoveToFolder",
  MSListsConnected = "MSListsConnected",
  MSNLogo = "MSNLogo",
  MSNVideos = "MSNVideos",
  MSNVideosSolid = "MSNVideosSolid",
  MSNVolume = "MSNVolume",
  MultiSelect = "MultiSelect",
  MultiSelectMirrored = "MultiSelectMirrored",
  MusicInCollection = "MusicInCollection",
  MusicInCollectionFill = "MusicInCollectionFill",
  MusicNote = "MusicNote",
  MuteChat = "MuteChat",
  MyMoviesTV = "MyMoviesTV",
  MyNetwork = "MyNetwork",
  Nav2DMapView = "Nav2DMapView",
  NavigateBack = "NavigateBack",
  NavigateBackMirrored = "NavigateBackMirrored",
  NavigateExternalInline = "NavigateExternalInline",
  NavigateForward = "NavigateForward",
  NavigateForwardMirrored = "NavigateForwardMirrored",
  NavigationFlipper = "NavigationFlipper",
  NetworkDeviceScanning = "NetworkDeviceScanning",
  NetworkTower = "NetworkTower",
  NewAnalyticsQuery = "NewAnalyticsQuery",
  NewFolder = "NewFolder",
  NewMail = "NewMail",
  News = "News",
  NewsSearch = "NewsSearch",
  NewTeamProject = "NewTeamProject",
  Next = "Next",
  NormalWeight = "NormalWeight",
  NoteForward = "NoteForward",
  NotePinned = "NotePinned",
  NoteReply = "NoteReply",
  NotExecuted = "NotExecuted",
  NotImpactedSolid = "NotImpactedSolid",
  NugetLogo = "NugetLogo",
  NUIFace = "NUIFace",
  Number = "Number",
  NumberedList = "NumberedList",
  NumberedListMirrored = "NumberedListMirrored",
  NumberedListNumber = "NumberedListNumber",
  NumberedListNumberMirrored = "NumberedListNumberMirrored",
  NumberedListText = "NumberedListText",
  NumberedListTextMirrored = "NumberedListTextMirrored",
  NumberField = "NumberField",
  NumberSequence = "NumberSequence",
  NumberSymbol = "NumberSymbol",
  Oauth = "Oauth",
  ObjectRecognition = "ObjectRecognition",
  Octagon = "Octagon",
  ODLink = "ODLink",
  ODLink12 = "ODLink12",
  ODSharedChannel = "ODSharedChannel",
  ODSharedChannel12 = "ODSharedChannel12",
  OEM = "OEM",
  OfficeAddinsLogo = "OfficeAddinsLogo",
  OfficeAssistantLogo = "OfficeAssistantLogo",
  OfficeCatchUp = "OfficeCatchUp",
  OfficeChat = "OfficeChat",
  OfficeChatSolid = "OfficeChatSolid",
  OfficeFormsLogo = "OfficeFormsLogo",
  OfficeFormsLogo16 = "OfficeFormsLogo16",
  OfficeFormsLogo24 = "OfficeFormsLogo24",
  OfficeFormsLogoInverse = "OfficeFormsLogoInverse",
  OfficeFormsLogoInverse16 = "OfficeFormsLogoInverse16",
  OfficeFormsLogoInverse24 = "OfficeFormsLogoInverse24",
  OfficeLogo = "OfficeLogo",
  OfficeStoreLogo = "OfficeStoreLogo",
  OfficeVideoLogo = "OfficeVideoLogo",
  OfficeVideoLogoFill = "OfficeVideoLogoFill",
  OfficeVideoLogoInverse = "OfficeVideoLogoInverse",
  OfflineOneDriveParachute = "OfflineOneDriveParachute",
  OfflineOneDriveParachuteDisabled = "OfflineOneDriveParachuteDisabled",
  OfflineStorage = "OfflineStorage",
  OfflineStorageSolid = "OfflineStorageSolid",
  Onboarding = "Onboarding",
  OneDriveAdd = "OneDriveAdd",
  OneDriveFolder16 = "OneDriveFolder16",
  OneDriveLogo = "OneDriveLogo",
  OneNoteDocType = "OneNoteDocType",
  OneNoteEduLogoInverse = "OneNoteEduLogoInverse",
  OneNoteLogo = "OneNoteLogo",
  OneNoteLogo16 = "OneNoteLogo16",
  OneNoteLogoInverse = "OneNoteLogoInverse",
  OneNoteLogoInverse16 = "OneNoteLogoInverse16",
  OpenEnrollment = "OpenEnrollment",
  OpenFile = "OpenFile",
  OpenFolderHorizontal = "OpenFolderHorizontal",
  OpenInNewTab = "OpenInNewTab",
  OpenInNewWindow = "OpenInNewWindow",
  OpenPane = "OpenPane",
  OpenPaneMirrored = "OpenPaneMirrored",
  OpenSource = "OpenSource",
  OpenWith = "OpenWith",
  OpenWithMirrored = "OpenWithMirrored",
  Opportunities = "Opportunities",
  OrderLock = "OrderLock",
  Org = "Org",
  Orientation = "Orientation",
  Orientation2 = "Orientation2",
  OutlookLogo = "OutlookLogo",
  OutlookLogo16 = "OutlookLogo16",
  OutlookLogoInverse = "OutlookLogoInverse",
  OutlookLogoInverse16 = "OutlookLogoInverse16",
  OutlookSpacesBucket = "OutlookSpacesBucket",
  OutOfOffice = "OutOfOffice",
  PAAction = "PAAction",
  Package = "Package",
  Packages = "Packages",
  Padding = "Padding",
  PaddingBottom = "PaddingBottom",
  PaddingLeft = "PaddingLeft",
  PaddingRight = "PaddingRight",
  PaddingTop = "PaddingTop",
  Page = "Page",
  PageAdd = "PageAdd",
  PageArrowRight = "PageArrowRight",
  PageBlock = "PageBlock",
  PageCheckedin = "PageCheckedin",
  PageCheckedOut = "PageCheckedOut",
  PageData = "PageData",
  PageEdit = "PageEdit",
  PageHeader = "PageHeader",
  PageHeaderEdit = "PageHeaderEdit",
  PageLeft = "PageLeft",
  PageLink = "PageLink",
  PageList = "PageList",
  PageListFilter = "PageListFilter",
  PageListMirroredSolid = "PageListMirroredSolid",
  PageListSolid = "PageListSolid",
  PageLock = "PageLock",
  PagePermission = "PagePermission",
  PageRemove = "PageRemove",
  PageRight = "PageRight",
  PageShared = "PageShared",
  PageSolid = "PageSolid",
  PanoIndicator = "PanoIndicator",
  Parachute = "Parachute",
  ParachuteSolid = "ParachuteSolid",
  Parameter = "Parameter",
  ParkingLocation = "ParkingLocation",
  ParkingLocationMirrored = "ParkingLocationMirrored",
  ParkingMirroredSolid = "ParkingMirroredSolid",
  ParkingSolid = "ParkingSolid",
  PartlyClearNight = "PartlyClearNight",
  PartlyCloudyDay = "PartlyCloudyDay",
  PartlyCloudyNight = "PartlyCloudyNight",
  PartlySunnyDay = "PartlySunnyDay",
  PartlySunnyFlurriesDay = "PartlySunnyFlurriesDay",
  PartlySunnyShowersDay = "PartlySunnyShowersDay",
  PartlySunnyShowersNight = "PartlySunnyShowersNight",
  PartlySunnyTStormsDay = "PartlySunnyTStormsDay",
  PartlySunnyTStormsNight = "PartlySunnyTStormsNight",
  PartyLeader = "PartyLeader",
  PassiveAuthentication = "PassiveAuthentication",
  PasswordField = "PasswordField",
  Paste = "Paste",
  PasteAsCode = "PasteAsCode",
  PasteAsText = "PasteAsText",
  Pause = "Pause",
  PaymentCard = "PaymentCard",
  PBIAnomaliesMarker = "PBIAnomaliesMarker",
  PBIAnomaly = "PBIAnomaly",
  PBIColumn = "PBIColumn",
  PBIConnectPoints = "PBIConnectPoints",
  PBIDirectQuery = "PBIDirectQuery",
  PBIDual = "PBIDual",
  PBIGap = "PBIGap",
  PBIGoalEntry = "PBIGoalEntry",
  PBIGoalEntryAdd = "PBIGoalEntryAdd",
  PBIHomeLayoutDefault = "PBIHomeLayoutDefault",
  PBIHomeLayoutExpanded = "PBIHomeLayoutExpanded",
  PBIImport = "PBIImport",
  PBILiveConnect = "PBILiveConnect",
  PBIPerspective = "PBIPerspective",
  PBIReportTemplate = "PBIReportTemplate",
  PBIZero = "PBIZero",
  PC1 = "PC1",
  PDF = "PDF",
  PencilReply = "PencilReply",
  Pentagon = "Pentagon",
  PenWorkspace = "PenWorkspace",
  People = "People",
  PeopleAdd = "PeopleAdd",
  PeopleAlert = "PeopleAlert",
  PeopleBlock = "PeopleBlock",
  PeopleExternalShare = "PeopleExternalShare",
  PeoplePause = "PeoplePause",
  PeopleRepeat = "PeopleRepeat",
  Permissions = "Permissions",
  PermissionsSolid = "PermissionsSolid",
  Personalize = "Personalize",
  Phishing = "Phishing",
  PhishingCampaign = "PhishingCampaign",
  PhishingHook = "PhishingHook",
  Phone = "Phone",
  Photo = "Photo",
  Photo2 = "Photo2",
  Photo2Add = "Photo2Add",
  Photo2Fill = "Photo2Fill",
  Photo2Remove = "Photo2Remove",
  PhotoBlock = "PhotoBlock",
  PhotoCollection = "PhotoCollection",
  PhotoError = "PhotoError",
  PhotoVideoMedia = "PhotoVideoMedia",
  Picture = "Picture",
  PictureCenter = "PictureCenter",
  PictureFill = "PictureFill",
  PictureLibrary = "PictureLibrary",
  PicturePosition = "PicturePosition",
  PictureStretch = "PictureStretch",
  PictureTile = "PictureTile",
  PieDouble = "PieDouble",
  PieSingle = "PieSingle",
  PieSingleSolid = "PieSingleSolid",
  Pill = "Pill",
  Pin = "Pin",
  Pinned = "Pinned",
  PinnedFill = "PinnedFill",
  PinnedSolid = "PinnedSolid",
  PinSolid12 = "PinSolid12",
  PinSolidOff12 = "PinSolidOff12",
  PinToTab = "PinToTab",
  PivotChart = "PivotChart",
  PlainText = "PlainText",
  PlannerLogo = "PlannerLogo",
  PlanView = "PlanView",
  Play = "Play",
  PlaybackRate1x = "PlaybackRate1x",
  PlayerSettings = "PlayerSettings",
  PlaylistMusic = "PlaylistMusic",
  PlayResume = "PlayResume",
  PlayReverse = "PlayReverse",
  PlayReverseResume = "PlayReverseResume",
  PlaySolid = "PlaySolid",
  Plug = "Plug",
  PlugConnected = "PlugConnected",
  PlugDisconnected = "PlugDisconnected",
  PlugSolid = "PlugSolid",
  POI = "POI",
  POISolid = "POISolid",
  PollResults = "PollResults",
  PopExpand = "PopExpand",
  PortalAppTemplate32 = "PortalAppTemplate32",
  PostUpdate = "PostUpdate",
  PowerApps = "PowerApps",
  PowerApps2Logo = "PowerApps2Logo",
  PowerAppsLogo = "PowerAppsLogo",
  PowerAppsTemplate = "PowerAppsTemplate",
  PowerAutomateLogo = "PowerAutomateLogo",
  PowerBILogo = "PowerBILogo",
  PowerBILogo16 = "PowerBILogo16",
  PowerBILogoBackplate16 = "PowerBILogoBackplate16",
  PowerButton = "PowerButton",
  PowerPointDocument = "PowerPointDocument",
  PowerPointLogo = "PowerPointLogo",
  PowerPointLogo16 = "PowerPointLogo16",
  PowerPointLogoInverse = "PowerPointLogoInverse",
  PowerPointLogoInverse16 = "PowerPointLogoInverse16",
  PowerShell = "PowerShell",
  PowerShell2 = "PowerShell2",
  PowerStandby = "PowerStandby",
  PowerVirtualAgentsLogo = "PowerVirtualAgentsLogo",
  Precipitation = "Precipitation",
  PresenceChickletVideo = "PresenceChickletVideo",
  Presentation = "Presentation",
  Presentation12 = "Presentation12",
  Preview = "Preview",
  PreviewBelow = "PreviewBelow",
  PreviewLink = "PreviewLink",
  PreviewSideBySide = "PreviewSideBySide",
  Previous = "Previous",
  PrimaryCalendar = "PrimaryCalendar",
  Print = "Print",
  PrintfaxPrinterFile = "PrintfaxPrinterFile",
  Priority = "Priority",
  Process = "Process",
  ProcessAdvisor = "ProcessAdvisor",
  Processing = "Processing",
  ProcessingCancel = "ProcessingCancel",
  ProcessingPause = "ProcessingPause",
  ProcessingRun = "ProcessingRun",
  ProcessMap = "ProcessMap",
  ProcessMetaTask = "ProcessMetaTask",
  Product = "Product",
  ProductCatalog = "ProductCatalog",
  ProductionFloorManagement = "ProductionFloorManagement",
  ProductList = "ProductList",
  ProductRelease = "ProductRelease",
  ProductVariant = "ProductVariant",
  ProductWarning = "ProductWarning",
  ProfileSearch = "ProfileSearch",
  ProFootball = "ProFootball",
  ProgressLoopInner = "ProgressLoopInner",
  ProgressLoopOuter = "ProgressLoopOuter",
  ProgressRing5 = "ProgressRing5",
  ProgressRingDots = "ProgressRingDots",
  ProHockey = "ProHockey",
  ProjectCollection = "ProjectCollection",
  ProjectDocument = "ProjectDocument",
  ProjectLogo16 = "ProjectLogo16",
  ProjectLogo32 = "ProjectLogo32",
  ProjectLogoInverse = "ProjectLogoInverse",
  ProjectManagement = "ProjectManagement",
  PromotedDatabase = "PromotedDatabase",
  Pronouns = "Pronouns",
  ProtectedDocument = "ProtectedDocument",
  ProtectionCenterLogo32 = "ProtectionCenterLogo32",
  ProtectRestrict = "ProtectRestrict",
  ProvisioningPackage = "ProvisioningPackage",
  PublicCalendar = "PublicCalendar",
  PublicContactCard = "PublicContactCard",
  PublicContactCardMirrored = "PublicContactCardMirrored",
  PublicEmail = "PublicEmail",
  PublicFolder = "PublicFolder",
  PublishContent = "PublishContent",
  PublishCourse = "PublishCourse",
  PublisherLogo = "PublisherLogo",
  PublisherLogo16 = "PublisherLogo16",
  PublisherLogoInverse16 = "PublisherLogoInverse16",
  Puzzle = "Puzzle",
  PY = "PY",
  PythonLanguage = "PythonLanguage",
  QandA = "QandA",
  QandAMirror = "QandAMirror",
  QRCode = "QRCode",
  QuadColumn = "QuadColumn",
  Quantity = "Quantity",
  QuarterCircle = "QuarterCircle",
  QueryList = "QueryList",
  Questionnaire = "Questionnaire",
  QuestionnaireMirrored = "QuestionnaireMirrored",
  QueueAdvanced = "QueueAdvanced",
  QuickNote = "QuickNote",
  QuickNoteSolid = "QuickNoteSolid",
  Quotes = "Quotes",
  R = "R",
  RadioBtnOff = "RadioBtnOff",
  RadioBtnOn = "RadioBtnOn",
  RadioBullet = "RadioBullet",
  Rain = "Rain",
  RainShowersDay = "RainShowersDay",
  RainShowersNight = "RainShowersNight",
  RainSnow = "RainSnow",
  Rate = "Rate",
  RawSource = "RawSource",
  Read = "Read",
  ReadingMode = "ReadingMode",
  ReadingModeSolid = "ReadingModeSolid",
  ReadOutLoud = "ReadOutLoud",
  RealEstate = "RealEstate",
  ReceiptCheck = "ReceiptCheck",
  ReceiptForward = "ReceiptForward",
  ReceiptProcessing = "ReceiptProcessing",
  ReceiptReply = "ReceiptReply",
  ReceiptTentative = "ReceiptTentative",
  ReceiptTentativeMirrored = "ReceiptTentativeMirrored",
  ReceiptUndelivered = "ReceiptUndelivered",
  Recent = "Recent",
  Record2 = "Record2",
  RecordRouting = "RecordRouting",
  RecruitmentManagement = "RecruitmentManagement",
  RectangleShape = "RectangleShape",
  RectangleShapeSolid = "RectangleShapeSolid",
  RectangularClipping = "RectangularClipping",
  RecurringEvent = "RecurringEvent",
  RecurringTask = "RecurringTask",
  RecycleBin = "RecycleBin",
  Redeploy = "Redeploy",
  RedEye = "RedEye",
  RedEye12 = "RedEye12",
  Redo = "Redo",
  Refresh = "Refresh",
  RegistryEditor = "RegistryEditor",
  Relationship = "Relationship",
  ReleaseDefinition = "ReleaseDefinition",
  ReleaseGate = "ReleaseGate",
  ReleaseGateCheck = "ReleaseGateCheck",
  ReleaseGateError = "ReleaseGateError",
  ReminderGroup = "ReminderGroup",
  ReminderPerson = "ReminderPerson",
  ReminderTime = "ReminderTime",
  Remote = "Remote",
  RemoteApplication = "RemoteApplication",
  Remove = "Remove",
  RemoveContent = "RemoveContent",
  RemoveEvent = "RemoveEvent",
  RemoveFilter = "RemoveFilter",
  RemoveFromShoppingList = "RemoveFromShoppingList",
  RemoveFromTrash = "RemoveFromTrash",
  RemoveLink = "RemoveLink",
  RemoveLinkChain = "RemoveLinkChain",
  RemoveLinkX = "RemoveLinkX",
  RemoveOccurrence = "RemoveOccurrence",
  Rename = "Rename",
  RenewalCurrent = "RenewalCurrent",
  RenewalFuture = "RenewalFuture",
  ReopenPages = "ReopenPages",
  Repair = "Repair",
  RepeatAll = "RepeatAll",
  RepeatHeaderRows = "RepeatHeaderRows",
  RepeatOne = "RepeatOne",
  Reply = "Reply",
  ReplyAll = "ReplyAll",
  ReplyAllAlt = "ReplyAllAlt",
  ReplyAllMirrored = "ReplyAllMirrored",
  ReplyAlt = "ReplyAlt",
  ReplyMirrored = "ReplyMirrored",
  Repo = "Repo",
  ReportAdd = "ReportAdd",
  ReportAlert = "ReportAlert",
  ReportAlertMirrored = "ReportAlertMirrored",
  ReportDocument = "ReportDocument",
  ReportHacked = "ReportHacked",
  ReportLibrary = "ReportLibrary",
  ReportLibraryMirrored = "ReportLibraryMirrored",
  ReportLock = "ReportLock",
  ReportTrophy = "ReportTrophy",
  ReportWarning = "ReportWarning",
  RepoSolid = "RepoSolid",
  Rerun = "Rerun",
  ReservationOrders = "ReservationOrders",
  Reset = "Reset",
  ResetDevice = "ResetDevice",
  ResponsesMenu = "ResponsesMenu",
  ReturnKey = "ReturnKey",
  ReturnToSession = "ReturnToSession",
  RevenueManagement = "RevenueManagement",
  ReviewRequestMirroredSolid = "ReviewRequestMirroredSolid",
  ReviewRequestSolid = "ReviewRequestSolid",
  ReviewResponseSolid = "ReviewResponseSolid",
  ReviewSolid = "ReviewSolid",
  RevToggleKey = "RevToggleKey",
  RewardsLogo = "RewardsLogo",
  RewardsLogoArt64 = "RewardsLogoArt64",
  RewardsLogoSolid = "RewardsLogoSolid",
  Rewind = "Rewind",
  RewindEightX = "RewindEightX",
  RewindFourX = "RewindFourX",
  RewindOneFiveX = "RewindOneFiveX",
  RewindOneX = "RewindOneX",
  RewindPointFiveX = "RewindPointFiveX",
  RewindTwoX = "RewindTwoX",
  Ribbon = "Ribbon",
  Ribbon2 = "Ribbon2",
  RibbonSolid = "RibbonSolid",
  RightDoubleQuote = "RightDoubleQuote",
  RightTriangle = "RightTriangle",
  Ringer = "Ringer",
  RingerActive = "RingerActive",
  RingerOff = "RingerOff",
  RingerRemove = "RingerRemove",
  RingerSolid = "RingerSolid",
  Robot = "Robot",
  Rocket = "Rocket",
  Room = "Room",
  Rotate = "Rotate",
  Rotate90Clockwise = "Rotate90Clockwise",
  Rotate90CounterClockwise = "Rotate90CounterClockwise",
  RowsChild = "RowsChild",
  RowsGroup = "RowsGroup",
  Rugby = "Rugby",
  Running = "Running",
  Sad = "Sad",
  SadSolid = "SadSolid",
  SamsungGallery = "SamsungGallery",
  Save = "Save",
  SaveAll = "SaveAll",
  SaveAndClose = "SaveAndClose",
  SaveAs = "SaveAs",
  SavedOffline = "SavedOffline",
  SaveTemplate = "SaveTemplate",
  SaveToMobile = "SaveToMobile",
  Savings = "Savings",
  ScaleUp = "ScaleUp",
  ScaleVolume = "ScaleVolume",
  ScatterChart = "ScatterChart",
  ScheduleEventAction = "ScheduleEventAction",
  SchoolDataSyncLogo = "SchoolDataSyncLogo",
  ScopeTemplate = "ScopeTemplate",
  Screen = "Screen",
  ScreenCast = "ScreenCast",
  ScreenPreviewOn = "ScreenPreviewOn",
  ScreenTime = "ScreenTime",
  Script = "Script",
  ScrollUpDown = "ScrollUpDown",
  SDCard = "SDCard",
  Search = "Search",
  SearchAndApps = "SearchAndApps",
  SearchArt64 = "SearchArt64",
  SearchBookmark = "SearchBookmark",
  SearchCalendar = "SearchCalendar",
  SearchData = "SearchData",
  SearchIssue = "SearchIssue",
  SearchIssueMirrored = "SearchIssueMirrored",
  SearchNearby = "SearchNearby",
  SecondaryNav = "SecondaryNav",
  Section = "Section",
  Sections = "Sections",
  SecurityCamera = "SecurityCamera",
  SecurityGroup = "SecurityGroup",
  SecurityTest = "SecurityTest",
  SeeDo = "SeeDo",
  SelectAll = "SelectAll",
  Sell = "Sell",
  SemiboldWeight = "SemiboldWeight",
  Send = "Send",
  SendMirrored = "SendMirrored",
  SentimentAnalysis = "SentimentAnalysis",
  Separator = "Separator",
  Server = "Server",
  ServerEnviroment = "ServerEnviroment",
  ServerProcesses = "ServerProcesses",
  ServiceActivity = "ServiceActivity",
  ServiceOff = "ServiceOff",
  SetAction = "SetAction",
  Settings = "Settings",
  SettingsAdd = "SettingsAdd",
  SettingsSecure = "SettingsSecure",
  SettingsSync = "SettingsSync",
  ShakeDevice = "ShakeDevice",
  Shapes = "Shapes",
  ShapeSolid = "ShapeSolid",
  Share = "Share",
  SharedDatabase = "SharedDatabase",
  SharedNotes = "SharedNotes",
  ShareiOS = "ShareiOS",
  Sharepoint2013LogoInverse = "Sharepoint2013LogoInverse",
  SharepointAppIcon16 = "SharepointAppIcon16",
  SharepointLogo = "SharepointLogo",
  SharepointLogoInverse = "SharepointLogoInverse",
  Shield = "Shield",
  ShieldAlert = "ShieldAlert",
  ShieldSolid = "ShieldSolid",
  Shirt = "Shirt",
  Shop = "Shop",
  ShoppingCart = "ShoppingCart",
  ShoppingCartSolid = "ShoppingCartSolid",
  ShopServer = "ShopServer",
  Showers = "Showers",
  ShowGrid = "ShowGrid",
  ShowResults = "ShowResults",
  ShowResultsMirrored = "ShowResultsMirrored",
  ShowTimeAs = "ShowTimeAs",
  ShowVisualFilter = "ShowVisualFilter",
  SidePanel = "SidePanel",
  SidePanelMirrored = "SidePanelMirrored",
  Signin = "Signin",
  SignOut = "SignOut",
  SimplifiedView = "SimplifiedView",
  SingleBookmark = "SingleBookmark",
  SingleBookmarkSolid = "SingleBookmarkSolid",
  SingleColumn = "SingleColumn",
  SingleColumnEdit = "SingleColumnEdit",
  SIPMove = "SIPMove",
  SiteScan = "SiteScan",
  SizeLegacy = "SizeLegacy",
  SkipBack10 = "SkipBack10",
  SkipForward30 = "SkipForward30",
  SkiResorts = "SkiResorts",
  SkypeArrow = "SkypeArrow",
  SkypeCheck = "SkypeCheck",
  SkypeCircleArrow = "SkypeCircleArrow",
  SkypeCircleCheck = "SkypeCircleCheck",
  SkypeCircleClock = "SkypeCircleClock",
  SkypeCircleMinus = "SkypeCircleMinus",
  SkypeCircleSlash = "SkypeCircleSlash",
  SkypeClock = "SkypeClock",
  SkypeForBusinessLogo = "SkypeForBusinessLogo",
  SkypeForBusinessLogo16 = "SkypeForBusinessLogo16",
  SkypeForBusinessLogoFill = "SkypeForBusinessLogoFill",
  SkypeForBusinessLogoFill16 = "SkypeForBusinessLogoFill16",
  SkypeLogo = "SkypeLogo",
  SkypeLogo16 = "SkypeLogo16",
  SkypeMessage = "SkypeMessage",
  SkypeMinus = "SkypeMinus",
  SkypeSlash = "SkypeSlash",
  Sleet = "Sleet",
  Slider = "Slider",
  SliderHandleSize = "SliderHandleSize",
  SliderThumb = "SliderThumb",
  Slideshow = "Slideshow",
  SmartGlassRemote = "SmartGlassRemote",
  SnapToGrid = "SnapToGrid",
  Snooze = "Snooze",
  Snow = "Snow",
  Snowflake = "Snowflake",
  SnowShowerDay = "SnowShowerDay",
  SnowShowerNight = "SnowShowerNight",
  Soccer = "Soccer",
  SocialListeningLogo = "SocialListeningLogo",
  Sort = "Sort",
  SortDown = "SortDown",
  SortLines = "SortLines",
  SortLinesAscending = "SortLinesAscending",
  SortUp = "SortUp",
  Source = "Source",
  Spacer = "Spacer",
  Speakers = "Speakers",
  SpecialEvent = "SpecialEvent",
  Speech = "Speech",
  SpeechOff = "SpeechOff",
  SpeedHigh = "SpeedHigh",
  Spelling = "Spelling",
  Split = "Split",
  SplitObject = "SplitObject",
  Sprint = "Sprint",
  SQLAnalyticsPool = "SQLAnalyticsPool",
  SQLServerLogo = "SQLServerLogo",
  Squalls = "Squalls",
  SquareShape = "SquareShape",
  SquareShapeSolid = "SquareShapeSolid",
  Stack = "Stack",
  StackColumnChart = "StackColumnChart",
  StackedBarChart = "StackedBarChart",
  StackedBarChartFull = "StackedBarChartFull",
  StackedColumnChart2 = "StackedColumnChart2",
  StackedColumnChart2Fill = "StackedColumnChart2Fill",
  StackedLineChart = "StackedLineChart",
  StackIndicator = "StackIndicator",
  StaffNotebookLogo16 = "StaffNotebookLogo16",
  StaffNotebookLogo32 = "StaffNotebookLogo32",
  StaffNotebookLogoFill16 = "StaffNotebookLogoFill16",
  StaffNotebookLogoFill32 = "StaffNotebookLogoFill32",
  StaffNotebookLogoInverted16 = "StaffNotebookLogoInverted16",
  StaffNotebookLogoInverted32 = "StaffNotebookLogoInverted32",
  Starburst = "Starburst",
  StarburstSolid = "StarburstSolid",
  StatusCircleBlock = "StatusCircleBlock",
  StatusCircleBlock2 = "StatusCircleBlock2",
  StatusCircleCheckmark = "StatusCircleCheckmark",
  StatusCircleErrorX = "StatusCircleErrorX",
  StatusCircleExclamation = "StatusCircleExclamation",
  StatusCircleInfo = "StatusCircleInfo",
  StatusCircleInner = "StatusCircleInner",
  StatusCircleOuter = "StatusCircleOuter",
  StatusCircleQuestionMark = "StatusCircleQuestionMark",
  StatusCircleRing = "StatusCircleRing",
  StatusCircleSync = "StatusCircleSync",
  StatusErrorFull = "StatusErrorFull",
  StatusTriangle = "StatusTriangle",
  StatusTriangleExclamation = "StatusTriangleExclamation",
  StatusTriangleInner = "StatusTriangleInner",
  StatusTriangleOuter = "StatusTriangleOuter",
  Step = "Step",
  StepInsert = "StepInsert",
  StepShared = "StepShared",
  StepSharedAdd = "StepSharedAdd",
  StepSharedInsert = "StepSharedInsert",
  StickyNotesOutlineAppIcon = "StickyNotesOutlineAppIcon",
  StickyNotesSolidAppIcon = "StickyNotesSolidAppIcon",
  StockDown = "StockDown",
  StockUp = "StockUp",
  Stop = "Stop",
  StopSolid = "StopSolid",
  Stopwatch = "Stopwatch",
  StorageAcount = "StorageAcount",
  StorageOptical = "StorageOptical",
  StoreLogo16 = "StoreLogo16",
  StoreLogoMed20 = "StoreLogoMed20",
  Storyboard = "Storyboard",
  StreamDiscover = "StreamDiscover",
  Streaming = "Streaming",
  StreamingDataflow = "StreamingDataflow",
  StreamingDataset = "StreamingDataset",
  StreamingOff = "StreamingOff",
  StreamLogo = "StreamLogo",
  StreamPlaylist = "StreamPlaylist",
  Street = "Street",
  StreetsideSplitMinimize = "StreetsideSplitMinimize",
  Strikethrough = "Strikethrough",
  StrikethroughKorean = "StrikethroughKorean",
  Subscribe = "Subscribe",
  Subscript = "Subscript",
  SubstitutionsIn = "SubstitutionsIn",
  SubtractShape = "SubtractShape",
  Suitcase = "Suitcase",
  SummaryChart = "SummaryChart",
  SunAdd = "SunAdd",
  Sunny = "Sunny",
  SunQuestionMark = "SunQuestionMark",
  Superscript = "Superscript",
  SurveyQuestionResponse = "SurveyQuestionResponse",
  SurveyQuestions = "SurveyQuestions",
  SwayLogo16 = "SwayLogo16",
  SwayLogo32 = "SwayLogo32",
  SwayLogoFill16 = "SwayLogoFill16",
  SwayLogoFill32 = "SwayLogoFill32",
  SwayLogoInverse = "SwayLogoInverse",
  Switch = "Switch",
  SwitcherStartEnd = "SwitcherStartEnd",
  SwitchUser = "SwitchUser",
  Sync = "Sync",
  SyncError = "SyncError",
  SyncFolder = "SyncFolder",
  SyncOccurence = "SyncOccurence",
  SyncOccurenceCancel = "SyncOccurenceCancel",
  SyncStatus = "SyncStatus",
  SyncStatusSolid = "SyncStatusSolid",
  SyncToPC = "SyncToPC",
  System = "System",
  Tab = "Tab",
  TabCenter = "TabCenter",
  Table = "Table",
  TableBrandedColumn = "TableBrandedColumn",
  TableBrandedRow = "TableBrandedRow",
  TableColumn = "TableColumn",
  TableComputed = "TableComputed",
  TableFirstColumn = "TableFirstColumn",
  TableGroup = "TableGroup",
  TableHeaderRow = "TableHeaderRow",
  TableLastColumn = "TableLastColumn",
  TableLink = "TableLink",
  TablePermission = "TablePermission",
  Tablet = "Tablet",
  TabletMode = "TabletMode",
  TableTotalRow = "TableTotalRow",
  TabletSelected = "TabletSelected",
  TabOneColumn = "TabOneColumn",
  TabThreeColumn = "TabThreeColumn",
  TabTwoColumn = "TabTwoColumn",
  Tag = "Tag",
  TagGroup = "TagGroup",
  TagSolid = "TagSolid",
  TagUnknown = "TagUnknown",
  TagUnknown12 = "TagUnknown12",
  TagUnknown12Mirror = "TagUnknown12Mirror",
  TagUnknownMirror = "TagUnknownMirror",
  TaskAdd = "TaskAdd",
  Taskboard = "Taskboard",
  TaskGroup = "TaskGroup",
  TaskGroupMirrored = "TaskGroupMirrored",
  TaskList = "TaskList",
  TaskLogo = "TaskLogo",
  TaskManager = "TaskManager",
  TaskManagerMirrored = "TaskManagerMirrored",
  TaskSolid = "TaskSolid",
  Taxi = "Taxi",
  TeamFavorite = "TeamFavorite",
  TeamsLogo = "TeamsLogo",
  TeamsLogo16 = "TeamsLogo16",
  TeamsLogoInverse = "TeamsLogoInverse",
  Teamwork = "Teamwork",
  Teeth = "Teeth",
  Telemarketer = "Telemarketer",
  TemporaryAccessPass = "TemporaryAccessPass",
  TemporaryUser = "TemporaryUser",
  Tennis = "Tennis",
  TestAdd = "TestAdd",
  TestAutoSolid = "TestAutoSolid",
  TestBeaker = "TestBeaker",
  TestBeakerSolid = "TestBeakerSolid",
  TestCase = "TestCase",
  TestExploreSolid = "TestExploreSolid",
  TestImpactSolid = "TestImpactSolid",
  TestParameter = "TestParameter",
  TestPlan = "TestPlan",
  TestRemove = "TestRemove",
  TestStep = "TestStep",
  TestSuite = "TestSuite",
  TestUserSolid = "TestUserSolid",
  TextAlignBottom = "TextAlignBottom",
  TextAlignMiddle = "TextAlignMiddle",
  TextAlignTop = "TextAlignTop",
  TextBox = "TextBox",
  TextCallout = "TextCallout",
  TextDocument = "TextDocument",
  TextDocumentEdit = "TextDocumentEdit",
  TextDocumentSettings = "TextDocumentSettings",
  TextDocumentShared = "TextDocumentShared",
  TextField = "TextField",
  TextOverflow = "TextOverflow",
  TextParagraphOption = "TextParagraphOption",
  TextRecognition = "TextRecognition",
  TextRotate270Degrees = "TextRotate270Degrees",
  TextRotate90Degrees = "TextRotate90Degrees",
  TextRotateHorizontal = "TextRotateHorizontal",
  TextRotation = "TextRotation",
  TFVCLogo = "TFVCLogo",
  ThisPC = "ThisPC",
  ThreeQuarterCircle = "ThreeQuarterCircle",
  ThumbnailView = "ThumbnailView",
  ThumbnailViewMirrored = "ThumbnailViewMirrored",
  Thunderstorms = "Thunderstorms",
  Ticket = "Ticket",
  Tiles = "Tiles",
  Tiles2 = "Tiles2",
  TimeEntry = "TimeEntry",
  Timeline = "Timeline",
  TimelineDelivery = "TimelineDelivery",
  TimelineMatrixView = "TimelineMatrixView",
  TimelineProgress = "TimelineProgress",
  TimePicker = "TimePicker",
  Timer = "Timer",
  TimeSheet = "TimeSheet",
  Title = "Title",
  TitleMirrored = "TitleMirrored",
  ToDoLogoBottom = "ToDoLogoBottom",
  ToDoLogoInverse = "ToDoLogoInverse",
  ToDoLogoOutline = "ToDoLogoOutline",
  ToDoLogoTop = "ToDoLogoTop",
  ToggleBorder = "ToggleBorder",
  ToggleFilled = "ToggleFilled",
  ToggleLeft = "ToggleLeft",
  ToggleRight = "ToggleRight",
  ToggleThumb = "ToggleThumb",
  Toll = "Toll",
  Toolbox = "Toolbox",
  Total = "Total",
  Touch = "Touch",
  TouchPointer = "TouchPointer",
  Trackers = "Trackers",
  TrackersMirrored = "TrackersMirrored",
  Train = "Train",
  TrainSolid = "TrainSolid",
  TransferCall = "TransferCall",
  Transition = "Transition",
  TransitionEffect = "TransitionEffect",
  TransitionPop = "TransitionPop",
  TransitionPush = "TransitionPush",
  Translate = "Translate",
  Transportation = "Transportation",
  Trending12 = "Trending12",
  TriangleDown12 = "TriangleDown12",
  TriangleLeft12 = "TriangleLeft12",
  TriangleRight12 = "TriangleRight12",
  TriangleShape = "TriangleShape",
  TriangleShapeSolid = "TriangleShapeSolid",
  TriangleSolid = "TriangleSolid",
  TriangleSolidDown12 = "TriangleSolidDown12",
  TriangleSolidLeft12 = "TriangleSolidLeft12",
  TriangleSolidRight12 = "TriangleSolidRight12",
  TriangleSolidUp12 = "TriangleSolidUp12",
  TriangleUp12 = "TriangleUp12",
  TriggerApproval = "TriggerApproval",
  TriggerAuto = "TriggerAuto",
  TriggerPhrase = "TriggerPhrase",
  TriggerUser = "TriggerUser",
  Trim = "Trim",
  TrimEnd = "TrimEnd",
  TrimStart = "TrimStart",
  TripleColumn = "TripleColumn",
  TripleColumnEdit = "TripleColumnEdit",
  TripleColumnWide = "TripleColumnWide",
  Trophy = "Trophy",
  Trophy2 = "Trophy2",
  Trophy2Solid = "Trophy2Solid",
  TurnRight = "TurnRight",
  TVMonitor = "TVMonitor",
  TVMonitorSelected = "TVMonitorSelected",
  TypeScriptLanguage = "TypeScriptLanguage",
  Umbrella = "Umbrella",
  UnavailableOffline = "UnavailableOffline",
  Underline = "Underline",
  UnderlineA = "UnderlineA",
  UnderlineKorean = "UnderlineKorean",
  UnderlineP = "UnderlineP",
  UnderlineRussian = "UnderlineRussian",
  UnderlineS = "UnderlineS",
  UnderlineSerbian = "UnderlineSerbian",
  Undo = "Undo",
  Uneditable = "Uneditable",
  Uneditable2 = "Uneditable2",
  Uneditable2Mirrored = "Uneditable2Mirrored",
  UneditableMirrored = "UneditableMirrored",
  UneditableSolid12 = "UneditableSolid12",
  UneditableSolidMirrored12 = "UneditableSolidMirrored12",
  Unfavorite = "Unfavorite",
  UngroupObject = "UngroupObject",
  UniteShape = "UniteShape",
  Unknown = "Unknown",
  UnknownCall = "UnknownCall",
  UnknownMirrored = "UnknownMirrored",
  UnknownMirroredSolid = "UnknownMirroredSolid",
  UnknownSolid = "UnknownSolid",
  Unlock = "Unlock",
  UnlockSolid = "UnlockSolid",
  Unpin = "Unpin",
  UnpublishContent = "UnpublishContent",
  UnSetColor = "UnSetColor",
  UnstackSelected = "UnstackSelected",
  Unsubscribe = "Unsubscribe",
  UnsyncFolder = "UnsyncFolder",
  UnsyncOccurence = "UnsyncOccurence",
  Untag = "Untag",
  Up = "Up",
  UpdateRestore = "UpdateRestore",
  UpgradeAnalysis = "UpgradeAnalysis",
  Upload = "Upload",
  UpperCase = "UpperCase",
  URLBlock = "URLBlock",
  USB = "USB",
  UserClapper = "UserClapper",
  UserEvent = "UserEvent",
  UserFollowed = "UserFollowed",
  UserGauge = "UserGauge",
  UserOptional = "UserOptional",
  UserPause = "UserPause",
  UserRemove = "UserRemove",
  UserSync = "UserSync",
  UserWarning = "UserWarning",
  UserWindow = "UserWindow",
  Vacation = "Vacation",
  Vaccination = "Vaccination",
  VaccinationRecent = "VaccinationRecent",
  Variable = "Variable",
  Variable2 = "Variable2",
  Variable3 = "Variable3",
  VariableGroup = "VariableGroup",
  VB = "VB",
  VennDiagram = "VennDiagram",
  VerifiedBrand = "VerifiedBrand",
  VerifiedBrandSolid = "VerifiedBrandSolid",
  VersionControlPush = "VersionControlPush",
  VerticalDistributeCenter = "VerticalDistributeCenter",
  Video = "Video",
  Video360Generic = "Video360Generic",
  VideoAdd = "VideoAdd",
  VideoLightOff = "VideoLightOff",
  VideoOff = "VideoOff",
  VideoOff2 = "VideoOff2",
  VideoSearch = "VideoSearch",
  VideoSolid = "VideoSolid",
  View = "View",
  ViewAll = "ViewAll",
  ViewAll2 = "ViewAll2",
  ViewDashboard = "ViewDashboard",
  ViewInAR = "ViewInAR",
  ViewList = "ViewList",
  ViewListGroup = "ViewListGroup",
  ViewListTree = "ViewListTree",
  ViewOriginal = "ViewOriginal",
  VirtualNetwork = "VirtualNetwork",
  VisioDiagram = "VisioDiagram",
  VisioDiagramSync = "VisioDiagramSync",
  VisioDocument = "VisioDocument",
  VisioLogo = "VisioLogo",
  VisioLogo16 = "VisioLogo16",
  VisioLogoInverse = "VisioLogoInverse",
  VisioLogoInverse16 = "VisioLogoInverse16",
  VisualBasicLanguage = "VisualBasicLanguage",
  VisuallyImpaired = "VisuallyImpaired",
  VisualsFolder = "VisualsFolder",
  VisualsStore = "VisualsStore",
  VisualStudioForWindows = "VisualStudioForWindows",
  VisualStudioForWindowsAlt = "VisualStudioForWindowsAlt",
  VoicemailForward = "VoicemailForward",
  VoicemailIRM = "VoicemailIRM",
  VoicemailReply = "VoicemailReply",
  Volume0 = "Volume0",
  Volume1 = "Volume1",
  Volume2 = "Volume2",
  Volume3 = "Volume3",
  VolumeDisabled = "VolumeDisabled",
  VSTSAltLogo1 = "VSTSAltLogo1",
  VSTSAltLogo2 = "VSTSAltLogo2",
  VSTSLogo = "VSTSLogo",
  Waffle = "Waffle",
  WaffleOffice365 = "WaffleOffice365",
  WaitlistConfirm = "WaitlistConfirm",
  WaitlistConfirmMirrored = "WaitlistConfirmMirrored",
  Warning = "Warning",
  Warning12 = "Warning12",
  WarningSolid = "WarningSolid",
  WavingHand = "WavingHand",
  WebAppBuilderFragment = "WebAppBuilderFragment",
  WebAppBuilderFragmentCreate = "WebAppBuilderFragmentCreate",
  WebAppBuilderFragmentLock = "WebAppBuilderFragmentLock",
  WebAppBuilderFragmentTest = "WebAppBuilderFragmentTest",
  WebAppBuilderModule = "WebAppBuilderModule",
  WebAppBuilderModuleLock = "WebAppBuilderModuleLock",
  WebAppBuilderModuleTest = "WebAppBuilderModuleTest",
  WebAppBuilderSlot = "WebAppBuilderSlot",
  Webcam2 = "Webcam2",
  Webcam2Off = "Webcam2Off",
  WebComponents = "WebComponents",
  WebEnvironment = "WebEnvironment",
  WebPublish = "WebPublish",
  WebSearch = "WebSearch",
  Website = "Website",
  WebTemplate = "WebTemplate",
  Weights = "Weights",
  Wheelchair = "Wheelchair",
  Whiteboard = "Whiteboard",
  WhiteBoardApp16 = "WhiteBoardApp16",
  WhiteBoardApp32 = "WhiteBoardApp32",
  WifiEthernet = "WifiEthernet",
  WifiWarning4 = "WifiWarning4",
  WindDirection = "WindDirection",
  WindowEdit = "WindowEdit",
  WindowsLogo = "WindowsLogo",
  Wines = "Wines",
  WipePhone = "WipePhone",
  WordDocument = "WordDocument",
  WordLogo = "WordLogo",
  WordLogo16 = "WordLogo16",
  WordLogoInverse = "WordLogoInverse",
  WordLogoInverse16 = "WordLogoInverse16",
  Work = "Work",
  WorkFlow = "WorkFlow",
  WorkforceManagement = "WorkforceManagement",
  WorkItem = "WorkItem",
  WorkItemAlert = "WorkItemAlert",
  WorkItemBar = "WorkItemBar",
  WorkItemBarSolid = "WorkItemBarSolid",
  WorkItemBug = "WorkItemBug",
  World = "World",
  WorldClock = "WorldClock",
  XPowerY = "XPowerY",
  XRay = "XRay",
  YammerLogo = "YammerLogo",
  ZeroDayCalendar = "ZeroDayCalendar",
  ZeroDayPatch = "ZeroDayPatch",
  ZipFolder = "ZipFolder",
  Zoom = "Zoom",
  ZoomIn = "ZoomIn",
  ZoomOut = "ZoomOut",
  ZoomToFit = "ZoomToFit",
}

// tslint:disable-next-line:deprecation
export type IconNamesInput = keyof typeof IconNames;
