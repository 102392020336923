import { postRequest, download } from "./request";
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'file... Remove this comment to see the full error message
import { saveAs } from "file-saver";
import { TenantSettings } from "../../types/portalReducerTypes";
import { SystemIndicatorSettings } from "../../types/commonTypes";

const defaultTenantSettings: TenantSettings = {
  theme: { style: "light", color: "blue" },
  language: "de-DE",
  timezone: "",
};

export const tenantService = {
  search,
  openSearchLink,
  getWidgets,
  loadWidgetPayload,
  getTenants,
  getLanguages,
  getSystemIndicatorSettings,
  getMenu,
  getPluginIcons,
  downloadFile,
  updateTenantSettings,
  removeTenantSettings,
  getTenantSettings,
  getGlobalSettings,
  saveGlobalSettings,
  get defaultSettings() {
    return defaultTenantSettings;
  },
};
function getLanguages() {
  return postRequest("api/tenant/availablelanguages", {});
}
function getSystemIndicatorSettings(): Promise<SystemIndicatorSettings> {
  return postRequest("api/tenant/systemindicatorsettings", {});
}
function getTenants() {
  return postRequest("api/tenant/availabletenants", {});
}
function search(s: any) {
  return postRequest("api/tenant/search", { searchtext: s });
}
function openSearchLink(linkId: any) {
  return postRequest("api/tenant/opensearchlink", { linkId });
}
function getWidgets() {
  return postRequest("api/tenant/getwidgets", {});
}
function getMenu(tenantId: any) {
  return postRequest("api/tenant/getmenu", { tenantId });
}
function getPluginIcons() {
  return postRequest("api/tenant/getpluginicons", {});
}
function loadWidgetPayload(pluginToken: any, instanceId: any) {
  return postRequest("api/tenant/loadWidgetPayload", { pluginToken, instanceId });
}
function downloadFile(fileid: any) {
  return download("api/tenant/downloadfile?fileid=" + fileid).then((response) => {
    saveAs(response, (response as any).filename);
  });
}

function getTenantSettings() {
  let s = JSON.parse(`${localStorage.getItem("currentTenantSettings")}`);
  if (s === null) {
    return defaultTenantSettings;
  } else {
    return s;
  }
}

function updateTenantSettings(settings: any) {
  localStorage.setItem("currentTenantSettings", JSON.stringify(settings));
}

function removeTenantSettings() {
  localStorage.removeItem("currentTenantSettings");
}

function saveGlobalSettings(settings: any) {
  return postRequest("api/tenant/savesettings", settings);
}

function getGlobalSettings() {
  return postRequest("api/tenant/getsettings", {});
}
