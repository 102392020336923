import React, { useState } from "react";
import { IconButton, Label, Toggle, mergeStyles } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { setEyeAbleVisible } from "../../../utils/eyeAbleFunctions";
import { useId } from "@fluentui/react-hooks";

type AccessibilityAssistantToggleProperties = {
  setDialogOpen?: (open: boolean) => void;
};

const AccessibilityAssistantToggle = (props: AccessibilityAssistantToggleProperties) => {
  const { setDialogOpen } = props;
  const [showIcon, setShowIcon] = useState(!JSON.parse(`${localStorage.getItem("eyeAbleHideIcon")}`));
  const id = useId();

  function changeValue(newShowIcon: boolean) {
    setEyeAbleVisible(newShowIcon);
    setShowIcon(newShowIcon);
  }

  const [t] = useTranslation();
  const label = (
    <div className={labelContainerClass}>
      <Label htmlFor={id}>{t("dialogs.accessibilityIntroDialog.eyeAbleAssist")}</Label>
      <IconButton styles={infoButtonStyles} iconProps={{ iconName: "Info" }} onClick={() => setDialogOpen?.(true)} />
    </div>
  );

  return (
    <>
      <Toggle
        id={id}
        checked={showIcon}
        inlineLabel
        label={label}
        styles={toggleStyles}
        onChange={(ev, checked) => changeValue(!!checked)}
      />
    </>
  );
};

const toggleStyles = {
  root: { justifyContent: "space-between" },
  label: { order: "unset", margin: "unset", padding: 0 },
};
const infoButtonStyles = { root: { width: 24, height: 24 } };
const labelContainerClass = mergeStyles({ display: "flex", alignItems: "center" });

export default AccessibilityAssistantToggle;
