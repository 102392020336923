import { ThemeElement } from '../utils/enums';
import * as Constants from '../utils/constants';
export class ThemeConfigManager {
    static themeConfig = null;
    /**
     * @description Return the theme config
     * @returns {ThemeConfigManager}
     */
    static getThemeConfig() {
        if (!ThemeConfigManager.themeConfig) {

            // @ts-expect-error ts-migrate(2322) FIXME: Type 'ThemeConfig' is not assignable to type 'null... Remove this comment to see the full error message
            ThemeConfigManager.themeConfig = new ThemeConfig(defaultThemeConfig);
        }
        return this.themeConfig;
    }
    /**
     * @description Set the custom theme config property
     * @param {object} value
     */

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
    static setThemeConfig(value) {
        let newThemeConfig = { ...defaultThemeConfig, ...value };

        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ThemeConfig' is not assignable to type 'null... Remove this comment to see the full error message
        this.themeConfig = new ThemeConfig(newThemeConfig);
    }
}
class ThemeConfig {
    constructor(obj = {}) {
        (this as any).button = new Config(ThemeElement.Button, obj);
        (this as any).input = new Config(ThemeElement.Input, obj);
        (this as any).inputDate = new Config(ThemeElement.InputDate, obj);
        (this as any).inputTime = new Config(ThemeElement.InputTime, obj);
        (this as any).radioButton = new Config(ThemeElement.RadioButton, obj);
        (this as any).checkBox = new Config(ThemeElement.CheckBox, obj);
        (this as any).choiceSetTitle = new Config(ThemeElement.ChoiceSetTitle, obj);
    }
}
// Each instance of this class holds config of specific element type 
class Config {

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
    constructor(type, customConfig = {}) {
        (this as any).type = type;

        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (this as any).ios = defaultThemeConfig[type].ios || defaultThemeConfig[type];

        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (this as any).android = defaultThemeConfig[type].android || defaultThemeConfig[type];

        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (this as any).windows = defaultThemeConfig[type].windows || defaultThemeConfig[type];

        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (customConfig[type]) { // any custom config ?

            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            let config = customConfig[type];
            (this as any).ios = config["ios"] ? { ...(this as any).ios, ...config["ios"] } : { ...(this as any).ios, ...config };
            (this as any).android = config["android"] ? { ...(this as any).android, ...config["android"] } : { ...(this as any).android, ...config };
            (this as any).windows = config["windows"] ? { ...(this as any).windows, ...config["windows"] } : { ...(this as any).windows, ...config };
        }
    }
}
/**
 * @description Default Theme config JSON. This can be overriden by invoking setThemeConfig method.
 */
// Open Issue : Setting `textTransform` style in android doesn't render the text itself.
// Refer : https://github.com/facebook/react-native/issues/21966
const defaultThemeConfig = {
    button: {
        ios: {
            borderRadius: 15,
            backgroundColor: Constants.buttonDefaultColor,
            color: Constants.WhiteColor,
            textTransform: 'none'
        },
        android: {
            borderRadius: 15,
            backgroundColor: Constants.buttonDefaultColor,
            color: Constants.WhiteColor,
            textTransform: undefined
        },
        windows: {
            borderRadius: 0,
            backgroundColor: Constants.buttonDefaultColor,
            color: Constants.WhiteColor,
            textTransform: 'none'
        },
    },
    input: {
        borderColor: Constants.EmphasisColor,
        backgroundColor: Constants.WhiteColor,
        borderRadius: 5,
        borderWidth: 1
    },
    inputDate: {
        width: Constants.FullWidth,
        height: 44,
        padding: 5,
        borderWidth: 1,
        backgroundColor: Constants.WhiteColor,
        borderColor: Constants.LightGreyColor,
        borderRadius: 5,
    },
    inputTime: {
        width: Constants.FullWidth,
        height: 44,
        padding: 5,
        borderWidth: 1,
        backgroundColor: Constants.WhiteColor,
        borderColor: Constants.LightGreyColor,
        borderRadius: 5,
    },
    radioButton: {
        width: 28,
        height: 28
    },
    checkBox: {
        width: 28,
        height: 28
    },
    choiceSetTitle: {
        marginLeft: 8,
        flexShrink: 1
    },
};
