import { BaseModel } from './base-model'
import { ElementType } from '../utils/enums'
import { ModelFactory } from './model-factory';

export class PluginStarterModel extends BaseModel {
    type = ElementType.PluginStarter;

    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.children = [];

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        this.children.push(...ModelFactory.createGroup(payload.children, this));
    }
}

export class PluginTileModel extends BaseModel {
    caption: any;
    icon: any;
    pluginToken: any;
    type = ElementType.PluginTile;

    constructor(payload: any, parent: any) {
        super(payload, parent);
        this.icon = payload.icon;
        this.caption = payload.caption;
        this.pluginToken = payload.pluginToken;
    }
}