import * as React from "react";
import {
  DefaultButton,
  PrimaryButton,
  Stack,
  ProgressIndicator,
  Pivot,
  PivotItem,
  Link,
  MessageBar,
  MessageBarType,
  mergeStyles,
} from "@fluentui/react";
import { helpers } from "../initializationHelper";
import { setErrors, setLoading } from "../store/slices/wizardSlice";
import { useSelector, useDispatch } from "react-redux";
import SqlServer from "./steps/SqlServer";
import SqlDatabase from "./steps/SqlDatabase";
import InitAndRestart from "./steps/InitAndRestart";
import Login from "./steps/AdminLogin";
const stackTokens = {
  childrenGap: 40,
};
const buttonStyles = {
  root: {
    alignItems: "center",
    paddingTop: 10,
  },
};
const pivotStyles = {
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  itemContainer: {
    overflow: "hidden",
  },
};
const overflowStyle = {
  height: "100%",
  overflow: "hidden",
};
const messageBarStyles = {
  root: {
    marginTop: 10,
  },
};
const pivotInlineStyle = {
  flexGrow: 0,
  flexShrink: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};
const errorStyleClass = mergeStyles({ margin: "0 0 0.75em 0" });
export default () => {
  const [selectedKey, setSelectedKey] = React.useState(0);
  const [initialized, setInitialized] = React.useState(false);
  const sql = useSelector((state) => (state as any).wizard.sql);
  const login = useSelector((state) => (state as any).wizard.login);
  const errors = useSelector((state) => (state as any).wizard.errors);
  const loading = useSelector((state) => (state as any).wizard.loading);
  const dispatch = useDispatch();
  const clearErrors = () => {
    dispatch(setErrors([]));
  };
  const next = () => {
    switch (selectedKey) {
      case 0: {
        //SqlServer
        const errors = helpers.validateServer(sql);
        if (errors.length === 0) {
          clearErrors();
          setSelectedKey(selectedKey + 1); //continue
        } else {
          dispatch(setErrors(errors));
        }
        break;
      }
      case 1: {
        //SQLDatabase
        const errors = helpers.validateDatabase(sql);
        if (errors.length === 0) {
          clearErrors();
          if (sql.useExistingDB) {
            if (sql.database) {
              setSelectedKey(selectedKey + 1);
            }
          } else {
            setSelectedKey(selectedKey + 1);
          }
        } else {
          dispatch(setErrors(errors));
        }
        break;
      }
      case 2: {
        // Login
        if (login.needsLogin) {
          const errors = helpers.validateLogin(login);
          if (errors.length === 0) {
            clearErrors();
            setSelectedKey(selectedKey + 1); //continue
          } else {
            dispatch(setErrors(errors));
          }
        } else {
          clearErrors();
          setSelectedKey(selectedKey + 1); //continue
        }
        break;
      }
      default:
        break;
    }
  };
  const prev = () => {
    clearErrors();
    setSelectedKey(selectedKey - 1);
  };
  const init = () => {
    dispatch(setLoading(true));
    helpers.initPortal(sql, login).then((response) => {
      dispatch(setLoading(false));
      if (response.success) {
        setInitialized(true);
      } else {
        dispatch(setErrors([response.error]));
      }
    });
  };
  return (
    <>
      <Pivot
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ flexGrow: number; flexShrink: number; over... Remove this comment to see the full error message
        style={pivotInlineStyle}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ root: { display: string; gridTemplateColum... Remove this comment to see the full error message
        styles={pivotStyles}
        aria-label="Infoma ePortal Initial"
        selectedKey={selectedKey.toString()}
      >
        <PivotItem headerText="SQL Server" itemKey="0" style={overflowStyle}>
          <SqlServer />
        </PivotItem>
        <PivotItem headerText="SQL Datenbank" itemKey="1" style={overflowStyle}>
          <SqlDatabase />
        </PivotItem>
        <PivotItem headerText="Admin Login" itemKey="2" style={overflowStyle}>
          <Login />
        </PivotItem>
        <PivotItem headerText="Übersicht" itemKey="3" style={overflowStyle}>
          <InitAndRestart initialized={initialized} />
        </PivotItem>
      </Pivot>
      {errors.length !== 0 && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          styles={messageBarStyles}
          dismissButtonAriaLabel="Close"
        >
          {errors.map((error: any, index: any) => (
            <p key={index} className={errorStyleClass}>
              {error}
            </p>
          ))}

          <Link
            href="https://www.docs.infoma-eportal.de/PortalSetup"
            target="_blank"
            styles={{ root: { padding: "0px!important" } }}
          >
            Weitere Informationen finden Sie in unserer Dokumentation.
          </Link>
        </MessageBar>
      )}
      {loading && <ProgressIndicator label={selectedKey !== 3 ? "Suche Datenbank..." : "Initialisiere Portal..."} />}
      <Stack horizontal tokens={stackTokens} styles={buttonStyles} horizontalAlign="space-between">
        {selectedKey !== 0 ? (
          <DefaultButton text="Zurück" onClick={prev} allowDisabledFocus disabled={false} />
        ) : (
          <div />
        )}
        {selectedKey === 3 ? (
          <PrimaryButton text="Fertigstellen" onClick={init} />
        ) : (
          <PrimaryButton text="Weiter" onClick={next} allowDisabledFocus disabled={false} />
        )}
      </Stack>
    </>
  );
};
