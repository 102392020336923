import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IButtonStyles } from "@fluentui/react/lib/Button";
import {
  FontIcon,
  OverflowSet,
  CommandBarButton,
  IOverflowSetItemProps,
  useTheme,
  mergeStyles,
  ResizeGroup,
  mergeStyleSets,
  css,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { constants } from "../../constants";
import {
  setPlugin,
  setPluginMenu,
  resetState,
  unloadTenantScriptBundles,
  setPortalSettingsPaneOpen,
  setPortalNotificationPaneOpen,
} from "../../store/slices/portalSlice";
import { authenticationService } from "../authorization/authenticationService";
import { userService } from "../authorization/userService";
import { getDarkerColorOfTwo } from "../../utils/helperFunctions";
import SearchBar from "./SearchBar";
import UserSettings from "../admin/UserSettings";
import TenantPickerDropdown from "../admin/TenantPickerDropdown";
import ProfilePickerDropdown from "../admin/ProfilePickerDropdown";
import { RootState } from "../../store/store";
import { useIsMobileMediaQuery } from "../../hooks/mediaQueryHooks";
import MobilePluginBar from "./MobilePluginBar";

type MenuBarResizeGroupData = {
  searchInOverflow: boolean;
  items: IOverflowSetItemProps[];
  overflowItems: IOverflowSetItemProps[];
  cacheKey: string;
};

const MenuBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plugins = useSelector((state: RootState) => state.portal.plugins);
  const sessionTimeout = useSelector((state: RootState) => state.portal.sessionTimeout);
  const changePassword = useSelector((state: RootState) => state.portal.changePassword);
  const enableTfa = useSelector((state: RootState) => state.portal.enableTwoFactor);
  const unreadNotifications = useSelector((state: RootState) => state.portal.unreadNotifications);
  const [t] = useTranslation();
  const { palette, semanticColors, fonts } = useTheme();
  const [mobilePluginBarOpen, setMobilePluginBarOpen] = useState(false);
  const isMobile = useIsMobileMediaQuery(() => setMobilePluginBarOpen(false));

  let blurEffect: React.CSSProperties = {};
  if (sessionTimeout || changePassword || enableTfa) {
    blurEffect.filter = "blur(4px)";
  }

  const openHelp = (id: any) => {
    dispatch(setPlugin({ pluginId: id }));
    dispatch(setPluginMenu(id));
  };

  const onLogout = () => {
    userService.closeCompany();
    dispatch(resetState());
    dispatch(unloadTenantScriptBundles());
    authenticationService.logout();
    navigate("./login", { replace: true });
  };

  const helpPlugins = Object.values(plugins ?? {}).filter((p) => p.area === "HELP");
  const helpPluginId = helpPlugins.length > 0 ? helpPlugins[0].id : null;

  function getItems(overflow: boolean): IOverflowSetItemProps[] {
    return [
      ...(helpPluginId
        ? [
            {
              key: "help",
              iconProps: { iconName: "Help" },
              text: t("menu.help"),
              iconOnly: true,
              onClick: () => openHelp(helpPluginId),
            },
          ]
        : []),
      {
        key: "notifications",
        iconProps: {
          iconName: "Megaphone",
          ["data-unread"]: unreadNotifications,
          ...(unreadNotifications > 0
            ? {
                styles: {
                  root: {
                    "::after": {
                      content: "attr(data-unread)",
                      position: "absolute",
                      textAlign: "center",
                      borderRadius: 10,
                      ...(overflow
                        ? {
                            ...fonts.tiny,
                            width: 15,
                            height: 15,
                            top: 3,
                            left: 15,
                            backgroundColor: semanticColors.warningIcon,
                            color: semanticColors.bodyBackground,
                            lineHeight: 15,
                          }
                        : {
                            ...fonts.medium,
                            width: 20,
                            height: 20,
                            top: -6,
                            left: 10,
                            backgroundColor: semanticColors.bodyBackground,
                            color: semanticColors.warningIcon,
                            lineHeight: 20,
                          }),
                      fontWeight: 700,
                    },
                  },
                },
              }
            : {}),
        },
        text: t("menu.notifications"),
        iconOnly: true,
        onClick: () => dispatch(setPortalNotificationPaneOpen(true)),
      },
      {
        key: "settings",
        iconProps: { iconName: "Settings" },
        text: t("menu.settings"),
        iconOnly: true,
        onClick: () => dispatch(setPortalSettingsPaneOpen(true)),
      },
    ];
  }

  const menuBarClass = mergeStyles({
    displayName: "menubar",
    display: "flex",
    alignItems: "center",
    minHeight: constants.appBarHeight,
    maxHeight: constants.appBarHeight,
    height: constants.appBarHeight,
    boxSizing: "border-box",
    backgroundColor: getDarkerColorOfTwo(palette.themeLight, palette.themeDark),
    borderBottom: `1px solid ${semanticColors.disabledSubtext}`,
  });

  const menubarWaffleIconClass = mergeStyles({
    display: "flex",
    width: 76,
    minWidth: 76,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    ":hover": {
      backgroundColor: getDarkerColorOfTwo(palette.themeLighter, palette.themeDarker),
    },
  });

  const buttonStyles = () => {
    const backgroundColor = getDarkerColorOfTwo(palette.themeLighter, palette.themeDarker);
    return {
      root: {
        padding: "10px",
        backgroundColor: "inherit",
      },
      rootHovered: { backgroundColor: backgroundColor },
      rootExpanded: { backgroundColor: backgroundColor },
      rootExpandedHovered: { backgroundColor: backgroundColor },
      rootPressed: { backgroundColor: backgroundColor },
      icon: { color: "#fff!important" },
      menuIcon: { color: "#fff!important" },
      flexContainer: { position: "relative" },
    } as IButtonStyles;
  };

  const onRenderItem = (item: IOverflowSetItemProps) => {
    if (item.onRender) return item.onRender(item);

    return (
      <CommandBarButton
        role="menuitem"
        data-debugid={item.name}
        aria-label={item.name}
        styles={buttonStyles()}
        iconProps={item.iconProps}
        onClick={item.onClick}
      />
    );
  };

  const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
    return (
      <CommandBarButton
        ariaLabel="Mehr"
        role="menuitem"
        styles={buttonStyles()}
        menuIconProps={{ iconName: "More" }}
        menuProps={{ items: overflowItems!, calloutProps: { preventDismissOnEvent: (ev) => ev.type === "resize" } }}
      />
    );
  };

  return (
    <div className={menuBarClass} style={blurEffect}>
      <div className={css(classNames.menuBarGroup, classNames.menuBarLeftGroup)}>
        {isMobile ? (
          <>
            <CommandBarButton
              styles={buttonStyles()}
              iconProps={{ iconName: "GlobalNavButton" }}
              onClick={() => setMobilePluginBarOpen((v) => !v)}
            />
            <MobilePluginBar isOpen={mobilePluginBarOpen} setIsOpen={setMobilePluginBarOpen} />
          </>
        ) : (
          <div
            data-debugid="homeButton"
            className={menubarWaffleIconClass}
            onClick={() => dispatch(setPlugin(undefined))}
          >
            <FontIcon iconName="WaffleOffice365" className={classNames.waffleIcon} />
          </div>
        )}
        <div className={classNames.pickerWrapper}>
          <TenantPickerDropdown />
          <ProfilePickerDropdown />
        </div>
      </div>
      <div className={css(classNames.menuBarGroup, classNames.menuBarCenterGroup)}>
        <ResizeGroup
          style={{ width: "100%" }}
          data={{ searchInOverflow: false, items: getItems(false), overflowItems: [] }}
          onRenderData={(data: MenuBarResizeGroupData) => {
            return (
              <div className={classNames.menuBarCenterContent}>
                {!data.searchInOverflow && <SearchBar />}

                <div>
                  <OverflowSet
                    role="menubar"
                    items={data.items}
                    overflowItems={data.overflowItems}
                    onRenderItem={onRenderItem}
                    onRenderOverflowButton={onRenderOverflowButton}
                    styles={overflowSetStyles}
                  />
                </div>
              </div>
            );
          }}
          onReduceData={(prevData: MenuBarResizeGroupData): MenuBarResizeGroupData | undefined => {
            if (prevData.items.length > 0)
              return {
                ...prevData,
                overflowItems: [...getItems(true)],
                items: [],
                cacheKey: `${prevData.searchInOverflow}${getItems(true).length}`,
              };

            if (!prevData.searchInOverflow)
              return {
                ...prevData,
                searchInOverflow: true,
                overflowItems: [
                  {
                    key: "search",
                    onRender: () => (
                      <div style={{ padding: "0.5em 1em" }}>
                        <SearchBar />
                      </div>
                    ),
                  },
                  ...prevData.overflowItems,
                ],
                cacheKey: `${true}${prevData.overflowItems.length + 1}`,
              };

            return undefined;
          }}
          onGrowData={(prevData: MenuBarResizeGroupData): MenuBarResizeGroupData | undefined => {
            if (prevData.searchInOverflow)
              return {
                ...prevData,
                searchInOverflow: false,
                overflowItems: prevData.overflowItems.filter((i) => i.key !== "search"),
                cacheKey: `${false}${prevData.overflowItems.length - 1}`,
              };

            if (prevData.overflowItems.length > 0)
              return {
                ...prevData,
                items: [...getItems(false)],
                overflowItems: [],
                cacheKey: `${prevData.searchInOverflow}${0}`,
              };

            return undefined;
          }}
        />
      </div>
      <div className={classNames.menuBarGroup}>
        <UserSettings onLogout={onLogout} />
      </div>
    </div>
  );
};

export default MenuBar;

const classNames = mergeStyleSets({
  menuBarGroup: { display: "flex", height: "100%" },
  menuBarLeftGroup: { minWidth: 220, overflow: "hidden" },
  menuBarCenterGroup: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    overflow: "hidden",
    minWidth: 48,
  },
  menuBarCenterContent: {
    displayName: "menubar-content",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    gap: "0.75em",
  },
  pickerWrapper: { display: "flex", flexDirection: "column", overflow: "hidden" },
  waffleIcon: { color: "white", fontSize: 18 },
});

const overflowSetStyles = { root: { height: constants.appBarHeight - 1 } };
