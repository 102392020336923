import React, { useEffect, useRef } from "react";
import { pluginService } from "./authorization/pluginService";
import { constants } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import {
  setPlugin,
  companyOpened,
  showDialog,
  closeDialog,
  setNavigation,
  setPluginMenu,
  setPageZoom,
  setPageLink,
  setQueuedLink,
  setPlugins,
  setTfaWizardOpen,
  setTfaLoginId,
  setPluginIcons,
} from "../store/slices/portalSlice";
import { userService } from "./authorization/userService";
import dispatchEvent, { eventsToDispatch } from "../utils/events";
import BasePlugin, { BasePluginRef } from "./BasePlugin";
import WidgetContainer from "./widget/WidgetContainer";
import { useTranslation } from "react-i18next";
import Dialogs from "./dialogs/Dialogs";
import SessionTimeout from "./helpers/SessionTimeout";
import { mergeStyleSets, Spinner, useTheme } from "@fluentui/react";
import * as signalR from "@microsoft/signalr";
import { tenantService } from "./authorization/tenantService";
import GlobalShortcuts from "./helpers/GlobalShortcuts";
import ChangePasswordPanel from "./admin/changePassword/ChangePasswordPanel";
import SystemIndicator from "./admin/SystemIndicator";
import { authenticationService } from "./authorization/authenticationService";
import ProcessingTimeout from "./helpers/ProcessingTimeout";
import PluginBar from "./navigation/PluginBar";
import MenuBar from "./navigation/MenuBar";
import PortalSettingsPane from "./admin/portalSettings/PortalSettingsPane";
import PortalNotificationsPane from "./admin/portalSettings/PortalNotificationsPane";
import { useIsMobileMediaQuery } from "../hooks/mediaQueryHooks";

const hubConnection = new signalR.HubConnectionBuilder()
  .withUrl("./apiSignalR/Hub/signal")
  .withAutomaticReconnect()
  .configureLogging(signalR.LogLevel.Information)
  .build();

hubConnection.onreconnected((connectionId) => {
  if (connectionId) userService.reconnect(connectionId);
});

const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
  console.error("UNHANDLED PROMISE REJECTION:", event.reason);
  event.preventDefault();
};

const Dashboard = (props: any) => {
  const plugin = useSelector((state) => (state as any).portal.plugin);
  const loaded = useSelector((state) => (state as any).portal.loaded);
  const pageLink = useSelector((state) => (state as any).portal.pageLink);
  const sessionTimeout = useSelector((state) => (state as any).portal.sessionTimeout);
  const changePassword = useSelector((state) => (state as any).portal.changePassword);
  const enableTfa = useSelector((state) => (state as any).portal.enableTwoFactor);
  const queuedLink = useSelector((state) => (state as any).portal.queuedLink);
  const isExternal = props.external;
  const pluginContainer = useRef<BasePluginRef>(null);
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const language = useSelector((state: RootState) => state.portal.language);
  const isMobile = useIsMobileMediaQuery();

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("unhandledrejection", unhandledRejectionHandler);
    dispatch(setPlugin(undefined));

    //if (!hubConnection.connectionStarted && hubConnection.state === "Disconnected") {
    hubConnection.start().then((a) => {
      if (hubConnection.connectionId) {
        initHubConnection();
        if (!isExternal) {
          userService.openCompany(hubConnection.connectionId).then(
            (response) => {
              dispatch(companyOpened(response));
              i18n.changeLanguage(response.language);

              tenantService.getPluginIcons().then((response) => {
                dispatch(setPluginIcons(response.pluginIcons));
              });
            },
            (error) => {
              authenticationService.logout();
              navigate("login?generalerror=" + error);
            }
          );
        } else {
          userService
            .openExternalSession(
              hubConnection.connectionId,
              props.externalPlugin,
              props.externalPageLink,
              props.externalTenantId
            )
            .then((response) => {
              dispatch(companyOpened(response));
              i18n.changeLanguage(response.language);
              dispatch(setPageLink("external" + props.externalPageLink));
              dispatch(setPlugin({ pluginId: props.externalPlugin }));
            });
        }
      }
    });

    return () => {
      resetHubConnection();
      hubConnection.stop();
      window.removeEventListener("unhandledrejection", unhandledRejectionHandler);
    };
  }, []);

  useEffect(() => {
    if (!plugin) {
      pluginContainer.current?.unMount?.();
      setTimeout(() => {
        window.portalInterface.changePortalSiteTitle({
          plugin: undefined,
          page: undefined,
          dataId: undefined,
          status: undefined,
        });
      }, 500);
    } else {
      pluginContainer.current?.reload?.(plugin, pageLink);
    }
  }, [plugin]);

  useEffect(() => {
    pluginContainer?.current?.reload?.(plugin, pageLink);
  }, [pageLink, theme, language]);

  const initHubConnection = () => {
    hubConnection.on("message", (id, msg, title) => dispatch(showDialog({ type: "msg", id, msg, title })));
    hubConnection.on("error", (id, msg, title, details) =>
      dispatch(showDialog({ type: "error", id, msg, title, details }))
    );
    hubConnection.on("confirm", (title, msg, id) => dispatch(showDialog({ type: "confirm", id, title, msg })));
    hubConnection.on("pageinfo", (pagezoom) => dispatch(setPageZoom(pagezoom)));
    hubConnection.on("languagechange", (language) => {});
    hubConnection.on("objectrun", handleObjectRun);
    hubConnection.on("closepage", (pageId) => dispatchEvent(eventsToDispatch.PLUGIN_CLOSE_PAGE, { pageId }));
    hubConnection.on("openfiledialog", (title, filter, id) =>
      dispatch(showDialog({ type: "openfile", id, title, filter }))
    );
    hubConnection.on("openplugin", onOpenPlugin);
    hubConnection.on("refreshpage", (pageId, skipChildren) =>
      dispatchEvent(eventsToDispatch.PLUGIN_REFRESH_PAGE, { pageId, skipChildren })
    );
    //hubConnection.on("refreshpagemetadata", (pageId) => dispatchEvent(eventsToDispatch.PLUGIN_REFRESH_PAGEMETADATA, { pageId }));
    hubConnection.on("downloadfile", (fileid) => tenantService.downloadFile(fileid));
    hubConnection.on("openreportviewer", (id, title, pluginId, tenantId) =>
      dispatch(showDialog({ type: "reportviewer", id, title, pluginId, tenantId }))
    );
    hubConnection.on("reloadnavigation", handleReloadNavigation);
    hubConnection.on("reloadmenu", handleReloadMenu);
    hubConnection.on("notification", handleShowNotification);
    hubConnection.on("hidenotification", handleHideNotification);
    hubConnection.on("progressbar", handleProgressBar);
    hubConnection.on("showtfawizard", (loginId: number) => {
      dispatch(setTfaWizardOpen(true));
      dispatch(setTfaLoginId(loginId));
    });
    hubConnection.on("closepagenotifications", (pageId) => {
      dispatchEvent(eventsToDispatch.PLUGIN_CLOSE_PAGE_NOTIFICATIONS, { pageId });
    });
    hubConnection.on("forcerefreshwebsite", () => {
      window.location.reload();
    });
    hubConnection.on("custompagenotification", (pageId, action, data) => {
      try {
        const o = JSON.parse(data);
        dispatchEvent(eventsToDispatch.PLUGIN_CUSTOMPAGE_NOTIFICATION, { pageId, action, data: o });
      } catch {
        dispatchEvent(eventsToDispatch.PLUGIN_CUSTOMPAGE_NOTIFICATION, { pageId, action, data });
      }
    });
  };

  const resetHubConnection = () => {
    const methods = [
      "message",
      "error",
      "confirm",
      "pageinfo",
      "languagechange",
      "objectrun",
      "closepage",
      "openfiledialog",
      "openplugin",
      "refreshpage",
      "refreshpagemetadata",
      "downloadfile",
      "openreportviewer",
      "reloadnavigation",
      "reloadmenu",
      "notification",
      "hidenotification",
      "progressbar",
      "showtfawizard",
      "closepagenotifications",
      "forcerefreshwebsite",
      "custompagenotification",
    ];
    methods.forEach((m) => hubConnection.off(m));
  };

  const handleProgressBar = (type: any, payload: any) => {
    switch (type) {
      case "show": {
        const bar = JSON.parse(payload);
        dispatch(showDialog({ type: "progressbar", bar }));
        break;
      }
      case "update": {
        const bar = JSON.parse(payload);
        dispatch(showDialog({ type: "updatedprogressbar", bar }));
        break;
      }
      case "close": {
        dispatch(closeDialog({ type: "progressbar", id: payload }));
        break;
      }
    }
  };

  const handleReloadMenu = (tenantId: any) => {
    if (isExternal) {
      return;
    }

    tenantService.getMenu(tenantId).then((menu) => {
      dispatch(setPlugins(menu.plugins));
    });
  };

  const handleReloadNavigation = (pluginId: any) => {
    if (isExternal) {
      return;
    }
    pluginService.loadNavigation(pluginId).then((nav) => {
      dispatch(setNavigation(nav));
      dispatch(setPluginMenu(pluginId));
    });
  };

  const onOpenPlugin = (id: any, type: any, link: any) => {
    let plugin = undefined;

    if (pluginContainer.current?.getPluginId) {
      plugin = pluginContainer.current.getPluginId();
    }
    if (id !== plugin) {
      if (link) {
        switch (type) {
          case 0: {
            dispatch(setPageLink(link));
            break;
          }
          case 1: {
            dispatch(setQueuedLink(link));
            break;
          }
        }
      }
      dispatch(setPlugin({ pluginId: id }));
      dispatch(setPluginMenu(id));
    } else {
      if (link) {
        switch (type) {
          case 0: {
            dispatch(setPageLink(link));
            break;
          }
          case 1: {
            pluginService.onLinkClicked(link);
            break;
          }
        }
      }
    }
  };

  const pluginLoaded = (id: any) => {
    if (queuedLink) {
      pluginService.onLinkClicked(queuedLink);
      dispatch(setQueuedLink(null));
    }
  };

  const handleShowNotification = (id: any, payload: any) => {
    const notification = JSON.parse(payload);
    dispatchEvent(eventsToDispatch.PLUGIN_SHOW_NOTIFICATION, { id, notification });
  };

  const handleHideNotification = (id: any) => {
    dispatchEvent(eventsToDispatch.PLUGIN_HIDE_NOTIFICATION, { id });
  };

  const handleObjectRun = (objectType: any, objectId: any, modal: any, lookupMode: any, caption: string) => {
    switch (objectType) {
      case "page": {
        dispatchEvent(eventsToDispatch.PLUGIN_OPEN_PAGE, {
          pageId: objectId,
          modal,
          caption,
          lookupMode,
        });
        break;
      }
    }
  };

  let blurEffect = {};
  if (sessionTimeout || changePassword || enableTfa) {
    (blurEffect as any).filter = "blur(4px)";
  }

  if (!loaded) {
    return (
      <div style={{ height: "100vh", display: "flex", justifyContent: "center" }}>
        <Spinner
          label="Lade Mandant..."
          styles={{ circle: { width: 70, height: 70 }, label: { fontSize: "1.5rem" } }}
        />
      </div>
    );
  }

  if (!isExternal) {
    return (
      <div className={classNames.rootContainer}>
        {!changePassword && (
          <>
            <MenuBar />
            <div className={classNames.contentWrapper}>
              {!isMobile && (
                <div className={classNames.pluginBarContainer} style={blurEffect}>
                  <PluginBar />
                </div>
              )}
              <main className={classNames.mainContentContainer} style={blurEffect}>
                <div className={classNames.mainContent}>
                  {loaded && (
                    <React.Fragment>
                      <WidgetContainer visible={!plugin} />
                      <BasePlugin id={plugin} linkId={pageLink} ref={pluginContainer} onPluginLoaded={pluginLoaded} />
                    </React.Fragment>
                  )}
                </div>
              </main>
            </div>
          </>
        )}
        <Dialogs />
        <SystemIndicator />
        <GlobalShortcuts />
        <ChangePasswordPanel />
        <PortalSettingsPane />
        <PortalNotificationsPane />
        <SessionTimeout />
        <ProcessingTimeout />
      </div>
    );
  } else {
    return (
      <div className={classNames.rootContainer}>
        <main className={classNames.mainContentContainer} style={blurEffect}>
          <div className={classNames.mainContent}>
            {loaded && <BasePlugin id={plugin} linkId={pageLink} ref={pluginContainer} onPluginLoaded={pluginLoaded} />}
          </div>
          <Dialogs />
        </main>
        <SessionTimeout />
      </div>
    );
  }
};

export default Dashboard;

const classNames = mergeStyleSets({
  rootContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  mainContent: {
    flexGrow: 1,
    overflow: "auto",
  },
  mainContentContainer: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  pluginBarContainer: {
    maxWidth: 400,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
  },
});
