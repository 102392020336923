import React from "react";
import { ElementWrapper } from "../elements/ElementWrapper";
import { ActionWrapper } from "./ActionWrapper";

export const ActionSet = (props: any) => {
  const payload = props.json;

  return (
    <ElementWrapper json={payload} isFirst={props.isFirst}>
      <ActionWrapper actions={payload.actions} />
    </ElementWrapper>
  );
};
