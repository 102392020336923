import React from "react";
import {
  Persona,
  PersonaSize,
  ContextualMenu,
  ContextualMenuItemType,
  IContextualMenuItem,
  mergeStyles,
  useTheme,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { authenticationService } from "../authorization/authenticationService";
import { setChangePasswordPanelOpen, setTfaWizardOpen } from "../../store/slices/portalSlice";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";

export default function UserSettings(props: any) {
  const linkRef = React.useRef(null);
  const showTfaOption = useSelector((state: RootState) => state.portal.showTfaOption);
  const showPasswordOption = useSelector((state: RootState) => state.portal.showPasswordOption);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { semanticColors } = useTheme();

  const onShowContextualMenu = React.useCallback(() => {
    setShowContextualMenu(!showContextualMenu);
  }, [showContextualMenu]);

  const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

  const currentUser = authenticationService.currentUserValue;

  const menuItems: IContextualMenuItem[] = [
    {
      key: "logout",
      id: "logout",
      ["data-debugid"]: "logout",
      text: t("menu.logout"),
      onClick: props.onLogout,
      itemProps: { styles: { label: { fontWeight: 500, textAlign: "end", color: semanticColors.errorIcon } } },
      // iconProps: { iconName: "SignOut" },
    },
    {
      key: "divider_1",
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: "bigPersona",
      onRender: () => (
        <Persona
          text={currentUser.fullName}
          imageUrl={currentUser.picture}
          size={PersonaSize.size72}
          coinProps={coinProps}
          styles={{ root: { margin: 8 } }}
        />
      ),
    },
  ];

  if (showTfaOption || showPasswordOption) {
    menuItems.push({
      key: "divider_2",
      itemType: ContextualMenuItemType.Divider,
    });
  }

  if (showTfaOption) {
    menuItems.push({
      key: "setupTfa",
      id: "setupTfa",
      ["data-debugid"]: "setupTfa",
      text: "Zwei-Faktor Authentifizierung",
      onClick: () => {
        dispatch(setTfaWizardOpen(true));
      },
      itemProps: { styles: { label: { fontWeight: 500 } } },
      iconProps: { iconName: "PasswordField" },
    });
  }

  if (showPasswordOption) {
    menuItems.push({
      key: "changePassword",
      id: "changePassword",
      ["data-debugid"]: "changePassword",
      text: t("menu.changePassword"),
      onClick: () => {
        dispatch(setChangePasswordPanelOpen(true));
      },
      itemProps: { styles: { label: { fontWeight: 500 } } },
      iconProps: { iconName: "PasswordField" },
    });
  }

  return (
    <React.Fragment>
      <div onClick={onShowContextualMenu} className={personaDivClass} ref={linkRef} data-debugid="openUserSettings">
        <Persona
          imageUrl={currentUser.picture}
          text={currentUser.fullName}
          size={PersonaSize.size32}
          hidePersonaDetails={false}
          styles={personaStyles}
          coinProps={coinProps}
        />
      </div>
      <ContextualMenu
        //@ts-ignore - Custom props not supported but get added to the right DOM node anyway
        calloutProps={{ ["data-debugid"]: "userSettingsMenu" }}
        items={menuItems}
        hidden={!showContextualMenu}
        target={linkRef}
        gapSpace={-2}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
      />
    </React.Fragment>
  );
}

const personaDivClass = mergeStyles({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginInline: "0.5rem",
});

const personaStyles = {
  primaryText: { display: "none" },
  details: { display: "none" },
};

const coinProps = { styles: { image: { borderRadius: "25%" }, initials: { borderRadius: "25%" } } };
