import { useEffect, useState } from "react";

const useStateWithLocalStorage = <T>(storageKey: string, propertyName: string, fallbackValue: T) => {
  const [value, setValue] = useState<{ [propertyName: string]: T }>(
    JSON.parse(`${localStorage.getItem(storageKey)}`) ?? { [propertyName]: fallbackValue }
  );

  const changeValue = (newValue: T) => {
    setValue((prev) => ({ ...prev, [propertyName]: newValue }));
  };

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey, propertyName]);

  return [value[propertyName], changeValue] as [T, (newValue: T) => void];
};

export default useStateWithLocalStorage;
