import React, { useState, useEffect, useCallback, useReducer } from "react";
import { Label, Text, mergeStyleSets, Icon, TextField, PrimaryButton, ActionButton } from "@fluentui/react";
import { ReactComponent as InfomaLogo } from "../../../images/InfomaLogo.svg";
import { ReactComponent as AxiansLogo } from "../../../images/AXIANSLogo.svg";
import AboutUsPanel from "../../dialogs/AboutUsPanel";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticationService } from "../authenticationService";
import ChangePasswordField from "../../admin/changePassword/ChangePasswordField";
import { useTranslation } from "react-i18next";
import ChangePasswordCriteria from "../../admin/changePassword/ChangePasswordCriteria";

type PasswordState = {
  newPassword: {
    value: string;
    error: string;
  };
  repeatPassword: {
    value: string;
    error: string;
  };
  hasErrors: boolean;
};

const ResetPasswordPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [generalError, setGeneralError] = useState<string | undefined>(undefined);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const baseColor = { r: 162, g: 0, b: 103 };
  const buttonColor = `rgb(${baseColor.r},${baseColor.g},${baseColor.b})`;
  const buttonColorDarker = `rgba(${baseColor.r},${baseColor.g},${baseColor.b},0.5)`;
  const buttonColorDark = `rgba(${baseColor.r},${baseColor.g},${baseColor.b},0.2)`;
  const [isReset, setIsReset] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [resetOk, setResetOk] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");

    const check = async () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json", "Accept-Type": "application/json" },
        };

        const response = await fetch(`login/checkresetpassword?id=${id}`, requestOptions);
        if (!response.ok) {
          if (response.status === 498)
            setGeneralError(
              "Link abgelaufen. Tut uns leid, dieser Link zum Zurücksetzen des Kennworts ist nicht mehr gültig. Sie können sich gerne einen neuen Link generieren lassen."
            );
          else setGeneralError("Der Aufruf ist ungültig. Bitte prüfen Sie die angegebene Url.");
        } else setIsReset(true);
      } catch (error: any) {
        setGeneralError("Der Aufruf ist ungültig. Bitte prüfen Sie die angegebene Url.");
      }
      setLoading(false);
    };

    if (id) check();
    else setLoading(false);
  }, []);

  const checkNewPassword = useCallback((password: string) => {
    if (!password) return t("dialogs.changePasswordPanel.newPasswordMustNotBeEmpty");
    return "";
  }, []);

  const checkNewPasswordRepeat = useCallback((password: string, repeat: string) => {
    if (password !== repeat) return t("dialogs.changePasswordPanel.newPasswordsDoNotMatch");

    return "";
  }, []);

  const reducer = (state: PasswordState, action: PasswordAction): PasswordState => {
    let newState: PasswordState;
    let hasErrors: boolean;

    switch (action.type) {
      case "setNewPassword":
        newState = {
          ...state,
          newPassword: { value: action.value, error: checkNewPassword(action.value) },
          repeatPassword: {
            value: state.repeatPassword.value,
            error: checkNewPasswordRepeat(action.value, state.repeatPassword.value),
          },
        };
        hasErrors = newState.newPassword.error + newState.repeatPassword.error !== "";

        return { ...newState, hasErrors };
      case "setRepeatPassword":
        newState = {
          ...state,
          repeatPassword: { value: action.value, error: checkNewPasswordRepeat(state.newPassword.value, action.value) },
        };
        hasErrors = newState.newPassword.error + newState.repeatPassword.error !== "";

        return { ...newState, hasErrors };
      case "reset":
        return initialPasswordState;
    }
  };

  const [passwordState, passwordDispatch] = useReducer(reducer, initialPasswordState);

  const textFieldStyle = {
    root: {
      width: "100%",
      marginBottom: 20,
    },
    fieldGroup: {
      backgroundColor: buttonColorDark,
      borderColor: buttonColor,
      color: "white",
      ":hover": {
        borderColor: "white",
      },
      ":after": {
        borderColor: buttonColor,
      },
    },
    field: {
      color: "white",
      "::placeholder": {
        color: "white",
      },
    },
    subComponentStyles: {
      label: {
        root: {
          color: "white",
          fontSize: 18,
        },
      },
    },
    revealButton: {
      ":hover i": {
        color: "rgb(0, 14, 26)",
      },
    },
    revealIcon: {
      color: "white",
    },
  };

  const buttonStyles = {
    root: {
      width: "100%",
      backgroundColor: buttonColor,
      borderColor: buttonColor,
      color: "white",
    },
    rootDisabled: {
      backgroundColor: buttonColorDarker,
      borderColor: buttonColor,
      opacity: 0.5,
    },
    rootHovered: {
      backgroundColor: buttonColorDarker,
      borderColor: buttonColor,
    },
    rootPressed: {
      backgroundColor: buttonColorDark,
      borderColor: buttonColor,
    },
  };

  const actionButtonStyles = {
    root: {
      width: 130,
      paddingLeft: 0,
      color: "white",
    },
    rootHovered: {
      color: buttonColor,
    },
    rootPressed: {
      color: buttonColor,
    },
  };

  const resetPassword = async () => {
    if (!userName) return;
    try {
      await authenticationService.resetPassword(userName);
    } catch {}
    setMailSent(true);
  };

  const setNewPassword = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const id = params.get("id");

      const response = await authenticationService.setNewPassword(passwordState.newPassword.value, id ?? "");
      if (!response.success) setGeneralError(response.error);
      else setResetOk(true);
    } catch {}
  };

  const renderContent = () => {
    if (loading) return null;
    if (!isReset) {
      if (!mailSent) {
        return (
          <>
            <Label styles={{ root: { fontSize: "clamp(24px, 4vw, 32px)", color: "white", whiteSpace: "nowrap" } }}>
              Kennwort vergessen?
            </Label>
            <div className={classNames.loginsWrapper}>
              {generalError && (
                <div className={classNames.generalErrorWrapper}>
                  <Icon iconName="StatusErrorFull" styles={{ root: { paddingRight: 4 } }} />
                  <Text variant="small" styles={{ root: { color: "white" } }}>
                    {generalError}
                  </Text>
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text variant="medium" style={{ color: "white", padding: "1rem 0" }}>
                  Wie lautet Ihr Benutzername?
                </Text>
                <TextField
                  styles={textFieldStyle}
                  label="Benutzername"
                  required
                  canRevealPassword
                  onChange={(_, v) => setUserName(v ?? "")}
                  value={userName}
                  data-debugid="mailAdress"
                />
                <PrimaryButton
                  styles={buttonStyles}
                  data-debugid="sendMailButton"
                  text={"Benutzername bestätigen"}
                  onClick={resetPassword}
                  allowDisabledFocus
                />
                <ActionButton styles={actionButtonStyles} onClick={() => navigate("/login")}>
                  Zurück zum Login
                </ActionButton>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <Label styles={{ root: { fontSize: "clamp(24px, 4vw, 32px)", color: "white", whiteSpace: "nowrap" } }}>
              Hilfe ist unterwegs.
            </Label>
            <div className={classNames.loginsWrapper}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text variant="medium" style={{ color: "white", padding: "1rem 0" }}>
                  Wir überprüfen, ob ein Konto mit "{userName}" verknüpft ist. Wenn ja, senden wir Ihnen Anweisungen zum
                  Zurücksetzen Ihres Kennworts.
                </Text>
                <Text variant="medium" style={{ color: "white", padding: "1rem 0" }}>
                  Falls Sie keine E-Mail von uns erhalten, schauen Sie bitte in Ihrem Spam-Ordner nach oder stellen Sie
                  sicher, dass Sie einen gültigen Anmeldenamen verwenden. Sollten Sie weiterhin Probleme haben auf Ihr
                  Konto zugreifen zu können, wenden Sie sich bitte an Ihren Administrator oder eröffnen Sie ein Ticket.
                </Text>
                <ActionButton styles={actionButtonStyles} onClick={() => navigate("/login")}>
                  Zurück zum Login
                </ActionButton>
              </div>
            </div>
          </>
        );
      }
    } else {
      if (!resetOk) {
        return (
          <>
            <Label styles={{ root: { fontSize: "clamp(24px, 4vw, 32px)", color: "white", whiteSpace: "nowrap" } }}>
              Kennwort vergeben
            </Label>
            <div className={classNames.loginsWrapper}>
              {generalError && (
                <div className={classNames.generalErrorWrapper}>
                  <Icon iconName="StatusErrorFull" styles={{ root: { paddingRight: 4 } }} />
                  <Text variant="small" styles={{ root: { color: "white" } }}>
                    {generalError}
                  </Text>
                </div>
              )}
              <Text variant="medium" style={{ color: "white", padding: "1rem 0" }}>
                Bitte geben Sie ein neues Kennwort ein.
              </Text>
              <ChangePasswordField
                type="New"
                label={t("dialogs.changePasswordPanel.newPassword")}
                password={passwordState.newPassword}
                dispatch={passwordDispatch}
                //@ts-ignore
                style={{
                  subComponentStyles: { label: { root: { color: "white" } } },
                }}
              />
              <ChangePasswordField
                type="Repeat"
                label={t("dialogs.changePasswordPanel.repeatNewPassword")}
                password={passwordState.repeatPassword}
                dispatch={passwordDispatch}
                //@ts-ignore
                style={{
                  subComponentStyles: { label: { root: { color: "white" } } },
                }}
              />
              <ChangePasswordCriteria style={{ color: "white" }} password={passwordState.newPassword.value} />
              <PrimaryButton
                styles={buttonStyles}
                data-debugid="loginButton"
                text={"Neues Kennwort speichern"}
                disabled={
                  passwordState.hasErrors || !passwordState.newPassword.value || !passwordState.repeatPassword.value
                }
                onClick={setNewPassword}
                allowDisabledFocus
              />
            </div>
          </>
        );
      } else {
        return (
          <>
            <Label styles={{ root: { fontSize: "clamp(24px, 4vw, 32px)", color: "white", whiteSpace: "nowrap" } }}>
              Das hat geklappt
            </Label>
            <div className={classNames.loginsWrapper}>
              <Text variant="medium" style={{ color: "white", padding: "1rem 0" }}>
                Ihr neues Kennwort wurde gespeichert. Sie können sich nun mit Ihrem neuen Kennwort anmelden.
              </Text>
              <PrimaryButton
                styles={buttonStyles}
                data-debugid="loginButton"
                text={"Zurück zu Anmeldung"}
                onClick={() => navigate("/login")}
                allowDisabledFocus
              />
            </div>
          </>
        );
      }
    }
  };

  return (
    <div className={classNames.fullSizeBackground}>
      <div className={classNames.loginPanel}>
        <div className={classNames.loginPanelContent}>
          <InfomaLogo className={classNames.infomaLogo} style={{ paddingBottom: "2rem" }} />
          {renderContent()}
        </div>
        <div className={classNames.loginPanelFooter}>
          <AboutUsPanel />
        </div>
      </div>
      <AxiansLogo className={classNames.axiansLogo} />
    </div>
  );
};

export default ResetPasswordPage;

const classNames = mergeStyleSets({
  fullSizeBackground: {
    height: "100%",
    backgroundColor: "#000912",
    backgroundImage: "url('images/LoginBackground.jpg')",
    background:
      "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 700px), url('images/LoginBackground.jpg'), #000912",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginPanel: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  loginPanelContent: {
    margin: "auto 0",
    paddingInline: "clamp(20px, 10vw, 150px)",
  },
  loginPanelFooter: {
    display: "flex",
    width: "80%",
    maxWidth: 350,
    padding: "1em",
    paddingLeft: "clamp(20px, 10vw, 150px)",
    justifyContent: "space-between",
    flexWrap: "wrap",
    whiteSpace: "pre",
  },
  loginsWrapper: {
    width: "100%",
    maxWidth: 350,
    display: "flex",
    flexDirection: "column",
  },
  errorWrapper: {
    display: "flex",
    alignItems: "center",
    padding: 4,
    color: "white",
  },
  generalErrorWrapper: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    color: "white",
    border: "1px solid white",
  },
  divider: {
    display: "flex",
    color: "white",
    padding: "12px 0",
    textAlign: "center",
    ":before, :after": {
      content: "''",
      borderTop: "1px solid white",
      flex: "1 1 auto",
      margin: "auto 15px",
    },
    ":before": {
      marginLeft: 0,
    },
    ":after": {
      marginRight: 0,
    },
  },
  infomaLogo: {
    width: "clamp(200px, 25vw, 300px)",
  },
  axiansLogo: {
    width: "clamp(100px, 15vw, 200px)",
    position: "fixed",
    right: 20,
    bottom: 30,
  },
});

type PasswordAction =
  | { type: "setNewPassword"; value: string }
  | { type: "setRepeatPassword"; value: string }
  | { type: "reset" };

const initialPasswordState: PasswordState = {
  newPassword: { value: "", error: "" },
  repeatPassword: { value: "", error: "" },
  hasErrors: false,
};
