import React, { useState, useEffect } from "react";
import { Label, Text, mergeStyleSets, Icon } from "@fluentui/react";
import { authenticationService } from "../authenticationService";
import { ReactComponent as InfomaLogo } from "../../../images/InfomaLogo.svg";
import { ReactComponent as AxiansLogo } from "../../../images/AXIANSLogo.svg";
import UserNamePasswordLogin from "./UserNamePasswordLogin";
import LoginButton from "./LoginButton";
import OpenIdLoginButton from "./OpenIdLoginButton";
import AboutUsPanel from "../../dialogs/AboutUsPanel";
import useAppSpaceVersion from "../../../hooks/useAppSpaceVersion";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TwoFactorAuthentification } from "./TwoFactorAuthetification";

const LoginPage = () => {
  const [providers, setProviders] = useState<LoginProvider[]>([]);
  const [activeProvider, setActiveProvider] = useState<LoginProvider>();
  const [tenant, setTenant] = useState({ name: "", image: "" });
  const [loading, setLoading] = useState(true);
  const [tfa, setTfa] = useState<any>(null);
  const [loginError, setLoginError] = useState<LoginError | undefined>(undefined);
  const [generalError, setGeneralError] = useState<string | null>(null);
  const version = useAppSpaceVersion();
  const navigate = useNavigate();
  const location = useLocation();
  const [tfaEnabled, setTFAEnabled] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirectUrl = params.get("redirectUrl");
    const finishAuthenticationid = params.get("finishAuthenticationId");

    if (!finishAuthenticationid) {
      const user = authenticationService.currentUserValue;
      if (user) {
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          if (user.fullName !== "externalUser" && user.id !== 0) {
            navigate("/");
          }
        }
      }
    }
    const p = location.pathname.split("/");
    const tenant = { Name: "" };
    if (p.length > 2) {
      tenant.Name = p[2];
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Type": "application/json" },
      body: JSON.stringify(tenant),
    };
    fetch("login/metadata", requestOptions).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ name: any; }' is not assignabl... Remove this comment to see the full error message
          setTenant({ name: data.tenantName });
          setProviders(data.loginProviders);
          setResetPassword(data.resetPassword);
          setActiveProvider(
            data.loginProviders?.find((p: LoginProvider) => p.providerType === 2) ?? data.loginProviders[0]
          );
          setLoading(false);

          if (params.get("error") && params.get("provider")) {
            const error = params.get("error");
            const provider = params.get("provider");
            setLoginError({ id: provider, error });
          }

          if (params.get("generalerror")) {
            const genError = params.get("generalerror");
            setGeneralError(genError);
          }

          if (finishAuthenticationid) {
            setLoading(true);

            authenticationService.finishAuthentication(finishAuthenticationid).then(
              (user) => {
                if (user) {
                  if (redirectUrl) {
                    navigate(redirectUrl);
                    //props.history.push(redirectUrl);
                  } else {
                    navigate("/");
                    //props.history.push("/");
                  }
                }
              },
              (error) => {
                alert(error);
              }
            );
          }
        });
      } else {
        setLoading(false);
      }
    });
  }, []);

  const loginOpenId = async (provider: any) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept-Type": "application/json" },
    };
    const response = await fetch("/login/providerreferrer?providerId=" + provider.id, requestOptions);

    if (response.ok) {
      var data = await response.json();
      if (data && data.referrer) {
        window.location.assign(data.referrer);
      }
    } else {
      response.text().then((data) => {
        alert(data);
      });
    }
    return;
  };

  const login = (id: number, userName: string, pwd: string) => {
    authenticationService.login(userName, pwd, id.toString()).then(
      () => {
        const { from } = (location.state as any) || { from: { pathname: "/" } };
        navigate(from.pathname, { replace: true });
      },
      (error) => {
        if (typeof error === "object" && error.tfa !== undefined) {
          setTfa({
            id: error.id,
            caption: error.tfaname,
            type: error.type,
            subtype: error.subtype,
            codelength: error.codelength,
          });
          setTFAEnabled(true);
        } else {
          setLoginError({ id: id.toString(), error });
        }
      }
    );
  };

  const redirectToDashboard = () => {
    const { from } = (location.state as any) || { from: { pathname: "/" } };
    navigate(from.pathname, { replace: true });
    //props.history.push(from);
  };

  const additionalLdapChecks = (username: string, password: string) => {
    var result: any = {};
    if (username.indexOf("/") != -1) {
      result.userNameError = "Der Benutzername enthält ungültige Zeichen. Meinten Sie '\\'?";
      return result;
    }
    const usernameparts = username.split("\\");
    if (usernameparts.length < 1 || usernameparts.length > 2) {
      result.userNameError = "Bitte geben Sie eine Domäne an.";
    }
    return result;
  };

  const renderLoginProviderButtons = () => {
    return (
      <React.Fragment>
        {providers
          .filter((provider) => provider.id !== activeProvider?.id)
          .map((provider) => renderLoginProvider("button", provider))}
      </React.Fragment>
    );
  };

  const renderLoginProvider = (partToRender: "button" | "provider", provider?: LoginProvider) => {
    if (!provider) return null;

    switch (provider.providerType) {
      case 2: // UserName/PW
        return partToRender === "button" ? (
          <LoginButton key={provider.id} provider={provider} onClick={() => setActiveProvider(provider)} />
        ) : (
          <UserNamePasswordLogin
            key={provider.id}
            provider={provider}
            onLogin={login}
            canResetPassword={resetPassword}
            usernamePlaceholder="Benutzername"
            passwordPlaceholder="Kennwort"
          />
        );
      case 3: // OpenID
        return partToRender === "button" ? (
          <OpenIdLoginButton key={provider.id} provider={provider} onClick={() => loginOpenId(provider)} />
        ) : null;
      case 4: // SAML
        return null;
      case 5: // LDAP
        return partToRender === "button" ? (
          <LoginButton key={provider.id} provider={provider} onClick={() => setActiveProvider(provider)} />
        ) : (
          <UserNamePasswordLogin
            key={provider.id}
            canResetPassword={resetPassword}
            provider={provider}
            onLogin={login}
            usernamePlaceholder={provider.metadata === "defaultDomain" ? "Benutzername" : "Domäne\\Benutzername"}
            passwordPlaceholder="Kennwort"
            additionalChecks={additionalLdapChecks}
          />
        );
      default:
        return null;
    }
  };

  const moreThanOneProvider = providers.some((p) => activeProvider && p.id !== activeProvider.id);

  if (loading) return null;

  return (
    <div className={classNames.fullSizeBackground}>
      <div className={classNames.loginPanel}>
        {tenant?.name && <h3>{tenant.name}</h3>}
        <div className={classNames.loginPanelContent}>
          <InfomaLogo className={classNames.infomaLogo} />
          <Label styles={{ root: { fontSize: "clamp(32px, 4vw, 46px)", color: "white", whiteSpace: "nowrap" } }}>
            Willkommen im
            <br /> Infoma AppSpace
          </Label>
          <div className={classNames.loginsWrapper}>
            {generalError && (
              <div className={classNames.generalErrorWrapper}>
                <Icon iconName="StatusErrorFull" styles={{ root: { paddingRight: 4 } }} />
                <Text variant="small" styles={{ root: { color: "white" } }}>
                  {generalError}
                </Text>
              </div>
            )}
            <div className={classNames.divider}>{`Mit ${activeProvider?.caption} anmelden`}</div>
            {renderLoginProvider("provider", activeProvider)}
            {loginError && loginError.error && (
              <div className={classNames.errorWrapper}>
                <Icon iconName="StatusErrorFull" styles={{ root: { paddingRight: 4 } }} />
                <Text variant="small" styles={{ root: { color: "white" } }}>
                  {loginError.error}
                </Text>
              </div>
            )}
            {moreThanOneProvider && <div className={classNames.divider}>oder</div>}
            {renderLoginProviderButtons()}
          </div>
        </div>

        <div className={classNames.loginPanelFooter}>
          <AboutUsPanel />
          {version?.productVersion && (
            <Text block styles={textStyles}>
              {`${version.productVersion}`}
            </Text>
          )}
        </div>
      </div>
      <AxiansLogo className={classNames.axiansLogo} />
      {tfaEnabled && (
        <TwoFactorAuthentification hideModal={() => setTFAEnabled(false)} onLogin={redirectToDashboard} tfa={tfa} />
      )}
    </div>
  );
};

export default LoginPage;

const classNames = mergeStyleSets({
  fullSizeBackground: {
    height: "100%",
    backgroundColor: "#000912",
    backgroundImage: "url('images/LoginBackground.jpg')",
    background:
      "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 700px), url('images/LoginBackground.jpg'), #000912",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginPanel: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  loginPanelContent: {
    margin: "auto 0",
    paddingInline: "clamp(20px, 10vw, 150px)",
  },
  loginPanelFooter: {
    display: "flex",
    width: "80%",
    maxWidth: 350,
    padding: "1em",
    paddingLeft: "clamp(20px, 10vw, 150px)",
    justifyContent: "space-between",
    flexWrap: "wrap",
    whiteSpace: "pre",
  },
  loginsWrapper: {
    width: "100%",
    maxWidth: 350,
  },
  errorWrapper: {
    display: "flex",
    alignItems: "center",
    padding: 4,
    color: "white",
  },
  generalErrorWrapper: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    color: "white",
    border: "1px solid white",
  },
  divider: {
    display: "flex",
    color: "white",
    padding: "12px 0",
    textAlign: "center",
    ":before, :after": {
      content: "''",
      borderTop: "1px solid white",
      flex: "1 1 auto",
      margin: "auto 15px",
    },
    ":before": {
      marginLeft: 0,
    },
    ":after": {
      marginRight: 0,
    },
  },
  infomaLogo: {
    width: "clamp(200px, 25vw, 300px)",
  },
  axiansLogo: {
    width: "clamp(100px, 15vw, 200px)",
    position: "fixed",
    right: 20,
    bottom: 30,
  },
});

const textStyles = { root: { color: "white" } };
