import React from "react";

import { StyleSheet, Text, Linking } from "../reactnative";
let SPECIAL_CHAR_REGEX = new RegExp("[^a-z\\\\s\\d]", "gi");
type Props = {
  text: string;
  defaultStyles?: any[];
  numberOfLines: number;
};
const MarkdownFormatter = (props: Props) => {
  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'matchedIndices' implicitly has type 'any... Remove this comment to see the full error message
  const matchedIndices = [];

  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'matchesFound' implicitly has type 'any[]... Remove this comment to see the full error message
  const matchesFound = [];

  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'matchesStyleTypes' implicitly has type '... Remove this comment to see the full error message
  const matchesStyleTypes = [];

  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'matchesStyles' implicitly has type 'any[... Remove this comment to see the full error message
  const matchesStyles = [];

  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'patterns' implicitly has type 'any[]' in... Remove this comment to see the full error message
  const patterns = [];

  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'styles' implicitly has type 'any[]' in s... Remove this comment to see the full error message
  const styles = [];

  // @ts-expect-error ts-migrate(7034) FIXME: Variable 'styleTypes' implicitly has type 'any[]' ... Remove this comment to see the full error message
  const styleTypes = [];
  const numberOfLines = props.numberOfLines;
  const userStyles = props.defaultStyles;
  var { text } = props;
  const regexArray = MD_FORMATTER_CONFIG;
  const init = () => {
    for (var i = 0; i < regexArray.length; i++) {
      let pattern = regexArray[i].pattern;
      let patternType = regexArray[i].patternType;
      let groups = regexArray[i].groups;
      switch (patternType) {
        case "start-end":
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'string[]'... Remove this comment to see the full error message
          pattern =
            pattern[0].replace(SPECIAL_CHAR_REGEX, "\\$&") +
            "(?= )(.*?)" +
            pattern[1].replace(SPECIAL_CHAR_REGEX, "\\$&");
          break;
        case "symmetric":
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'string[]'... Remove this comment to see the full error message
          pattern =
            pattern[0].replace(SPECIAL_CHAR_REGEX, "\\$&") + "(.*?)" + pattern[0].replace(SPECIAL_CHAR_REGEX, "\\$&");
          break;
        case "asymmetric":
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'string[]'... Remove this comment to see the full error message
          pattern = pattern[0].replace(SPECIAL_CHAR_REGEX, "\\$&");
          let regexForm = "";
          let p = regexArray[i].pattern[0];
          for (var j = 0; j < p.length; j++) {
            regexForm = regexForm + "\\" + p[j];
          }
          // create all group regex
          let part = regexForm.length / groups;
          let regex = "";
          for (var j = 0; j < groups; j++) {
            let group = regexForm.substring(part * j, part * (j + 1));
            let middleIndex = group.length / 2;
            let firstHalf = group.substring(0, middleIndex);
            let secondHalf = group.substring(middleIndex, group.length);
            let middle = j < middleIndex ? group.substring(0, middleIndex) : group.substring(middleIndex, group.length);
            regex = regex + firstHalf + "([^" + middle + "]+)" + secondHalf;
          }

          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'string[]'... Remove this comment to see the full error message
          pattern = regex;
          break;
        case "custom":
        default:
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'string[]'... Remove this comment to see the full error message
          pattern = pattern[0];
          break;
      }

      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      patterns.push(RegExp(pattern, "ig"));
      styles.push(regexArray[i].styles);
      styleTypes.push(regexArray[i].type);
    }
  };
  const preprocessor = () => {
    // non-matching strings are captured with the help of (?!) negative lookahead
    let unorderedRegExp = new RegExp("[\\n\\r](?!-\\s)(.*)", "igm");
    let orderedRegExp = new RegExp("[\\n\\r](?!\\d.\\s)(.*)", "igm");
    // holds the strings that begin with (\n or \r) that neither match unordered nor ordered patters.
    let matched = [];
    let parsed = unorderedRegExp.exec(text);
    while (parsed !== null) {
      var resultString = parsed[0];
      var matchedOrdered = resultString.match(orderedRegExp);
      if (matchedOrdered) {
        matched.push(parsed);
      }
      parsed = unorderedRegExp.exec(text);
    }
    // slice the new line character and update the text
    matched.forEach((value) => {
      if (text[value.index] === "\n" || text[value.index] === "\r") {
        text = text.slice(0, value.index) + " " + text.slice(value.index + 1);
      }
    });
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  const parseText = (type, styles, pattern) => {
    let t = text;
    let parsed = pattern.exec(t);
    while (parsed !== null) {
      matchedIndices.push(parsed.index);
      let spacesToBeAdded = Math.abs(parsed[0].length - parsed[1].length);
      let spacesStr = "";
      for (var i = 0; i < spacesToBeAdded; i++) {
        spacesStr = spacesStr + " ";
      }
      text = t.replace(parsed[0], parsed[1] + spacesStr);
      matchesStyleTypes.push(type + "Text");
      matchesStyles.push(styles);
      if (type === "bullet") {
        parsed[1] = "\t\u2022\t" + parsed[1];
      } else if (type === "numbered") {
        var regex = /\d+\.\s+/i;
        parsed[1] = parsed[0].replace(regex, "\t$&\t");
      }
      matchesFound.push(parsed);
      parsed = pattern.exec(t);
    }
  };
  const renderText = () => {
    let jsxArray = [];
    let elementJsxArray = [];
    var elementStylesArray = [] as any[];
    let elementLinksArray = [];
    let remainingText = text;
    let processedText = "";
    //arrange matches to process left to right

    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchedIndices' implicitly has an 'any[]... Remove this comment to see the full error message
    let sortedMatchedIndices = [...matchedIndices].sort(function (a, b) {
      return a - b;
    });
    if (matchesFound.length < 1) {
      jsxArray.push(
        <Text key={"text"} style={userStyles} numberOfLines={numberOfLines}>
          {remainingText}
        </Text>
      );
    } else {
      // let lastIdx = -1;
      for (var i = 0; i <= matchedIndices.length - 1; i++) {
        // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
        if (matchesFound[i]) {
          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchedIndices' implicitly has an 'any[]... Remove this comment to see the full error message
          let idx = matchedIndices.indexOf(sortedMatchedIndices[i]);
          //check if previous elementJsxArray is or has the current match

          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchedIndices' implicitly has an 'any[]... Remove this comment to see the full error message
          let lastIdx = matchedIndices.indexOf(sortedMatchedIndices[i - 1]);
          if (i !== 0) {
            if (idx === lastIdx) {
              // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchedIndices' implicitly has an 'any[]... Remove this comment to see the full error message
              idx = findNthIndexOfElement(matchedIndices, sortedMatchedIndices[i], 2);
            }

            // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
            let containsMatch = elementJsxArray[elementJsxArray.length - 1].indexOf(matchesFound[idx][0]);

            // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
            let isMatch = elementJsxArray[elementJsxArray.length - 1] === matchesFound[idx][1];

            // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesStyleTypes' implicitly has an 'an... Remove this comment to see the full error message
            let containsStyle = elementStylesArray[elementStylesArray.length - 1].indexOf(matchesStyleTypes[idx]);
            if ((containsMatch !== -1 && containsStyle === -1) || (isMatch && containsStyle === -1)) {
              // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
              let matchedStr = matchesFound[idx][0];
              let lastElement = elementJsxArray.pop();
              let modifiedElement = lastElement.replace(matchedStr, "");
              let dividedElements = splitValue(lastElement, lastElement.indexOf(matchedStr), matchedStr);

              let lastElementStyles = elementStylesArray.pop();
              if (modifiedElement !== "" && !isMatch) {
                elementJsxArray.push(dividedElements[0]);
                elementStylesArray.push(lastElementStyles);
                if (lastElementStyles.indexOf("hyperlinkText") !== -1) {
                  // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
                  elementLinksArray.push(matchesFound[lastIdx][2]);
                } else {
                  elementLinksArray.push(null);
                }
              }

              // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
              elementJsxArray.push(matchesFound[idx][1]);

              // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesStyleTypes' implicitly has an 'an... Remove this comment to see the full error message
              let elementStyle = [matchesStyleTypes[idx]];

              // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesStyles' implicitly has an 'any[]'... Remove this comment to see the full error message
              elementStyle = elementStyle.concat(matchesStyles[idx]);
              elementStylesArray.push(elementStyle.concat(lastElementStyles));
              if (dividedElements.length > 1) {
                elementJsxArray.push(dividedElements[1]);
                elementStylesArray.push(lastElementStyles);
                if (lastElementStyles.indexOf("hyperlinkText") !== -1) {
                  // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
                  elementLinksArray.push(matchesFound[lastIdx][2]);
                } else {
                  elementLinksArray.push(null);
                }
              }
              continue;
            }
          }
          // string before match

          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
          let uptoIdx = matchesFound[idx].index - processedText.length;
          if (uptoIdx < 0) {
            continue;
          }
          let beforeMatchStr = remainingText.substring(0, uptoIdx);
          elementJsxArray.push(beforeMatchStr);
          elementStylesArray.push([null]);
          elementLinksArray.push(null);
          // matched string

          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesStyleTypes' implicitly has an 'an... Remove this comment to see the full error message
          if (matchesStyleTypes[idx] === "numberedText") {
            // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
            elementJsxArray.push(matchesFound[idx][1]);
          } else {
            // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
            elementJsxArray.push(matchesFound[idx][1]);
          }

          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesStyleTypes' implicitly has an 'an... Remove this comment to see the full error message
          elementStylesArray.push([matchesStyleTypes[idx]].concat(matchesStyles[idx]));

          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesStyleTypes' implicitly has an 'an... Remove this comment to see the full error message
          if (matchesStyleTypes[idx] === "hyperlinkText") {
            // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
            elementLinksArray.push(matchesFound[idx][2]);
          } else {
            elementLinksArray.push(null);
          }

          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
          let fromIdx = matchesFound[idx].index + matchesFound[idx][0].length - processedText.length;
          remainingText = remainingText.substring(fromIdx, remainingText.length);

          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'matchesFound' implicitly has an 'any[]' ... Remove this comment to see the full error message
          processedText = processedText + beforeMatchStr + matchesFound[idx][0];
        }
      }
      elementJsxArray.push(remainingText);
      elementStylesArray.push([null]);
      elementLinksArray.push(null);
      jsxArray.push(createJsx(elementJsxArray, elementStylesArray, elementLinksArray));
    }
    return jsxArray;
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'elementJsxArray' implicitly has an 'any... Remove this comment to see the full error message
  const createJsx = (elementJsxArray, elementStylesArray, elementLinksArray) => {
    // create jsx element

    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'tempJSX' implicitly has type 'any[]' in ... Remove this comment to see the full error message
    var tempJSX = [];

    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'partialJsx' implicitly has type 'any[]' ... Remove this comment to see the full error message
    var partialJsx = [];

    // @ts-expect-error ts-migrate(7034) FIXME: Variable 'WrapJsx' implicitly has type 'any[]' in ... Remove this comment to see the full error message
    var WrapJsx = [];
    var fullJsx = [];

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'eachWord' implicitly has an 'any' type.
    elementJsxArray.map((eachWord, index) => {
      let key = "text_" + index;
      if (
        elementStylesArray[index].indexOf("bulletText") !== -1 ||
        elementStylesArray[index].indexOf("numberedText") !== -1
      ) {
        tempJSX.push(
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          <Text key={"list_item_" + index} style={[userStyles.concat(elementStylesArray[index])]}>
            {eachWord}
          </Text>
        );
      } else {
        tempJSX.push(
          <Text key={key} style={[elementStylesArray[index]]} onPress={() => addOnPress(elementLinksArray[index])}>
            {eachWord}
          </Text>
        );
      }
    });

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'eachWord' implicitly has an 'any' type.
    elementJsxArray.map((eachWord, index) => {
      let key = "text__" + index;
      if (elementStylesArray[index].indexOf("bulletText") !== -1) {
        if (WrapJsx.length !== 0) {
          fullJsx.push(
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            <Text key={key + WrapJsx.length + "wrap_list"} style={this.userStyles}>
              {/* @ts-expect-error ts-migrate(7005) FIXME: Variable 'WrapJsx' implicitly has an 'any[]' type. */}
              {WrapJsx}
            </Text>
          );
          WrapJsx = [];
        }

        // @ts-expect-error ts-migrate(7005) FIXME: Variable 'tempJSX' implicitly has an 'any[]' type.
        partialJsx.push(tempJSX[index]);
      } else {
        if (partialJsx.length !== 0) {
          fullJsx.push(
            <Text key={key + "_list"} style={userStyles}>
              {/* @ts-expect-error ts-migrate(7005) FIXME: Variable 'partialJsx' implicitly has an 'any[]' ty... Remove this comment to see the full error message */}
              <Text key={key + partialJsx.length + "_list"}>{partialJsx}</Text>
            </Text>
          );
          partialJsx = [];
        }
        if (eachWord.trim() != "")
          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'tempJSX' implicitly has an 'any[]' type.
          WrapJsx.push(tempJSX[index]);
      }
    });
    if (fullJsx.length === 0) {
      // @ts-expect-error ts-migrate(2740) FIXME: Type 'Element' is missing the following properties... Remove this comment to see the full error message
      fullJsx = (
        <Text key={"text_" + fullJsx.length + tempJSX.length} style={userStyles} numberOfLines={numberOfLines}>
          {/* @ts-expect-error ts-migrate(7005) FIXME: Variable 'tempJSX' implicitly has an 'any[]' type. */}
          {tempJSX}
        </Text>
      );
    } else if (WrapJsx.length !== 0) {
      fullJsx.push(
        <Text key={"text_" + fullJsx.length + WrapJsx.length} style={userStyles} numberOfLines={numberOfLines}>
          {/* @ts-expect-error ts-migrate(7005) FIXME: Variable 'WrapJsx' implicitly has an 'any[]' type. */}
          {WrapJsx}
        </Text>
      );
    }
    return fullJsx;
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
  const addOnPress = (url) => {
    if (!url) {
      //Check for rich-text-block textRun action
      if ((props as any).onClick) {
        (props as any).onClick();
      }
      return null;
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'canOpenURL' does not exist on type '(pro... Remove this comment to see the full error message
      Linking.canOpenURL(url)
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'supported' implicitly has an 'any' type... Remove this comment to see the full error message
        .then((supported) => {
          if (!supported) {
            console.log("Can't handle url: " + url);
            return null;
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'openURL' does not exist on type '(props:... Remove this comment to see the full error message
            return Linking.openURL(url);
          }
        })
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'err' implicitly has an 'any' type.
        .catch((err) => console.error("An error occurred", err));
    }
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'str' implicitly has an 'any' type.
  const splitValue = (str, index, separator) => {
    if (str.indexOf(separator) !== -1) {
      return [str.substring(0, index), str.substring(index + separator.length)];
    } else {
      return [str];
    }
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'arr' implicitly has an 'any' type.
  const findNthIndexOfElement = (arr, element, nthIndex) => {
    var index = -1;
    for (var i = 0, len = arr.length; i < len; i++) {
      if (i in arr && element === arr[i] && !--nthIndex) {
        index = i;
        break;
      }
    }
    return index;
  };
  init();
  preprocessor();
  for (var i = 0; i <= styleTypes.length - 1; i++) {
    // @ts-expect-error ts-migrate(7005) FIXME: Variable 'styleTypes' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    parseText(styleTypes[i], styles[i], patterns[i]);
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
  return renderText(text);
};
export default MarkdownFormatter;
const styles = StyleSheet.create({
  hyperlinkText: {
    color: "blue",
    textDecorationLine: "underline",
  },
  boldText: {
    fontWeight: "bold",
  },
  italicText: {
    fontStyle: "italic",
  },
});
// regex configs for supported markdowns
const MD_FORMATTER_CONFIG = [
  {
    type: "numbered",
    styles: [],
    pattern: ["^\\d+.\\s+(.*)"],
    patternType: "custom",
    groups: 1,
  },
  {
    type: "numbered",
    styles: [],
    pattern: ["[\\r,\\n]\\d+.\\s+(.*)"],
    patternType: "custom",
    groups: 1,
  },
  {
    type: "bullet",
    styles: [],
    pattern: ["^-\\s+(.*)"],
    patternType: "custom",
    groups: 1,
  },
  {
    type: "bullet",
    styles: [],
    pattern: ["[\\n,\\r]-\\s+(.*)"],
    patternType: "custom",
    groups: 1,
  },
  {
    type: "bold",
    styles: [styles.boldText],
    pattern: ["**"],
    patternType: "symmetric",
    groups: 1,
  },
  {
    type: "italic",
    styles: [styles.italicText],
    pattern: ["_"],
    patternType: "symmetric",
    groups: 1,
  },
  {
    type: "hyperlink",
    styles: [styles.hyperlinkText],
    pattern: ["[]()"],
    patternType: "asymmetric",
    groups: 2,
  },
];
