import React from "react";
import { useSelector } from "react-redux";
import { Toggle } from "@fluentui/react";
import { setThemeStyle } from "../../../store/slices/portalSlice";
import { RootState, useAppDispatch } from "../../../store/store";
import { useTranslation } from "react-i18next";

const ThemeStyleToggle = () => {
  const darkMode = useSelector((state: RootState) => state.portal.theme.style === "dark");
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  return (
    <Toggle
      checked={darkMode}
      inlineLabel
      label={t("settings.darkMode")}
      styles={toggleStyles}
      onChange={() => {
        dispatch(setThemeStyle(darkMode ? "light" : "dark"));
      }}
    />
  );
};

const toggleStyles = { root: { justifyContent: "space-between" }, label: { order: "unset", margin: "unset" } };

export default ThemeStyleToggle;
