import { Icon, IIconStyles, Shimmer, ShimmerElementType } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface PluginIconProperties {
  pluginToken?: string;
  size?: number;
}

const PluginIcon = (props: PluginIconProperties) => {
  const { pluginToken, size = 42 } = props;

  const pluginIcon = useSelector((state: RootState) =>
    pluginToken ? state.portal.pluginIcons[pluginToken] : undefined
  );

  if (!pluginIcon) return <Shimmer shimmerElements={[{ type: ShimmerElementType.circle, height: size }]} />;

  const iconStyles: IIconStyles = { root: { width: size, height: size, overflow: "visible", alignSelf: "center" } };

  if (!pluginIcon?.icon) return <Icon iconName={"PlaceholderAppIcon"} styles={iconStyles} />;

  return (
    <Icon
      styles={iconStyles}
      imageProps={{
        src: `data:image/svg+xml;base64,${pluginIcon.icon}`,
        styles: iconStyles,
      }}
    />
  );
};

export default PluginIcon;
