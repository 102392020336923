import React from "react";
import LoginButton from "./LoginButton";

interface OpenIdLoginButtonProperties {
  provider: LoginProvider;
  onClick: Function;
}

function OpenIdLoginButton(props: OpenIdLoginButtonProperties) {
  const { provider, onClick } = props;

  return <LoginButton provider={provider} onClick={onClick} externalProvider />;
}

export default OpenIdLoginButton;
