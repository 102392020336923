import React, { useState } from "react";
import {
  Panel,
  useTheme,
  Text,
  mergeStyleSets,
  Stack,
  IStackTokens,
  MessageBar,
  MessageBarType,
  Icon,
  IPanelStyles,
  mergeStyles,
  IStyle,
  IMessageBarStyles,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { userService } from "../../authorization/userService";
import { setPortalNotificationPaneOpen, setUnReadNotifications } from "../../../store/slices/portalSlice";

const PortalNotificationsPane = () => {
  const isPanelOpen = useSelector((state: RootState) => state.portal.portalNotificationPaneOpen);
  const [t] = useTranslation();
  const [notifications, setNotifications] = useState<Notification[] | null>(null);

  const dispatch = useAppDispatch();

  const onDelete = (id: string, unread: boolean) => {
    userService.deleteNotification(id).then((response) => {
      if (response.success) {
        dispatch(setUnReadNotifications(unread ? -1 : 0));
        const copy = notifications?.filter((n) => n.id !== id);
        if (copy) setNotifications(copy);
      }
    });
  };

  const toogleRead = (id: string, read: boolean) => {
    userService.setNotificationRead(id, read).then((response) => {
      if (response.success) {
        dispatch(setUnReadNotifications(read ? -1 : 1));
      }
    });

    const copy = notifications?.map((n) => {
      if (n.id === id) {
        const c = { ...n };
        c.read = read;
        return c;
      }
      return n;
    });
    if (copy) setNotifications(copy);
  };

  return (
    <>
      <Panel
        isOpen={isPanelOpen}
        onDismiss={() => dispatch(setPortalNotificationPaneOpen(false))}
        isLightDismiss
        allowTouchBodyScroll={true}
        closeButtonAriaLabel={t("buttons.close")}
        headerText={t("menu.notifications")}
        styles={panelStyles}
        onOpen={() => {
          if (!notifications) userService.getNotifications().then((n) => setNotifications(n));
        }}
      >
        <div className={staticClassNames.contentWrapper}>
          <Stack style={{ padding: 0 }} tokens={itemAlignmentsStackTokens}>
            {notifications &&
              notifications.map((n) => {
                return <NotificationCard key={n.id} notification={n} toogleRead={toogleRead} onDelete={onDelete} />;
              })}
          </Stack>
        </div>
      </Panel>
    </>
  );
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export default PortalNotificationsPane;

interface NotificationCardProperties {
  notification: Notification;
  toogleRead: (id: string, read: boolean) => void;
  onDelete: (id: string, unread: boolean) => void;
}

const NotificationCard: React.FC<NotificationCardProperties> = (props) => {
  const { palette, effects } = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const [t] = useTranslation();

  let messageBarType: MessageBarType = MessageBarType.info;
  switch (props.notification.rating) {
    case 1:
      messageBarType = MessageBarType.warning;
      break;
    case 2:
      messageBarType = MessageBarType.severeWarning;
      break;
    case 3:
      messageBarType = MessageBarType.blocked;
      break;
    case 4:
      messageBarType = MessageBarType.error;
      break;
  }

  const renderDetails = () => {
    return (
      <>
        <div onClick={() => setShowDetails(!showDetails)} className={staticClassNames.notificationDetailsWrapper}>
          {props.notification.content ? (
            <Text variant="smallPlus" style={{ flexGrow: 1 }}>
              {showDetails ? t("menu.hideNotificationDetails") : t("menu.showNotificationDetails")}{" "}
              <Icon iconName={showDetails ? "Remove" : "Add"} />
            </Text>
          ) : (
            <div style={{ flexGrow: 1 }} />
          )}
          <Text variant="tiny">{props.notification.code}</Text>
        </div>
        {showDetails && (
          <div>
            <Text>{props.notification.content}</Text>
          </div>
        )}
      </>
    );
  };

  const notificationWrapperClass = mergeStyles([
    {
      border: `1px solid ${palette.themeSecondary}`,
      borderRadius: effects.roundedCorner2,
      padding: 10,
      position: "relative",
    } as IStyle,
    !props.notification.read && { borderLeft: `5px solid ${palette.themePrimary}` },
  ]);

  const messageBarStyles: IMessageBarStyles = {
    root: { borderRadius: effects.roundedCorner2 },
    iconContainer: { alignItems: "center" },
    innerText: { fontSize: 16, fontWeight: 600 },
  };

  return (
    <div
      className={notificationWrapperClass}
      onClick={() => {
        if (!props.notification.read) props.toogleRead(props.notification.id, !props.notification.read);
      }}
    >
      <div style={{ position: "relative" }}>
        <MessageBar messageBarType={messageBarType} styles={messageBarStyles}>
          <div style={{ paddingRight: 40, lineHeight: 1.25 }}>{props.notification.title}</div>
        </MessageBar>
        <Icon
          iconName={props.notification.read ? "Mail" : "Read"}
          style={{ position: "absolute", top: 9, right: 32, cursor: "pointer", fontSize: "12pt" }}
          onClick={(e) => {
            props.toogleRead(props.notification.id, !props.notification.read);
            e.stopPropagation();
          }}
        />
        <Icon
          iconName="Delete"
          style={{ position: "absolute", top: 9, right: 8, cursor: "pointer", fontSize: "12pt" }}
          onClick={() => props.onDelete(props.notification.id, !props.notification.read)}
        />
      </div>
      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Text variant="mediumPlus">{props.notification.tweet}</Text>
      </div>
      {renderDetails()}
    </div>
  );
};

const panelStyles: Partial<IPanelStyles> = {
  scrollableContent: { display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" },
  content: {
    flex: "1 1 auto",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
};

const staticClassNames = mergeStyleSets({
  contentWrapper: { flex: "1 1 auto", overflowY: "auto" },
  notificationDetailsWrapper: { cursor: "pointer", display: "flex", alignItems: "center" },
});

type Notification = {
  id: string;
  code: string;
  title: string;
  tweet: string;
  content: string;
  rating: number;
  read: boolean;
};
