import React from "react";
import { useId } from "@fluentui/react-hooks";
import { constants } from "../../constants";
import { Icon, ContextualMenu, PrimaryButton, Dialog, DialogType, DialogFooter } from "@fluentui/react";
import { useTranslation } from "react-i18next";

interface DialogProperties {
  key: string;
  title: string;
  buttonId?: string;
  dialogId?: string;
  msg?: string;
  type?: string;
  icon: string;
  onClose?: any;
  children?: React.ReactNode;
}

const PortalDialog = (props: DialogProperties) => {
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");
  const [t] = useTranslation();

  const dialogStyles = {
    main: { maxWidth: props.type === "error" ? "500px !important" : 450 },
    layer: { zIndex: constants.zIndex },
  };

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      dragOptions: dragOptions,
      focusTrapZoneProps: {
        ["data-debugid"]: props.dialogId,
      } as any,
    }),
    [labelId, subTextId, props.dialogId]
  );

  const showCloseButton = typeof props.onClose === "function";

  const dialogContentProps = {
    type: DialogType.normal,
    title: (
      <React.Fragment>
        <Icon
          iconName={props.icon}
          style={{ paddingRight: 10 }}
          data-debugid={props.dialogId ? `${props.dialogId}-icon` : undefined}
        />
        <span data-debugid={props.dialogId ? `${props.dialogId}-title` : undefined}>{props.title}</span>
      </React.Fragment>
    ),
    closeButtonAriaLabel: t("buttons.close"),
    subText: props.msg,
    showCloseButton: showCloseButton,
    styles: { subText: { whiteSpace: "pre-wrap" } },
  };

  return (
    <Dialog hidden={false} onDismiss={props.onClose} dialogContentProps={dialogContentProps} modalProps={modalProps}>
      {props.children && <div style={{ minHeight: 40 }}>{props.children}</div>}
      {props.onClose && (
        <DialogFooter>
          <PrimaryButton data-debugid={props.buttonId} onClick={props.onClose} text={t("buttons.ok")} />
        </DialogFooter>
      )}
    </Dialog>
  );
};

export default PortalDialog;

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};
