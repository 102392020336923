import React, { useContext, useState } from "react";
import {

  StyleSheet,
  Image,
} from "../../reactnative";

import { HostConfigManager } from "../../utils/host-config";
import * as Utils from "../../utils/util";
import * as Enums from "../../utils/enums";
import * as Constants from "../../utils/constants";

import { ElementWrapper } from "../elements/ElementWrapper";

import { SelectAction } from "../actions";
import { StyleManager } from "../../styles/style-config";
import { InputContext } from "../../utils/context";

const ContainResizeMode = "contain";
const hostConfig = HostConfigManager.getHostConfig();

// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
const styleConfig = StyleManager.getManager().styles;

export const Img = (props: any) => {

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'addResourceInformation' does not exist o... Remove this comment to see the full error message
  const { addResourceInformation } = useContext(InputContext);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  let altText = undefined;
  let horizontalAlignment = undefined;
  let selectAction = undefined;
  let width = undefined;
  let height = undefined;
  let isSizeUndefined: any = undefined;
  let type = undefined;
  let url: any = undefined;
  let id = undefined;
  let spacing = undefined;
  let separator = undefined;
  let backgroundColor = undefined;

  let payload = props.json;
  addResourceInformation(payload.url, "");

  const parseHostConfig = () => {
    altText = payload.altText || Constants.EmptyString;
    horizontalAlignment = getImageAlignment();
    selectAction = payload.selectAction || null;
    if (Utils.isNullOrEmpty(payload.size)) {
      isSizeUndefined = true;
      payload.size = Constants.Auto;
    }

    type = payload.type || Constants.EmptyString;
    let imageUrl = payload.url || Constants.EmptyString;

    url = Utils.getImageUrl(imageUrl);
    id = payload.id || Constants.EmptyString;
    const spacingValue = Utils.parseHostConfigEnum(Enums.Spacing, payload.spacing, Enums.Spacing.Small);

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    spacing = hostConfig.getEffectiveSpacing(spacingValue);
    separator = payload.separator || false;
    backgroundColor = Utils.hexToRGB(payload.backgroundColor) || Constants.TransparentString;
  };

  const isPersonStyle = () => {
    let styleValue = Utils.parseHostConfigEnum(Enums.ImageStyle, payload.style, Enums.ImageStyle.Default);
    return parseInt(styleValue, 10) === 0 ? false : true;
  };

  const getImageAlignment = () => {
    let imageAlignmentStyle = [];

    switch (payload.horizontalAlignment) {
      case Constants.CenterString:
        imageAlignmentStyle.push(styles.centerAlignment);
        break;
      case Constants.RightString:
        imageAlignmentStyle.push(styles.rightAlignment);
        break;
      default:
        imageAlignmentStyle.push(styles.leftAlignment);
        break;
    }
    return imageAlignmentStyle;
  };

  const applySize = () => {
    let sizeStyle = [];
    let sizeValue = Utils.parseHostConfigEnum(Enums.Size, payload.size, Enums.Size.Auto);
    const w = payload.width;
    const h = payload.height;
    /**
     * Scenario 1 : Either height or width has string value (Ex: '80px'),
     *               use the integer portion.
     * Scenario 2 : If the height or width has string value (Ex: 'stretch'),
     *              ignore and use the size property to determine dimensions.
     * Scenario 3 : If either width or height is missing, apply the given value to the
     *              other property.
     */
    if (Utils.isaNumber(w) || Utils.isaNumber(h)) {
      width = Utils.getSize(w) || imageWidth;
      height = Utils.getSize(h) || getHeight(width);
      if (width === 0) {
        width = "auto";
      }
      if (height === 0) {
        height = "auto";
      }
      sizeStyle.push({ width: width, height: height });
    } else {
      switch (sizeValue) {
        case 1: {
          sizeStyle.push([
            styles.imageStretch,
            {
              width: imageWidth,
              height: imageHeight,
            },
          ]);
          break;
        }
        case 2: {
          sizeStyle.push({

            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            width: hostConfig.imageSizes.small,

            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            height: hostConfig.imageSizes.small,
          });

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          width = hostConfig.imageSizes.small;
          break;
        }
        case 3: {

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          sizeStyle.push({ width: hostConfig.imageSizes.medium, height: hostConfig.imageSizes.medium });
          const spacingValue = Utils.parseHostConfigEnum(Enums.Spacing, payload.spacing, Enums.Spacing.Medium);

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          spacing = hostConfig.getEffectiveSpacing(spacingValue);

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          width = hostConfig.imageSizes.medium;
          break;
        }
        case 4: {

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          sizeStyle.push({ width: hostConfig.imageSizes.large, height: hostConfig.imageSizes.large });
          const spacingValue = Utils.parseHostConfigEnum(Enums.Spacing, payload.spacing, Enums.Spacing.Large);

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          spacing = hostConfig.getEffectiveSpacing(spacingValue);

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          width = hostConfig.imageSizes.large;
          break;
        }
        default: {
          /**
           * When the images are rendered via imageset and if the size is undefined or Auto,
           * the size of the image is taken as medium as default as per native iOS renderer.
           */
          sizeStyle.push(styles.imageAuto);
          if ((isSizeUndefined && payload.fromImageSet == true) || payload.fromImageSet == true) {
            const spacingValue = Utils.parseHostConfigEnum(Enums.Spacing, payload.spacing, Enums.Spacing.Medium);

            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            spacing = hostConfig.getEffectiveSpacing(spacingValue);
            sizeStyle.push({

              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              width: hostConfig.imageSizes.medium,

              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              height: hostConfig.imageSizes.medium,
            });

            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            width = hostConfig.imageSizes.medium;
          } else {
            sizeStyle.push({ width: imageWidth, height: imageHeight });
            width = imageWidth;
          }
          break;
        }
      }
    }
    return sizeStyle;
  };

  const getHeight = (width: any) => {
    let widthToHeightRatio = imageHeight / imageWidth || 1;
    if (isPersonStyle() || (props.columnWidth && props.columnWidth != Constants.Auto)) return width;
    else return width * widthToHeightRatio;
  };

  const getWidth = (layoutWidth: any, imageWidth: any) => {
    if (isPersonStyle() || (props.columnWidth && props.columnWidth != Constants.Auto)) return layoutWidth;
    else return imageWidth;
  };

  const getSize = (url: any, callback: any) => {
    var img = document.createElement("img");
    img.addEventListener("load", function () {
      callback(this.naturalWidth, this.naturalHeight);
    });
    img.src = url;
  };

  const onPageLayoutHandler = (event: any) => {
    const { width: layoutWidth } = event.nativeEvent.layout;
    //This function is implemented to determine the actual dimensions of the component.
    getSize(
      url,
      (width: any, height: any) => {
        /**
         * If the payload contains "fromImageset" i.e(if the image is rendered via ImageSet),
         * the height and width of the image is set to maxImageHeight for sizes "auto" and "stretch"
         */

        if (
          payload.fromImageSet == true &&
          (payload.size === Constants.Auto || payload.size === Constants.AlignStretch)
        ) {

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          setImageWidth(hostConfig.imageSet.maxImageHeight);

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          setImageHeight(hostConfig.imageSet.maxImageHeight);

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          width = payload.width || hostConfig.imageSet.maxImageHeight;

          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          height = payload.height || hostConfig.imageSet.maxImageHeight;
        } else {
          const imageWidth = getWidth(layoutWidth, width);
          const widthToHeightRatio = height / width;
          const imageHeight = widthToHeightRatio * imageWidth;

          setImageHeight(imageHeight);
          setImageWidth(imageWidth);
          width = payload.width || imageWidth;
          height = payload.height || imageHeight;
        }

      },
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
      (error: any) => {
        console.log(`Couldn't get the image size: ${error.message}`);
      }
    );
  };

  parseHostConfig();

  if (!type || !Utils.isValidImageURI(payload.url)) {
    return null;
  }

  let imageComputedStyle = applySize();
  imageComputedStyle.push({ backgroundColor: backgroundColor });
  let wrapperComputedStyle = horizontalAlignment;

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  wrapperComputedStyle.push({ backgroundColor: "transparent" });

  if (payload.fromImageSet == true) {

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    wrapperComputedStyle.push({ margin: spacing });
  }

  /**
   * If the payload size is "auto" or "stretch" and
   * if the payload does not contain explicit width and height, computing the border radius
   * from the state variable "imageWidth" which is determined using Image.getSize()
   */
  if (
    (payload.size === Constants.Auto || payload.size === Constants.AlignStretch) &&
    !(payload.width || payload.height)
  ) {
    if (isPersonStyle()) {
      imageComputedStyle.push({ borderRadius: imageWidth / 2 });
    }
  } else {
    if (isPersonStyle()) {

      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      imageComputedStyle.push({ borderRadius: width / 2 });
    }
  }

  let imageUrl = Utils.getImageUrl(url);

  let containerContent = (
    <ElementWrapper
      json={payload}
      isFirst={props.isFirst}
      style={wrapperComputedStyle}
      onPageLayout={onPageLayoutHandler}
    >
      <Image isBackgroundImage={props.isBackgroundImage} style={imageComputedStyle} source={{ uri: imageUrl }} />
    </ElementWrapper>
  );

  if (
    payload.selectAction === undefined ||

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    HostConfigManager.getHostConfig().supportsInteractivity === false
  ) {
    return containerContent;
  } else {
    return <SelectAction selectActionData={payload.selectAction}>{containerContent}</SelectAction>;
  }
};

const styles = StyleSheet.create({
  leftAlignment: {
    alignItems: Constants.FlexStart,
  },
  centerAlignment: {
    alignItems: Constants.CenterString,
  },
  rightAlignment: {
    alignItems: Constants.FlexEnd,
  },
  image: {
    marginTop: 15,
  },
  imageStretch: {
    alignSelf: Constants.AlignStretch,
    resizeMode: Constants.AlignStretch,
  },
  imageAuto: {
    alignSelf: Constants.CenterString,
    resizeMode: ContainResizeMode,
  },
});
