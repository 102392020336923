import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { concatStyleSets, Icon, IContextualMenuItem, Text, useTheme } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalization/globalization";
import {
  companyOpened,
  resetState,
  setPlugin,
  setPluginIcons,
  setShowTenantDropdown,
  unloadTenantScriptBundles,
} from "../../store/slices/portalSlice";
import { RootState, useAppDispatch } from "../../store/store";
import { TenantLoginDto } from "../../types/commonTypes";
import { authenticationService } from "../authorization/authenticationService";
import { tenantService } from "../authorization/tenantService";
import { userService } from "../authorization/userService";
import FilterableDropdown from "./FilterableDropdown";
import FilterableDropdownItem from "./FilterableDropdownItem";

const TenantPickerDropdown = () => {
  const user = authenticationService.currentUserValue;

  const dispatch = useAppDispatch();
  const visible = useSelector((state: RootState) => state.portal.showTenantDropdown);
  const setVisible = (value: boolean) => dispatch(setShowTenantDropdown(value));

  const [tenants, setTenants] = useState<TenantLoginDto[]>([]);

  const getTenants = async () => {
    const response = await tenantService.getTenants();
    setTenants(response?.tenants ?? []);
  };

  useEffect(() => {
    if (visible || tenants.length === 0) {
      getTenants();
    }
  }, [visible]);

  const { semanticColors } = useTheme();
  const [t] = useTranslation();

  const handleChangeTenant = (tenantId: number, isDefault: boolean) => {
    dispatch(unloadTenantScriptBundles());
    dispatch(resetState());
    userService.changeCompany(tenantId, isDefault).then((response) => {
      authenticationService.changeTenant(tenantId, response.tenantCaption);
      dispatch(companyOpened(response.changeCompanyResult));
      dispatch(setPlugin(undefined));
      i18n.changeLanguage(response.changeCompanyResult.language);

      tenantService.getPluginIcons().then((response) => {
        dispatch(setPluginIcons(response.pluginIcons));
      });
    });
  };

  const dropdownTitle = (
    <>
      <Text variant="large" styles={titleTextStyles}>
        {t("dialogs.tenantPicker.changeTenant")}
      </Text>
      <Text
        variant="large"
        styles={concatStyleSets(titleTextStyles, { root: { color: semanticColors.disabledBodySubtext } })}
      >
        {" (Alt + M)"}
      </Text>
    </>
  );

  const items = tenants.map((t) => ({
    key: t.tenantId as any,
    text: t.caption,
    secondaryText: t.description,
    checked: t.isDefault,
    onRender: renderTenantItem,
    refreshData: getTenants,
    onChangeTenant: handleChangeTenant,
  }));

  return (
    <FilterableDropdown
      buttonText={user.tenantName}
      dropdownTitle={dropdownTitle}
      items={items}
      visible={visible}
      setVisible={setVisible}
      allowDropdown={tenants.length > 1}
      debugId={"ChangeTenant"}
    />
  );
};

export default TenantPickerDropdown;

const titleTextStyles = { root: { fontWeight: "500", userSelect: "none" } };

type TenantContextualMenuItem = IContextualMenuItem & {
  refreshData: () => void;
  onChangeTenant: (tenantId: number, isDefault: boolean) => void;
};

const renderTenantItem = (item: TenantContextualMenuItem, dismissMenu: () => void) => {
  return <TenantItem item={item} dismissMenu={dismissMenu} />;
};

const TenantItem = (props: { item: TenantContextualMenuItem; dismissMenu: () => void }) => {
  const { item, dismissMenu } = props;
  const [t] = useTranslation();

  const mainInfoComponent = (
    <>
      <Text variant="mediumPlus" block styles={tenantCaptionTextStyles}>
        {item.text}
      </Text>
      <Text variant="smallPlus" block>
        {item.secondaryText}
      </Text>
    </>
  );

  const handleClick = () => {
    item.onChangeTenant(item.key as any, !!item.checked);
    dismissMenu();
  };

  const secondaryIconProps = {
    iconName: item.checked ? "HomeSolid" : "Home",
    title: t("dialogs.tenantPicker.setAsDefaultTenant"),
  };
  const secondaryIconHandleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!item.checked) {
      await userService.setFavoriteTenant(item.key as any);
      item.refreshData();
    }
  };

  return (
    <FilterableDropdownItem
      mainInfoComponent={mainInfoComponent}
      iconComponent={iconComponent}
      onClick={handleClick}
      secondaryButtonIconProps={secondaryIconProps}
      secondaryButtonOnClick={secondaryIconHandleClick}
      debugId={item.text}
    />
  );
};

const tenantCaptionTextStyles = { root: { fontWeight: "500" } };

const tenantIconStyles = { root: { height: 32, width: 32, fontSize: 32, display: "block" } };
const iconComponent = <Icon iconName="CityNext" styles={tenantIconStyles} />;
