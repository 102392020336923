export interface SystemIndicatorSettings {
  testSystem: boolean;
  tenantType: TenantType;
  showBorder: boolean;
  text: string;
  style: SystemIndicatorStyle;
}

export enum TenantType {
  Production = 0,
  Test = 1,
  Dev = 2,
  Demo = 3,
}

export enum SystemIndicatorStyle {
  None = 0,
  Attention = 10,
  Info = 20,
  InfoGradient = 30,
}

export interface VersionDto {
  productVersion: string;
}

export interface TenantLoginDto {
  tenantId: number;
  isDefault: boolean;
  caption: string;
  description: string;
}

export interface UserProfileDto {
  profileId: number;
  code: string;
  description: string;
  isDefault: boolean;
}

export interface PluginDto {
  caption: string;
  icon: string;
  id: string;
  area: string;
}

export interface ChangeProfileResultDto {
  profileId: number;
  profileName: string;
  plugins: {
    [key: string]: PluginDto;
  };
}

export type SearchResultDto = {
  pluginToken: string;
  linkId: string;
  icon: string;
  description: string;
};

export type PluginSearchResultDto = {
  pluginName: string;
  pluginId: string;
  icon: string;
  results: SearchResultDto[];
};
