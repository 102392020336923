import React, { useCallback, useReducer, useState } from "react";
import { Icon, mergeStyleSets, Panel, PrimaryButton, Text, useTheme } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { userService } from "../../authorization/userService";
import { RootState, useAppDispatch } from "../../../store/store";
import { setChangePasswordPanelOpen, showDialog, setPasswordChanged } from "../../../store/slices/portalSlice";
import ChangePasswordField from "./ChangePasswordField";
import ChangePasswordCriteria from "./ChangePasswordCriteria";
import { useSelector } from "react-redux";

const ChangePasswordPanel = () => {
  const isPanelOpen = useSelector((state: RootState) => state.portal.changePasswordPanelOpen);
  const isInitialChange = useSelector((state: RootState) => state.portal.changePassword);
  const [globalError, setGlobalError] = useState("");
  const dispatch = useAppDispatch();

  const checkOldPassword = useCallback((password: string) => {
    if (!password) return t("dialogs.changePasswordPanel.oldPasswordMustNotBeEmpty");

    return "";
  }, []);

  const checkNewPassword = useCallback((oldPassword: string, password: string) => {
    if (!password) return t("dialogs.changePasswordPanel.newPasswordMustNotBeEmpty");
    if (oldPassword === password) return t("dialogs.changePasswordPanel.newPasswordMustDifferFromOldOne");

    return "";
  }, []);

  const checkNewPasswordRepeat = useCallback((password: string, repeat: string) => {
    if (password !== repeat) return t("dialogs.changePasswordPanel.newPasswordsDoNotMatch");

    return "";
  }, []);

  const reducer = (state: PasswordState, action: PasswordAction): PasswordState => {
    let newState: PasswordState;
    let hasErrors: boolean;

    switch (action.type) {
      case "setOldPassword":
        newState = {
          ...state,
          oldPassword: { value: action.value, error: checkOldPassword(action.value) },
        };
        hasErrors = newState.oldPassword.error + newState.newPassword.error + newState.repeatPassword.error !== "";

        return { ...newState, hasErrors };
      case "setNewPassword":
        newState = {
          ...state,
          newPassword: { value: action.value, error: checkNewPassword(state.oldPassword.value, action.value) },
          repeatPassword: {
            value: state.repeatPassword.value,
            error: checkNewPasswordRepeat(action.value, state.repeatPassword.value),
          },
        };
        hasErrors = newState.oldPassword.error + newState.newPassword.error + newState.repeatPassword.error !== "";

        return { ...newState, hasErrors };
      case "setRepeatPassword":
        newState = {
          ...state,
          repeatPassword: { value: action.value, error: checkNewPasswordRepeat(state.newPassword.value, action.value) },
        };
        hasErrors = newState.oldPassword.error + newState.newPassword.error + newState.repeatPassword.error !== "";

        return { ...newState, hasErrors };
      case "reset":
        return initialPasswordState;
    }
  };

  const [passwordState, passwordDispatch] = useReducer(reducer, initialPasswordState);

  const [t] = useTranslation();
  const { semanticColors } = useTheme();

  const dismissPanel = useCallback(() => {
    passwordDispatch({ type: "reset" });
    setGlobalError("");
    dispatch(setChangePasswordPanelOpen(false));
  }, []);

  const changePassword = () => {
    setGlobalError("");

    userService.changePassword(passwordState.oldPassword.value, passwordState.newPassword.value).then((result) => {
      if (!result.success) {
        setGlobalError(result.error);
      } else if (result.success) {
        dispatch(setPasswordChanged({}));
        dispatch(
          showDialog({
            type: "msg",
            id: "passwordChangedMessage",
            title: t("dialogs.changePasswordPanel.changeSuccessful"),
            msg: t("dialogs.changePasswordPanel.passwordChangedSuccessfully"),
          })
        );
        dismissPanel();
      }
    });
  };

  const onRenderFooterContent = () => (
    <PrimaryButton
      data-debugid="changePasswordButton"
      onClick={changePassword}
      text={t("menu.changePassword")}
      disabled={
        passwordState.hasErrors ||
        !passwordState.oldPassword.value ||
        !passwordState.newPassword.value ||
        !passwordState.repeatPassword.value
      }
    />
  );

  return (
    <Panel
      isOpen={isPanelOpen}
      headerText={t("menu.changePassword")}
      closeButtonAriaLabel={t("buttons.close")}
      onDismiss={isInitialChange ? undefined : dismissPanel}
      hasCloseButton={!isInitialChange}
      onRenderFooterContent={onRenderFooterContent}
      styles={panelStyles}
    >
      <div className={classNames.passwordWrapper}>
        <ChangePasswordField
          type="Old"
          label={t("dialogs.changePasswordPanel.oldPassword")}
          password={passwordState.oldPassword}
          dispatch={passwordDispatch}
        />
      </div>
      <div className={classNames.passwordWrapper}>
        <ChangePasswordField
          type="New"
          label={t("dialogs.changePasswordPanel.newPassword")}
          password={passwordState.newPassword}
          dispatch={passwordDispatch}
        />
        <ChangePasswordField
          type="Repeat"
          label={t("dialogs.changePasswordPanel.repeatNewPassword")}
          password={passwordState.repeatPassword}
          dispatch={passwordDispatch}
        />
      </div>

      <ChangePasswordCriteria password={passwordState.newPassword.value} />

      {globalError && (
        <div className={classNames.errorWrapper}>
          <Icon
            styles={{ root: { color: semanticColors.errorText, marginRight: "0.5em", fontWeight: "bold" } }}
            iconName="Error"
          />
          <Text styles={{ root: { color: semanticColors.errorText, fontWeight: 500 } }}>{globalError}</Text>
        </div>
      )}
    </Panel>
  );
};

export default ChangePasswordPanel;

type PasswordState = {
  oldPassword: {
    value: string;
    error: string;
  };
  newPassword: {
    value: string;
    error: string;
  };
  repeatPassword: {
    value: string;
    error: string;
  };
  hasErrors: boolean;
};

type PasswordAction =
  | { type: "setOldPassword"; value: string }
  | { type: "setNewPassword"; value: string }
  | { type: "setRepeatPassword"; value: string }
  | { type: "reset" };

const initialPasswordState: PasswordState = {
  oldPassword: { value: "", error: "" },
  newPassword: { value: "", error: "" },
  repeatPassword: { value: "", error: "" },
  hasErrors: false,
};
const panelStyles = { scrollableContent: { flex: "1 1 auto" } };

const classNames = mergeStyleSets({
  passwordWrapper: {
    marginTop: "1em",
  },
  errorWrapper: {
    display: "flex",
    alignItems: "center",
  },
});
