import React from "react";
import ReactDOM from "react-dom";
import { mergeStyles, IconButton, DefaultButton, Text, useTheme } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { constants } from "../../constants";
import useFluentDefaultLayerHost from "../../hooks/useFluentDefaultLayerHost";

const Modal = (props: any) => {
  const { palette } = useTheme();
  const [t] = useTranslation();
  const layerHost = useFluentDefaultLayerHost();

  const modalStyle = mergeStyles({
    position: "fixed",
    backgroundColor: palette.neutralLighter,
    display: "flex",
    flexDirection: "column",
    padding: 10,
  });

  const modal = (
    <div className={overlayStyle}>
      <div className={modalStyle} style={props.style}>
        <div>
          <Text variant="xLarge" className={buttonStyle}>
            {props.title}
          </Text>
          <IconButton
            className={floatRight}
            iconProps={cancelIcon}
            title={t("buttons.cancel")}
            onClick={props.onClose}
            ariaLabel={t("buttons.cancel")}
          />
        </div>
        {props.children}
        <div style={{ marginTop: 10 }}>
          <DefaultButton style={{ float: "right" }} text={t("buttons.close")} onClick={props.onClose} />
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, layerHost);
};

export default Modal;

const overlayStyle = mergeStyles({
  zIndex: constants.zIndex - 10,
  position: "fixed",
  inset: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
});

const buttonStyle = mergeStyles({
  margin: 10,
});

const floatRight = mergeStyles({
  float: "right",
});

const cancelIcon = { iconName: "Cancel" };
