import React, { useState } from "react";
import { TextField, Text, PrimaryButton, cssColor, ActionButton, Modal, mergeStyleSets } from "@fluentui/react";
import { authenticationService } from "../authenticationService";
import PortalDialog from "../../dialogs/PortalDialog";
import { useNavigate } from "react-router-dom";

interface UserNamePasswordLoginProperties {
  provider: LoginProvider;
  onLogin: (providerId: number, username: string, password: string) => void;
  usernamePlaceholder?: string;
  passwordPlaceholder?: string;
  canResetPassword: boolean;
  additionalChecks?: (username: string, password: string) => { userNameError?: string; passwordError?: string };
}

const UserNamePasswordLogin = (props: UserNamePasswordLoginProperties) => {
  const { provider } = props;

  const [userName, setUserName] = useState("");
  const [pwd, setPwd] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [pwdError, setPwdError] = useState("");
  const navigate = useNavigate();

  const baseColor = cssColor(provider.color) ?? { r: 162, g: 0, b: 103 };
  const buttonColor = `rgb(${baseColor.r},${baseColor.g},${baseColor.b})`;
  const buttonColorDarker = `rgba(${baseColor.r},${baseColor.g},${baseColor.b},0.5)`;
  const buttonColorDark = `rgba(${baseColor.r},${baseColor.g},${baseColor.b},0.2)`;

  const changeUserName = (e: any, v: any) => {
    if (v.length > 0 && userNameError !== "") {
      setUserNameError("");
    }
    setUserName(v);
  };

  const changePwd = (e: any, v: any) => {
    if (v.length > 0 && pwdError !== "") {
      setPwdError("");
    }
    setPwd(v);
  };

  const onKeyDown = (e: any, id: any) => {
    if (e.which == 13 || e.keyCode == 13) {
      login();
    }
  };

  const login = () => {
    let hasError = false;
    if (userName.length === 0) {
      setUserNameError("Bitte geben Sie einen Benutzername ein.");
      hasError = true;
    }
    if (pwd.length === 0) {
      setPwdError("Bitte geben Sie ein Kennwort an.");
      hasError = true;
    }

    if (props.additionalChecks) {
      var result = props.additionalChecks(userName, pwd);
      if (result.userNameError) {
        setUserNameError(result.userNameError);
        hasError = true;
      }
      if (result.passwordError) {
        setPwdError(result.passwordError);
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }

    props.onLogin(provider.id, userName, pwd);
  };

  const textFieldStyle = {
    root: {
      width: "100%",
      marginBottom: 20,
    },
    fieldGroup: {
      backgroundColor: buttonColorDark,
      borderColor: buttonColor,
      color: "white",
      ":hover": {
        borderColor: "white",
      },
      ":after": {
        borderColor: buttonColor,
      },
    },
    field: {
      color: "white",
      "::placeholder": {
        color: "white",
      },
    },
    subComponentStyles: {
      label: {
        root: {
          color: "white",
          fontSize: 18,
        },
      },
    },
    revealButton: {
      ":hover i": {
        color: "rgb(0, 14, 26)",
      },
    },
    revealIcon: {
      color: "white",
    },
  };

  const buttonStyles = {
    root: {
      width: "100%",
      backgroundColor: buttonColor,
      borderColor: buttonColor,
      color: "white",
    },
    rootHovered: {
      backgroundColor: buttonColorDarker,
      borderColor: buttonColor,
    },
    rootPressed: {
      backgroundColor: buttonColorDark,
      borderColor: buttonColor,
    },
  };

  const actionButtonStyles = {
    root: {
      width: "auto",
      color: "white",
      paddingLeft: 0,
    },
    rootHovered: {
      color: buttonColor,
    },
    rootPressed: {
      color: buttonColor,
    },
  };

  return (
    <React.Fragment>
      <TextField
        styles={textFieldStyle}
        label="Benutzername"
        required
        onChange={changeUserName}
        errorMessage={userNameError}
        value={userName}
        placeholder={props.usernamePlaceholder}
        data-debugid="username"
      />
      <TextField
        label="Kennwort"
        required
        styles={textFieldStyle}
        type="password"
        placeholder={props.passwordPlaceholder}
        canRevealPassword
        onChange={changePwd}
        errorMessage={pwdError}
        value={pwd}
        data-debugid="password"
        onKeyDown={(e) => onKeyDown(e, provider.id)}
      />
      <PrimaryButton
        styles={buttonStyles}
        data-debugid="loginButton"
        text={"Anmelden"}
        onClick={() => login()}
        allowDisabledFocus
      />
      {props.canResetPassword && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text style={{ color: "white" }} variant="medium">
            Sie haben Ihr
          </Text>
          <ActionButton styles={actionButtonStyles} onClick={() => navigate("/resetpassword")}>
            Kennwort vergessen?
          </ActionButton>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserNamePasswordLogin;
