import React from "react";
import { useId } from "@fluentui/react-hooks";
import { Icon, ContextualMenu, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeDialog } from "../../store/slices/portalSlice";
import { RootState } from "../../store/store";
import dispatchEvent, { eventsToDispatch } from "../../utils/events";
import { constants } from "../../constants";

const ConfirmDialog = () => {
  const confirms = useSelector((state: RootState) => state.portal.confirmDialogs);

  if (confirms.length === 0) return null;

  const renderConfirms = () => {
    return confirms.map((confirm, index) => {
      return (
        <InternalConfirmDialog key={index} id={confirm.id} title={confirm.title} index={index} msg={confirm.msg} />
      );
    });
  };
  return <>{renderConfirms()}</>;
};

export default ConfirmDialog;

const InternalConfirmDialog = (props: { id: string; title: string; index: number; msg: string }) => {
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      dragOptions: dragOptions,
      focusTrapZoneProps: {
        ["data-debugid"]: `confirm-${props.index + 1}`,
      } as any,
    }),
    [labelId, subTextId, props.index]
  );

  const toggleConfirmClick = (r: boolean) => {
    dispatch(closeDialog({ type: "confirm", id: props.id }));

    window.portalInterface.confirm(props.id, r).then((result: any) => {
      dispatchEvent(eventsToDispatch.PLUGIN_REFRESH_PAGEDATA, {
        data: result,
      });
    });
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: (
      <>
        <Icon
          iconName="WaitlistConfirm"
          style={{ paddingRight: 10 }}
          data-debugid={`confirm-${props.index + 1}-icon`}
        />
        <span data-debugid={`confirm-${props.index + 1}-title`}>{props.title}</span>
      </>
    ),
    closeButtonAriaLabel: t("buttons.close"),
    subText: props.msg,
    styles: { subText: { whiteSpace: "pre-wrap" } },
  };
  return (
    <Dialog
      key={`CONFIRMDIALOG_${props.index}`}
      hidden={false}
      onDismiss={() => toggleConfirmClick(false)}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <DialogFooter>
        <PrimaryButton data-debugid="confirmOk" onClick={() => toggleConfirmClick(true)} text={t("buttons.yes")} />
        <DefaultButton data-debugid="confirmCancel" onClick={() => toggleConfirmClick(false)} text={t("buttons.no")} />
      </DialogFooter>
    </Dialog>
  );
};

const dialogStyles = {
  main: { maxWidth: 450 },
  layer: { zIndex: constants.zIndex },
};

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};
