import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Label,
  Panel,
  TextField,
  Text,
  mergeStyleSets,
  CommandBarButton,
  IconButton,
  TooltipHost,
  ITooltipHostStyles,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { setCustomTheme, setPortalSettingsPaneOpen } from "../../../store/slices/portalSlice";
import { RootState, useAppDispatch } from "../../../store/store";
import ThemeColorPicker from "./ThemeColorPicker";
import ThemeStyleToggle from "./ThemeStyleToggle";
import TenantSettingsGroup from "./TenantSettingsGroup";
import useAppSpaceVersion from "../../../hooks/useAppSpaceVersion";
import AccessibilityAssistantToggle from "./AccessibilityAssistantToggle";
import AccessibilityAssistantIntroDialog from "../../dialogs/AccessibilityAssistantIntroDialog";
import { useId } from "@fluentui/react-hooks";

const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const PortalSettingsPane = () => {
  const isPanelOpen = useSelector((state: RootState) => state.portal.portalSettingsPaneOpen);
  const [customThemeText, setCustomThemeText] = useState("");
  const version = useAppSpaceVersion();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const tooltipId = useId("tooltip");

  const introDialogRef = useRef<null | { setDialogOpen: (open: boolean) => void }>(null);

  const downloadDebugInfo = async () => {
    try {
      await window.portalInterface.downloadDebugInfo();
    } catch (e: any) {
      console.log(e);
    }
  };

  const ToolTipElement = (
    <div style={{ padding: "1em 1em" }}>
      <Text>
        {t("dialogs.technicalDetails.technicalDetails_list_info_part_1")}{" "}
        <span style={{ fontWeight: "bold" }}>{t("dialogs.technicalDetails.technicalDetails_list_info_part_2")}</span>{" "}
        {t("dialogs.technicalDetails.technicalDetails_list_info_part_3")}
      </Text>
      <ul>
        <li>{t("dialogs.technicalDetails.technicalDetails_list_item_one")}</li>
        <li>{t("dialogs.technicalDetails.technicalDetails_list_item_two")}</li>
        <li>{t("dialogs.technicalDetails.technicalDetails_list_item_three")}</li>
        <li>{t("dialogs.technicalDetails.technicalDetails_list_item_four")}</li>
        <li>{t("dialogs.technicalDetails.technicalDetails_list_item_five")}</li>
      </ul>
    </div>
  );

  return (
    <>
      <AccessibilityAssistantIntroDialog ref={introDialogRef} />
      <Panel
        isOpen={isPanelOpen}
        onDismiss={() => dispatch(setPortalSettingsPaneOpen(false))}
        isLightDismiss
        closeButtonAriaLabel={t("buttons.close")}
        headerText={t("menu.settings")}
        styles={panelStyles}
      >
        <div className={classNames.contentWrapper}>
          <ThemeColorPicker />
          <ThemeStyleToggle />
          <TextField
            label="Custom Theme (JSON)"
            multiline
            value={customThemeText}
            onChange={(e, newValue) => newValue !== undefined && setCustomThemeText(newValue)}
            onBlur={() => dispatch(setCustomTheme(customThemeText))}
            styles={{ root: { marginBottom: 8, display: "none" } }}
          />
          <TenantSettingsGroup />
          <AccessibilityAssistantToggle setDialogOpen={introDialogRef?.current?.setDialogOpen} />
          <div style={{ marginTop: "1em" }}>
            <Text variant="xLarge">Hilfe</Text>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>{t("dialogs.technicalDetails.technicalDetails_title")}</Label>
              <TooltipHost
                content={ToolTipElement}
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <IconButton iconProps={{ iconName: "Info" }} />
              </TooltipHost>
            </div>
            <CommandBarButton
              text={t("dialogs.technicalDetails.technicalDetails_download")}
              iconProps={{ iconName: "Download" }}
              className={classNames.commandBarButton}
              onClick={() => downloadDebugInfo()}
            />
          </div>
        </div>
        {version?.productVersion && (
          <div>
            <Label>Version</Label>
            <Text>{`${version.productVersion}`}</Text>
          </div>
        )}
      </Panel>
    </>
  );
};

export default PortalSettingsPane;

const panelStyles = {
  scrollableContent: { display: "flex", flexDirection: "column", height: "100%" },
  content: {
    flex: "1 1 auto",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
};

const classNames = mergeStyleSets({
  contentWrapper: { flex: "1 1 auto" },

  commandBarButton: {
    padding: "5px 1px 5px 1px",
    "::hover": {
      background: "transparent",
    },
  },
});
