import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { concatStyleSets, Icon, IContextualMenuItem, Text, useTheme } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { changeUserProfile, setPluginIcons, setShowProfileDropdown } from "../../store/slices/portalSlice";
import { RootState, useAppDispatch } from "../../store/store";
import { UserProfileDto } from "../../types/commonTypes";
import { userService } from "../authorization/userService";
import FilterableDropdown from "./FilterableDropdown";
import FilterableDropdownItem from "./FilterableDropdownItem";
import { tenantService } from "../authorization/tenantService";

const ProfilePickerDropdown = () => {
  const dispatch = useAppDispatch();
  const visible = useSelector((state: RootState) => state.portal.showProfileDropdown);
  const setVisible = (value: boolean) => dispatch(setShowProfileDropdown(value));

  const profileName = useSelector((state: RootState) => state.portal.profile?.profileName ?? "");
  const [profiles, setProfiles] = useState<UserProfileDto[]>([]);

  const getProfiles = async () => {
    const response = await userService.getProfiles();
    setProfiles(response?.profiles ?? []);
  };

  useEffect(() => {
    if (visible || profiles.length === 0) {
      getProfiles();
    }
  }, [visible]);

  const { semanticColors } = useTheme();
  const [t] = useTranslation();

  const dropdownTitle = (
    <>
      <Text variant="large" styles={titleTextStyles}>
        {t("dialogs.profilePicker.changeProfile")}
      </Text>
      <Text
        variant="large"
        styles={concatStyleSets(titleTextStyles, { root: { color: semanticColors.disabledBodySubtext } })}
      >
        {" (Alt + P)"}
      </Text>
    </>
  );

  const items = profiles.map((p) => ({
    key: p.profileId as any,
    text: p.code,
    secondaryText: p.description,
    checked: p.isDefault,
    onRender: renderProfileItem,
    refreshData: getProfiles,
  }));

  return (
    <FilterableDropdown
      buttonText={profileName}
      dropdownTitle={dropdownTitle}
      items={items}
      visible={visible}
      setVisible={setVisible}
      allowDropdown={profiles.length > 1}
      buttonTextVariant="medium"
      debugId={"ChangeProfile"}
    />
  );
};

export default ProfilePickerDropdown;

const titleTextStyles = { root: { fontWeight: "500", userSelect: "none" } };

const renderProfileItem = (item: IContextualMenuItem, dismissMenu: () => void) => {
  return <ProfileItem item={item} dismissMenu={dismissMenu} />;
};

const ProfileItem = (props: { item: IContextualMenuItem; dismissMenu: () => void }) => {
  const { item, dismissMenu } = props;
  const [t] = useTranslation();

  const dispatch = useAppDispatch();
  const onChangeProfile = async () => {
    await dispatch(changeUserProfile(item.key as any));

    dismissMenu();

    const pluginIconsResponse = await tenantService.getPluginIcons();
    dispatch(setPluginIcons(pluginIconsResponse.pluginIcons));
  };

  const mainInfoComponent = (
    <>
      <Text variant="mediumPlus" block styles={profileCaptionTextStyles}>
        {item.text}
      </Text>
      <Text variant="smallPlus" block>
        {item.secondaryText}
      </Text>
    </>
  );

  const secondaryIconProps = {
    iconName: item.checked ? "HomeSolid" : "Home",
    title: t("dialogs.profilePicker.setAsDefaultProfile"),
  };
  const secondaryIconHandleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!item.checked) {
      await userService.setFavoriteProfile(item.key as any);
      item.refreshData();
    }
  };

  return (
    <FilterableDropdownItem
      mainInfoComponent={mainInfoComponent}
      iconComponent={iconComponent}
      onClick={onChangeProfile}
      secondaryButtonIconProps={secondaryIconProps}
      secondaryButtonOnClick={secondaryIconHandleClick}
      debugId={item.text}
    />
  );
};

const profileCaptionTextStyles = { root: { fontWeight: "500" } };

const profileIconStyles = { root: { height: 32, width: 32, fontSize: 32, display: "block" } };
const iconComponent = <Icon iconName="AccountManagement" styles={profileIconStyles} />;
