import React from "react";

import { StyleSheet } from "../../reactnative";


import { ElementWrapper } from "../elements/ElementWrapper";

import { Label } from "./";
import * as Constants from "../../utils/constants";


// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const TextBlock = (props) => {
  const payload = props.json;

  if (payload.isVisible === false) {
    return null;
  }

  return (
    <ElementWrapper json={payload} style={styles.textContainer} isFirst={props.isFirst}>
      <Label
        text={payload.text}
        size={payload.size}
        weight={payload.weight}
        color={payload.color}
        isSubtle={payload.isSubtle}
        fontStyle={payload.fontStyle}
        wrap={payload.wrap}
        align={payload.horizontalAlignment}
        maxLines={payload.maxLines}
        style={styles.text}
        containerStyle={props.containerStyle}
      />
    </ElementWrapper>
  );
};

const styles = StyleSheet.create({
  textContainer: {
    width: Constants.FullWidth,
    alignItems: Constants.CenterString,
    backgroundColor: "transparent",
  },
  text: {
    width: Constants.FullWidth,
  },
});
