import React, { useState } from "react";
import { Text, getTheme, mergeStyleSets, FontWeights, Modal, IIconProps, TextField, Icon } from "@fluentui/react";
import { PrimaryButton, IconButton, IButtonStyles } from "@fluentui/react/lib/Button";
import { authenticationService } from "../authenticationService";
import AuthCode from "../../admin/AuthCode";

export const TwoFactorAuthentification = (props: any) => {
  const [code, setCode] = useState<string | undefined>("");
  const [error, setError] = useState<string | undefined>(undefined);

  const tfaAuth = (c: string | undefined) => {
    authenticationService.loginTfa(c ?? "", props.tfa?.id ?? "").then(
      () => {
        props.onLogin();
      },
      (error) => {
        setError(error);
      }
    );
  };

  return (
    <div>
      <Modal isOpen={true} onDismiss={props.hideModal} isBlocking={true} containerClassName={contentStyles.container}>
        <div className={contentStyles.header}>
          <Icon iconName="AuthenticatorApp" />
          <span style={{ marginLeft: 20 }}>Authentifizierung</span>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <p>Bitte geben Sie den Sicherheitscode aus Ihrer {props.tfa.caption} ein.</p>
          <AuthCode
            length={props.tfa.codelength}
            onChange={(s) => setCode(s)}
            onEnter={(s) => tfaAuth(s)}
            containerClassName={contentStyles.authContainer}
            inputClassName={contentStyles.input}
          />
        </div>
        {error && (
          <div className={contentStyles.errorWrapper}>
            <Icon iconName="StatusErrorFull" styles={{ root: { paddingRight: 4 } }} />
            <Text variant="small" styles={{ root: { color: "white" } }}>
              {error}
            </Text>
          </div>
        )}

        <div className={contentStyles.footer}>
          <PrimaryButton text="Authentifizieren" onClick={() => tfaAuth(code)}></PrimaryButton>
        </div>
      </Modal>
    </div>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    width: 380,
    backgroundColor: "#00061E",
  },
  errorWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0px 24px",
    color: "white",
  },
  authContainer: {
    textAlign: "center",
  },
  input: {
    width: 30,
    margin: 5,
    height: 40,
    fontSize: "20pt",
    textAlign: "center",
    border: "none",
    color: "white",
    backgroundColor: "#252939",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: "white",
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    color: "white",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  footer: {
    flex: "1 1 auto",
    float: "right",
    padding: "12px 24px 14px 24px",
  },
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
    color: "white",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
