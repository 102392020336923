import React, { useCallback, useEffect } from "react";
import PluginBar from "./PluginBar";
import { IPanelStyles, Panel, PanelType } from "@fluentui/react";

type MobilePluginBarProperties = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

const MobilePluginBar = (props: MobilePluginBarProperties) => {
  const { isOpen, setIsOpen } = props;

  const onPopState = useCallback(
    (event) => {
      if (event.state === "navpanelclosed") {
        window.history.back();
        closePanel();
      }
    },
    [closePanel]
  );

  useEffect(() => {
    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  const panelStyles: Partial<IPanelStyles> = {
    main: { width: "100%", "@media (min-width: 480px)": { width: 340 } },
    scrollableContent: { flex: "1 1 auto", display: "flex", flexDirection: "column", overflow: "hidden" },
    content: { flex: "1 1 auto", display: "flex", flexDirection: "column", padding: 0, overflow: "hidden" },
    commands: { padding: 0 },
  };

  function closePanel() {
    setIsOpen(false);
    if (window.history.state === "navpanelopen") window.history.go(-2);
  }

  function onOpenPanel() {
    window.history.pushState("navpanelclosed", "", null);
    window.history.pushState("navpanelopen", "", null);
  }

  return (
    <Panel
      isOpen={isOpen}
      onOpen={onOpenPanel}
      onDismiss={closePanel}
      styles={panelStyles}
      isLightDismiss
      hasCloseButton={false}
      allowTouchBodyScroll
      type={PanelType.smallFixedNear}
    >
      <PluginBar mobile parentDismiss={closePanel} />
    </Panel>
  );
};

export default MobilePluginBar;
