import React, { useState, useEffect } from "react";

import { Registry } from "./components/registration/registry";
import { InputContext } from "./utils/context";
import { StyleManager } from "./styles/style-config";

import { ActionWrapper } from "./components/actions/ActionWrapper";
import { HostConfigManager } from "./utils/host-config";

import { ContainerWrapper } from "./components/containers";

import { SelectAction } from "./components/actions";
import { ModelFactory } from "./models";

import { StyleSheet, Text } from "./reactnative";
import ResourceInformation from "./utils/resource-information";
import { ThemeConfigManager } from "./utils/theme-config";
import { useTheme, mergeStyles } from "@fluentui/react";
import * as Utils from "./utils/util";
const version = "2.0";
type Props = {
  payload: any;
  hostConfig?: any;
  themeConfig?: any;
  onExecuteAction?: (...args: any[]) => any;
  onParseError?: (...args: any[]) => any;
  contentHeight?: number;
  containerStyle?: any;
};
const AdaptiveCard = (props: Props) => {
  const [showErrors, setShowErrors] = useState(false);
  const [payload, setPayload] = useState(props.payload);
  const [cardModel, setCardModel] = useState(undefined);
  const inputArray = {};
  const resourceInformationArray: any = [];
  // commonly used styles

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const styleConfig = StyleManager.getManager().styles;
  useEffect(() => {
    // hostConfig
    if (props.hostConfig) {
      HostConfigManager.setHostConfig(props.hostConfig);
    }
    // themeConfig
    if (props.themeConfig) {
      ThemeConfigManager.setThemeConfig(props.themeConfig);
    }
    if ((props as any).isActionShowCard) {
      setCardModel(props.payload);
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      setCardModel(ModelFactory.createElement(props.payload));
    }
  }, [props.hostConfig, props.themeConfig, props.payload]);

  const { semanticColors } = useTheme();
  const cardStyleClass = mergeStyles({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    flex: "0 0 auto",
    padding: 10,
    margin: 0,
    backgroundColor: semanticColors.bodyBackground,
    border: `1px solid ${semanticColors.bodyBackground}`,
    position: "relative",
  });
  const styles = StyleSheet.create({
    container: {
      padding: 10,
      backgroundColor: semanticColors.bodyBackground,
    },
    actionContainer: {
      marginVertical: 10,
    },
    backgroundImage: {
      width: "100%",
    },
  });
  if (!cardModel) return null;
  const toggleVisibilityForElementWithID = (idArray: any) => {
    toggleCardModelObject(cardModel, [...idArray]);
    setCardModel(cardModel);
  };
  const checkTargetElementsForID = (object: any, targetElements: any) => {
    targetElements.forEach((target: any) => {
      if (target instanceof String || typeof target === "string") {
        if (target == object["id"]) {
          object.isVisible = !object.isVisible;
          var index = targetElements.indexOf(object["id"]);
          if (index !== -1) targetElements.splice(index, 1);
          return;
        }
      } else if ((target instanceof Object || typeof target === "object") && target !== null) {
        if (target["elementId"] === object["id"]) {
          if (!Utils.isNullOrEmpty(target["isVisible"])) {
            object.isVisible = target["isVisible"];
          } else {
            object.isVisible = !object.isVisible;
          }
          var index = targetElements.indexOf(target);
          if (index !== -1) targetElements.splice(index, 1);
          return;
        }
      }
    });
  };
  const toggleCardModelObject = (object: any, idArrayValue: any) => {
    if (idArrayValue.length === 0) return;
    if (object.hasOwnProperty("id")) {
      checkTargetElementsForID(object, idArrayValue);
      if (idArrayValue.length === 0) return;
    }
    if (object.children !== undefined && object.children.length !== 0) {
      object.children.forEach((element: any) => {
        if (idArrayValue.length === 0) return;
        toggleCardModelObject(element, idArrayValue);
      });
    }
    //Adaptive cards has actions array in addition to the body which is added as children
    if (object.type === "AdaptiveCard") {
      if (object.actions !== undefined && object.actions.length !== 0) {
        object.actions.forEach((element: any) => {
          if (idArrayValue.length === 0) return;
          toggleCardModelObject(element, idArrayValue);
        });
      }
    }
    return;
  };
  const getResourceInformation = () => {
    return resourceInformationArray;
  };
  const addInputItem = (key: any, value: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    inputArray[key] = value;
  };
  const addResourceInformation = (urlString: any, mimeTypeString: any) => {
    let newResourceObject = new ResourceInformation(urlString, mimeTypeString);
    resourceInformationArray.push(newResourceObject);
  };
  const parsePayload = () => {
    let children: any = [];

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (cardModel.children.length === 0) return children;

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    children = Registry.getManager().parseRegistryComponents(cardModel.children, onParseError);

    return children.map((ChildElement: any, index: any) =>
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      React.cloneElement(ChildElement, { containerStyle: cardModel.style, isFirst: index === 0 })
    );
  };
  const getAdaptiveCardContent = () => {
    let containerStyles = [styles.container];
    //If containerStyle is passed by the user from adaptive card via props, we will override this style
    props.containerStyle && containerStyles.push(props.containerStyle);
    //if this.state.cardModel.minHeight is undefined or null, then minheight value will be null. So it will automatically handled the undefined and null cases also...

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const minHeight = Utils.convertStringToNumber(cardModel.minHeight);
    //We will pass the style as array, since it can be updated in the container wrapper if required.
    typeof minHeight === "number" && containerStyles.push({ minHeight });
    //If contentHeight is passed by the user from adaptive card via props, we will set this as height
    props.contentHeight && containerStyles.push({ height: props.contentHeight });
    var adaptiveCardContent = (
      <ContainerWrapper style={containerStyles} json={cardModel}>
        {parsePayload()}

        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {!Utils.isNullOrEmpty(cardModel.actions) && <ActionWrapper actions={cardModel.actions} />}
      </ContainerWrapper>
    );
    // checks if selectAction option is available for adaptive card
    if (!Utils.isNullOrEmpty(payload.selectAction)) {
      adaptiveCardContent = (
        <SelectAction style={styles.container} selectActionData={payload.selectAction}>
          {adaptiveCardContent}
        </SelectAction>
      );
    }
    return adaptiveCardContent;
  };
  const isSupportedVersion = () => {
    //Ignore the schema version number when AdaptiveCard is used from Action.ShowCard as it is not mandatory
    if ((props as any).isActionShowCard) {
      return true;
    }
    if (!payload.version) return false;
    const payloadVersion = Utils.parseVersion(payload.version);
    const clientVersion = Utils.parseVersion(version);
    if (clientVersion.major != payloadVersion.major) {
      return payloadVersion.major < clientVersion.major;
    } else if (clientVersion.minor != payloadVersion.minor) {
      return payloadVersion.minor < clientVersion.minor;
    } else {
      return true;
    }
  };
  const onParseError = (error: any) => {
    if (props.onParseError) {
      props.onParseError(error);
    }
  };
  // Invoke onExecuteAction if the consumer app provide it via props.
  const onExecuteAction = (action: any, ignoreInputValidation: any) => {
    if (!ignoreInputValidation && !validateInputs()) setShowErrors(true);
    else if (props.onExecuteAction) {
      props.onExecuteAction(action);
    }
  };
  const validateInputs = () => {
    if (inputArray) {
      for (const key in inputArray) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (inputArray[key].errorState) return false;
      }
    }
    return true;
  };
  //return <div>AdaptiveCard</div>
  const isTransparent = payload.backgroundImage ? true : false;
  const lang = payload.lang || "en";
  // version check
  if (!isSupportedVersion()) {
    const message = payload.fallbackText || "We're sorry, this card couldn't be displayed";
    return <Text style={styleConfig.defaultFontConfig}>{message}</Text>;
  }
  return (
    <InputContext.Provider
      value={{
        lang,
        addInputItem,
        inputArray,
        onExecuteAction,
        isTransparent,
        onParseError,
        addResourceInformation,
        showErrors,
        toggleVisibilityForElementWithID,
      }}
    >
      <div className={cardStyleClass}>{getAdaptiveCardContent()}</div>
    </InputContext.Provider>
  );
};
export default AdaptiveCard;
